import {useState, useEffect} from 'react';
import {WBButton} from '../../../UIComponents/WBComponents';
import styles from './SearchUtilities.module.css';
import {useCustomService} from './useCustomService';
import {useJobCard} from '../../context/JobCardContext';

const SearchUtilities = () => {
  const {iconTrash, searchUtilities, utilityContainer, title, header, content, buttonWrapper} =
    styles;
  const [searchKeyword, setSearchKeyword] = useState('');
  const {selectedUtilities, addUtility, removeUtility} = useJobCard();
  const {
    utilities,
    loading: utilitiesLoading,
    error: utilitiesError,
    searchUtilities: searchUtilitiesAPI,
  } = useCustomService();

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchKeyword.trim()) {
        searchUtilitiesAPI(searchKeyword);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchKeyword, searchUtilitiesAPI]);

  return (
    <div className={searchUtilities}>
      <input
        type="text"
        value={searchKeyword}
        onChange={e => setSearchKeyword(e.target.value)}
        placeholder="Search utilities..."
      />
      {(utilities.length > 0 || utilitiesLoading || utilitiesError) && (
        <div className={utilityContainer}>
          {utilities.length > 0 && (
            <div className={header}>
              <span>Utility</span>
              <span>Cost ($)</span>
              <span></span>
            </div>
          )}
          <div className={content}>
            {utilitiesLoading ? (
              <div>Loading...</div>
            ) : utilitiesError ? (
              <div>{utilitiesError}</div>
            ) : utilities.length > 0 ? (
              utilities.map(utility => (
                <div key={utility.id}>
                  <span>{utility.title}</span>
                  <span>{parseFloat(utility.cost).toFixed(2)}</span>
                  <div className={buttonWrapper}>
                    <WBButton
                      title="Add"
                      color="grey"
                      height="34px"
                      styles={{padding: '8px 24px'}}
                      func={() => addUtility(utility)}
                    />
                  </div>
                </div>
              ))
            ) : searchKeyword.trim() ? (
              <div>No results found</div>
            ) : null}
          </div>
        </div>
      )}
      <span className={title}>
        {selectedUtilities.length > 0 ? 'Selected Utilities' : 'No Utilities Selected'}
      </span>
      {selectedUtilities.length > 0 && (
        <div className={utilityContainer}>
          <div className={header}>
            <span>Utility</span>
            <span>Cost ($)</span>
            <span></span>
          </div>
          <div className={content}>
            {selectedUtilities.map(utility => (
              <div key={utility.id}>
                <span>{utility.title}</span>
                <span>{parseFloat(utility.cost).toFixed(2)}</span>
                <div className={buttonWrapper}>
                  <WBButton
                    icon={<i className={iconTrash}></i>}
                    title="Remove"
                    color="grey"
                    height="34px"
                    styles={{padding: '8px 24px'}}
                    func={() => removeUtility(utility.id)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchUtilities;
