// import moneySendSVG from "../../assets/money-send.svg"
// import moneyReceiveSVG from "../../assets/money-receive.svg"
// import briefcaseSVG from "../../assets/briefcase.svg"
// import medalStarSVG from "../../assets/medalstar.svg"
import styles from "../card.module.css"
import Card from "./Card"

const CardInfos = () => {
  return (
    <>
      <article className={styles.cardInformation}>
        <Card
          src="/images/client/money-send.svg"
          alt="money_sent"
          description="Money Spent"
          number="$15,650.20"
        />
        <Card
          src="/images/client/money-receive.svg"
          alt="outstanding_amount"
          description="Outstanding Amount"
          number="$300.20"
        />
        <Card
          src="/images/client/briefcase.svg"
          alt="total_jobs"
          description="Total Jobs"
          number="11"
        />
        <Card
          src="/images/client/medalstar.svg"
          alt="customer_lifetime"
          description="Customer Lifetime"
          number="10 Years"
        />
      </article>
    </>
  )
}

export default CardInfos
