import styles from './ChecklistNotes.module.css';

const ChecklistNotes = () => {
  const {container, title, content} = styles;

  return (
    <div className={container}>
      <h3 className={title}>Checklist Notes:</h3>
      <div className={content}>
        <p>No Notes</p>
      </div>
    </div>
  );
};

export default ChecklistNotes;
