import {useState, useEffect} from 'react';
import {PostDB} from '../../../../DBTools';

export const useJobDetailsData = () => {
  const [data, setData] = useState({
    contactsRelationshipList: [],
    industriesList: [],
    categoryTypesList: [],
    jobPriorityList: [],
    serviceTypesList: [],
    callOutFeesList: [],
    specialBookingsList: [],
    jobTypesList: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (branch, method = 'POST') => {
    try {
      return await PostDB({
        branch,
        json: {
          method,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      });
    } catch (error) {
      console.error(`Error fetching ${branch}:`, error);
      return [];
    }
  };

  const fetchAndSetData = async (endpoint, key, method = 'POST') => {
    try {
      const response = await fetchData(endpoint, method);
      if (Array.isArray(response)) {
        setData(prevData => ({
          ...prevData,
          [key]: response,
        }));
      }
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([
          fetchAndSetData('/settings/contacts_relationship', 'contactsRelationshipList'),
          fetchAndSetData('/settings/industries', 'industriesList'),
          fetchAndSetData('/settings/job_types', 'jobTypesList'),
          fetchAndSetData('/getJobCategories', 'categoryTypesList'),
          fetchAndSetData('/settings/job_priority', 'jobPriorityList'),
          fetchAndSetData('/settings/service_types', 'serviceTypesList'),
          fetchAndSetData('/settings/callout_fees', 'callOutFeesList'),
          fetchAndSetData('/getJobSpecialBookings', 'specialBookingsList'),
        ]);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      console.log('🔄 Final Fetched Data:', data);
    }
  }, [data, isLoading]);

  return {
    data,
    isLoading,
    error,
  };
};
