import styles from './ComplaintsModal.module.css';
import {WBButton} from '../../../UIComponents/WBComponents';
import {WBDropdown, WBDropdownOption} from '../common/WBDropdown';
import {useState} from 'react';
import avatar from '../assets/loggedByAvatar.svg';

const ComplaintsModal = ({isOpen, onClose}) => {
  const [defaultDescriptionValue, setDefaultDescriptionValue] = useState(``);
  const [selectedTab, setSelectedTab] = useState('lodgment');
  const [surveyAnswers, setSurveyAnswers] = useState({
    paidBill: '-- Select --',
    reviewedProblem: '-- Select --',
    quotedPrice: '-- Select --',
    signedIpad: '-- Select --',
    happyWithWork: '-- Select --',
  });
  const [resolutionData, setResolutionData] = useState({
    type: '-- Select Type --',
    department: '-- Select Department --',
    technician: '-- Select Technician --',
  });
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [notes, setNotes] = useState([
    {
      timestamp: 'April 15, 2024, 2:54 pm',
      author: 'Samantha',
      content:
        'Waverley called to advise that the card she was going to use for the call out fee, she wont be able to use on the day so asked if we can invoice the call out. advised her we cant do that. she asked if she can pay it over the phone on the day, i advised thats doable. shes gonna go ahead with that.',
    },
  ]);
  const [newNoteContent, setNewNoteContent] = useState('');
  const [isLodgmentCompleted, setIsLodgmentCompleted] = useState(false);
  const [isResolutionCompleted, setIsResolutionCompleted] = useState(false);
  if (!isOpen) return null;

  const {
    // icons
    iconClose,
    iconDownArrow,
    iconCheck,
    iconEdit,
    iconWaiting,
    iconNote,
    iconFile,
    iconImage,

    // Modal container
    modalOverlay,
    modalContainer,

    // Header section
    modalHeader,
    headerTitle,
    headerTabs,
    selectedTab: selectedTabClass,
    tabContent,
    tabTextContainer,
    completedLabel,
    tabItem,
    tabNumber,
    closeButton,

    // Sub-header section
    subHeader,
    identitySection,
    identityItem,
    identityLabel,
    identityValue,
    complaintValue,
    statusSection,

    // Main content
    modalBody,
    contentContainer,

    leftContent,
    description,
    descriptionTitle,
    descriptionTextArea,
    loggedSource,
    loggedSourceTitle,
    loggedBySection,
    loggedByAvatar,
    loggedByValue,
    surveyQuestions,
    questionsGrid,
    questionItem,
    questionText,
    resolutionInput,

    // left content (resolution)
    resolutionContainer,
    resolutionRow,
    inputGroup,
    inputLabel,
    refundInput,

    rightContent,
    sectionTitle,
    notesList,
    noteItem,
    noteHeader,
    noteTimestamp,
    notePostedBy,
    noteContent,
    addNoteSection,
    noteContainer,
    noteInput,
    noteActions,
    noteFooter,
    verticalLine,

    // Footer
    modalFooter,
    footerLeft,
    footerRight,
  } = styles;

  // Survey questions data
  const surveyQuestionsData = [
    {
      id: 'paidBill',
      question: 'Are you the person who paid this bill?',
    },
    {
      id: 'reviewedProblem',
      question: 'Did our technician review the problem with you?',
    },
    {
      id: 'quotedPrice',
      question: 'Did our technician quote you the exact price prior to doing the work?',
    },
    {
      id: 'signedIpad',
      question:
        'Did our technician have you sign on the iPad to approve the work prior to starting?',
    },
    {
      id: 'happyWithWork',
      question: 'Were you happy with the work performed?',
    },
  ];

  const renderDropdown = questionId => (
    <WBDropdown
      leftitem={surveyAnswers[questionId]}
      rightitem={<i className={iconDownArrow}></i>}
      height="50px"
      borderRadius="10px"
      style={{minWidth: '140px'}}
      background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
    >
      <WBDropdownOption title="Yes" func={() => handleAnswerChange(questionId, 'Yes')} />
      <WBDropdownOption title="No" func={() => handleAnswerChange(questionId, 'No')} />
    </WBDropdown>
  );

  const handleAnswerChange = (questionId, value) => {
    setSurveyAnswers(prev => ({
      ...prev,
      [questionId]: value,
    }));
  };

  const handlePublishNote = () => {
    if (!newNoteContent.trim()) return;

    const newNote = {
      timestamp: new Date()
        .toLocaleString('en-AU', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        })
        .replace(' at', ','),
      author: 'Samantha',
      content: newNoteContent.trim(),
    };

    setNotes(prevNotes => [...prevNotes, newNote]);
    setNewNoteContent('');
    setIsAddingNote(false);
  };

  const handleCreateComplaint = () => {
    setIsLodgmentCompleted(true);
    setSelectedTab('resolution');
  };

  const handleResolveComplaint = () => {
    setIsResolutionCompleted(true);
  };

  const handleResolutionChange = (field, value) => {
    setResolutionData(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className={modalOverlay}>
      <div className={modalContainer}>
        {/* Header */}
        <div className={modalHeader}>
          <h2 className={headerTitle}>Complaint</h2>
          <div className={headerTabs}>
            <button
              className={`${tabItem} ${selectedTab === 'lodgment' ? selectedTabClass : ''}`}
              onClick={() => setSelectedTab('lodgment')}
            >
              <div className={tabContent}>
                <span className={tabNumber}>1</span>
                <div className={tabTextContainer}>
                  <span>Lodgment</span>
                  <span className={completedLabel}>{isLodgmentCompleted ? 'Completed' : ''}</span>
                </div>
              </div>
            </button>
            <button
              className={`${tabItem} ${selectedTab === 'resolution' ? selectedTabClass : ''}`}
              onClick={() => setSelectedTab('resolution')}
            >
              <div className={tabContent}>
                <span className={tabNumber}>2</span>
                <div className={tabTextContainer}>
                  <span>Resolution</span>
                  <span className={completedLabel}>{isResolutionCompleted ? 'Completed' : ''}</span>
                </div>
              </div>
            </button>
          </div>
          <button onClick={onClose} className={closeButton}>
            <i className={iconClose}></i>
          </button>
        </div>

        {/* Sub-header with identity and status */}
        <div className={subHeader}>
          <div className={identitySection}>
            <div className={identityItem}>
              <span className={identityLabel}>Name</span>
              <span className={identityValue}>Leslie Alexander</span>
            </div>
            <div className={identityItem}>
              <span className={identityLabel}>Job #</span>
              <span className={identityValue}>595862</span>
            </div>
          </div>
          <div className={statusSection}>
            {isResolutionCompleted && (
              <div className={loggedBySection}>
                <span className="bold">Logged By</span>{' '}
                <img src={avatar} alt="avatar" className={loggedByAvatar} />
                <span className={loggedByValue}>Mike T</span>
              </div>
            )}
            <div className={identityLabel}>Complaint Status</div>
            <div
              className={complaintValue}
              style={{color: isResolutionCompleted ? '#44C989' : '#F87700'}}
            >
              {isResolutionCompleted ? (
                <>
                  <i className={iconCheck}></i>
                  Resolved
                </>
              ) : (
                <>
                  <i className={iconWaiting}></i>
                  Waiting Client Response
                </>
              )}
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className={modalBody}>
          <div className={contentContainer}>
            <div className={leftContent}>
              {selectedTab === 'lodgment' ? (
                <>
                  {/* description section */}
                  <div
                    style={{
                      background: '#ffffff',
                      padding: '36px',
                      borderRadius: '10px',
                      marginBottom: '24px',
                    }}
                  >
                    <h3 className={sectionTitle}>Complaint Description</h3>
                    {/* description */}
                    <div className={description}>
                      <h4 className={descriptionTitle}>Description</h4>
                      <textarea
                        className={descriptionTextArea}
                        defaultValue={defaultDescriptionValue}
                        placeholder="Enter complaint description..."
                      />
                    </div>
                    {/* logged source */}
                    <div className={loggedSource}>
                      <h4 className={loggedSourceTitle}>Logged Source</h4>
                      <WBDropdown
                        leftitem={'Email'}
                        rightitem={<i className={iconDownArrow}></i>}
                        height="50px"
                        width="100%"
                        borderRadius="10px"
                        rightItemPadding="16px 24px 16px 0"
                        leftItemPadding="16px 0 16px 24px"
                        background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
                        style={{border: 'none'}}
                      >
                        <WBDropdownOption
                          title="Email"
                          divStyle={{
                            borderBottom: '1px solid #F3FAFF',
                            padding: '16px 16px 16px 16px',
                          }}
                        />
                      </WBDropdown>
                    </div>
                  </div>

                  {/* survey questions section */}
                  <div style={{background: '#ffffff', padding: '36px', borderRadius: '10px'}}>
                    <div className={surveyQuestions}>
                      <h3 className={sectionTitle}>Survey Questions</h3>
                      <div className={questionsGrid}>
                        {surveyQuestionsData.map(({id, question}) => (
                          <div key={id} className={questionItem}>
                            <span className={questionText}>{question}</span>
                            {renderDropdown(id)}
                          </div>
                        ))}

                        {/* Separate row for reason input when work satisfaction is "No" */}
                        {surveyAnswers.happyWithWork === 'No' && (
                          <div
                            className={questionItem}
                            style={{paddingBottom: '24px', borderBottom: '1px solid #E5E7EB'}}
                          >
                            <input
                              className={resolutionInput}
                              placeholder="Please Enter Reason Why You Not Happy Work That Been Performed"
                            />
                          </div>
                        )}

                        <div className={questionItem}>
                          <span className={questionText}>
                            What would you like out of this resolution?
                          </span>
                          <input
                            className={resolutionInput}
                            placeholder="Please Enter Answer Here"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                // resolution section
                <div className={resolutionContainer}>
                  <h3 className={sectionTitle}>Resolution</h3>

                  <div className={resolutionRow}>
                    <div className={inputGroup}>
                      <span className={inputLabel}>Type</span>
                      <WBDropdown
                        leftitem={resolutionData.type}
                        rightitem={<i className={iconDownArrow}></i>}
                        height="50px"
                        width="100%"
                        borderRadius="10px"
                        background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                      >
                        <WBDropdownOption
                          title="Test 1"
                          func={() => handleResolutionChange('type', 'Test 1')}
                        />
                        <WBDropdownOption
                          title="Test 2"
                          func={() => handleResolutionChange('type', 'Test 2')}
                        />
                      </WBDropdown>
                    </div>
                    <div className={inputGroup}>
                      <span className={inputLabel}>Department Responsible</span>
                      <WBDropdown
                        leftitem={resolutionData.department}
                        rightitem={<i className={iconDownArrow}></i>}
                        height="50px"
                        width="100%"
                        borderRadius="10px"
                        background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                      >
                        <WBDropdownOption
                          title="Test 1"
                          func={() => handleResolutionChange('department', 'Test 1')}
                        />
                        <WBDropdownOption
                          title="Test 2"
                          func={() => handleResolutionChange('department', 'Test 2')}
                        />
                      </WBDropdown>
                    </div>
                  </div>

                  <div className={inputGroup}>
                    <span className={inputLabel}>Technician Responsible</span>
                    <WBDropdown
                      leftitem={resolutionData.technician}
                      rightitem={<i className={iconDownArrow}></i>}
                      height="50px"
                      width="100%"
                      borderRadius="10px"
                      background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                    >
                      <WBDropdownOption
                        title="Test 1"
                        func={() => handleResolutionChange('technician', 'Test 1')}
                      />
                      <WBDropdownOption
                        title="Test 2"
                        func={() => handleResolutionChange('technician', 'Test 2')}
                      />
                    </WBDropdown>
                  </div>

                  <div className={inputGroup}>
                    <span className={inputLabel}>Refund Amount</span>
                    <input type="text" placeholder="Enter Amount" className={refundInput} />
                  </div>

                  <div className={inputGroup}>
                    <span className={inputLabel}>Description</span>
                    <textarea
                      className={descriptionTextArea}
                      placeholder="Please Enter The Outcome Of Resolution"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={rightContent}>
              <h3 className={sectionTitle}>Office Notes</h3>
              <div className={notesList}>
                {notes.map((note, index) => (
                  <div key={index} className={noteItem}>
                    <div className={noteHeader}>
                      <span className={noteTimestamp}>{note.timestamp}</span>
                      <span className={notePostedBy}>Posted by {note.author}</span>
                    </div>
                    <p className={noteContent}>{note.content}</p>
                    <div className={verticalLine}></div>
                  </div>
                ))}
              </div>

              {isAddingNote ? (
                <div className={addNoteSection}>
                  <h3>Add Note</h3>
                  <div className={noteContainer}>
                    <textarea
                      className={noteInput}
                      placeholder="Enter your note here..."
                      value={newNoteContent}
                      onChange={e => setNewNoteContent(e.target.value)}
                    />
                    <div className={noteActions}>
                      <WBButton
                        title="File"
                        icon={<i className={iconFile}></i>}
                        color="grey"
                        styles={{
                          borderRadius: '6px',
                          padding: '8px 20px',
                          fontSize: '14px',
                          fontFamily: 'robotoBold',
                          border: '1px solid #DFDFDF',
                        }}
                      />
                      <WBButton
                        title="Image"
                        icon={<i className={iconImage}></i>}
                        color="grey"
                        styles={{
                          borderRadius: '6px',
                          padding: '8px 20px',
                          fontSize: '14px',
                          fontFamily: 'robotoBold',
                          border: '1px solid #DFDFDF',
                        }}
                      />
                    </div>
                  </div>
                  <div className={noteFooter}>
                    <WBButton
                      title="Cancel"
                      color="grey"
                      styles={{padding: '0 24px', fontSize: '14px'}}
                      func={() => setIsAddingNote(false)}
                    />
                    <WBButton
                      title="Publish Note"
                      color="grey"
                      icon={<i className={iconCheck}></i>}
                      styles={{padding: '0 24px', fontSize: '14px'}}
                      func={handlePublishNote}
                    />
                  </div>
                </div>
              ) : (
                <WBButton
                  title="Add Note"
                  color="grey"
                  icon={<i className={iconNote}></i>}
                  styles={{padding: '24px'}}
                  func={() => setIsAddingNote(true)}
                />
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className={modalFooter}>
          <div className={footerLeft}>
            <WBButton title="Cancel" color="grey" styles={{padding: '0 24px'}} func={onClose} />
          </div>
          <div className={footerRight}>
            <WBButton title="View Log" color="grey" styles={{padding: '0 24px'}} func={onClose} />
            <WBButton
              title={
                isResolutionCompleted
                  ? 'Edit Complaint'
                  : selectedTab === 'resolution'
                  ? 'Resolve Complaint'
                  : 'Create Complaint'
              }
              color="grey"
              icon={
                isResolutionCompleted ? <i className={iconEdit}></i> : <i className={iconCheck}></i>
              }
              styles={{padding: '0 24px'}}
              func={selectedTab === 'resolution' ? handleResolveComplaint : handleCreateComplaint}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComplaintsModal;
