import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const SubFilterItems = ({ editFunc, subFilterSelected }) => {
    const deleteSubFilter = () => { };

    const [subFiltersFormatted, formatSubFilters] = useState(<></>);

    const subfilters = useWBTableData();

    useEffect(() => {
        if (subfilters !== null && subfilters !== undefined) {
            if (subfilters.filters.length > 0) {
                formatSubFilters(
                    subfilters.filters.map((filter) => (
                        <div key={filter.sub_filter_id} className="row" id={filter.sub_filter_id} onDoubleClick={() => { subFilterSelected(filter); editFunc(); }}>
                            {/* <div>{filter.filter_id}</div> */}
                            <div className="cell">{filter.sub_filter_name}</div>
                            <div className="cell">{filter.filter_name}</div>
                            {/* <div className="cell description">{filter.description}</div> */}
                            <div className="cell">{filter.active === 1 ? "Active" : "Not Active"}</div>
                            <div className="cell auto">
                                <WBDropdown leftitem={
                                    <div className="title">
                                        <div className="option">
                                            <span className="dot-icon"></span>
                                            <span className="dot-icon"></span>
                                            <span className="dot-icon"></span>
                                        </div>
                                    </div>
                                } align="end">
                                    <WBDropdownOption title="Edit" func={() => { subFilterSelected(filter); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteSubFilter(filter.sub_filter_id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatSubFilters(<div>No items found</div>);
            }
        }
        else {
            formatSubFilters(<div>No items found</div>);
        }
    }, [subfilters]);

    return (
        <div className="body" id="bodyData">{subFiltersFormatted}</div>
    )
}

export default SubFilterItems;