import styles from './SaleDetails.module.css';

const SaleDetails = () => {
  const {
    container,
    header,
    title,
    body,
    detailLabel,
    detailContent,
    saleNumber,
    tableHeader,
    tableRow,
    summary,
    summaryRow,
  } = styles;

  return (
    <div className={container}>
      <div className={header}>
        <h2 className={title}>Sale</h2>
      </div>
      <div className={body}>
        <div className={saleNumber}>
          <span># 3221542631</span> <span>Monday, March 18, 2024 at 07:48 AM</span>
        </div>
        <div className={tableHeader}>
          <span className={detailLabel}>Title</span>
          <span className={detailContent}>Price</span>
        </div>
        <div className={tableRow}>
          <span className={detailLabel}>CCTV CAMERA INSPECTION AND DRAIN LOCATOR</span>
          <span className={detailContent}>$602.40</span>
        </div>
        <div className={tableRow}>
          <span className={detailLabel}>STRAIGHT WASHER CHANGE WITH MACHINING</span>
          <span className={detailContent}>$227.02</span>
        </div>
        <div className={tableRow}>
          <span className={detailLabel}>Callout Fee</span>
          <span className={detailContent}>$49.00</span>
        </div>
        <div className={summary}>
          <div className={summaryRow}>
            <span className={detailLabel}>Discount</span>
            <span className={detailContent}>$0.00 CR</span>
          </div>
          <div className={summaryRow}>
            <span className={detailLabel}>Total Sale Amount</span>
            <span className={detailContent}>$2,341.86</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleDetails;
