import { useEffect, useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBDropdown, WBDropdownOption, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import Suppliers from "../../../../Data Source/Suppliers.json";

const AddMaterials = ({ closeModalMethod }) => {
    const [matTitle, setMatTitle] = useState();
    const [matCost, setMatCost] = useState();
    const [matDesc, setMatDesc] = useState();
    const [matSupplier, setMatSupplier] = useState();
    const [matSupplierName, setMatSupplierName] = useState();
    const [matStatus, setMatStatus] = useState();
    const [suppliers] = useState(Suppliers);
    const [suppliersFormatted, setSuppliersFormatted] = useState();

    const [changesMade, setChangesMade] = useState(false);

    const toggleMatStatus = () => setMatStatus(status => { let result = !status; alert("status changes: " + result); return result });

    const SaveMaterial = async () => {
        // 
        let bodyObj = {
            id: null,
            supplier_id: matSupplier,
            code: null,
            title: matTitle,
            description: matDesc,
            cost: matCost,
            active: matStatus
        }
        // let response = "Success";
        let response = await PostDB({
            branch: "/editMaterial", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Material has been successfully updated");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Material. Check your fields and try again");
        }
    }

    useEffect(() => {
        if (suppliers.length > 0) {
            setSuppliersFormatted(
                suppliers.map((supplier, i) =>
                    <WBDropdownOption key={i} title={supplier.name} func={() => { setMatSupplier(supplier.supplier_id); setMatSupplierName(supplier.name); console.log(supplier); }} />
                )
            )
        }
    }, [suppliers]);

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Material</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtActive" className="form-label">
                                        Filter Active?
                                    </label>
                                    <div className="p-10">
                                        <WBIOSToggle active={matStatus === 1 ? true : false} func={() => toggleMatStatus()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label className="form-label">
                                        Supplier
                                    </label>
                                    <WBDropdown leftitem={matSupplierName ? matSupplierName : "Select Supplier"} rightitem={<i className="icon-arrow-down"></i>} color={"white"} optionHidden={true} >
                                        {suppliersFormatted}
                                    </WBDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matName" className="form-label">
                                        Material Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="matName"
                                        className="half"
                                        id="matName"
                                        placeholder="enter material name"
                                        defaultValue={matTitle}
                                        onChange={(e) => { setChangesMade(true); setMatTitle(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matDesc" className="form-label">
                                        Material Description
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <textarea
                                        name="matDesc"
                                        className="half"
                                        id="matDesc"
                                        placeholder="enter material description"
                                        defaultValue={matDesc}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setMatDesc(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matCost" className="form-label">
                                        Material Cost
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="number"
                                        name="matCost"
                                        className="half"
                                        id="matCost"
                                        placeholder="enter material cost"
                                        defaultValue={matCost}
                                        onChange={(e) => { setChangesMade(true); setMatCost(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <WBButton title="Save" color={"green"} func={() => SaveMaterial()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddMaterials;