import UnderlineTabs from './common/UnderlineTabs';
import InvoiceContent from './InvoiceContent';
import QuotesContent from './QuotesTab/QuotesContent';
import ComplaintsContent from './ComplaintsTab/ComplaintsContent';
import JobMilestoneContent from './JobMilestoneTab/JobMilestoneContent';
import MaterialsContent from './MaterialsTab/MaterialsContent';
import styles from './InvoiceSection.module.css';
import {useJobCard} from '../context/JobCardContext';

const InvoiceSection = () => {
  const {iconBriefcase, invoiceSection, invoiceHeader, invoiceTable, divider} = styles;

  const {selectedTab, setSelectedTab} = useJobCard();

  const renderContent = () => {
    switch (selectedTab) {
      case 'Complaints':
        return <ComplaintsContent />;
      case 'Quotes':
        return <QuotesContent />;
      case 'Milestone':
        return <JobMilestoneContent />;
      case 'Materials':
        return <MaterialsContent />;
      default:
        return (
          <>
            <InvoiceContent />
            <div className={divider}></div>
            <InvoiceContent />
          </>
        );
    }
  };

  return (
    <div className={invoiceSection}>
      {/* header */}
      <div className={invoiceHeader}>
        <UnderlineTabs
          text={'Sales'}
          isSelected={selectedTab === 'Sales'}
          icon={iconBriefcase}
          badge={'1'}
          onClick={() => setSelectedTab('Sales')}
        />
        <UnderlineTabs
          text={'Quotes'}
          isSelected={selectedTab === 'Quotes'}
          badge={'6'}
          onClick={() => setSelectedTab('Quotes')}
        />
        <UnderlineTabs
          text={'Complaints'}
          isSelected={selectedTab === 'Complaints'}
          badge={'1'}
          onClick={() => setSelectedTab('Complaints')}
        />
        <UnderlineTabs
          text={'Job Milestone'}
          isSelected={selectedTab === 'Milestone'}
          onClick={() => setSelectedTab('Milestone')}
        />
        <UnderlineTabs
          text={'Materials'}
          isSelected={selectedTab === 'Materials'}
          badge={'2'}
          onClick={() => setSelectedTab('Materials')}
        />
      </div>
      <div className={invoiceTable}>{renderContent()}</div>
    </div>
  );
};

export default InvoiceSection;
