import styles from './reports.module.css';

const ReportItem = ({ title, hasRunButton, onRunReport }) => {
  if (hasRunButton) {
    return (
      <div className={styles.runReportTab}>
        <p>{title}</p>
        <div 
          className={styles.runReportButton}
          onClick={() => onRunReport(title)}
        >
          <img src="/images/icons/play.svg" alt="play" />
          <p>Run Report</p>
        </div>
      </div>
    );
  }
  return <p>{title}</p>;
};

const ReportColumn = ({ title, items, onRunReport }) => {
  return (
    <div className={styles.reportsColumn}>
      <h2 className={styles.reportColumnHeader}>{title}</h2>
      {items.map((item, index) => (
        <ReportItem 
          key={`${title}-${index}`}
          title={item.title}
          hasRunButton={item.hasRunButton}
          onRunReport={onRunReport}
        />
      ))}
    </div>
  );
};

export default ReportColumn;
