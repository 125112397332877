import styles from "../addJobModals.module.css";
const JobTypeButtons = ({ jobType, onClick }) => {
  return (
    <>
      <div className={styles.jobTypeButton} onClick={onClick}>
        <p>{jobType}</p>
      </div>
    </>
  );
};

export default JobTypeButtons;
