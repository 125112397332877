import { useEffect, useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBDropdown, WBDropdownOption } from "../../../../Components/UIComponents/WBComponents";
import Suppliers from "../../../../Data Source/Suppliers.json";

const EditMaterial = ({ editedID, closeModalMethod }) => {
    const [matID, setMatID] = useState();
    const [matCode, setMatCode] = useState();
    const [matTitle, setMatTitle] = useState();
    const [matCost, setMatCost] = useState();
    const [matDesc, setMatDesc] = useState();
    const [matSupplier, setMatSupplier] = useState();
    const [matSupplierName, setMatSupplierName] = useState();
    const [suppliers] = useState(Suppliers);
    const [suppliersFormatted, setSuppliersFormatted] = useState();

    const [changesMade, setChangesMade] = useState(false);


    useEffect(() => {
        const GetMaterial = async () => {
            const response = await PostDB({
                branch: "/getMaterial",
                json: {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ material_id: editedID })
                }
            });
            console.log(response);
            // const response = editedID;

            if (typeof response !== 'string') {
                setMatID(response.id);
                setMatSupplier(response.supplier_id);
                setMatCode(response.code);
                setMatTitle(response.title);
                setMatDesc(response.description);
                setMatCost(response.cost);
                setMatSupplierName(response.supplier_id < 4 ? Suppliers.find(supplier => supplier.supplier_id === response.supplier_id).name : undefined);
            }
            else {
                alert("Could not retrieve material with ID: " + editedID);
                // closeModalMethod();
            }
        }

        // const GetSuppliers = async () => {
        //     const response = await PostDB({
        //         branch: "/getSuppliers",
        //         json: {
        //             method: "POST",
        //             headers: {
        //                 Accept: "application/json",
        //                 "Content-Type": "application/json",
        //             }
        //         }
        //     });
        //     if (typeof response === "object" && response.length > 0) {
        //         setSuppliers(response);
        //     }
        // }
        GetMaterial();
        // GetSuppliers();
    }, []);

    const SaveMaterial = async () => {
        let bodyObj = {
            id: matID,
            supplier_id: matSupplier,
            code: matCode,
            title: matTitle,
            description: matDesc,
            cost: matCost
        }
        // let response = "Success";
        let response = await PostDB({
            branch: "/editMaterial", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Material has been successfully updated");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Material. Check your fields and try again");
        }
    }

    useEffect(() => {

    }, [matSupplier])

    useEffect(() => {
        if (suppliers.length > 0) {
            setSuppliersFormatted(
                suppliers.map((supplier, i) =>
                    <WBDropdownOption key={i} title={supplier.name} func={() => { setMatSupplier(supplier.supplier_id); setMatSupplierName(supplier.name); }} />
                )
            )
        }
    }, [suppliers]);

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Material</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Material ID:</label>
                                    <p className="pl-10" id="code">{matID ? matID : "N/A"}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Code:</label>
                                    <p className="pl-10" id="code">{matCode ? matCode : "N/A"}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Supplier ID:</label>
                                    <p className="pl-10" id="code">{matSupplier ? matSupplier : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label className="form-label">
                                        Supplier
                                    </label>
                                    <WBDropdown leftitem={matSupplierName !== undefined ? matSupplierName ? matSupplierName : "Select Supplier" : <i style={{ color: "black" }}>Supplier not found</i>} rightitem={<i className="icon-arrow-down"></i>} color={"white"} optionHidden={true} >
                                        {suppliersFormatted}
                                    </WBDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matName" className="form-label">
                                        Material Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="matName"
                                        className="half"
                                        id="matName"
                                        placeholder="enter material name"
                                        defaultValue={matTitle}
                                        onChange={(e) => { setChangesMade(true); setMatTitle(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matDesc" className="form-label">
                                        Material Description
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <textarea
                                        name="matDesc"
                                        className="half"
                                        id="matDesc"
                                        placeholder="enter material description"
                                        defaultValue={matDesc}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setMatDesc(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matCost" className="form-label">
                                        Material Cost
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="number"
                                        name="matCost"
                                        className="half"
                                        id="matCost"
                                        placeholder="enter material cost"
                                        defaultValue={matCost}
                                        onChange={(e) => { setChangesMade(true); setMatCost(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <WBButton title="Save" color={"green"} func={() => SaveMaterial()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditMaterial;