import { useState } from "react";
import UtilityItems from "../../../Components/Settings/Utility/UtilityItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddUtility from "./Add/AddUtility";
import EditUtility from "./Edit/EditUtility";

const UtilitiesPage = () => {
  const [isModalAddOpen, setIsModal1Open] = useState(false);
  const [isModalEditOpen, setIsModal2Open] = useState(false);

  const toggleAddUtilityModal = () => { setIsModal1Open(!isModalAddOpen); } // addService()
  const toggleEditUtilityModal = (id) => { setIsModal2Open(!isModalEditOpen); if (id) toggleEditUtilityModal(); } // addService()

  const [editedID, setEditedID] = useState();

  const headers = ["Image", "code", "title", "description", "cost", "status", "action"];

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddUtility closeModalMethod={toggleAddUtilityModal} setEditID={setEditedID} switchModal={toggleEditUtilityModal} />}
      {isModalEditOpen && <EditUtility closeModalMethod={toggleEditUtilityModal} editedID={editedID} />}
      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Utilities</b>
          </div>
          <WBButton title="Add Utility" color="white" func={toggleAddUtilityModal} />
        </div>
        <WBTable tableData="/utilitiesJson" tableHeaders={headers} >
          <UtilityItems editFunc={toggleEditUtilityModal} utilitySelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default UtilitiesPage;