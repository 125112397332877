import styles from "./UnderlineTabs.module.css";

const UnderlineTabs = ({ text, isSelected, customStyles, icon, badge, onClick }) => {
	const { underlineTab, tab, selected, badgeStyle } = styles;

	return (
		<div className={underlineTab} style={customStyles}>
			<button
				className={`btn no-border no-background ${tab} ${isSelected ? selected : ""}`}
				onClick={onClick}
			>
				{icon !== undefined && (
					<span className={icon} style={{ marginRight: "-2px" }}></span>
				)}
				{text}
				{badge !== undefined && <span className={badgeStyle}>{badge}</span>}
			</button>
		</div>
	);
};

export default UnderlineTabs;
