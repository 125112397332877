import styles from './reports.module.css';
import ReportColumn from './ReportColumn';
import { useNavigate } from 'react-router-dom';

const reportData = {
  clients: {
    title: 'Clients',
    items: [
      { title: 'List of Clients' },
      { title: 'Clients VIP Over $1000', hasRunButton: true },
      { title: 'List of Sales' },
      { title: 'Sales Payments' },
      { title: 'VIP customers without emails' },
      { title: 'All customers with emails' },
      { title: 'VIP customers with emails' },
    ],
  },
  jobs: {
    title: 'Jobs',
    items: [
      { title: 'Jobs over 10k' },
      { title: 'Jobs by Month' },
      { title: 'Emergency Jobs' },
      { title: 'Job Cost' },
      { title: 'Jobs Scheduled & Under $1,200' },
    ],
  },
  sales: {
    title: 'Sales',
    items: [
      { title: 'Number of Sales' },
      { title: 'Sales by Month' },
      { title: 'List of Sales' },
      { title: 'Sales payments' },
    ],
  },
  custom: {
    title: 'Custom Reports',
    items: [
      { title: 'Sales greater than callout fee $89' },
    ],
  },
};

const ReportsJobsPage = () => {
  const navigate = useNavigate();

  const handleRunReport = (reportTitle) => {
    navigate('/run-report', { state: { reportTitle } });
  };

  return (
    <main className={styles.reportsJobPageContainer}>
      <h1 className={styles.reportsHeader}>Reports</h1>
      <div className={styles.reportsBox}>
        <div className={styles.reportColumnContainer}>
          {Object.entries(reportData)
            .filter(([key]) => key !== 'custom')
            .map(([key, data]) => (
              <ReportColumn
                key={key}
                title={data.title}
                items={data.items}
                onRunReport={handleRunReport}
              />
            ))}
        </div>
        <div className={styles.customReportsContainer}>
          <ReportColumn
            title={reportData.custom.title}
            items={reportData.custom.items}
            onRunReport={handleRunReport}
          />
        </div>
      </div>
    </main>
  );
};

export default ReportsJobsPage;
