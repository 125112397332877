import React from 'react';
import styles from './searchmodal.module.css';
import SearchResultFilter from './SearchResultFilter';
import SearchResultItem from './SearchResultItem';
import { useSearch } from './hooks/useSearch';

const SearchModal = ({isOpen, onClose}) => {
  const {
    searchValue,
    setSearchValue,
    selectedFilter,
    setSelectedFilter,
    isLoading,
    getFilteredResults
  } = useSearch();

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSearchInput = e => {
    setSearchValue(e.target.value);
  };

  const handleFilterClick = filterText => {
    setSelectedFilter(filterText);
  };

  if (!isOpen) return null;

  return (
    <div className={styles.searchModal} onClick={handleOverlayClick}>
      <div className={styles.searchContainer}>
        <div className={styles.searchArea}>
          <div className={styles.searchAreaLeft}>
            <img src="/images/search/search.svg" alt="search" />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Start typing (job id, customer details, etc.)"
              value={searchValue}
              onChange={handleSearchInput}
            />
          </div>
          <div className={styles.searchAreaRight}>
            <p>Ctrl + Space</p>
          </div>
        </div>

        {!searchValue && (
          <div className={styles.recentSearchesContainer}>
            <p className="bold">Recent Searches</p>
            <div className={styles.recentSearchTab}>
              <div className={styles.recentSearchTabLeft}>
                <img src="/images/search/smallsearch.svg" alt="search" />
                <p>Eduard Smith</p>
              </div>
              <div className={styles.recentSearchTabRight}>
                <p>View Results</p>
              </div>
            </div>
            <div className={styles.otherRecentSearches}>
              <div className={styles.otherRecentSearchTab}>
                <img src="/images/search/smallsearch.svg" alt="search" />
                <p>665456</p>
              </div>
              <div className={styles.otherRecentSearchTab}>
                <img src="/images/search/smallsearch.svg" alt="search" />
                <p>886654</p>
              </div>
            </div>
          </div>
        )}

        {searchValue && (
          <div className={styles.searchResultsContainer}>
            <div className={styles.searchResultsHeader}>
              <SearchResultFilter
                text="All"
                selected={selectedFilter === 'All'}
                onClick={() => handleFilterClick('All')}
              />
              <SearchResultFilter
                text="Job"
                selected={selectedFilter === 'Job'}
                onClick={() => handleFilterClick('Job')}
              />
              <SearchResultFilter
                text="Customer"
                selected={selectedFilter === 'Customer'}
                onClick={() => handleFilterClick('Customer')}
              />
            </div>
            <div className={styles.searchResultsBody}>
              {isLoading ? (
                <div className={styles.loadingState}>Searching...</div>
              ) : (
                getFilteredResults().map((result, index) => (
                  <React.Fragment key={index}>
                    {(index === 0 || result.category !== getFilteredResults()[index - 1].category) && (
                      <div className={styles.resultCategory}>
                        <p>{result.category}</p>
                      </div>
                    )}
                    <SearchResultItem
                      type={result.type}
                      data={result.data}
                      onClose={onClose}
                    />
                  </React.Fragment>
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
