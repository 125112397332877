import { useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";

const AddTag = ({ closeModalMethod }) => {
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [status, setStatus] = useState(false);

    const [changesMade, setChangesMade] = useState(false);

    const toggleTagStatus = () => setStatus(status => { let result = !status; setChangesMade(true); alert("status changes: " + result); return result });

    const SaveTag = async () => {
        let bodyObj = {
            id: null,
            trade_id: 1,
            title: title,
            description: description,
            active: status
        }
        const response = await PostDB({
            branch: "/editTag",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Tag has been successfully added");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Tag. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Tag</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="tagCode" className="form-label">
                                        Tag Active Status
                                    </label>
                                    <div className="pl-10">
                                        <WBIOSToggle active={status === 1 ? true : false} func={() => toggleTagStatus()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="tagName" className="form-label">
                                        Tag Name
                                    </label>
                                    <input
                                        type="text"
                                        name="tagName"
                                        className="half"
                                        id="tagName"
                                        placeholder="enter tag name"
                                        defaultValue={title}
                                        required
                                        onChange={(e) => { setChangesMade(true); setTitle(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matDesc" className="form-label">
                                        Material Description
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <textarea
                                        name="matDesc"
                                        className="half"
                                        id="matDesc"
                                        placeholder="enter material description"
                                        defaultValue={description}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setDescription(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
                        <WBButton title="Save" color={"green"} func={() => SaveTag()} />
                    </div>
                </div>
            </div>
        </div >
    )

}

export default AddTag;