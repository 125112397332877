import { useState } from "react";
import { WBButton } from "../../../../Components/UIComponents/WBComponents";

const AddJobType = ({ closeModalMethod }) => {

    const [changesMade, setChangesMade] = useState(false);
    const Save = () => { };

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue">
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Job Type</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <p>This is incomplete and will be added later.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <WBButton title="Save" color={"green"} func={() => Save()} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddJobType;