import React, {useEffect} from 'react';
import styles from './searchmodal.module.css';
import {useNavigate} from 'react-router-dom';

const SearchResultItem = ({type, data, onClose}) => {
  const navigate = useNavigate();

  const handleViewJob = () => {
    if (data?.jobId) {
      navigate(`/customer/${data?.jobId}/profile`);
      onClose();
    }
  };

  const handleViewCustomer = () => {
    if (data?.customerId) {
      navigate(`/customer/${data?.customerId}/profile`);
      onClose();
    }
  };

  const renderJobContent = () => (
    <>
      <div className={styles.resultItemLeft}>
        <img src="/images/search/job.svg" alt="job" />
        <p>{data.name}</p>
      </div>
      <div className={styles.resultItemMiddle}>
        <p>Job ID {data.jobId}</p>
        <div className={styles.jobStatus}>
          <p>Job Status {data.status}</p>
        </div>
      </div>
      <div className={styles.resultItemRight} onClick={handleViewJob}>
        <p>View Job Card</p>
      </div>
    </>
  );

  const renderCustomerContent = () => (
    <>
      <div className={styles.resultItemLeft}>
        <img src="/images/search/profile.svg" alt="profile" />
        <p>{data.name}</p>
      </div>
      <div className={styles.resultItemMiddle}>
        {data.contactInfo.map((contact, index) => (
          <div key={index} className={styles.mobileNumber}>
            <img src="/images/search/phone.svg" alt="phone" />
            <p className="bold">{contact.type}</p>
            <div className={styles.mobileNumberInner}>
              <p>{contact.number}</p>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.resultItemRight} onClick={handleViewCustomer}>
        <p>View Customer Profile</p>
      </div>
    </>
  );

  const renderPropertyContent = () => (
    <>
      <div className={styles.resultItemLeft}>
        <img src="/images/search/building.svg" alt="property" />
        <p>{data.name}</p>
      </div>
      <div className={styles.resultItemMiddle}>
        <div className={styles.location}>
          <img src="/images/search/location.svg" alt="location" />
          <p>{data.address}</p>
        </div>
      </div>
      <div className={styles.resultItemRight}>
        <p>View Property</p>
      </div>
    </>
  );

  const contentMap = {
    job: renderJobContent,
    customer: renderCustomerContent,
    property: renderPropertyContent,
  };

  const renderContent = contentMap[type.toLowerCase()] || (() => null);

  return <div className={styles.resultItem}>{renderContent()}</div>;
};

export default SearchResultItem;
