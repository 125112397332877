import styles from "../addJobModals.module.css";
const ModalNavigation = ({
  customerDetailsModal,
  propertyDetailsModal,
  jobDetailsModal,
  summaryModal,
  closeModal,
  selectedTab,
  disabledNavigation,
}) => {
  return (
    <>
      <div className={styles.modalHeader}>
        <h2>Job Booking</h2>
        <div className={styles.jobTabContainer}>
          <div className={`${styles.jobHeaderTab} ${selectedTab === 1 ? styles.jobHeaderTabSelected : ""}`} onClick={disabledNavigation ? null : customerDetailsModal}>
            <div className={styles.greyCircle}>
              <p className="bold">1</p>
            </div>
            <p>Customer Details</p>
          </div>
          <div
            className={`${styles.jobHeaderTab} ${selectedTab === 2 ? styles.jobHeaderTabSelected : ""}`}
            onClick={disabledNavigation ? null : propertyDetailsModal}
          >
            <div className={styles.greyCircle}>
              <p className="bold">2</p>
            </div>
            <p>Property Details</p>
          </div>
          <div className={`${styles.jobHeaderTab} ${selectedTab === 3 ? styles.jobHeaderTabSelected : ""}`} onClick={disabledNavigation ? null : jobDetailsModal}>
            <div className={styles.greyCircle}>
              <p className="bold">3</p>
            </div>
            <p>Job Details</p>
          </div>
          <div className={`${styles.jobHeaderTab} ${selectedTab === 4 ? styles.jobHeaderTabSelected : ""}`} onClick={disabledNavigation ? null : summaryModal}>
            <div className={styles.greyCircle}>
              <p className="bold">4</p>
            </div>
            <p>Summary</p>
          </div>
        </div>
        {/* <span className={styles.closeBtn} onClick={closeModal}>
          &times;
        </span> */}
      </div>
    </>
  );
};

export default ModalNavigation;
