import React from "react";
import { useState, useContext, useReducer, useEffect } from "react";
import styles from "./addJobModals.module.css";
import CustomerDetails from "./components/CustomerDetails";
import ModalNavigation from "./components/ModalNavigation";
import JobTypeButtons from "./components/JobTypeButtons";
import NotesInput from "./components/NotesInput";
import IndustryButtons from "./components/IndustryButtons";
import CategoryButtons from "./components/CategoryButtons";
import SelectedJobTypeButtons from "./components/SelectedJobTypeButtons";
import BookingDropdown from "./components/BookingDropdown";
import { useJobCustomerModals } from "./hooks/useJobCustomerModals";
import DatePickerInput from "./components/DatePickerInput";
import { JobBookingContext } from "./JobBookingContext";
import CancellationConfirmation from "./components/CancellationConfirmation";


const JobJobDetailsModal = ({
  isOpen,
  closeModal,
  customerDetailsModal,
  jobDetailsModal,
  propertyDetailsModal,
  summaryModal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [isCancel, setIsCancel] = useState(false);
  const handleHideCloseBooking = () => {
    setIsCancel(!isCancel);
  };
  const { bookingData, updateBookingData, fetchedData, customerID, jobsiteID } =
    useContext(JobBookingContext);

  const { timeChoices } = useJobCustomerModals();
  const {
    industriesList = [],
    jobPriorityList = [],
    serviceTypesList = [],
    callOutFeesList = [],
    categoryTypesList = [],
    specialBookingsList = [],
  } = fetchedData || {};

  const initialFormState = {
    cust_id: customerID,
    siteId: jobsiteID,
    industryId: "",
    categoryId: "",
    jobPriorityId: "",
    serviceTypeId: "",
    jobTypeIds: [],
    callOutFeeId: "",
    preferredDate: null,
    preferredTime: "",
    jobDescription: "",
    specialBookingId: "",
  };

  useEffect(() => {
    dispatch({type: "UPDATE_FIELD", field: "cust_id", value: customerID})
  }, [customerID]);

  useEffect(() => {
    dispatch({type: "UPDATE_FIELD", field: "siteId", value: jobsiteID})
  }, [jobsiteID]);

  useEffect(() => {
    if (bookingData.jobDetails) {
      dispatch({ type: "POPULATE", data: bookingData.jobDetails });
    }
  }, [bookingData.jobDetails]);

  const formReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_FIELD":
        return { ...state, [action.field]: action.value };
      case "RESET":
        return initialFormState;
      case "POPULATE":
        return { ...initialFormState, ...action.data }; 
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  const industries = {
    category: ["Residential", "Commercial", "Industrial"],
  };

  const handleClickIndustry = (industry) => {
    setSelectedIndustry(industry);
    dispatch({
      type: "UPDATE_FIELD",
      field: "industryId",
      value: industry.id,
    });
  };

  const handleClickCategory = (category) => {
    dispatch({ type: "UPDATE_FIELD", field: "categoryId", value: category.id });
  };

  const handleJobTypeClick = (jobType) => {
    const jobTypeId = getAllJobTypes().find(jt => jt.title === jobType)?.id;
    if (jobTypeId && !formState.jobTypeIds.includes(jobTypeId)) {
      dispatch({
        type: "UPDATE_FIELD",
        field: "jobTypeIds",
        value: [...formState.jobTypeIds, jobTypeId],
      });
    }
  };

  const handleRemoveJobType = (jobType) => {
    const jobTypeId = getAllJobTypes().find(jt => jt.title === jobType)?.id;
    if (jobTypeId) {
      dispatch({
        type: "UPDATE_FIELD",
        field: "jobTypeIds",
        value: formState.jobTypeIds.filter((id) => id !== jobTypeId),
      });
    }
  };

  const getAllJobTypes = () => {
    return industriesList.flatMap(industry => industry.jobTypes);
  };

  useEffect(() => {

  console.log("specialBookingsList:", specialBookingsList);
    if (!isOpen) {
      setIsCancel(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleNext = () => {
    updateBookingData("jobDetails", formState);
    console.log(formState);
    summaryModal();
  };

  const jobList = industriesList
    .find((item) => item.name === "Plumber")
    ?.jobTypes.map((jobType) => jobType.title);
  console.log(jobList);

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentJobDetails}>
        <div className={styles.modalBody}>
          <ModalNavigation
            customerDetailsModal={customerDetailsModal}
            propertyDetailsModal={propertyDetailsModal}
            jobDetailsModal={jobDetailsModal}
            summaryModal={summaryModal}
            closeModal={closeModal}
            selectedTab={3}
          />
          <div className={styles.modalInformationContainer}>
            <div className={styles.modalLeft}>
              <h3 className={`${styles.fontBig} bold`}>Job Details</h3>
              <div className={styles.modalJobDetails}>
                <p className={`${styles.fontMedium} bold`}>Industry</p>
                <div className={styles.industryButtonContainer}>
                  {industriesList.map((industry) => (
                    <IndustryButtons
                      key={industry.id}
                      industry={industry.department}
                      selected={formState.industryId === industry.id}
                      onClick={() => handleClickIndustry(industry)}
                    />
                  ))}
                </div>
                <p className={`${styles.fontMedium} bold`}>Category</p>
                <div className={styles.categoryButtonContainer}>
                  {categoryTypesList.map((category) => (
                    <CategoryButtons
                      key={category.id}
                      category={category.title}
                      selected={formState.categoryId === category.id}
                      onClick={() => handleClickCategory(category)}
                    />
                  ))}
                </div>
                <div className={styles.jobPrioServiceContainer}>
                  <div className={styles.jobPrioService}>
                    <p className={`${styles.fontMedium} bold`}>Job Priority</p>
                    <BookingDropdown
                      leftitem={jobPriorityList.find(item => item.id === formState.jobPriorityId)?.title || 'Choose Job Priority'}
                      width="315px"
                      height="47px"
                      onChange={(value) => {
                        const selectedPriority = jobPriorityList.find(item => item.title === value);
                        dispatch({
                          type: "UPDATE_FIELD",
                          field: "jobPriorityId",
                          value: selectedPriority.id,
                        });
                      }}
                      dropdownItems={jobPriorityList.map((item) => item.title)}
                    />
                  </div>
                  <div className={styles.jobPrioService}>
                    <p className={`${styles.fontMedium} bold`}>Service Type</p>
                    <BookingDropdown
                      leftitem={serviceTypesList.find(item => item.id === formState.serviceTypeId)?.title || 'Choose Service Type'}
                      width="315px"
                      height="47px"
                      onChange={(value) => {
                        const selectedServiceType = serviceTypesList.find(item => item.title === value);
                        dispatch({
                          type: "UPDATE_FIELD",
                          field: "serviceTypeId",
                          value: selectedServiceType.id,
                        });
                      }}
                      dropdownItems={serviceTypesList.map((item) => item.title)}
                    />
                  </div>
                </div>
                <p className={`${styles.fontMedium} bold`}>Select Job Types</p>
                <div className={styles.jobTypeContainer}>
                  <div className={styles.jobTypeSelection}>
                    {formState.jobTypeIds.length === 0 ? (
                      <p>Enter job type</p>
                    ) : (
                      formState.jobTypeIds.map((jobTypeId) => {
                        const jobType = getAllJobTypes().find(jt => jt.id === jobTypeId);
                        return jobType ? (
                          <SelectedJobTypeButtons
                            key={jobTypeId} 
                            jobType={jobType.title}
                            onClick={() => handleJobTypeClick(jobType.title)}
                            removeClick={() => handleRemoveJobType(jobType.title)}
                          />
                        ) : null;
                      })
                    )}
                  </div>
                  <div className={styles.jobTypeButtonContainer}>
                    {selectedIndustry &&
                      industriesList
                        .find((item) => item.name === selectedIndustry.name)
                        ?.jobTypes.filter(
                          (jobType) =>
                            !formState.jobTypeIds.includes(jobType.id)
                        )
                        .map((jobType) => (
                          <JobTypeButtons
                            key={jobType.id}
                            jobType={jobType.title}
                            onClick={() => handleJobTypeClick(jobType.title)}
                          />
                        ))}
                  </div>
                </div>
                <p className={`${styles.fontMedium} bold`}>Callout Fee</p>
                <div style={{ marginBottom: "29px" }}>
                  <BookingDropdown
                    leftitem={callOutFeesList.find(item => item.id === formState.callOutFeeId)?.price || 'Choose Callout Fee'}
                    width="645px"
                    height="47px"
                    backgroundColor="rgba(246, 246, 246, 1)"
                    marginBottom="0px"
                    onChange={(value) => {
                      const selectedCallOutFee = callOutFeesList.find(item => item.price === value);
                      dispatch({
                        type: "UPDATE_FIELD",
                        field: "callOutFeeId",
                        value: selectedCallOutFee.id,
                      });
                    }}
                    dropdownItems={callOutFeesList.map((item) => item.price)}
                  />
                </div>
                <p className={`${styles.fontMedium} bold`}>
                  Preferred Date & Time
                </p>
                <div className={styles.dateTimeContainer}>
                  <div className={styles.dateContainer}>
                    <p className={`${styles.fontMedium} bold`}>Date</p>
                    <DatePickerInput
                      setDate={(value) => {
                        dispatch({
                          type: "UPDATE_FIELD",
                          field: "preferredDate",
                          value: formatDate(value),
                        });
                      }}
                    />
                  </div>
                  <div className={styles.timeContainer}>
                    <p className={`${styles.fontMedium} bold`}>Time</p>
                    <BookingDropdown
                      leftitem={formState.preferredTime || "Time"}
                      rightitem="/images/client/clock.svg"
                      dropdownItems={timeChoices}
                      width="251px"
                      height="49px"
                      marginBottom="0px"
                      marginTop="0px"
                      onChange={(value) =>
                        dispatch({
                          type: "UPDATE_FIELD",
                          field: "preferredTime",
                          value,
                        })
                      }
                    />
                  </div>
                </div>
                <p className={`${styles.fontMedium} bold`}>
                  Add Job Description
                </p>
                <textarea
                  className={styles.jobDescriptionInput}
                  placeholder="Enter Job Description Here"
                  value={formState.jobDescription}
                  onChange={(e) =>
                    dispatch({
                      type: "UPDATE_FIELD",
                      field: "jobDescription",
                      value: e.target.value,
                    })
                  }
                ></textarea>
                <p className={`${styles.fontMedium} bold`}>Special Booking</p>
                <div>
                  <BookingDropdown
                    leftitem={specialBookingsList.find(item => item.id === formState.specialBookingId)?.title || 'Choose Special Booking'}
                    width="645px"
                    height="47px"
                    backgroundColor="rgba(246, 246, 246, 1)"
                    marginBottom="0px"
                    onChange={(value) => {
                      const selectedSpecialBooking = specialBookingsList.find(item => item.title === value);
                      dispatch({
                        type: "UPDATE_FIELD",
                        field: "specialBookingId",
                        value: selectedSpecialBooking.id,
                      });
                    }}
                    dropdownItems={specialBookingsList.map((item) => item.title)}
                  />
                </div>
              </div>
            </div>
            <div className={styles.modalRight}>
              <CustomerDetails />
              <NotesInput />
              <div className={styles.buttonContainer}>
                <div className={styles.cancelButton} onClick={handleHideCloseBooking}>
                  <p>Cancel</p>
                </div>
                <div className={styles.subButtonContainer}>
                  <div
                    className={styles.backButton}
                    onClick={propertyDetailsModal}
                  >
                    <p>Back</p>
                  </div>
                  <div className={styles.nextButton} onClick={handleNext}>
                    <p>Next</p>
                  </div>
                </div>
              </div>
              {isCancel && (
                <CancellationConfirmation
                  closeModal={closeModal}
                  handleHideCloseBooking={handleHideCloseBooking}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobJobDetailsModal;
