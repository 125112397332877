// import "../styles/contactNotes.css";
// import plus from "../assets/contactAndNotes/plus.png";
// import chevronRight from "../assets/contactAndNotes/chevron-right.png";
// import location from "../assets/contactAndNotes/location.png";
// import call from "../assets/contactAndNotes/call.png";
// import sms from "../assets/contactAndNotes/sms.png";
// import edit from "../assets/contactAndNotes/edit.png";
// import messageText from "../assets/contactAndNotes/messageText.png";
import ContactList from "./ContactList";
import Notes from "./Notes";
import styles from "../contactNotes.module.css"

const ContactNotes = () => {
	return (
		<div>
			<div className={styles.contactNotes}>
				<ContactList />
				<Notes />
			</div>
		</div>
	);
};

export default ContactNotes;