const ChangelogPage = () => {
  return (
    <div className="container bg-white minw-720 logs">
      <div className="section">
        <h3>Log Changes</h3>
        <div className="log_section section">
          <p>
            <b>Wednesday 31st, January, 2024</b>
          </p>
          <p> - Add Filter function</p>
          <p> - Add Sub Filter function</p>  
          <p> - Add Filters UI//UX improvement</p>
        </div>
        <div className="log_section section">
          <p>
            <b>30-01-2024</b>
          </p>
          <p> - Add Filters functions</p>
        </div>
        <div className="log_section section">
          <p>
            <b>30-08-2023</b>
          </p>
          <p> - Settings page UI updated</p>
        </div>
        <div className="log_section section">
          <p>
            <b>29-08-2023</b>
          </p>
          <p>- Settings page UI updated</p>
        </div>
        <div className="log_section section">
          <p>
            <b>23-08-2023</b>
          </p>
          <p>- Settings page UI updated</p>
          <p>
            - Organisation <b>add</b> added
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChangelogPage;
