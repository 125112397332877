/* eslint-disable react/style-prop-object */
import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CallDB, PostDB } from "../../../Components/DBTools";
import { WBButton, WBDrawer, WBDropdown, WBDropdownOption, WBLinker, WBModal, WBSelector, WBToolTip } from "../../../Components/UIComponents/WBComponents";

const OrgTemplatePage = () => {
	let { id } = useParams();
	let navigate = useNavigate();

	const [selectedLocationsReady, setSelectedLocationsReady] = useState(false);

	const [displayName, setDisplayName] = useState(""); /* IMPORTANT is this currently not being saved to the DB, but it can be called from the DB. */
	const [companyName, setCompanyName] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [businessPhone, setBusinessPhone] = useState(0);
	const [businessEmail, setBusinessEmail] = useState("");
	const [businessWebsite, setBusinessWebsite] = useState("");
	const [businessAddress, setBusinessAddress] = useState("");
	const [businessABN, setBusinessABN] = useState(0);
	const [businessACN, setBusinessACN] = useState(0);
	const [currencyType, setCurrencyType] = useState("AUD");
	const [serviceLocations, setServiceLocations] = useState(); // This is where the preloaded serviceLocations are stored
	const [contractLicences, setContractLicences] = useState([]);
	const [businessBSB, setBusinessBSB] = useState("");
	const [businessAccountNumber, setBusinessAccountNumber] = useState("");
	const [organisationMargin, setOrganisationMargin] = useState();
	const [bpay, setBPAY] = useState(0);
	const [bpayActive, setBpayActive] = useState(false);
	const [squareID, setSquareID] = useState(null);
	const [xeroID, setXeroID] = useState(null);
	const [quickID, setQuickID] = useState(null);
	const [myobID, setMYOBID] = useState(null);
	const [selectedLocations, setSelectedLocations] = useState([]); // This is where the edited service locations are stored

	const [billableTemplates, setBillableTemplates] = useState([]);
	const [contractLicencesFormatted, setContractLicencesFormatted] = useState(<></>);
	const [integrationsFormatted, setIntegrationsFormatted] = useState(<></>)
	// const [subtitleNames, setSubTitleNames] = useState([]);   //removed until later development

	const [deleteModal, setDeleteModal] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const toggleDeleteModal = () => setDeleteModal(!deleteModal);

	const Start = useCallback(async () => {
		let response = await CallDB({ branch: "/getOrgs" });
		let selectedOrg = response.find((org) => org.id.toString() === id);
		setDisplayName(selectedOrg.short_name ? selectedOrg.short_name : "");
		setCompanyName(selectedOrg.name ? selectedOrg.name : "");
		setBusinessName(selectedOrg.business_name ? selectedOrg.business_name : "");
		setBusinessAddress(selectedOrg.business_address ? selectedOrg.business_address : "");
		setBusinessPhone(selectedOrg.business_phone ? selectedOrg.business_phone : "");
		setBusinessEmail(selectedOrg.email ? selectedOrg.email : "");
		setBusinessWebsite(selectedOrg.website ? selectedOrg.website : "");
		setBusinessABN(selectedOrg.abn ? selectedOrg.abn : "");
		setBusinessACN(selectedOrg.acn ? selectedOrg.acn : "");
		setCurrencyType(selectedOrg.currency ? selectedOrg.currency : "AUD");
		setContractLicences(selectedOrg.contractor_licences ? selectedOrg.contractor_licences : []);
		setBusinessBSB(selectedOrg.bank_bsb ? selectedOrg.bank_bsb : "");
		setBusinessAccountNumber(selectedOrg.bank_acc_no ? selectedOrg.bank_acc_no : "");
		setOrganisationMargin(selectedOrg.org_margin ? selectedOrg.org_margin : 0);
		setBPAY(selectedOrg.bpay_id ? selectedOrg.bpay_id : "");
		setBpayActive(selectedOrg.pay_is_active ? selectedOrg.pay_is_active : false)
		setServiceLocations(
			selectedOrg.service_areas
				? selectedOrg.service_areas.map((area, i) => ({
					label: area.label,
					service_location_id: area.service_location_id,
					bhTemplateID: area.bhTemplateID,
					active: area.active,
					service_area: area.service_area !== null ? area.service_area.map((sArea, j) => ({
						id: sArea.id,
						locality: sArea.locality,
						postcode: sArea.postcode,
						state: sArea.state
					}))
						: []
				}))
				: []
		);
		setSquareID(!isNaN(selectedOrg.business_square) ? selectedOrg.business_square : selectedOrg.business_square !== null && selectedOrg.business_square !== "null" ? Math.random() * 100 : null);
		setXeroID(!isNaN(selectedOrg.business_xero) ? selectedOrg.business_xero : selectedOrg.business_xero !== null && selectedOrg.business_xero !== "null" ? Math.random() * 100 : null);
		setQuickID(!isNaN(selectedOrg.business_quickbooks) ? selectedOrg.business_quickbooks : selectedOrg.business_quickbooks !== null && selectedOrg.business_quickbooks !== "null" ? Math.random() * 100 : null);
		setMYOBID(!isNaN(selectedOrg.business_myob) ? selectedOrg.business_myob : selectedOrg.business_myob !== null && selectedOrg.business_myob !== "null" ? Math.random() * 100 : null);

		let response2 = await PostDB({
			branch: "/getAllBHTemplates",
			json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		});
		if (response2.length > 0) {
			setBillableTemplates(response2);
		}
		// GetBillableHourTemplates(selectedOrg.defaultBHTemplate ? selectedOrg.defaultBHTemplate : null);
	}, [id]);

	const AddContactorLicence = () => {
		setContractLicences(existingLicences => [...existingLicences, { label: "", lic_no: '' }]);
	}

	const AddServiceLocation = async () => {
		setSelectedLocations(sell => [...sell, { label: "", service_location_id: null, bhTemplateID: 1, active: 0, service_area: [] }]);
		setServiceLocations(serl => [...serl, { label: "", service_location_id: null, bhTemplateID: 1, active: 0, service_area: [] }]);
	}

	const UpdateServiceLocation = (index, value) => {
		if (value.length > 0 && selectedLocations && selectedLocations.length > 0) {
			// console.log("Parsed value: ", value)
			const newLocations = [...selectedLocations];
			newLocations[index].service_area = value;
			// await BuildSubTitle(value, index);
			setSelectedLocations(newLocations);
			// console.log("Service Areas Updated for " + index + ": ", newLocations);
		}
	}


	const UpdateServiceLocationName = (index, value) => {
		if (value.length > 0 && selectedLocations) {
			const newLocations = [...selectedLocations];
			newLocations[index].label = value;
			setSelectedLocations(newLocations);
			setServiceLocations(newLocations);
			// console.log("Label Updated for " + index + ": ", newLocations);
		}
	}

	const UpdateServiceLocationStatus = (index, value) => {
		const newLocations = [...selectedLocations];
		value === true ? newLocations[index].active = 1 : newLocations[index].active = 0;
		setSelectedLocations(newLocations);
		// console.log("Status Updated for " + index + " to " + value + ": ", newLocations);
	}

	const UpdateBPAYActive = (value) => {
		const newStatus = [...bpay];
		value === true ? newStatus[0].active = 1 : newStatus[0].active = 0;
		setBpayActive(newStatus);
		// console.log("Status Updated for BPAY to " + value + ": ", newStatus);
	}

	// const UpdateBPAYCode = (value) => {
	// 	if (value.length > 0 && bpay) {
	// 		const newCode = [...bpay];
	// 		newCode[0].code = value;
	// 		setBPAY(newCode);
	// 		// console.log("Label Updated for BPAY Code: ", newCode);
	// 	}
	// }

	const UpdateBHTemplate = (index, bhtID) => {
		if (bhtID !== null && bhtID !== 0 && serviceLocations[index]) {
			const newBH = [...serviceLocations];
			newBH[index].bhTemplateID = bhtID;
			setServiceLocations(newBH);
			// console.log("Template updated for Service Locations: ", bhtID);
		}
	}

	const UpdateServiceLocationMargin = (index, value) => {
		if (value !== null && value !== 0 && serviceLocations[index]) {
			const newBH = [...serviceLocations];
			newBH[index].margin = value;
			setServiceLocations(newBH);
			// console.log("Template updated for Service Locations: ", value);
		}
	}

	// ---- This will be updated later when spare time is found
	// const BuildSubTitle = (serviceAreas, index) => {
	// 	let names = "N/A";
	// 	if (serviceAreas.length > 0) {
	// 		let localityNames = serviceAreas.map(area => area.name.split(',')[0]); // Extract the first part of the name
	// 		if (localityNames.length > 3) {
	// 			names = localityNames.slice(0, 3).join(', ') + ",...";
	// 		}
	// 		else {
	// 			names = localityNames.join(', ');
	// 		}
	// 	}
	// 	setSubTitleNames(subTitleNames => {
	// 		let newSubTitleNames = [...subTitleNames]; // create a copy of the array
	// 		newSubTitleNames[index] = names; // update the value at the specific index
	// 		return newSubTitleNames; // return the new array
	// 	});
	// }

	useEffect(() => {
		if (contractLicences.length > 0)
			setContractLicencesFormatted(
				contractLicences.map((licence, index) => (
					<div key={index} className="org-licences">
						<p className="p-20">{index + 1}</p>
						<input
							type="text"
							className="p-20"
							value={licence.label}
							placeholder="Label Name"
							onChange={(e) => {
								const tempLicences = [...contractLicences];
								tempLicences[index].label = e.target.value;
								setContractLicences(tempLicences);
							}}
						/>
						<input className="p-20" type="text" value={licence.lic_no} placeholder="Licence number" onChange={(e) => {
							const tempLicences = [...contractLicences];
							tempLicences[index].lic_no = e.target.value;
							setContractLicences(tempLicences);
						}} />
						<p className="remove p-20" onClick={() => setContractLicences(licences => licences.filter((_, i) => i !== index))}>Remove</p>
					</div>
				))
			)
		else {
			setContractLicencesFormatted([]);
		}
	}, [contractLicences])


	useEffect(() => {
		if (serviceLocations && serviceLocations.length > 0) {
			setSelectedLocations(serviceLocations);
			setSelectedLocationsReady(true);
		}
	}, [serviceLocations])

	useEffect(() => {
		Start();

		return () => {
			setContractLicences([]);
			setSelectedLocations([]);
			setServiceLocations([]);
		}
	}, [Start]);

	const SubmitOrg = async () => {
		// 
		let formattedSL = JSON.parse(JSON.stringify(selectedLocations)); // creates a copy of a React useState object without a direct reference
		formattedSL.forEach(location => {
			location.service_area = location.service_area.map(area => {
				const [locality, state, postcode] = area.name.split(', ');
				return {
					id: area.id,
					locality: locality,
					state: state,
					postcode: postcode
				};
			});
		});

		let bodyObj = JSON.stringify({
			org_id: id,
			org_name: companyName,
			business_name: businessName,
			business_address: businessAddress,
			business_phone: businessPhone,
			business_email: businessEmail,
			business_website: businessWebsite,
			abn: businessABN,
			acn: businessACN,
			currency: currencyType,
			licence_numbers: contractLicences,
			business_bsb: businessBSB,
			business_bank_account_number: businessAccountNumber,
			org_margin: organisationMargin !== 0 ? organisationMargin : 0,
			business_bpay: [{
				code: bpay,
				active: bpayActive
			}],
			service_locations: formattedSL,
			business_square: squareID,
			business_xero: xeroID,
			business_quickbooks: quickID,
			business_myob: myobID
		});
		// // console.log(bodyObj);
		// 
		const response = await PostDB({
			branch: "/editOrg", json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: bodyObj
			}
		});

		if (response === "success") {
			alert("Organisation was updated successfully.")
		}
		else {
			alert("There was an issue editing organisations. Make sure the fields are filled correctly.")
		}
	}

	const deleteOrg = async (myOrg) => {
		// 
		if (myOrg !== "1" && myOrg !== "2" && myOrg !== "3" && myOrg !== "4") { // So core examples are not deleted
			let tempName = displayName;
			const response = await PostDB({
				branch: "/delOrg", json: {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ org_id: myOrg })
				}
			});
			// 
			if (response.result === "success") {
				setShowModal(!showModal);
				alert(tempName + " has been deleted successfully");
				return navigate("/settings");
			}
			else {
				alert("Company does not exist");
			}
		}
		else {
			alert(displayName + " cannot be deleted for testing purposes");
		}
	}; // Temp for deleteOrg()


	const LocationServices = useMemo(() => {
		const FormatPreload = (sArea) => {
			let result = [];
			if (sArea[0]) {
				if (Object.keys(sArea[0]).length === 2)
					result = sArea; // Preload format found, does not need to be reformatted to be displayed
				else if (Object.keys(sArea[0]).length > 2)
					result = sArea.map((area) => ({ id: area.id, name: area.locality + ", " + area.state + ", " + area.postcode }))
			}
			return result;
		}

		const GetTemplateName = (location) => {
			const template = billableTemplates.find(temp => temp.id === location.bhTemplateID);
			return template ? template.title : 'Template not found';
		}

		return (
			serviceLocations &&
			serviceLocations.map((location, index) => (
				<div key={index} className="row">
					<div className="col">
						<div className="input">
							<WBDrawer title={location.label} active={location.active === 1 ? true : false} activeReturn={(value) => UpdateServiceLocationStatus(index, value)}>
								<div className="row">
									<div className="col">
										<div className="input">
											<label htmlFor="business_address" className="form-label">
												Label
											</label>
											<input
												type="text"
												name="service_location_label"
												className="form-control"
												id="service_location_label"
												defaultValue={location.label}
												placeholder="enter service location name"
												onBlur={(e) => UpdateServiceLocationName(index, e.target.value)}
											/>
										</div>
										<div className="input" />
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h2 className="pl-20">Location</h2>
								<div className="row">
									<div className="col">
										<WBSelector
											title="Location Area"
											data="/getarea"
											identifiers={["id", ["locality", "state", "postcode"]]}
											preload={FormatPreload(location.service_area)}
											returnFunc={(value) => UpdateServiceLocation(index, value)}
											side />
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h2 className="pl-20">Billable Hour Template</h2>
								<div className="row">
									<div className="col">
										<div className="input">
											<label htmlFor="business_address" className="form-label">
												Selected Template
											</label>
											<WBDropdown key={location.id} leftitem={GetTemplateName(location)} rightitem={<i className="icon-arrow-down"></i>} color={"white"}>
												{billableTemplates.length > 0 &&
													billableTemplates.map((bt, i) => (
														<WBDropdownOption key={bt.id} title={bt.title} func={() => UpdateBHTemplate(index, bt.id)} />
													))
												}
											</WBDropdown>
										</div>
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h2 className="pl-20">Billable Hour Template</h2>
								<div className="row">
									<div className="col">
										<div className="input">
											<div className="titleandbutton">
												<label htmlFor="business_address" className="form-label">
													Service Margin Markup
													<WBToolTip text={"This margin will be added to Services used in this Service Location"} />
												</label>
											</div>
											<input
												type="number"
												name="service_location_margin"
												style={{ width: "25%" }}
												className="form-control"
												id="service_location_margin"
												defaultValue={location.label}
												placeholder="enter margin markup value"
												onBlur={(e) => UpdateServiceLocationMargin(index, e.target.value)}
											/> %
										</div>
									</div>
								</div>
							</WBDrawer>
						</div>
					</div>
				</div>
			))
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceLocations, selectedLocationsReady, billableTemplates]);

	useEffect(() => {
		// console.log("Square: " + squareID + " | Xero: " + xeroID + " | QuickBooks: " + quickID + " | MYOB: " + myobID);
		setIntegrationsFormatted(
			<div>
				<div className="row">
					<div className="col" style={{ paddingBottom: "5px" }}>
						<div className="input">
							<label className="form-label">
								Payment
							</label>
							<WBLinker title="Square" image={"/images/services/39003 1.png"} linked={squareID !== null ? true : false} returnFunc={setSquareID} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col" style={{ paddingBottom: "5px" }}>
						<div className="input">
							<label className="form-label">
								Accounting
							</label>
							<WBLinker title="Xero" image={"/images/services/1200px-Xero_software_logo 1.png"} linked={xeroID !== null ? true : false} returnFunc={setXeroID} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col" style={{ paddingBottom: "5px" }}>
						<div className="input">
							<WBLinker title="Quickbooks" image={"/images/services/39004.png"} linked={quickID !== null ? true : false} returnFunc={setQuickID} />
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col" style={{ paddingBottom: "5px" }}>
						<div className="input">
							<WBLinker title={"myob"} image={"/images/services/MYOB_Logo 1.png"} linked={myobID !== null ? true : false} returnFunc={setMYOBID} />
						</div>
					</div>
				</div>
			</div>
		)
	}, [xeroID, squareID, myobID, quickID])

	return (
		<div className="content-area">
			{deleteModal &&
				<WBModal title={"Delete Organisation"} closeFunction={toggleDeleteModal} submitFunction={() => deleteOrg(id)} >
					<p>{displayName}</p>
					<p></p>
				</WBModal>}
			{showModal &&
				<WBModal title={"Organisation Deleted."} nobuttons>
					<h2 className="pt-40">Name: <p style={{ fontSize: "20px", fontWeight: "normal" }}>{displayName}</p></h2>
				</WBModal>}
			<div id="organisation-form">
				<div id="header">
					<div>
						<div>Organisation</div>
						<div>
							<h3>{displayName}</h3>
						</div>
					</div>
					<div>
						<p className="remove" onClick={toggleDeleteModal}>
							Delete Organisation
						</p>
					</div>
				</div>
			</div>
			<div className="organisation-section">
				<div className="row">
					<div className="col">
						<div className="input" style={{ display: "flex", alignSelf: "center", alignItems: "flex-start" }}>
							<img
								src="/images/servicetoday-logo.png"
								alt="Service Today"
							/>
						</div>
						<div className="input">
							<p style={{ paddingLeft: "20px" }}>
								<b>Company Logo</b>
							</p>
							<WBButton title="Upload Logo Here" icon={<i className="icon-media"></i>} color="white" width={"200px"} height={"48px"} pos={"start"} />
						</div>
						<div className="input" />
					</div>
				</div>
				<div className="border mtb-20"></div>
				<div className="row">
					<div className="col">
						<div className="input" style={{ paddingTop: "20px" }}>
							<label htmlFor="orgName" className="form-label">
								Organisation Display Name
							</label>
							{/* <p>Max 20 Characters (Remaining 20)</p> */}
							<input
								type="text"
								name="orgName"
								className="half"
								id="orgName"
								placeholder="enter organisation name"
								value={displayName}
								onChange={(e) => setDisplayName(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<h2 className="pl-20">Company Details</h2>
				<div className="row">
					<div className="col">
						<div className="input">
							<label htmlFor="company_name" className="form-label">
								Company Name
							</label>
							<input
								type="text"
								name="company_name"
								id="company_name"
								value={companyName}
								placeholder="enter company name"
								onChange={(e) => setCompanyName(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="business_name" className="form-label">
								Business/Trading Name
							</label>
							<input
								type="text"
								name="business_name"
								className="form-control"
								id="business_name"
								value={businessName}
								placeholder="enter business name"
								onChange={(e) => setBusinessName(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="input">
							<label htmlFor="business_address" className="form-label">
								Business Address
							</label>
							<input
								type="text"
								name="business_address"
								className="form-control"
								id="business_address"
								value={businessAddress}
								placeholder="enter business address"
								onChange={(e) => setBusinessAddress(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="business_address" className="form-label">
								Business Phone Number
							</label>
							<input
								type="number"
								name="business_phone"
								className="form-control"
								id="business_phone"
								value={businessPhone}
								placeholder="enter business phone"
								onChange={(e) => setBusinessPhone(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="input">
							<label htmlFor="business_email" className="form-label">
								Business Email Address
							</label>
							<input
								type="text"
								name="business_email"
								className="form-control"
								id="business_email"
								value={businessEmail}
								placeholder="enter business email"
								onChange={(e) => setBusinessEmail(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="business_website" className="form-label">
								Business Website
							</label>
							<input
								type="text"
								name="business_website"
								className="form-control"
								id="business_website"
								value={businessWebsite}
								placeholder="enter website address"
								onChange={(e) => setBusinessWebsite(e.target.value)}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="input">
							<label htmlFor="abn" className="form-label">
								Business ABN
							</label>
							<input
								type="text"
								name="abn"
								className="form-control"
								id="abn"
								value={businessABN}
								placeholder="enter business abn number"
								onChange={(e) => setBusinessABN(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="acn" className="form-label">
								Business ACN
							</label>
							<input
								type="text"
								name="acn"
								className="form-control"
								id="acn"
								value={businessACN}
								placeholder="enter business acn number"
								onChange={(e) => setBusinessACN(e.target.value)}
							/>
						</div>
						<div className="input">
							<label htmlFor="currency" className="form-label">
								Currency
							</label>
							<WBDropdown leftitem={currencyType} rightitem={<i className="icon-arrow-down"></i>} defaultValue={"Select Currency"} func={(e) => setCurrencyType(e.target.value)} color={"white"} width={"100%"} height={"40px"} >
								<WBDropdownOption title={"AUD"} func={() => setCurrencyType("AUD")} />
								<WBDropdownOption title={"USD"} func={() => setCurrencyType("USD")} />
							</WBDropdown>
							{/* <div className="custom-dropdown">
								<select name="currency" id="currency" defaultValue={currencyType} onChange={(e) => setCurrencyType(e.target.value)}>
									<option disabled>-- Select Currency --</option>
									<option value="AUD">AUD</option>
									<option value="USD">USD</option>
								</select>
							</div> */}
						</div>
					</div>
				</div>
				{/* <div className="row">
						<div className="col">
							<div className="input" style={{ display: "flex", alignSelf: "center", alignItems: "flex-start" }}>
								<img
									src="/images/servicetoday-logo.png"
									alt="Service Today"
								/>
							</div>
							<div className="input">
								<p style={{ paddingLeft: "20px" }}>
									<b>Company Logo</b>
								</p>
								<WBButton title="Upload Logo Here" icon={<i className="icon-media"></i>} color="white" width={"200px"} height={"48px"} pos={"start"} />
							</div>
							<div className="input" />
						</div>
					</div> */}
				<div className="row">
					<div className="col">
						<div className="titleandbutton">
							<label className="pl-20">Contractor Licence Number</label>
							<WBButton title={"Add Licence Number"} color="blueborder" func={AddContactorLicence} />
						</div>
					</div>
				</div>
				{contractLicences.length > 0 &&
					<div className="row">
						<div className="col">
							<div className="input">
								{contractLicencesFormatted}
							</div>
						</div>
					</div>}
				{/* <div className="border mtb-20"></div>
					<h2 className="pl-20">Organisation Margin</h2>
					<div className="row">
						<p className="pl-40 font-size-12">This margin is used to give each organisation a markup or markdown in comparison to other organisations.</p>
						<div className="col">
							<div className="input">
								<label htmlFor="org_margin">Margin (%):</label>
								<input id="org_margin" className="p-20 half" type="number" value={organisationMargin} placeholder="Enter organisation margin" onChange={(e) => setOrganisationMargin(e.target.value)} />
							</div>
							<div className="input" />
						</div>
					</div> */}
				<div className="border mtb-20"></div>
				<h2 className="pl-20">Banking Transfer</h2>
				<div className="row">
					<div className="col">
						<div className="input">
							<label htmlFor="banking_transfer" className="form-label">
								BSB
							</label>
							<input className="p-20" type="number" value={businessBSB} placeholder="Enter BSB" onChange={(e) => setBusinessBSB(e.target.value)} />
						</div>
						<div className="input">
							<label htmlFor="banking_transfer" className="form-label">
								Account Number
							</label>
							<input className="p-20" type="number" value={businessAccountNumber} placeholder="Enter Account Number" onChange={(e) => setBusinessAccountNumber(e.target.value)} />
						</div>
						<div className="input" /><div className="input" />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="input">
							<WBDrawer title={"BPAY"} activeReturn={UpdateBPAYActive} active >
								<div className="row">
									<div className="col">
										<div className="input">
											<label htmlFor="business_address" className="form-label">
												Biller Code
											</label>
											<div className="inputandremove">
												<input
													type="text"
													name="service_location_label"
													className="form-control"
													id="service_location_label"
													defaultValue={bpay}
													placeholder="enter biller code here"
													onBlur={(e) => setBPAY(e.target.value)}
												/>
												<p className="remove">
													remove
												</p>
											</div>
											<div className="bpayDesc">
												<div className="row">
													<div className="col">
														<p className="bpayTitle">Biller Code</p>
													</div>
													<div className="col">
														<p className="bpayDesc">318998</p>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<p className="bpayTitle">Biller Short</p>
													</div>
													<div className="col">
														<p className="bpayDesc">SERVICE TODAY</p>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<p className="bpayTitle">Biller Long Name</p>
													</div>
													<div className="col">
														<p className="bpayDesc">ALLTRADES CONTRACTING SERVICES PTY LTD</p>
													</div>
												</div>
												<div className="row">
													<div className="col">
														<p className="bpayTitle">This Biller accepts</p>
													</div>
													<div className="col">
														<p className="bpayDesc">Cheques, Savings or Debit Card</p>
													</div>
												</div>
											</div>
										</div>
										<div className="input" />
										<div className="input" />
									</div>
								</div>
							</WBDrawer>
						</div>
					</div>
				</div>

				<div className="border mtb-20"></div>

				<div className="titleandbutton">
					<h2 className="pl-20 pr-20">Service Locations</h2>
					<WBButton title={"Add Service Location"} color="blueborder" func={AddServiceLocation} />
				</div>

				{LocationServices}

				<div className="border mtb-20"></div>

				<h2 className="pl-20">Integrations</h2>
				{integrationsFormatted}
				<div className="section">
					{/* <button className="btn btn-success" onClick={() => SubmitOrg}>
							Save
						</button> */}
					<WBButton title="Save" color="green" func={() => SubmitOrg()} />
				</div>
			</div>
		</div >
	);
};

export default OrgTemplatePage;
