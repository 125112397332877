import { useEffect, useState } from "react"
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const SpiffItems = ({ editFunc, spiffSelected }) => {
    const deleteUser = () => { };

    const [spiffsFormatted, formatSpiffs] = useState(<></>);

    const spiffs = useWBTableData();

    useEffect(() => {
        // console.log("Spiffs: ", spiffs);
        if (spiffs !== null && spiffs !== undefined) {
            if (spiffs.spiffs.length > 0) {
                formatSpiffs(
                    spiffs.spiffs.map((spiff) => (
                        <div key={spiff.id} className="row" onDoubleClick={() => { spiffSelected(spiff.id); editFunc(); }}>
                            <div className="cell">{spiff.code}</div>
                            <div className="cell">{spiff.title}</div>
                            <div className="cell">${spiff.cost}</div>
                            <div className="cell">{spiff.active === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { spiffSelected(spiff.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(spiff.id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatSpiffs(<div>No items found</div>);
            }
        }
        else {
            formatSpiffs(<div>No items found</div>);
        }
    }, [spiffs]);

    return (
        <div className="body" id="bodyData">{spiffsFormatted}</div>
    );
}

export default SpiffItems;