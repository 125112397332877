import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import styles from './JobTypes.module.css';
import {
  WBDropdown,
  WBDropdownOption,
} from '../../../../Components/Customer/JobCard/common/WBDropdown';

ChartJS.register(ArcElement, Tooltip, Legend);

const JobTypesChart = () => {
  const {
    iconDownArrow,
    container,
    title,
    chartWrapper,
    chartContainer,
    legendContainer,
    legendItem,
    labelStyle,
    colorIndicator,
    dottedLine,
    tradeCategory,
    titleWrapper,
  } = styles;

  const data = {
    labels: ['Blocked Drain', 'Leaking Tap / Toilet', 'Other Electrical', 'Heating Service'],
    datasets: [
      {
        data: [35, 25, 25, 15],
        backgroundColor: ['#8E3DFF', '#FF369E', '#FD9228', ' #0094FF'],
        borderWidth: 2,
        hoverOffset: 20,
      },
    ],
  };

  const trades = {
    'Blocked Drain': 'Plumbing',
    'Leaking Tap / Toilet': 'Plumbing',
    'Other Electrical': 'Electrical',
    'Heating Service': 'HVAC',
  };

  const options = {
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: '#0E1E29',
        bodyColor: '#0E1E29',
        borderColor: 'rgb(200, 200, 200)',
        borderWidth: 1,
        padding: 12,
        displayColors: false,
        titleFont: {
          family: 'robotoBold',
          size: 12,
        },
        bodyFont: {
          family: 'robotoRegular',
          size: 12,
        },
        callbacks: {
          label: function (context) {
            const value = context.parsed || 0;
            return `${value}%`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const handleTradeSelect = trade => {
    // Handle trade selection here
    console.log('Selected trade:', trade);
  };

  return (
    <div className={container}>
      <div className={titleWrapper}>
        <h2 className={title}>Job Types</h2>
        <WBDropdown
          leftitem="All Trades"
          rightitem={<i className={iconDownArrow} />}
          style={{
            border: 'none',
            background: 'none',
            boxShadow: 'none',
            fontFamily: 'robotoRegular',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '19.6px',
          }}
          rightItemPadding="0 0 0 4px"
          leftItemPadding="0 4px"
          height="30px"
        >
          <WBDropdownOption title="All Trades" func={() => handleTradeSelect('all')} />
          <WBDropdownOption title="Plumbing" func={() => handleTradeSelect('plumbing')} />
          <WBDropdownOption title="Electrical" func={() => handleTradeSelect('electrical')} />
          <WBDropdownOption title="HVAC" func={() => handleTradeSelect('hvac')} />
        </WBDropdown>
      </div>
      <div className={chartWrapper}>
        {/* Chart Container */}
        <div className={chartContainer}>
          <Pie data={data} options={options} />
        </div>

        {/* Custom Legend */}
        <div className={legendContainer}>
          {data.labels.map((label, index) => (
            <div key={label} className={legendItem}>
              {/* Color indicator */}
              <div
                className={colorIndicator}
                style={{backgroundColor: data.datasets[0].backgroundColor[index]}}
              />

              {/* Label */}
              <span className={labelStyle}>{label}</span>

              {/* Dotted line */}
              <div className={dottedLine} />

              {/* Trade category */}
              <span className={tradeCategory}>{trades[label]}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobTypesChart;
