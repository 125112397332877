export const initialPermissions = [
	{
		title: "Accept Jobs",
		description: "Display on the scheduler & accept jobs",
		isEnabled: true,
		hasTitle: true,
	},
	{
		title: "Account Status",
		description: "",
		isEnabled: true,
		hasTitle: true,
	},
	{
		title: "Inventory",
		description: "Truck Inventory, truck restocking, materials used",
		isEnabled: false,
		hasTitle: true,
	},
	{
		title: "Apprentice",
		description: "Apprentice mode restricts access to scheduler app",
		isEnabled: false,
		hasTitle: true,
	},
	{
		title: "Timesheet",
		description: "Clock in and Clock out timesheet for payroll purposes and location",
		isEnabled: true,
		hasTitle: true,
	},
];
