export const UseTech = () => {
    const stereg = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    const popup = ['ArrowLeft', 'ArrowUp', 'ArrowLeft', 'ArrowUp'];
    const clearLS = ['ArrowUp', 'ArrowUp', 'ArrowUp', 'ArrowLeft', 'ArrowDown'];

    let ind1 = 0;
    let ind2 = 0;
    let ind3 = 0;

    window.addEventListener('keydown', (event) => {
        if (event.key === stereg[ind1]) {
            ind1++;
            if (ind1 === stereg.length) {
                localStorage.setItem("test", "complete");
                ind1 = 0;
                window.location = "/settings/test";
            }
        }
        else {
            ind1 = 0;
        }
    });

    window.addEventListener('keydown', (event) => {
        if (event.key === popup[ind2]) {
            ind2++;
            if (ind2 === popup.length) {
                console.log("Popup triggered");
                alert("This is a test alert popup.");
                ind2 = 0;
            }
        }
        else {
            ind2 = 0;
        }
    });

    window.addEventListener('keydown', (event) => {
        if (event.key === clearLS[ind3]) {
            ind3++;
            if (ind3 === clearLS.length) {
                console.log("Popup triggered");
                localStorage.clear();
                alert("Local storage has been cleared.");
                ind3 = 0;
            }
        }
        else {
            ind3 = 0;
        }
    });

    // prevent doubleclick highlight
    document.addEventListener('mousedown', function (event) {
        if (event.detail > 1) {
            event.preventDefault();
        }
    }, false);

    // var leftNavScroller = document.querySelector("#settings nav .active");
    // if (leftNavScroller != null)
    //     leftNavScroller.parentNode.scrollIntoView({ behavior: "instant", block: "center", inline: "nearest" });
}