import {useState, useEffect, useCallback} from 'react';

export const useCustomService = () => {
  const [industries, setIndustries] = useState([]);
  const [utilities, setUtilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchIndustries = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/settings/industries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch industries');
      }

      const data = await response.json();
      setIndustries(data);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, []);

  const searchUtilities = useCallback(async keyword => {
    try {
      const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/utilitiesByKeyword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({keyword}),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch utilities');
      }

      const data = await response.json();
      console.log('Utilities search result:', data);
      setUtilities(data);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  useEffect(() => {
    fetchIndustries();
  }, [fetchIndustries]);

  return {industries, utilities, loading, error, searchUtilities};
};
