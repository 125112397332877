import React, { useState, useMemo, useEffect, useContext } from "react";
import styles from "../addJobModals.module.css";
import { JobBookingContext } from "../JobBookingContext";

const SummaryPropertyDetails = ({
  handleEditPropertyDetails,
  isEdit,
  cancel,
  getJobsiteContact,
  getJobsiteAddress,
}) => {
  const [selectedContactIndex, setSelectedContactIndex] = useState(0);

  const { fetchedData } = useContext(JobBookingContext)
  const { contactsRelationshipList = [] } = fetchedData || {}    

  const propertyContacts = useMemo(() => {
    const contacts = getJobsiteContact();
    return Array.isArray(contacts) ? contacts : [contacts];
  }, [getJobsiteContact]);

  const handleContactClick = (index) => {
    setSelectedContactIndex(index);
  };

  const selectedContact = propertyContacts[selectedContactIndex] || {};

  const relationship = useMemo(() => {
    const relationshipId = selectedContact.relationship;
    if (typeof relationshipId === 'number') {
      const foundRelationship = contactsRelationshipList.find(r => r.id === relationshipId);
      return foundRelationship ? foundRelationship.title : 'Unknown';
    }
    return relationshipId || 'Unknown';
  }, [selectedContact.relationship, contactsRelationshipList]);

  useEffect(() => {
    console.log(propertyContacts);
  }, [propertyContacts]);

  return (
    <div className={styles.summaryPropertyContactDetails}>
      <div className={styles.headerContainer}>
        <p className={`${styles.fontMedium} bold`}>Property Contact Details</p>
        <div className={styles.editButton} onClick={handleEditPropertyDetails}>
          <img src="/images/client/edit.svg" alt="edit" />
        </div>
      </div>
      <div className={styles.summaryCustomersContainer}>
        {propertyContacts.map((contact, index) => (
          <div
            key={index}
            className={`${styles.propertyContactCustomer} ${
              selectedContactIndex === index ? styles.selectedCustomer : ""
            }`}
            onClick={() => handleContactClick(index)}
          >
            <p>{`${contact.firstName || contact.first_name || ""} ${
              contact.lastName || contact.last_name || ""
            }`}</p>
          </div>
        ))}
      </div>
      {selectedContactIndex === 0 && (
        <div className={styles.summaryButtonDetailsContainer}>
          <div className={styles.summaryAccountSpan}>
            <p>Primary Contact</p>
          </div>
        </div>
      )}
      <div className={styles.summaryDetails}>
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Name</p>
          <div className={styles.summaryIndivDetailsValue}>
            <p>{`${selectedContact.firstName || selectedContact.first_name || ""} ${
              selectedContact.lastName || selectedContact.last_name || ""
            }`}</p>
          </div>
        </div>
        {/* {selectedContactIndex === 0 && (
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Billing Address</p>
            <div className={styles.summaryIndivDetailsValue}>
              <img src="/images/client/locationpin.svg" alt="location" />
              <p>
                {`${getJobsiteAddress().address_line || ""}, 
                ${getJobsiteAddress().suburb || ""}, 
                ${getJobsiteAddress().state || ""} 
                ${getJobsiteAddress().postcode || ""}`}
              </p>
            </div>
          </div>
        )} */}
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Email</p>
          <div className={styles.summaryIndivDetailsValue}>
            <img src="/images/client/email.svg" alt="email" />
            <p>{selectedContact.email || "N/A"}</p>
          </div>
        </div>
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Phone</p>
          <div className={styles.summaryIndivDetailsValue}>
            <img src="/images/client/mobile.svg" alt="phone" />
            <p>{selectedContact.phone || "N/A"}</p>
          </div>
        </div>
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Mobile</p>
          <div className={styles.summaryIndivDetailsValue}>
            <img src="/images/client/mobile.svg" alt="mobile" />
            <p>{selectedContact.mobile || "N/A"}</p>
          </div>
        </div>
      </div>
      <div className={styles.summaryMiscellaneous}>
        {/* <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryPropertyContactIndivDetailsKey}>Type</p>
          <div className={styles.summaryIndivDetailsValue}>
            <p>{selectedContact.type || "N/A"}</p>
          </div>
        </div> */}
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryPropertyContactIndivDetailsKey}>
            Relationship to Customer
          </p>
          <div className={styles.summaryIndivDetailsValue}>
            <p>{relationship}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryPropertyDetails;
