import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import Client from './Client/Client';
import CardInfos from './Card/CardInfos';
import ContactNotes from './ContactNotes/ContactNotes';
import Properties from './Properties/Properties';
import {useFetchCustomerData} from './Client/hooks/useFetchCustomerData';
const ClientPage = () => {
  const { customerId } = useParams();
  const { customerData, loading } = useFetchCustomerData(customerId);
  console.log(customerData);

  useEffect(() => {
    console.log('ID', customerId);
    console.log('Customer data', customerData);
  }, [customerData]);

  return (
    <>
      <div>
        <Client data={customerData} />
        <CardInfos />
        <ContactNotes />
        <Properties />
      </div>
    </>
  );
};

export default ClientPage;
