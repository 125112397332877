import { createContext, useState, useContext, useEffect } from 'react';
import useTradeCategoriesHook from '../AddService/PriceBookModal/useTradeCategoriesHook';
import { useCustomService } from '../AddService/CustomServiceModal/useCustomService';

import image1 from '../AddService/assets/image1.svg';
import image2 from '../AddService/assets/image2.svg';
import image3 from '../AddService/assets/image3.svg';
import image4 from '../AddService/assets/image4.svg';
import image5 from '../AddService/assets/image5.svg';
import image6 from '../AddService/assets/image6.svg';

const JobCardContext = createContext();

export const useJobCard = () => useContext(JobCardContext);

export const JobCardProvider = ({ children }) => {
  const [activeModalId, setActiveModalId] = useState(null);
  const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false);
  const [priceBookItems, setPriceBookItems] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [recentlyRemovedItems, setRecentlyRemovedItems] = useState([]);
  const [materialsCost, setMaterialsCost] = useState(2100);
  // services
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [servicesError, setServicesError] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  // utilities
  const [selectedUtilities, setSelectedUtilities] = useState([]);
  const {
    utilities,
    loading: utilitiesLoading,
    error: utilitiesError,
    searchUtilities,
  } = useCustomService();

  const [selectedTab, setSelectedTab] = useState('Sales');
  const [invoiceLogs, setInvoiceLogs] = useState([
    { date: '10:45am 08-07-2024', details: 'Invoice #512472 Created total amount $2341.86' },
    {
      date: '10:45am 08-07-2024',
      details: 'Service Item (1-117-606 CCTV CAMERA INSPECTION AND DRAIN LOCATOR) added $602.40',
    },
    {
      date: '10:45am 08-07-2024',
      details: 'Service Item (1-124-337 STRAIGHT WASHER CHANGE WITH MACHINING) added $227.02',
    },
    { date: '10:45am 08-07-2024', details: 'Callout fee added $49.00' },
    { date: '10:45am 08-07-2024', details: 'Payments of $300.00 Applied' },
  ]);

  const [quoteLogs, setQuoteLogs] = useState([
    { date: '09:30am 07-07-2024', details: 'Quote #Q12345 Created' },
    { date: '09:35am 07-07-2024', details: 'Added service: Plumbing Inspection $150' },
    { date: '09:40am 07-07-2024', details: 'Quote sent to customer via email' },
  ]);

  const [complaintLogs, setComplaintLogs] = useState([
    { date: '10:45am 08-07-2024', details: 'Complaint #512472 Created' },
    { date: '10:45am 08-07-2024', details: "Complaint status updated to 'In Progress'" },
    { date: '10:45am 08-07-2024', details: 'New comment added to complaint' },
  ]);

  const [materialsLogs, setMaterialsLogs] = useState([
    { date: '10:45am 08-07-2024', details: 'Materials #512472 Created' },
    { date: '10:45am 08-07-2024', details: "Materials status updated to 'In Progress'" },
    { date: '10:45am 08-07-2024', details: 'New comment added to materials' },
  ]);

  // Item view
  const [isItemViewOpen, setIsItemViewOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [invoiceItems, setInvoiceItems] = useState([]);

  const openItemView = itemId => {
    setSelectedItemId(itemId);
    setIsItemViewOpen(true);
  };

  const closeItemView = () => {
    setIsItemViewOpen(false);
    setSelectedItemId(null);
  };

  const images = [image1, image2, image3, image4, image5, image6];

  const {
    categories: tradeCategories,
    loading: tradeCategoriesLoading,
    error: tradeCategoriesError,
  } = useTradeCategoriesHook();

  const getRandomImage = () => {
    return images[Math.floor(Math.random() * images.length)];
  };

  const openModal = modalId => {
    setActiveModalId(modalId);
    setIsBodyOverflowHidden(true);
  };

  const closeModal = () => {
    console.log('closeModal called');
    setActiveModalId(null);
    setIsBodyOverflowHidden(false);
  };

  const updateItemQuantity = (itemId, newQuantity) => {
    setModalData(prevData => {
      const newData = prevData.map(item => {
        if (item.id === itemId) {
          if (newQuantity === 0 && item.quantity > 0) {
            addToRecentlyRemoved(item);
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      });
      return newData;
    });
  };

  const addCustomItem = newItem => {
    setModalData(prevData => [...prevData, { ...newItem, id: Date.now(), quantity: 1 }]);
  };

  const getTotalItemCount = () => {
    return modalData ? modalData.reduce((sum, item) => sum + (item.quantity || 0), 0) : 0;
  };

  const addToRecentlyRemoved = item => {
    setRecentlyRemovedItems(prev => {
      const existingItemIndex = prev.findIndex(removedItem => removedItem.id === item.id);
      const newItem = {
        ...item,
        removedAt: new Date().getTime(),
      };

      if (existingItemIndex !== -1) {
        const updatedItems = [...prev];
        updatedItems[existingItemIndex] = newItem;
        return updatedItems;
      } else {
        return [newItem, ...prev];
      }
    });
  };

  const restoreRecentlyRemovedItem = itemId => {
    const itemToRestore = recentlyRemovedItems.find(item => item.id === itemId);
    if (itemToRestore) {
      updateItemQuantity(itemId, 1);
      setRecentlyRemovedItems(prev => prev.filter(item => item.id !== itemId));
    }
  };

  const clearCart = () => {
    modalData.forEach(item => {
      if (item.quantity > 0) {
        addToRecentlyRemoved(item);
      }
    });
    setModalData(prevData => prevData.map(item => ({ ...item, quantity: 0 })));
  };

  // utilities
  const addUtility = utility => {
    setSelectedUtilities(prev => [...prev, utility]);
  };

  const removeUtility = utilityId => {
    setSelectedUtilities(prev => prev.filter(util => util.id !== utilityId));
  };

  const handleIndustryChange = industry => {
    setSelectedIndustry(industry);
  };
  const addServicesToInvoice = () => {
    setInvoiceItems(prevInvoiceItems => {
      const updatedItems = [...prevInvoiceItems];

      modalData.forEach(cartItem => {
        if (cartItem.quantity > 0) {
          const existingItem = updatedItems.find(item => item.id === cartItem.id);

          if (existingItem) {
            // Item exists, increment quantity
            existingItem.quantity += cartItem.quantity;
          } else {
            // New item, add to invoice
            updatedItems.push({ ...cartItem });
          }
        }
      });

      return updatedItems;
    });

    clearCart();
  };
  useEffect(() => {
    if (isBodyOverflowHidden) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [isBodyOverflowHidden]);

  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      const fifteenSecondsAgo = new Date().getTime() - 15 * 1000;
      setRecentlyRemovedItems(prev => prev.filter(item => item.removedAt > fifteenSecondsAgo));
    }, 1000);

    return () => clearInterval(cleanupInterval);
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/servicesJson`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }

        const data = await response.json();
        setServices(data.services);
        setModalData(
          data.services.map(service => ({
            ...service,
            quantity: 0,
            price: parseFloat(service.price),
            subtitle: service.short_desc,
            description: service.long_desc,
            categories: [service.trade, service.category, service.sub_category].filter(Boolean),
            image: service.thumbnail !== null ? service.thumbnail : getRandomImage(),
          }))
        );
        setServicesLoading(false);
      } catch (error) {
        setServicesError(error.message);
        setServicesLoading(false);
      }
    };

    fetchServices();
  }, []);

  // Callback Modal
  const [isCallbackModalOpen, setIsCallbackModalOpen] = useState(false);

  const openCallbackModal = () => {
    setIsCallbackModalOpen(true);
    setIsBodyOverflowHidden(true);
  };

  const closeCallbackModal = () => {
    setIsCallbackModalOpen(false);
    setIsBodyOverflowHidden(false);
  };

  return (
    <JobCardContext.Provider
      value={{
        activeModalId,
        modalData,
        priceBookItems,
        recentlyRemovedItems,
        materialsCost,
        tradeCategories,
        tradeCategoriesLoading,
        tradeCategoriesError,
        services,
        servicesLoading,
        servicesError,
        utilities,
        selectedUtilities,
        utilitiesLoading,
        utilitiesError,
        isItemViewOpen,
        selectedItemId,
        selectedIndustry,
        invoiceItems,
        selectedTab,
        complaintLogs,
        invoiceLogs,
        quoteLogs,
        isCallbackModalOpen,
        openCallbackModal,
        closeCallbackModal,
        materialsLogs,
        setInvoiceLogs,
        setComplaintLogs,
        setQuoteLogs,
        setMaterialsLogs,
        setSelectedTab,
        addServicesToInvoice,
        handleIndustryChange,
        openItemView,
        closeItemView,
        searchUtilities,
        addUtility,
        removeUtility,
        setMaterialsCost,
        openModal,
        closeModal,
        updateItemQuantity,
        addCustomItem,
        getTotalItemCount,
        restoreRecentlyRemovedItem,
        clearCart,
      }}
    >
      {children}
    </JobCardContext.Provider>
  );
};
