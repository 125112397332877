import { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { useWBTableData, WBDropdown, WBDropdownOption } from "../../UIComponents/WBComponents";
import { ModalContext } from "../UserManagement/context/ModalContext";
import { useOfficeUserForm } from "../UserManagement/OfficeUserModal/useOfficeUserForm";

const OfficeUsers = () => {
	const { openModal } = useContext(ModalContext);
	const tableData = useWBTableData();
	const [officeUsers, setOfficeUsers] = useState([]);

	const { toggleActiveStatus, deleteOfficeUser } = useOfficeUserForm(null, () => { });

	const refreshUserList = useCallback(() => {
		if (tableData) {
			setOfficeUsers(tableData);
		}
	}, [tableData]);

	useEffect(() => {
		refreshUserList();
	}, [refreshUserList]);

	const handleToggleActive = useCallback(
		async (userId, currentActiveStatus) => {
			try {
				const newActiveStatus = await toggleActiveStatus(userId, currentActiveStatus);
				setOfficeUsers((prevUsers) =>
					prevUsers.map((user) =>
						user.id === userId ? { ...user, active: newActiveStatus } : user,
					),
				);
			} catch (error) {
				console.error("Failed to toggle user status:", error);
			}
		},
		[toggleActiveStatus],
	);

	const handleUserAction = useCallback(
		(action, user) => {
			switch (action) {
				case "edit":
					openModal("Edit Office User", user);
					break;
				case "toggle":
					handleToggleActive(user.id, user.active);
					break;
				case "delete":
					if (window.confirm(`Are you sure you want to delete ${user.name}?`)) {
						deleteOfficeUser(user.id)
							.then(() => {
								setOfficeUsers((prevUsers) =>
									prevUsers.filter((u) => u.id !== user.id),
								);
								console.log("User deleted successfully");
							})
							.catch((error) => {
								console.error("Failed to delete user:", error);
							});
					}
					break;
				default:
					console.error("Unknown action:", action);
			}
		},
		[openModal, handleToggleActive, deleteOfficeUser],
	);

	const officeUsersFormatted = useMemo(() => {
		if (officeUsers.length === 0) {
			return <div>No items found</div>;
		}

		return officeUsers.map((user) => (
			<div key={user.id} className="row">
				<div className="static cell">{user.active === 1 ? <i className="icon-active" /> : <i className="icon-inactive" />}{user.name}</div>
				<div className="cell">{user.role}</div>
				<div className="cell">{user.email_work}</div>
				<div className="cell">{user.phone_mobile}</div>
				<div className="cell">{user.org_name}</div>
				<div className="cell">
					<span className={`bold ${user.active === 1 ? "text-green" : ""}`}>
						{user.active === 1 ? "Active" : "Inactive"}
					</span>
				</div>
				<div className="cell">
					<WBDropdown
						leftitem={
							<div className="title">
								<div className="option">
									<span className="dot-icon"></span>
									<span className="dot-icon"></span>
									<span className="dot-icon"></span>
								</div>
							</div>
						}
						align="end"
					>
						<WBDropdownOption
							title="Edit"
							func={() => handleUserAction("edit", user)}
						/>
						<WBDropdownOption
							title={user.active === 1 ? "Disable" : "Enable"}
							func={() => handleUserAction("toggle", user)}
						/>
						<WBDropdownOption
							title="Delete"
							func={() => handleUserAction("delete", user)}
						/>
					</WBDropdown>
				</div>
			</div>
		));
	}, [officeUsers, handleUserAction]);

	return (
		<div className="body" id="bodyData">
			{officeUsersFormatted}
		</div>
	);
};

export default OfficeUsers;
