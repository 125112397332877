import styles from "../properties.module.css";

const PropertiesContactDetails = () => {
  return (
    <>
      <div className={styles.propertySiteDetailsHeader}>
        <h3>Site Contact Details</h3>
        <div className={styles.propertySiteAddress}>
          <h4>Job Site Address</h4>
          <p>64 Cambridge Street, Lidcombe, 2141 NSW Australia</p>
        </div>

        <div className={styles.customerInfo}>
          <div className={styles.contactName}>
            <h4>Name</h4>
            <p>Steve Smith</p>
          </div>
          <div className={styles.contactMobile}>
            <h4>Mobile</h4>
            <div className={styles.contactPhoneIcon}>
              <img src="/images/client/green-phone.svg" alt="Call Icon" />
              <p>0412 345 678</p>
            </div>
          </div>
          <div className={styles.contactPhone}>
            <h4>Phone</h4>
            <div className={styles.contactPhoneIcon}>
              <img src="/images/client/green-phone.svg" alt="Call Icon" />
              <p>0412 345 678</p>
            </div>
          </div>
          <div className={styles.contactCustomerRelationship}>
            <h4>Customer Relationship</h4>
            <p>Primary Customer</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesContactDetails;
