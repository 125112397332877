import {createContext, useContext, useState} from 'react';

const MaterialsContext = createContext();

export const MaterialsProvider = ({children}) => {
  const [isMaterialOrderModalOpen, setIsMaterialOrderModalOpen] = useState(false);

  const orderStatus = {
    ORDER_PLACED: 0,
    READY_FOR_PICKUP: 1,
    ORDER_PICKED_UP: 2,
  };

  const completedSteps = [orderStatus.ORDER_PLACED, orderStatus.READY_FOR_PICKUP];

  const openMaterialOrderModal = () => setIsMaterialOrderModalOpen(true);
  const closeMaterialOrderModal = () => setIsMaterialOrderModalOpen(false);

  return (
    <MaterialsContext.Provider
      value={{
        isMaterialOrderModalOpen,
        openMaterialOrderModal,
        closeMaterialOrderModal,
        orderStatus,
        completedSteps,
      }}
    >
      {children}
    </MaterialsContext.Provider>
  );
};

export const useMaterialsContext = () => {
  const context = useContext(MaterialsContext);
  if (!context) {
    throw new Error('useMaterialsContext must be used within a MaterialsProvider');
  }
  return context;
}; 