import { NavLink, useLocation } from "react-router-dom";

const LeftNavOption = ({ name, link, id, disabled = false }) => {
  if (!id) {
    id = "";
  }
  const { pathname } = useLocation();
  return (
    <li className={disabled ? "disabled" : ""}>
      <NavLink to={link + id} className={pathname === link ? "active" : ""}>{name}</NavLink>
    </li>
  );
};

export default LeftNavOption;
