import {useState, useEffect} from 'react';

const parseSubCat = subCatString => {
  const result = [];
  const regex = /\{[^}]+\}/g;
  const matches = subCatString.match(regex);

  if (matches) {
    matches.forEach(match => {
      const idMatch = match.match(/"id:"\s*:\s*(\d+)/);
      const nameMatch = match.match(/"name:"\s*:\s*"([^"]+)"/);

      if (idMatch && nameMatch) {
        result.push({
          id: parseInt(idMatch[1], 10),
          name: nameMatch[1].trim(),
        });
      }
    });
  }

  return result;
};

const useTradeCategoriesHook = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesUrl = `${process.env.REACT_APP_NODEJSAPI}/pricebook/gettradecatandsubcat`;
        const servicesUrl = `${process.env.REACT_APP_NODEJSAPI}/servicesJson`;

        const [categoriesResponse, servicesResponse] = await Promise.all([
          fetch(categoriesUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
          fetch(servicesUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          }),
        ]);

        if (!categoriesResponse.ok) {
          throw new Error('Failed to fetch categories');
        }

        if (!servicesResponse.ok) {
          throw new Error('Failed to fetch services');
        }

        const categoriesData = await categoriesResponse.json();
        const servicesData = await servicesResponse.json();

        console.log('Raw data:', categoriesData);
        console.log('Services JSON:', servicesData);

        const formattedData = categoriesData.map(item => {
          const subCat = parseSubCat(item.sub_cat);

          return {
            id: item.trade_id,
            title: item.industry,
            isExpanded: false,
            childTabs: [
              {
                id: item.cat_id,
                title: item.cat,
                isExpanded: false,
                checkboxes: subCat.map(subCatItem => ({
                  id: subCatItem.id.toString(),
                  label: subCatItem.name,
                  isChecked: false,
                })),
              },
            ],
          };
        });

        setCategories(formattedData);
        console.log('Fetched and formatted categories:', formattedData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return {categories, loading, error};
};

export default useTradeCategoriesHook;
