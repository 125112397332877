import { useState } from "react";
import FilterItems from "../../../Components/Settings/Filters/FilterItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddFilter from "./Add/AddFilter";
import EditFilter from "./Edit/EditFilter";

const FiltersPage = () => {
  const [editedID, setEditedID] = useState();

  const [isModalAddOpen, setIsModal1Open] = useState(false);
  const [isModalEditOpen, setIsModal2Open] = useState(false);

  const toggleAddFiltersModal = () => { setIsModal1Open(!isModalAddOpen); } // addService()
  const toggleEditFiltersModal = (id) => { setIsModal2Open(!isModalEditOpen); if (id) toggleEditFiltersModal(); } // addService()

  const headers = ["filter name", "sub filter count", "description", "industry", "status", "action"];

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddFilter closeModalMethod={toggleAddFiltersModal} />}
      {isModalEditOpen && <EditFilter closeModalMethod={toggleEditFiltersModal} editedID={editedID} />}

      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Filters</b>
          </div>
          <div className="flex gap-10">
            <WBButton title="Go to SubFilters" link={"/settings/subfilters"} color="white" />
            <WBButton title="Add Filter" func={toggleAddFiltersModal} color={"white"} />
          </div>
        </div>
        <WBTable tableData="/getAllFilters" tableHeaders={headers}>
          <FilterItems editFunc={toggleEditFiltersModal} filterSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default FiltersPage;
