import { useState } from "react";
import Memberships from "../../../Components/Settings/Membership/Memberships";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddMembership from "./Add/AddMembership";
import EditMembership from "./Edit/EditMembership";

const MembershipPage = () => {

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddMembership closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditMembership closeModalMethod={toggleEditModal} editedID={editedID} />}

      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Memberships</b>
          </div>
          <WBButton title="Add Membership Plan" func={toggleAddModal} color={"white"} />
        </div>
        <WBTable tableDataOrg="/membershipsJson" tableHeaders={["name", "price", "period", "status", "action"]}>
          <Memberships editFunc={toggleEditModal} setEditedID={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default MembershipPage;
