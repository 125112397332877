import styles from "../addJobModals.module.css";

const CategoryButtons = ({ category, selected, onClick }) => {
  return (
    <>
      <div
        className={`${styles.categoryButton} ${selected ? styles.selected : ""}`}
        onClick={onClick}
      >
        <p>{category}</p>
      </div>
    </>
  );
};

export default CategoryButtons;
