import styles from './CallCenterLeaderboard.module.css';
import avatar1 from '../../assets/staticAvatars/avatar1.svg';
import avatar2 from '../../assets/staticAvatars/avatar2.svg';
import avatar3 from '../../assets/staticAvatars/avatar3.svg';
import avatar4 from '../../assets/staticAvatars/avatar4.svg';
import avatar5 from '../../assets/staticAvatars/avatar5.svg';

const CallCenterLeaderboard = () => {
  const {
    iconCall,
    leaderboardContainer,
    leaderboardTitleWrapper,
    leaderboardTitle,
    leaderboardTable,
    leaderboardHeader,
    leaderboardRow,
    userInfo,
    avatar,
  } = styles;

  const leaderboardData = [
    {id: 1, avatar: avatar1, name: 'Cody Fisher', jobsBooked: 120},
    {id: 2, avatar: avatar2, name: 'Brooklyn Simmons', jobsBooked: 105},
    {id: 3, avatar: avatar3, name: 'Leslie Alexander', jobsBooked: 94},
    {id: 4, avatar: avatar4, name: 'Jacob Jones', jobsBooked: 60},
    {id: 5, avatar: avatar5, name: 'Esther Howard', jobsBooked: 105},
  ];

  return (
    <div className={leaderboardContainer}>
      <div className={leaderboardTitleWrapper}>
        <div className={leaderboardTitle}>
          <div className={iconCall} />
          <h2>Call Center Leaderboard</h2>
        </div>
        <span>View Full Report</span>
      </div>

      <table className={leaderboardTable}>
        <thead>
          <tr className={leaderboardHeader}>
            <th>Pos.</th>
            <th>User</th>
            <th>Jobs Booked</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map(user => (
            <tr key={user.id} className={leaderboardRow}>
              <td>{user.id}</td>
              <td>
                <div className={userInfo}>
                  <img src={user.avatar} alt={`${user.name}'s avatar`} className={avatar} />
                  <span>{user.name}</span>
                </div>
              </td>
              <td>{user.jobsBooked}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CallCenterLeaderboard;
