import React from "react"
import styles from "../contactNotes.module.css"

const AddNotesModal = ({ isOpen, closeModal, notes }) => {
  if (!isOpen) return null

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentAddNotes}>
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <h2>Creating Note</h2>
            <span className={styles.closeBtn} onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className={styles.modalTextareaContainer}>
            <textarea
              className={styles.modalTextarea}
              placeholder="Enter Notes (A timestamp and your name will be appended automatically)"
            />
            <div className={styles.addNotesButtonContainer}>
              <div className={styles.addNotesModalButtonFile}>
                <img src="/images/client/file.svg" alt="plus" />
                <p>File</p>
              </div>
              <div className={styles.addNotesModalButtonImage}>
                <img src="/images/client/image.svg" alt="plus" />
                <p>Image</p>
              </div>
            </div>
          </div>
          <div className={styles.addNotesSubmissionContainer}>
            <div className={styles.addNotesSubmissionButton} onClick={closeModal}>
              <p>Cancel</p>
            </div>
            <div className={styles.addNotesSubmissionButton}>
              <img src="/images/client/note.svg" alt="plus" />
              <p>Publish Note</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddNotesModal
