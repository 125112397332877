import { useEffect, useState, useRef } from "react";
import { CallDB, PostDB } from "../../../../Components/DBTools";
import { AddServiceValidation } from "../../../../lib/js/addservicevalidation";
// import { useError } from "../../../../lib/js/errorpopup";
import { ToBase64 } from "../../../../lib/js/convertimagetobase64";
import { WBButton, WBDropdown, WBDropdownOption, WBModal, WBSelector, WBTabs } from "../../../../Components/UIComponents/WBComponents";

const AddService = ({ closeModalMethod, preload }) => {

    const [industry, setIndustry] = useState(0);
    const [category, setCategory] = useState(0);
    const [subCategory, setSubCategory] = useState(0);
    const [spiff, setSpiff] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [specialNotes, setSpecialNotes] = useState("");
    const [billableRates, setBillableRates] = useState([]);

    const [industriesFormatted, formatIndustries] = useState("");
    const [categoriesFormatted, formatCategories] = useState("");
    const [subCategoriesFormatted, formatSubCategories] = useState("");
    const [spiffsFormatted, formatSpiffs] = useState("");
    const [hourlyRatesFormatted, formatHourlyRates] = useState("");

    const [daysTrade, setDaysTrade] = useState(0);
    const [hoursTrade, setHoursTrade] = useState(1);
    const [minutesTrade, setMinutesTrade] = useState(0);
    const [daysHelper, setDaysHelper] = useState(0);
    const [hoursHelper, setHoursHelper] = useState(0);
    const [minutesHelper, setMinutesHelper] = useState(0);

    let tempMaterialCosts = 0.00;
    const [materialCosts, setMaterialCosts] = useState(tempMaterialCosts);

    let tempUtilitiesCosts = 0.00;
    const [utilitiesCosts, setUtilitiesCosts] = useState(tempUtilitiesCosts);

    const industryChange = useRef(false);
    const [allTrades, setAllTrades] = useState([]);
    const [marginData, setMarginData] = useState([]);
    const [manualMargins, setManualMargins] = useState([]);

    const [myFiltersSelected, setMyFiltersSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myTagsSelected, setMyTagsSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myMaterialsSelected, setMyMaterialsSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myUtilitiesSelected, setMyUtilitiesSelected] = useState([]); // built in WBSelector, returned to DB on submit

    const [isDisabled, setIsDisabled] = useState(false);

    // const { errormessage, setErrorMessage } = useError();
    const [finished, setFinished] = useState(false);
    const [submitResult, setSubmitResult] = useState();

    const [filesToSend, setFilesToSend] = useState([]);
    const [pdfsFormatted, setPDFsFormatted] = useState([]);
    const [imagesFormatted, setImagesFormatted] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);

    const [selectedOrg, setSelectedOrg] = useState(null);
    const [organisations, setOrganisations] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const GetServiceLocationData = async () => {
        const response = await CallDB({ branch: "/getorgs" });
        setOrganisations(response);
        setSelectedOrg(response[0]);

        let trades = await PostDB({
            branch: "/settings/industries",
            json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        setAllTrades(trades);

        const response2 = await PostDB({
            branch: "/getAllBHTemplates",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ org_id: 1 }), // This is just how its coded (org_id isnt actually required).....
            }
        });
        if (response2 !== null && response2 !== undefined) {
            response2.forEach((bh, i) => {
                const extraHourlyRates = JSON.parse(bh.extra_data);
                let allBH = [];
                if (extraHourlyRates) {
                    for (let i = 0; i < trades.length; i++) {
                        if (!allBH[i]) {
                            allBH[i] = {};
                            for (let j = 0; j < extraHourlyRates.length; j++) {
                                if (trades[i].id === extraHourlyRates[j].dept_id) {
                                    allBH[i].industry = extraHourlyRates[j].dept_id;
                                    allBH[i].industry_name = extraHourlyRates[j].department;
                                    if (extraHourlyRates[j].billable_type === "Standard Hours") {
                                        allBH[i].standardID = extraHourlyRates[j].id;
                                        allBH[i].standard = extraHourlyRates[j].hourly_rate;
                                    }
                                    if (extraHourlyRates[j].billable_type === "Helper Hours") {
                                        allBH[i].helperID = extraHourlyRates[j].id;
                                        allBH[i].helper = extraHourlyRates[j].hourly_rate;
                                    }
                                }
                            }
                            if (!Object.keys(allBH[i]).length) {
                                allBH.splice(i, 1);
                            }
                        }
                    }
                }
                response2[i].extra_data = allBH;
            });
            setBillableRates(response2);
        }
    };

    const handleImageInput = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "application/pdf") {
                const base64Image = await ToBase64(file);
                const pdf = URL.createObjectURL(event.target.files[0]);
                setFilesToSend((oldArray) => [...oldArray, {
                    base64Image,
                    pdf
                }]);
            } else {
                const base64Image = await ToBase64(file); // Custom component to turn any file to Base64
                setFilesToSend((oldArray) => [...oldArray, base64Image]);
            }
            // 
            let existingName = fileNames.find(name => name === file.name);
            if (existingName === undefined) {
                setFileNames((oldArray) => [...oldArray, file.name]);
            }
            else {
                let count = 1;
                for (let i = 0; i < fileNames.length; i++) {
                    if (fileNames[i] === (file.name + " (" + i + ")")) {
                        count++;
                    }
                }
                setFileNames((oldArray) => [...oldArray, file.name + " (" + count + ")"]);
            }
        }
        event.target.files = null;
        event.target.value = null;
    }

    const handleImageRemove = (indextoRemove) => {
        let myArray = filesToSend;
        let myArray2 = fileNames;
        myArray.splice(indextoRemove, 1);
        myArray2.splice(indextoRemove, 1);
        setFilesToSend(myArray);
        setFileNames(myArray2);
        RenderFiles();
    }

    const openImageInNewTab = (myFile) => {
        var win = window.open();
        win.document.write('<iframe src="' + myFile + '" frameborder="0" style="border:0;top:0px;left:0px;bottom:0px;right:0px;width:100%;height:100%;" allowfullscreen></iframe>');
    }

    const RenderFiles = () => {
        let allImagesFormatted = [];
        let allPDFsFormatted = [];
        for (let i = 0; i < filesToSend.length; i++) {
            let myFile = filesToSend[i];

            // console.log("FILE", myFile);

            let fileType;
            let blob;
            if (typeof myFile !== 'object') {
                fileType = myFile.substring(0, myFile.indexOf("/"));
            } else {
                fileType = myFile.base64Image.substring(0, myFile.base64Image.indexOf("/"));
                blob = myFile.pdf;
            }

            if (fileType === "data:image") {
                allImagesFormatted.push(
                    <div key={i} className="flex">
                        <div>
                            <img className="add-service-image" src={myFile} alt="UserSelectedImage" />
                        </div>
                        <div className="flex-column">
                            <p className="image-title">{fileNames[i]}</p>
                            {selectedThumbnail !== null ?
                                selectedThumbnail === i ?
                                    <p>Selected thumbnail</p> :
                                    <p className="thumbnail-prompt" onClick={() => setSelectedThumbnail(i)}>Set Thumbnail Image</p>
                                :
                                <p className="thumbnail-prompt" onClick={() => setSelectedThumbnail(i)}>Set Thumbnail Image</p>
                            }
                            <p className="view-image" onClick={() => openImageInNewTab(myFile)}>View Image</p>
                            <p className="remove" onClick={() => handleImageRemove(i)}>Remove Image</p>
                        </div>
                    </div>
                );
                // console.log("Image ID: ", i);
            }
            else if (fileType === "data:application") {
                // alert("While this works, pdf's will not be sent to the db until later testing.");
                allPDFsFormatted.push(
                    <div key={i} className="custom-row">
                        <div className="custom-cell">{fileNames[i]}</div>

                        <div className="custom-cell">PDF</div>
                        <div className="custom-cell">
                            <WBDropdown leftitem={<div className="title"><div className="option"><span className="dot-icon"></span><span className="dot-icon"></span><span className="dot-icon"></span></div></div>} color={"white"}>
                                <WBDropdownOption title={"View"} func={() => window.open(blob, "_new")} />
                                <WBDropdownOption title={"Delete"} func={() => handleImageRemove(i)} />
                            </WBDropdown>
                        </div>
                    </div>
                );
                // console.log("PDF ID: ", i);
            }

        }
        // console.log(allImagesFormatted);
        setImagesFormatted(allImagesFormatted);
        setPDFsFormatted(allPDFsFormatted);
        // console.log("My Files: ", filesToSend);
        // console.log("File Names: ", fileNames);
        setFinished(!finished);
    }

    useEffect(() => {
        RenderFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesToSend, fileNames, selectedThumbnail])

    const GetIndustries = async () => {
        // console.log("Call all Industries");
        let response = await PostDB({
            branch: "/settings/industries", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            if (response.length > 0) {
                formatIndustries(
                    response.map((ind) => (
                        <option key={ind.id} value={ind.id}>{ind.name}</option>
                    ))
                );
            }
            else {
                formatIndustries(
                    <option value="0">No Industries found</option>
                );
            }
        } else {
            formatIndustries(
                <option value="0">Failed to retrieve Industries</option>
            );
        }
    };

    const GetCategories = async (ind) => {
        if (parseInt(ind) !== 0) {
            industryChange.current = true;
            setIndustry(parseInt(ind));
            // console.log("Call Categories for Industry: " + ind);
            let response = await CallDB({ branch: "/pricebook/cat/" + ind });
            if (response !== null && response.length > 0) {
                formatCategories(
                    response.map((cat) => (
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))
                );
            }
            else if (response.length <= 0) {
                formatCategories(
                    <option value="0">No Categories Found</option>
                );
            }
            else {
                formatCategories(
                    <option value="0">Failed to retrieve Categories</option>
                );
            }
        }
    };


    // Gets the subcategories for the subcategory dropdown menu.
    const GetSubCategories = async (cat) => {
        if (parseInt(parseInt(cat)) !== 0) {
            setCategory(parseInt(cat));
            // console.log("Call Sub Categories for Category: " + cat);
            let response = await CallDB({ branch: "/pricebook/subcat/" + cat });
            if (response !== null && response.length > 0) {
                formatSubCategories(
                    response.map((sub) => (
                        <option key={sub.id} value={sub.id} className={sub.active ? "" : "disabled"}>{sub.title}</option>
                    ))
                );
            } else if (response.length <= 0) {
                formatSubCategories(
                    <option value="0">No Sub Categories Found</option>
                );
            } else {
                formatSubCategories(
                    <option value="0">Failed to retrieve SubCategories</option>
                );
            }
        }
    };

    // Gets the Spiffs.
    const GetSpiffs = async () => {
        const response = await PostDB({
            branch: "/spiffsJson", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                // body: JSON.stringify({ org_id: selectedOrg.current }) IMPORTANT switch back to this row when ready to use selected Organisation
                body: JSON.stringify({ org_id: 1 })
            }
        });
        // console.log("Spiffs: ", response.spiffs);
        if (response !== null) {
            if (response.spiffs.length > 0) {
                formatSpiffs(
                    response.spiffs.map((spiff) => (
                        <option key={spiff.id} value={spiff.id} className={spiff.active ? "" : "disabled"}>{spiff.title} - {parseInt(spiff.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                    ))
                );
            }
            else {
                formatSpiffs(
                    <option value="">No Spiffs Found</option>
                );
            }
        }
        else {
            formatSpiffs(
                <option value="">Failed to retrieve Spiffs</option>
            );
        }
    };

    // Legacy code. May be reused for duplicate rows in WBSelector
    // const toggleRow = (row) => {
    //     var el = document.getElementById(row);
    //     if (el.style.display === 'none')
    //         el.style.display = null;
    //     else
    //         el.style.display = 'none';
    // }

    const increaseMargin = (slID) => {
        setManualMargins(prevMargins => {
            const updated = [...prevMargins];
            let foundIndex = updated.findIndex(x => x.service_area_id === slID);
            if (foundIndex !== -1) {
                updated[foundIndex].margin++;
            }
            else {
                updated.push({ service_area_id: slID, margin: 1 })
            }
            return updated;
        });
    };

    const decreaseMargin = (slID) => {
        setManualMargins(prevMargins => {
            const updated = [...prevMargins];
            let foundIndex = updated.findIndex(x => x.service_area_id === slID);
            if (foundIndex !== -1) {
                if (updated[foundIndex].margin !== 0)
                    updated[foundIndex].margin--;
            }
            else {
                updated.push({ service_area_id: slID, margin: 0 })
            }
            return updated;
        });
        console.log("New selectedOrg: ", selectedOrg);
    }

    const FormatBillableHours = async () => {
        // console.log("Billable updated ---------------------------------------------------------------");
        if (industry !== 0) {
            if (billableRates !== null && selectedOrg !== null && billableRates.length > 0) {
                var calcTradeTime = +hoursTrade + (+daysTrade * 8);
                var calcHelperTime = +hoursHelper + (+daysHelper * 8);
                let serviceLocationByOrg = selectedOrg.service_areas;
                if (serviceLocationByOrg.length > 0) {
                    const sls = serviceLocationByOrg.map((sl, i) => {
                        // console.log("landed index: " + index);
                        let extra_data = billableRates.find(rate => rate.id === sl.bhTemplateID).extra_data;
                        // For later reference:
                        //      extra_data:             Has the hourly rates for calculation
                        //      serviceLocationByOrg:   Has the service locations stored in it
                        //      marginCalc:             Has the margin calculation for the final calculation
                        //      billableRates:          All billableRates information from /getAllBH
                        if (extra_data.length > 0) {
                            let foundIndustry = extra_data.find(data => data.industry === industry);
                            if (foundIndustry) {
                                let laborCost = ((calcTradeTime + (+minutesTrade / 60)) * foundIndustry.standard) +
                                    ((calcHelperTime + (+minutesHelper / 60)) * foundIndustry.helper);
                                let manualMargin = manualMargins.find(x => x.service_area_id === sl.service_location_id);
                                if (manualMargin === undefined) {
                                    // Handle the case where no matching element is found
                                    manualMargin = { service_area_id: sl.service_location_id, margin: 0 }; // or any default value you prefer
                                    manualMargins.push(manualMargin); // Optionally add the new object to the array
                                }
                                let locationMargin = ((sl.margin + (manualMargin.margin ? manualMargin.margin : 0)) / 100 + 1);
                                let price = laborCost + materialCosts + utilitiesCosts;
                                let finalCost = price * locationMargin;
                                let gst = finalCost / 10;
                                // console.log("(" + calcTradeTime + " * " + foundIndustry.standard + ") + (" + calcHelperTime + " * " + foundIndustry.helper + ") + " + materialCosts + " + " + utilitiesCosts + " = " + price + " / 10 = " + gst);
                                // console.log("Therefore: " + price + " - " + gst + " = " + (price - gst) + " / 10 = " + (price - gst) / 10);
                                return (
                                    <div key={sl.service_location_id} className="row">

                                        {/* Service Location Name */}
                                        <div className="cell pl-50">{sl.label}{/*<i className="pl-10">{"(+" + selectedOrg.org_margin + "%)"}</i>*/}</div>

                                        {/* Billable Hours */}
                                        <div className="cell" name="billable_hrs">
                                            <span style={{ display: "block" }}>
                                                {hoursTrade !== 0 || daysTrade !== 0
                                                    ? calcTradeTime + "hr"
                                                    : ""}
                                                {minutesTrade !== 0 ? minutesTrade + "m" : ""}
                                            </span>
                                            {calcHelperTime !== 0 && <span style={{ fontSize: "12px", display: "block", height: "10px" }}>
                                                {hoursHelper !== 0 || daysHelper !== 0 ? calcHelperTime + "hr" : ""}
                                                {minutesHelper !== 0 ? minutesHelper + "m" : ""}
                                                {hoursHelper !== 0 || daysHelper !== 0 || minutesHelper !== 0 ? "(Helper)" : ""}
                                            </span>}
                                        </div>

                                        {/* Billable Hourly Rate */}
                                        <div className="cell" id="billboard_hrs_rate_1">
                                            <span style={{ display: "block" }}>
                                                ${foundIndustry.standard.toFixed(2)
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph"}</span>
                                            {calcHelperTime !== 0 && <span style={{ display: "block", fontSize: "12px" }}>${foundIndustry.helper.toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph (Helper)"}</span>}
                                        </div>

                                        {/* Labor Cost */}
                                        <div className="cell" id="labour_cost_1">
                                            ${laborCost
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* Material Cost */}
                                        <div className="cell" id="material_cost_1">
                                            ${materialCosts
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* Utility Cost */}
                                        <div className="cell" id="utility_cost_1">
                                            ${utilitiesCosts
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* GST */}
                                        <div className="cell">
                                            ${gst
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </div>

                                        {/* Selling Price */}
                                        <div className="cell" id="selling_price_1">
                                            <div className="flex flex-column">
                                                <p>${finalCost
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                {/* <p style={{ fontSize: "12px" }}>(GST){
                                                    (finalCost + gst)
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p> */}
                                            </div>
                                        </div>

                                        {/* Margin Data */}
                                        <div className="cell pr-70" id="margin_percent_1">
                                            <div className="flex flex-row justify-between gap-10">
                                                <p className="w-50">+{manualMargin !== undefined ? manualMargin.margin : 0}%</p>
                                                <i className="icon-circle-minus" onClick={() => decreaseMargin(sl.service_location_id)}></i>
                                                <i className="icon-circle-plus" onClick={() => increaseMargin(sl.service_location_id)}></i>
                                            </div>
                                        </div>

                                    </div>
                                );
                            }
                            else {
                                return (
                                    <BlankServiceLocationRow title={sl.label + " has no Billable Hour template"} />
                                );
                            }
                        }
                        else {
                            return (
                                <BlankServiceLocationRow title={"No billable hours exist for " + sl.label} />
                            );
                        }
                    });
                    formatHourlyRates(sls);
                } else {
                    formatHourlyRates(
                        <BlankServiceLocationRow title={"No service locations found in " + selectedOrg.short_name} />
                    );
                }

            } else {
                formatHourlyRates(
                    <BlankServiceLocationRow title={"There was an error loading organisations. Please refresh"} />
                );
            }
        }
        else {
            formatHourlyRates(
                <BlankServiceLocationRow title={"Please select an industry to proceed."} />
            );
        }
        BuildAllMargins();
    };

    const BlankServiceLocationRow = ({ title = "N/A" }) => {
        return (
            <div className="row">
                <div className="cell pl-50 maxw-300">
                    <i>
                        {title}
                    </i>
                </div>
                <div className="cell" name="billable_hrs">
                    --
                </div>
                <div className="cell" id="billboard_hrs_rate_1">
                    --
                </div>
                <div className="cell" id="material_cost_1">
                    --
                </div>
                <div className="cell" id="labour_cost_1">
                    --
                </div>
                <div className="cell" id="utility_cost_1">
                    --
                </div>
                <div className="cell" id="selling_price_1">
                    --
                </div>
                <div className="cell pr-70" id="margin_percent_1">
                    --
                </div>
            </div>
        )
    }

    const BuildAllMargins = () => {

    }

    const SaveService = async (edit) => {
        setIsDisabled(!isDisabled);
        setSubmitResult(
            <div id="search-spiner" >
                <i className="icon-search-loader animate-rotate"></i>
                Saving. Please wait
            </div>
        )
        let allFiles = [];
        for (let i = 0; i < filesToSend.length; i++) {
            let temp = filesToSend[i];
            // if (temp.base64Image !== undefined) {
            //     if (i === selectedThumbnail)
            //         allFiles.push({ id: i, "file": temp.base64Image, "file_name": fileNames[i], activeThumbnail: true })
            //     else allFiles.push({ id: i, "file": temp.base64Image, "file_name": fileNames[i], activeThumbnail: false })
            // }
            // else {
            //     allFiles.push({ id: i, "file": temp, "file_name": fileNames[i] })
            // }
            if (i === selectedThumbnail)
                allFiles.push({ id: i, "file": temp, "file_name": fileNames[i], activeThumbnail: true })
            else allFiles.push({ id: i, "file": temp, "file_name": fileNames[i], activeThumbnail: false })
        }
        // 
        let bodyObj = JSON.stringify({
            inpTradeID: industry,
            org_id: 1,
            inpSerTitle: title,
            inpSerDesc: description,
            inpCatID: category,
            inpSubCatID: subCategory,
            // inpSerCode: code,
            inpSerNotes: specialNotes,
            inpSpiffID: spiff,
            tagData: myTagsSelected.map(tag => ({ tag_id: tag.id })),
            filtersData: myFiltersSelected.map(filter => ({ filter_id: filter.id, sub_filter_id: filter.subID })),
            serviceHours: {
                trademan_days: daysTrade,
                trademan_hours: hoursTrade,
                trademan_mins: minutesTrade,
                helper_days: daysHelper,
                helper_hours: hoursHelper,
                helper_mins: minutesHelper
            },
            utilData: myUtilitiesSelected.map(util => ({ util_id: util.id })),
            matData: myMaterialsSelected.map(mat => ({ mat_id: mat.id })),
            marginData: manualMargins,
            servicefiles: allFiles
        });
        console.log(bodyObj);
        if (AddServiceValidation(bodyObj)) {
            const response = await PostDB({
                branch: "/addService", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: bodyObj
                }
            })
            // 
            // console.log("This is what addService responded with: ", response);
            if (response.inserted_service_id) {
                if (edit) {
                    alert("Saved service code: " + response.inserted_service_id + ". \nA duplicate service has been created.\nFeel free to continue editing...");
                    setTitle(title => title + " - Duplicate");
                }
                else {
                    // setShowModal(!showModal);
                    // setTimeout(() => {
                    // return navigate("/settings/services")
                    // }, 2000);
                    alert("Service has been added successfully.")
                    closeModalMethod();
                }
            }
            else {
                alert("There was an error submitting to the database.");
                // setErrorMessage("There was an error creating this service. Make sure all fields are filled correctly.")
            }
        }
        else {
            console.log("hit failed validation");
            alert("One of your fields is incorrect. Make sure that all required field with '(Required)' tags are filled in correctly.");
            // setErrorMessage("Validation found issues");
        }
        setIsDisabled(!isDisabled);
    };

    const changeSelectedOrg = (value) => {
        console.log("Change organisation:", value);
        let result = organisations.find((org) => org.short_name === value);
        setMarginData([]);
        setSelectedOrg(result);
    };

    // Used to test that all select lists were storing data correctly. Uncomment to test
    // const TestButton = () => {
    //     console.log("Tags Selected: ", myTagsSelected);
    //     console.log("Filters Selected: ", myFiltersSelected);
    //     console.log("Materials Selected: ", myMaterialsSelected);
    //     console.log("Utilities Selected: ", myUtilitiesSelected);
    // }

    const RunPreload = async () => {
        await GetIndustries();
        setIndustry(preload[0] !== 0 && preload[0]);
        await GetCategories(preload[0]);
        setCategory(preload[1] !== 0 && preload[1]);
        await GetSubCategories(preload[1]);
        setSubCategory(preload[2] !== 0 && preload[2]);
    }

    useEffect(() => {
        if (preload[0] !== 0) {
            RunPreload(preload);
        }
        else {
            GetIndustries();
            setFilesToSend([]);
            setFileNames([]);
            setManualMargins([]);
        }
        GetServiceLocationData();
        GetSpiffs();

        window.addEventListener('keydown', function (event) { if (event.key === "Escape" && industry !== 0 && window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod(); else if (event.key === "Escape") closeModalMethod(); }); // closes the modal and goes back to ServicesPage
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Run only once, on load. No function here relies on industry or other factors to run

    useEffect(() => {
        FormatBillableHours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialCosts, utilitiesCosts, hoursTrade, hoursHelper, daysTrade, daysHelper, minutesHelper, minutesTrade, industry, selectedOrg, manualMargins]); // update table when price, time or margin change

    return (
        <div className="add-service-page">
            {/* {errormessage && <div className="alertpopup">{errormessage}</div>} */}
            {showModal &&
                <WBModal title={"Service created. Redirecting to Services...."} nobuttons>
                    <h2>Name: <p style={{ fontSize: "20px", fontWeight: "normal" }}>{title}</p></h2>
                    <h2>Description: <p style={{ fontSize: "20px", fontWeight: "normal" }}>{description}</p></h2>
                </WBModal>}
            <div className="border-bottom-grey p-10 bg-white" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="button-row left">
                    <h4 className="title"><b>Add Service</b></h4>
                </div>
                <div className="button-row right gap-10">
                    {submitResult}
                    <WBButton title="Cancel" color="white" func={() => { if (industry !== 0 && window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod(); else closeModalMethod(); }} />
                    <WBDropdown leftitem={"Save Service"} rightitem={<i className="icon-arrow-down"></i>} color={"green"}>
                        <WBDropdownOption title={"Save & Duplicate"} func={() => SaveService(true)} />
                        <WBDropdownOption title={"Save & Submit"} func={() => SaveService(false)} />
                    </WBDropdown>
                </div>
            </div>
            <div className="top-sticky flex-column bg-light-blue font-size-14">
                <WBTabs
                    headers={organisations.map((org) => org.short_name)}
                    func={(e) => changeSelectedOrg(e)}
                />
                <div className="table" id="addServiceTable">
                    <div className="table-container pb-0" style={{ maxHeight: "230px" }}>
                        <div className="header bg-blue">
                            <div className="cell pl-50 maxw-300">Service Locations</div>
                            <div className="cell">Billable Hours</div>
                            <div className="cell">Billable Hourly Rate</div>
                            <div className="cell">Labor Cost</div>
                            <div className="cell">Material Cost</div>
                            <div className="cell">Utility Cost</div>
                            <div className="cell">Tax</div>
                            <div className="cell">Price</div>
                            <div className="cell pr-70">Margin</div>
                        </div>
                        <div className="body">
                            {hourlyRatesFormatted}
                        </div>
                    </div>
                    {/* <div className="bg-white p-10 flex space-between" style={{ overflow: "none", fontStyle: "italic", fontSize: "12px" }}>
                        {selectedOrg !== null && <div>{'Organisation Margin for "' + selectedOrg.short_name + '": +' + selectedOrg.org_margin + "%"}</div>}
                        Price Calculation = (((Service Location Margin + Organisation Margin) / 100 + 1) x (Material Cost + Utility Cost + Labor Cost)) + Tax
                        <div>{"* GST included in Selling Price"}</div>
                    </div> */}
                    <div className="bg-white p-10" style={{ borderTop: "1px solid #F1F1F1" }} />
                </div>
            </div>
            <div className="modal_body">
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10">
                        <div className="flex p-20">
                            <div>
                                <h3>Categories <span className="not-bold font-size-14">(Required)</span></h3>
                                <p className="font-blue">Choose which category product belongs to</p>
                            </div>
                            <div className="flex ml-100 p-20 gap-10">
                                <div className="div_cat">
                                    <label className="cat_label" htmlFor="sel-industry">Industry</label>
                                    <select className="cat_select" name="sel-industry" id="sel-industry" value={industry} required onChange={(selected) => { GetCategories(selected.target.value) }}>
                                        <option value="0" disabled>-- Choose --</option>
                                        {industriesFormatted}
                                    </select>
                                </div>
                                <div className="div_cat">
                                    <label className="cat_label" htmlFor="sel-category">Category</label>
                                    <select className="cat_select" name="sel-category" id="sel-category" value={category} onChange={(selected) => GetSubCategories(selected.target.value)}>
                                        <option value="0" disabled>-- Choose --</option>
                                        {categoriesFormatted}
                                    </select>
                                </div>
                                <div className="div_cat">
                                    <label className="cat_label" htmlFor="sel-subcategory">Sub Category</label>
                                    <select className="cat_select" name="sel-subcategory" id="sel-subcategory" value={subCategory} onChange={(selected) => { setSubCategory(parseInt(selected.target.value)) }} > {/* Add on change here */}
                                        <option value="0" disabled>-- Choose --</option>
                                        {subCategoriesFormatted}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* <div className="pb-20">
                                    <label className="cat_label" htmlFor="service_code">Code</label>
                                    <input type="text" className="form-control" name="service_code" id="service_code" placeholder="enter title of the service" required onChange={(code) => setCode(code.target.value)} />
                                </div> */}

                        <div id="pricebook" className={industry !== 0 ? "" : "disabled"}>
                            <div className="flex-column gap-30 hidden">
                                <div className="add-service-row border-top-grey pt-10 pb-10"> {/* Row 1: Title, Description, Special Notes, Spiffs | Media */}
                                    <div className="add-service-column">
                                        <div className="pb-20">
                                            <label className="cat_label" htmlFor="service_title">Title <span className="not-bold font-size-14">(Required)</span></label>
                                            <input type="text" className="form-control" name="service_title" id="service_title" placeholder="enter title of the service" value={title} required onChange={(title) => setTitle(title.target.value)} />
                                        </div>

                                        <div className="pb-20">
                                            <label className="cat_label" htmlFor="service_desc">Description <span className="not-bold font-size-14">(Required)</span></label>
                                            <textarea className="form-control" name="service_desc" id="service_desc" cols="40" rows="5" placeholder="enter description of the service" onChange={(desc) => setDescription(desc.target.value)}></textarea>
                                        </div>

                                        <div className="pb-20">
                                            <label className="cat_label" htmlFor="service_notes">Special Notes</label>
                                            <textarea className="form-control" name="service_notes" id="service_notes" cols="40" rows="2" placeholder="enter special nots for technicians" onChange={(specNotes) => setSpecialNotes(specNotes.target.value)}></textarea>
                                        </div>

                                        <label className="sel-spiff" htmlFor="sel-spiff">Spiff</label>
                                        <select className="" name="sel-spiff" id="sel-spiff" defaultValue="0" required onChange={(selected) => { setSpiff(parseInt(selected.target.value)) }}>
                                            <option value="0">-- Choose Spiff --</option>
                                            {spiffsFormatted}
                                        </select>
                                    </div>
                                    <div className="add-service-column">
                                        <div className="div_form_item border-radius-10 pb-20" style={{
                                            display: "flex", justifyContent: "space-between", alignItems: "center"
                                        }}>
                                            <div>
                                                <label className="cat_label" htmlFor="media_short_desc">Media</label>
                                                <p id="media_short_desc" className="font-blue pl-10">Be sure to name your files appropriately before uploading</p>
                                            </div>
                                            <div className="upload-media-col">
                                                {/* <label className="cat_label" htmlFor="service_codea">Choose File</label> */}
                                                <label htmlFor="upload_media" className="form-label add-service-upload_media ml-0" style={{ fontFamily: "RobotoRegular" }}><i className="icon-media"></i> Upload</label>
                                                <input type="file" name="upload_media" id="upload_media" accept="image/jpeg,image/gif,image/png,image/webp,application/pdf" hidden onInput={handleImageInput} />
                                            </div>
                                        </div>
                                        <div className="p-10">
                                            <label className="cat_label" htmlFor="service_codea">Files</label>
                                            <div className="custom-table p-10">
                                                <div className="custom-header">
                                                    <div className="custom-cell">Name</div>
                                                    <div className="custom-cell">Type</div>
                                                    <div className="custom-cell">Actions</div>
                                                </div>
                                                {pdfsFormatted.length > 0 ? <div className="body">{pdfsFormatted}</div> : <div className="body"><div className="custom-row"><div className="custom-cell">No Files Uploaded</div><div className="custom-cell">--</div><div className="custom-cell">--</div></div></div>}
                                            </div>
                                        </div>
                                        <div className="p-10">
                                            <div className="div_form_item">
                                                <label className="cat_label" htmlFor="service_codea">Images</label>

                                                <div className="flex gap-10 p-10">
                                                    <div className="col">
                                                        {/* {images.length > 0 ? images : <p>No file selected</p>} */}
                                                        {imagesFormatted.length > 0 ? imagesFormatted : <p>No Images Selected</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-service-row border-top-grey pt-10 pb-10"> {/* Row 2: Filters | Tags */}
                                    <div className="add-service-column">
                                        <WBSelector title="Filter" data="/filtersByIndustry" industry="1" identifiers={["filter_id", "filter_name"]} subIdentifiers={["filter_id", "sub_filter_id", "sub_filter_name"]} returnFunc={setMyFiltersSelected} />
                                    </div>
                                    <div className="add-service-column">
                                        <WBSelector title="Tag" data="/tagsJson" identifiers={["id", "title"]} returnFunc={setMyTagsSelected} />
                                    </div>
                                </div>

                                <div className="add-service-row border-top-grey pt-10 pb-10"> {/* Row 3: Materials | Utilities */}
                                    <div className="add-service-column">
                                        <WBSelector title="Material" data="/materialsByKeyword" industry="1" identifiers={["id", "title", "final_cost"]} returnFunc={setMyMaterialsSelected} returnCostFunc={setMaterialCosts} />
                                    </div>
                                    <div className="add-service-column">
                                        <WBSelector title="Utilities" data="/utilitiesByKeyword" identifiers={["id", "title", "cost"]} returnFunc={setMyUtilitiesSelected} returnCostFunc={setUtilitiesCosts} />
                                    </div>
                                </div>

                                <div className="add-service-row border-top-grey pt-10 pb-10"> {/* Row 4: Tradesman Hours | Helper Hours */}
                                    <div className="add-service-column">
                                        <div>
                                            <h4>Tradesman <span className="not-bold font-size-14">(Required)</span></h4>
                                            <div className="hourly gap-10 flex">
                                                <select className="form-select" name="sel-days" id="sel-days" aria-label="Default select example" onChange={(daysT) => setDaysTrade(parseInt(daysT.target.value))}>
                                                    <option value="0">0 Days</option>
                                                    <option value="1">1 Days</option>
                                                    <option value="2">2 Days</option>
                                                    <option value="3">3 Days</option>
                                                </select>

                                                <select className="form-select" name="sel-hours" id="sel-hours" defaultValue="1" aria-label="Default select example" onChange={(hoursT) => setHoursTrade(parseInt(hoursT.target.value))}>
                                                    <option value="0">0 Hour</option>
                                                    <option value="1">1 Hour</option>
                                                    <option value="2">2 Hour</option>
                                                    <option value="3">3 Hour</option>
                                                    <option value="4">4 Hour</option>
                                                    <option value="5">5 Hour</option>
                                                    <option value="6">6 Hour</option>
                                                    <option value="7">7 Hour</option>
                                                    <option value="8">8 Hour</option>
                                                </select>

                                                <select className="form-select" name="sel-mins" id="sel-mins" aria-label="Default select example" onChange={(minutesT) => setMinutesTrade(parseInt(minutesT.target.value))}>
                                                    <option value="0">0 Min</option>
                                                    <option value="15">15 Min</option>
                                                    <option value="30">30 Min</option>
                                                    <option value="45">45 Min</option>
                                                </select>
                                            </div>
                                            <p className="font-blue italic">Billable Hours (Time it takes to complete task)</p>
                                        </div>
                                    </div>
                                    <div className="add-service-column">
                                        <div>
                                            <h4>Helper</h4>
                                            <div className="hourly flex gap-10">

                                                <select className="form-select" name="sel-days-helper" id="sel-days-helper" aria-label="Default select example" onChange={(daysH) => setDaysHelper(parseInt(daysH.target.value))}>
                                                    <option value="0">0 Days</option>
                                                    <option value="1">1 Days</option>
                                                    <option value="2">2 Days</option>
                                                    <option value="3">3 Days</option>

                                                </select>

                                                <select className="form-select" name="sel-hours-helper" id="sel-hours-helper" aria-label="Default select example" onChange={(hoursH) => setHoursHelper(parseInt(hoursH.target.value))}>
                                                    <option value="0">0 Hour</option>
                                                    <option value="1">1 Hour</option>
                                                    <option value="2">2 Hour</option>
                                                    <option value="3">3 Hour</option>
                                                    <option value="4">4 Hour</option>
                                                    <option value="5">5 Hour</option>
                                                    <option value="6">6 Hour</option>
                                                    <option value="7">7 Hour</option>
                                                    <option value="8">8 Hour</option>
                                                </select>

                                                <select className="form-select" name="sel-mins-helper" id="sel-mins-helper" aria-label="Default select example" onChange={(minutesH) => setMinutesHelper(parseInt(minutesH.target.value))}>
                                                    <option value="0">0 Min</option>
                                                    <option value="15">15 Min</option>
                                                    <option value="30">30 Min</option>
                                                    <option value="45">45 Min</option>
                                                </select>
                                            </div>
                                            <p className="font-blue italic">Billable Hours (Time it takes to complete task)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
                    <button className="btn btn-default" style={{ display: "inline-block", pointerEvents: isDisabled ? "none" : "" }} name="btn-saveService" id="btn-saveService" onClick={() => { SaveService(); window.location = "/settings/services" }}>Save Service</button>
                    <button className="btn btn-default" style={{ display: "inline-block", pointerEvents: isDisabled ? "none" : "" }} name="btn-saveService&Continue" id="btnsaveService&Continue" onClick={() => SaveService()}>Save Service and Continue Editing</button>
                    
                    {submitResult}
                </div> */}
            </div>
        </div>
    );
}

export default AddService;