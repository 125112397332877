import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const Memberships = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { };
    const [membershipsFormatted, formatMemberships] = useState(<></>);

    const memberships = useWBTableData();

    useEffect(() => {
        if (memberships !== null && memberships !== undefined) {
            if (memberships.memberships.length > 0) {
                formatMemberships(
                    memberships.memberships.map((membership) => (
                        <div key={membership.id} className="row" onDoubleClick={() => { setEditedID(membership.id); editFunc(); }}>
                            <div className="staticAuto cell">{membership.title}</div>
                            <div className="cell">{membership.price}</div>
                            <div className="cell">{membership.period}</div>
                            <div className="cell">{membership.active === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(membership.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(membership.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatMemberships(<div>No items found</div>);
            }
        }
        else {
            formatMemberships(<div>No items found</div>);
        }
    }, [memberships]);

    return (
        <div className="body" id="bodyData">{membershipsFormatted}</div>
    )
}

export default Memberships;