import {
  JobDetailsContainer,
  JobBookedMilestone,
  JobScheduledMilestone,
  JobStartedMilestone,
  MilestoneAvatar,
} from './components/MilestoneType';

import styles from './JobMilestoneContent.module.css';
import avatar1 from './assets/iconAvatar.svg';
import avatar2 from './assets/iconDispatch.svg';
import technician1 from './assets/iconTechnician.svg';
import technician2 from './assets/iconTechnician2.svg';

const JobMilestoneContent = () => {
  return (
    <div className={styles.timelineContainer}>
      <MilestoneRow
        avatar={{
          label: 'Call Center',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Clarissa T',
          iconSrc: avatar1,
        }}
        content={{
          type: 'booked',
          bookedBy: 'Clarissa',
          dateTime: '16-09-2024 - 08:28 pm',
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Call Center',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Clarissa T',
          iconSrc: avatar1,
        }}
        content={{
          type: 'scheduled',
          scheduledBy: 'Jake',
          dateTime: '24-09-2024 (8am - 11am)',
          technicianName: 'Aarian Thrupp',
          technicianLocation: 'RIVERSTONE',
          technicianIcon: technician1,
          isUrgencyListMove: false,
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Call Center',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Clarissa T',
          iconSrc: avatar1,
        }}
        content={{
          type: 'scheduled',
          scheduledBy: 'Clarissa T',
          dateTime: '24-09-2024 (8am - 11am)',
          technicianName: 'Aarian Thrupp',
          technicianLocation: 'RIVERSTONE',
          isUrgencyListMove: true,
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Call Center',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Clarissa T',
          iconSrc: avatar1,
        }}
        content={{
          type: 'scheduled',
          scheduledBy: 'Clarissa T',
          dateTime: 'Wednesday, 25 September 2024 (9am - 11am)',
          technicianName: 'Aarian Thrupp',
          technicianLocation: 'RIVERSTONE',
          isUrgencyListMove: true,
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Dispatch',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Dispatch',
          iconSrc: avatar2,
        }}
        content={{
          type: 'scheduled',
          scheduledBy: 'Adam',
          dateTime: 'Wednesday, 25 September 2024 (9am - 11am)',
          technicianName: 'Christopher RIchmond',
          technicianLocation: 'EDMONSON PK',
          technicianIcon: technician2,
          isUrgencyListMove: false,
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Tech',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Tech',
          iconSrc: technician1,
        }}
        content={{
          type: 'started',
          technicianName: 'Christopher Richmond',
          technicianLocation: 'EDMONSON PK',
          dateTime: '25-09-2024 -  11:15 am',
        }}
      />
      {/* partially completed */}
      <MilestoneRow
        avatar={{
          label: 'Tech',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Tech',
          iconSrc: technician1,
        }}
        content={{
          type: 'details',
          technicianName: 'Christopher Richmond',
          technicianLocation: 'EDMONSON PK',
          isCompleted: false,
          dateTime: '25-09-2024 -  11:37 am',
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Dispatch',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Dispatch',
          iconSrc: avatar2,
        }}
        content={{
          type: 'scheduled',
          scheduledBy: 'Adam',
          dateTime: 'Wednesday, 25 September 2024 (9am - 11am)',
          technicianName: 'Christopher RIchmond',
          technicianLocation: 'EDMONSON PK',
          technicianIcon: technician2,
          isUrgencyListMove: false,
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Tech',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Tech',
          iconSrc: technician2,
        }}
        content={{
          type: 'started',
          technicianName: 'Christopher Richmond',
          technicianLocation: 'EDMONSON PK',
          dateTime: '25-09-2024 -  11:15 am',
        }}
      />
      <MilestoneRow
        avatar={{
          label: 'Tech',
          date: '25-09-2024',
          time: '11:37 am',
          scheduledBy: 'Tech',
          iconSrc: technician1,
        }}
        content={{
          type: 'details',
          technicianName: 'Christopher Richmond',
          technicianLocation: 'EDMONSON PK',
          isCompleted: true,
          dateTime: '25-09-2024 -  11:37 am',
        }}
      />
    </div>
  );
};

const MilestoneRow = ({avatar, content}) => {
  return (
    <div className={styles.milestoneRow}>
      <div className={styles.avatarColumn}>
        <MilestoneAvatar
          label={avatar.label}
          date={avatar.date}
          time={avatar.time}
          scheduledBy={avatar.scheduledBy}
          iconSrc={avatar.iconSrc}
        />
      </div>
      <div className={styles.contentColumn}>
        <MilestoneContent {...content} />
      </div>
    </div>
  );
};

const MilestoneContent = ({type, ...props}) => {
  const MilestoneComponent = {
    booked: JobBookedMilestone,
    scheduled: JobScheduledMilestone,
    started: JobStartedMilestone,
    details: JobDetailsContainer,
  }[type];

  return MilestoneComponent ? <MilestoneComponent {...props} /> : null;
};

export default JobMilestoneContent;
