import styles from './TechnicianRevenue.module.css';
import avatar1 from '../../assets/staticAvatars/avatar1.svg';
import avatar2 from '../../assets/staticAvatars/avatar2.svg';
import avatar3 from '../../assets/staticAvatars/avatar3.svg';
import avatar4 from '../../assets/staticAvatars/avatar4.svg';
import avatar5 from '../../assets/staticAvatars/avatar5.svg';

const TechnicianRevenue = () => {
  const {
    iconTechnician,
    leaderboardContainer,
    leaderboardTable,
    leaderboardHeader,
    leaderboardTitleWrapper,
    leaderboardTitle,
    leaderboardRow,
    userInfo,
    avatar,
  } = styles;

  const technicianData = [
    {id: 1, avatar: avatar1, name: 'Cody Fisher', jobsCount: 120, revenue: 1650.3, jobsWon: 98.5},
    {
      id: 2,
      avatar: avatar2,
      name: 'Brooklyn Simmons',
      jobsCount: 105,
      revenue: 1350.22,
      jobsWon: 95.52,
    },
    {
      id: 3,
      avatar: avatar3,
      name: 'Leslie Alexander',
      jobsCount: 94,
      revenue: 1800.5,
      jobsWon: 85.66,
    },
    {id: 4, avatar: avatar4, name: 'Jacob Jones', jobsCount: 60, revenue: 900.0, jobsWon: 71.22},
    {
      id: 5,
      avatar: avatar5,
      name: 'Esther Howard',
      jobsCount: 105,
      revenue: 1350.22,
      jobsWon: 95.52,
    },
  ];

  return (
    <div className={leaderboardContainer}>
      <div className={leaderboardTitleWrapper}>
        <div className={leaderboardTitle}>
          <div className={iconTechnician} />
          <h2>Technician Revenue</h2>
        </div>
        <span>View Full Report</span>
      </div>
      <table className={leaderboardTable}>
        <thead>
          <tr className={leaderboardHeader}>
            <th>Pos.</th>
            <th>Tech</th>
            <th>No. Jobs</th>
            <th>Jobs Revenue</th>
            <th>Jobs Won</th>
          </tr>
        </thead>
        <tbody>
          {technicianData.map(tech => (
            <tr key={tech.id} className={leaderboardRow}>
              <td>{tech.id}</td>
              <td>
                <div className={userInfo}>
                  <img src={tech.avatar} alt={`${tech.name}'s avatar`} className={avatar} />
                  <span>{tech.name}</span>
                </div>
              </td>
              <td>{tech.jobsCount}</td>
              <td>${tech.revenue.toFixed(2)}</td>
              <td>{tech.jobsWon}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TechnicianRevenue;
