import { useState, forwardRef } from "react";
import styles from "../addJobModals.module.css";

const BookingInput = forwardRef(
  (
    {
      id,
      title,
      placeholder,
      required = false,
      leftitem,
      rightitem,
      dropdownItems,
      textColor,
      backgroundColor,
      border,
      height,
      width,
      style = {},
      padding,
      borderRadius,
      marginTop,
      marginBottom,
      onChange,
      value,
    },
    ref
  ) => {
    const inputStyle = {
      height,
      width,
      padding,
      borderRadius,
      backgroundColor,
      marginTop,
      marginBottom,
      ...style,
    };

    return (
      <div className={styles.customerDetailsFirstName}>
        <div style={{ display: "flex", gap: "5px" }}>
          <p className={`${styles.fontMedium} bold`}>{title}</p>
          {required && <p>(Required)</p>}
        </div>
        <input
          className={styles.customerInputNames}
          type="text"
          placeholder={placeholder}
          style={inputStyle}
          {...(value !== undefined && { value })}
          id={id}
          onChange={onChange}
        />
      </div>
    );
  }
);

export default BookingInput;
