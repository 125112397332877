import {WBDropdown, WBDropdownOption} from './common/WBDropdown';
import styles from './InvoiceContent.module.css';
import profileInvoice from './assets/profileInvoice.svg';
import InvoiceTable from './InvoiceTable';
import receipt from './assets/receipt.svg';
import {useJobCard} from '../context/JobCardContext';

const InvoiceContent = () => {
  const {
    iconArrowDown,
    invoiceTable,
    invoiceSubHeader,
    subHeaderLeft,
    invoice,
    invoiceNumber,
    subHeaderRight,
    invoiceStatus,
    invoiceSummary,
    sumarryPaidTag,
    summaryCustomer,
    summaryDate,
    summaryInvoiceNumber,
  } = styles;

  const {openModal, invoiceItems} = useJobCard();

  const handleButtonClick = () => {
    openModal('priceBook');
  };

  // Calculate subtotal
  const subtotal = invoiceItems.reduce((sum, item) => sum + item.price * item.quantity, 0);

  // Calculate GST (assuming 10%)
  const gst = subtotal * 0.1;

  // Calculate total
  const total = subtotal + gst;

  const invoiceData = {
    items: invoiceItems
      .filter(item => item.quantity > 0)
      .map(item => ({
        code: item.id.toString(),
        title: item.short_desc,
        breadcrumb: item.categories,
        description: item.long_desc,
        price: `$${(item.price * item.quantity).toFixed(2)}`,
        quantity: item.quantity,
      })),
    summary: [
      {label: 'Discount', value: '$0.00 CR'},
      {label: 'Coupon Amount', value: '$0.00 CR'},
      {label: 'Sub Total', value: `$${subtotal.toFixed(2)}`},
      {label: 'GST Tax 10%', value: `$${gst.toFixed(2)}`},
      {label: 'Total', value: `$${total.toFixed(2)}`},
      {label: 'Paid', value: '$600.00'},
    ],
    receipt: [
      {
        image: receipt,
        date: '19-03-2024',
        technician: 'Robert Fox',
        description: '-',
        terminalId: 'BPOINT',
        paymentType: 'Credit Card/Eftpos',
        amount: '$300.00',
      },
      {
        image: receipt,
        date: '20-03-2024',
        technician: 'Robert Fox',
        description: '-',
        terminalId: '555222',
        paymentType: 'Credit Card/Eftpos',
        amount: '$300.00',
      },
    ],
  };

  const downArrow = <i className={iconArrowDown}></i>;

  return (
    <div>
      {/* subheader */}
      <div className={invoiceSubHeader}>
        <div className={subHeaderLeft}>
          <span className={invoice}>Invoice</span>
          <span className={invoiceNumber}># 3221542631</span>
        </div>
        <div className={subHeaderRight}>
          <span className={invoiceStatus}>Emailed</span>
          <WBDropdown
            leftitem="Actions"
            rightitem={downArrow}
            color="whiteandblue"
            height="33px"
            minWidth="120px"
            borderRadius="6px"
            style={{
              fontSize: '14px',
              boxShadow: '0px 4px 4px 0px #0000000A',
            }}
            dropdownMenuStyle={{
              top: 'calc(100% + 4px)',
              minWidth: 'calc(100% + 8px)',
              borderRadius: '10px',
              border: '1px solid #F3FAFF',
              transformOrigin: 'top right',
              transform: 'translateX(8px)',
              overflow: 'hidden',
            }}
          >
            <WBDropdownOption
              title="Add Service"
              divStyle={{
                borderBottom: '1px solid #F3FAFF',
                padding: '16px 16px 16px 16px',
              }}
              anchorStyle={{
                textAlign: 'right',
              }}
              func={handleButtonClick}
            />
            <WBDropdownOption
              title="Cancel Service"
              divStyle={{
                padding: '16px',
              }}
              anchorStyle={{
                textAlign: 'right',
              }}
            />
          </WBDropdown>
        </div>
      </div>

      {/* summary */}
      <div className={invoiceSummary}>
        <span>$2,341.86</span>
        <span className={sumarryPaidTag}>Paid</span>
        <span className={summaryCustomer}>
          <img src={profileInvoice} alt="Profile" />
          <span>Robert Fox</span>
        </span>
        <span className={summaryDate}>
          Monday, March 18, 2024 <span>at 07:48 AM</span>
        </span>
        <span className={summaryInvoiceNumber}># 3221542631</span>
      </div>

      {/* invoice table */}
      <div className={invoiceTable}>
        <InvoiceTable data={invoiceData} />
      </div>
    </div>
  );
};

export default InvoiceContent;
