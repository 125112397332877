import {useState} from 'react';
import {WBButton} from '../../UIComponents/WBComponents';
import styles from './MediaDocumentsTabs.module.css';
import UnderlineTabs from './common/UnderlineTabs';

import home1 from './assets/homeImages/home1.svg';
import home2 from './assets/homeImages/home2.svg';
import home3 from './assets/homeImages/home3.svg';
import home4 from './assets/homeImages/home4.svg';

const MediaDocumentTabs = ({hideAddMediaButton = false}) => {
  const {
    iconDocument,
    iconGallery,
    iconPlus,

    mediaDocumentTabs,
    mediaDocumentHeader,
    headerLeft,
    headerRight,

    mediaDocumentBody,
    videosSection,
    beforeSection,
  } = styles;

  const [selectedTab, setSelectedTab] = useState('Media');

  const videoImages = [home1, home2, home3, home4];
  const beforeImages = [home4, home3, home2, home1, home3, home4];

  return (
    <div className={mediaDocumentTabs}>
      <div className={mediaDocumentHeader}>
        <div className={headerLeft}>
          <UnderlineTabs
            text={'Media'}
            isSelected={selectedTab === 'Media'}
            icon={iconGallery}
            badge={'6'}
            onClick={() => setSelectedTab('Media')}
          />
          <UnderlineTabs
            text={'Documents'}
            isSelected={selectedTab === 'Documents'}
            icon={iconDocument}
            badge={'2'}
            onClick={() => setSelectedTab('Documents')}
          />
        </div>
        {!hideAddMediaButton && (
          <div className={headerRight}>
            <WBButton
              title="Add Media"
              icon={<i className={iconPlus}></i>}
              color="grey"
              styles={{padding: '0 24px'}}
            />
          </div>
        )}
      </div>

      <div className={mediaDocumentBody}>
        <div className={videosSection}>
          <h3>Videos</h3>
          <div>
            {videoImages.map((src, index) => (
              <img key={index} src={src} alt="home images" />
            ))}
          </div>
        </div>
        <div className={beforeSection}>
          <h3>Before</h3>
          <div>
            {beforeImages.map((src, index) => (
              <img key={index} src={src} alt="home images" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaDocumentTabs;
