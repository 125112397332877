import styles from './MaterialsContent.module.css';
import OrdersTable from './OrdersTable';
import MaterialsUsedTable from './MaterialsUsedTable';
import MaterialOrderModal from './MaterialOrderModal';
import {MaterialsProvider} from './context/MaterialsContext';

const MaterialsContent = () => {
  const {materialsContent, ordersTableContainer, materialsUsedTableContainer} = styles;

  return (
    <MaterialsProvider>
      <div className={materialsContent}>
        <div className={ordersTableContainer}>
          <OrdersTable />
        </div>
        <div className={materialsUsedTableContainer}>
          <MaterialsUsedTable />
        </div>
        <MaterialOrderModal />
      </div>
    </MaterialsProvider>
  );
};

export default MaterialsContent;
