import {WBButton} from '../../../../UIComponents/WBComponents';
import OrderStatusSteps from './OrderStatusSteps';
import styles from './OrdersTable.module.css';
import {useMaterialsContext} from '../context/MaterialsContext';

const OrdersTable = () => {
  const {orderTable, title, spacer} = styles;
  const {openMaterialOrderModal, completedSteps} = useMaterialsContext();

  return (
    <div>
      <h2 className={title}>Materials & Equipment</h2>
      <table className={orderTable}>
        <thead>
          <tr>
            <th>Order #</th>
            <th>Status</th>
            <th className={spacer}></th>
            <th>Needed Time</th>
            <th>Approval Status</th>
            <th>Ready Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>224503</td>
            <td>
              <OrderStatusSteps completedSteps={completedSteps} />
            </td>
            <td className={spacer}></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <WBButton
                title="View"
                color="grey"
                styles={{padding: '0 24px'}}
                func={openMaterialOrderModal}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrdersTable;
