import styles from './PaymentDetails.module.css';
import receipt from '../../../assets/receipt.svg';

const PaymentDetails = () => {
  const {
    container,
    header,
    title,
    body,
    tableHeader,
    tableRow,
    columnImage,
    columnNumber,
    columnType,
    columnAmount,
  } = styles;

  return (
    <div className={container}>
      <div className={header}>
        <h2 className={title}>Payment</h2>
      </div>
      <div className={body}>
        <div className={tableHeader}>
          <span className={columnImage}>Receipt Image</span>
          <span className={columnNumber}>Receipt Number</span>
          <span className={columnType}>Payment Type</span>
          <span className={columnAmount}>Amount</span>
        </div>
        <div className={tableRow}>
          <span className={columnImage}>
            <img src={receipt} alt="Receipt" />
          </span>
          <span className={columnNumber}>497610</span>
          <span className={columnType}>Credit Card/Eftpos</span>
          <span className={columnAmount}>$300.00</span>
        </div>
        <div className={tableRow}>
          <span className={columnImage}>
            <img src={receipt} alt="Receipt" />
          </span>
          <span className={columnNumber}>497611</span>
          <span className={columnType}>Credit Card/Eftpos</span>
          <span className={columnAmount}>$300.00</span>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
