import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const JobTypes = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { }; // deleteUser()

    const [jobTypesFormatted, formatJobTypes] = useState(<></>);

    const jobTypes = useWBTableData();

    useEffect(() => {
        if (jobTypes !== null && jobTypes !== undefined) {
            if (jobTypes.length > 0) {
                formatJobTypes(
                    jobTypes.map((jobType) => (
                        <div key={jobType.id} className="row" onDoubleClick={() => { setEditedID(jobType.id); editFunc(); }}>
                            <div className="static1 cell">{jobType.title}</div>
                            <div className="cell">{jobType.trade}</div>
                            <div className="cell">
                                {jobType.active === 1 ? <span className="text-green bold">Active</span> : <span className="bold">Inactive</span>}
                            </div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(jobType.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(jobType.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatJobTypes(<div>No items found</div>);
            }
        }
        else {
            formatJobTypes(<div>No items found</div>);
        }
    }, [jobTypes])

    return (
        <div className="body">{jobTypesFormatted}</div>
    )
}

export default JobTypes;