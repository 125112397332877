import styles from "../card.module.css"
const Card = (card) => {
  return (
    <>
      <div className={styles.indivCards}>
        <img src={card.src} alt={card.alt} className="indiv-cards-icon" />
        <div className={styles.indivCardsContent}>
          <p className={styles.bigFont}>{card.description}</p>
          <p className={styles.biggestFont}>{card.number}</p>
        </div>
      </div>
    </>
  )
}

export default Card
