import { useState } from "react"
import styles from "../contactNotes.module.css"
import ViewNotesModal from "./ViewNotesModal"
import AddNotesModal from "./AddNotesModal"
import ViewAddNotesModal from "./ViewAddNotesModal"

const Notes = () => {
  const [modals, setModals] = useState({
    viewNotes: false,
    addNotes: false,
    viewAddNotes: false,
  })

  const toggleModal = (modalName, isOpen) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalName]: isOpen,
    }))
  }
  const openViewNotesModal = () => toggleModal("viewNotes", true);
  const closeViewNotesModal = () => toggleModal("viewNotes", false);

  const openAddNotesModal = () => toggleModal("addNotes", true);
  const closeAddNotesModal = () => toggleModal("addNotes", false);

  const openViewAddNotesModal = () => {
    toggleModal("viewNotes", false);
    toggleModal("viewAddNotes", true);
  }
  const closeViewAddNotesModal = () => toggleModal("viewAddNotes", false)

  const notes = [
    {
      date: "April 15, 2024, 2:54 pm",
      author: "Samantha",
      content:
        "Waverley called to advise that the card she was going to use for the call out fee, she wont be able to use on the day so asked if we can invoice the call out. advised her we cant do that. she asked if she can pay it over the phone on the day, i advised thats doable. shes gonna go ahead with that.",
    },
    {
      date: "April 16, 2024, 10:02 am",
      author: "James",
      content:
        "After Waverley was advised that invoicing the call-out fee wasn't an option and that she could pay over the phone on the day of the service, she agreed to proceed with that payment method.",
    },
  ]

  return (
    <>
      <div className={styles.notesContainer}>
        <div className={styles.notesHeader}>
          <h2>Notes</h2>
          <hr />
        </div>
        <div className={styles.notesSection}>
          <h4>April 15, 2024, 2:54 pm</h4>
          <span>Posted by Samantha</span>
          <div className={styles.fadeContainer}>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
              suscipit, eos cumque cum aliquid quasi velit eaque neque vel,
              eligendi voluptates ut. Quaerat beatae voluptatem mollitia iure
              soluta aliquam neque, dolorum eos et dolores minima ab iusto quas
              reprehenderit. Quasi, quo perspiciatis? Neque voluptates nesciunt,
              blanditiis quisquam unde ea aut rerum! Dolore vero ipsum officia,
            </p>
          </div>
          <div className={styles.notesButtons}>
            <div
              className={styles.viewNotesButton}
              onClick={openViewNotesModal}
            >
              <p>View All Notes</p>
            </div>
            <div className={styles.addNotesButton} onClick={openAddNotesModal}>
              <img src="/images/client/plus.svg" alt="plus" />
              <p>Add Note</p>
            </div>
          </div>
        </div>
      </div>

      <AddNotesModal
        isOpen={modals.addNotes}
        closeModal={closeAddNotesModal}
        notes={notes}
      />
      <ViewNotesModal
        isOpen={modals.viewNotes}
        closeModal={closeViewNotesModal}
        openViewAddModal={openViewAddNotesModal}
        notes={notes}
      />
      <ViewAddNotesModal
        isOpen={modals.viewAddNotes}
        closeModal={closeViewAddNotesModal}
        notes={notes}
      />
    </>
  )
}

export default Notes
