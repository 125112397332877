import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "../addJobModals.module.css";

const DatePickerInput = ({ setDate }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDate(date);
    setOpenCalendar(false); // Close the calendar after selecting a date
  };

  return (
    <>
      <div className={styles.calendarWrapper}>
        <div
          className={styles.calendarContainer}
          onClick={() => setOpenCalendar(!openCalendar)}
        >
          <p>
            {selectedDate ? selectedDate.toLocaleDateString() : "Select a date"}
          </p>
          <img src="/images/client/calendar.svg" alt="calendar" />
        </div>
        {openCalendar && (
          <div>
            <Calendar
              className={styles.calendarPopup}
              onChange={handleDateChange}
              value={selectedDate}
              onClickOutside={() => setOpenCalendar(false)} // Close when clicking outside
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DatePickerInput;
