import styles from './JobDetailsContainer.module.css';
import TaskDetails from './TaskDetails';

import mapImage from '../../../assets/map.svg';
import SaleDetails from './SaleDetails';
import PaymentDetails from './PaymentDetails';
import MediaDocumentTabs from '../../../../../JobCard/MediaDocumentTabs';
import WorkChecklist from './WorkChecklist';
import MaterialsList from './MaterialsList';

const JobDetailsContainer = ({
  isCompleted,
  technicianName,
  technicianLocation,
  dateTime: dateTimeDetails,
}) => {
  const {
    container,
    dashDesign,
    contentWrapper,
    detailsHeader,
    detailsBody,
    statusLabel,
    dateTime,

    iconMap,
    mapContainer,
    mapIcon,
    map,
  } = styles;

  return (
    <div className={container}>
      <div
        className={dashDesign}
        style={{backgroundColor: isCompleted ? '#44C989' : '#B49979'}}
      ></div>
      <div className={contentWrapper}>
        {/* header */}
        <div
          className={detailsHeader}
          style={{backgroundColor: isCompleted ? '#B8FAC5' : '#F3EBE4'}}
        >
          <p>
            <span className={statusLabel}>{isCompleted ? 'Completed' : 'Partially Completed'}</span>{' '}
            By {technicianName} ({technicianLocation})
          </p>
          <div className={dateTime}>{dateTimeDetails}</div>
        </div>
        <div className={detailsBody}>
          {/* map */}
          <div className={mapContainer}>
            <img src={mapImage} alt="Map" className={map} />
            <button className={`btn btn-default ${mapIcon}`}>
              <i className={iconMap}></i>
            </button>
          </div>
          {/* Task Details */}
          <TaskDetails isCompleted={isCompleted} />
          {/* Sale Details */}
          <SaleDetails />
          {/* Payment Details */}
          <PaymentDetails />
          {/* Media Gallery */}
          <MediaDocumentTabs hideAddMediaButton={true} />
          {/* Material Details */}
          <MaterialsList />
          {/* Ongoing Work Checklist */}
          <WorkChecklist isCompleted={isCompleted} />
          {/* Checklist Notes */}
        </div>
      </div>
    </div>
  );
};

export default JobDetailsContainer;
