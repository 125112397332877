import React, { useState, useContext, useReducer, useEffect } from "react";
import styles from "../addJobModals.module.css";
import JobTypeButtons from "./JobTypeButtons";
import IndustryButtons from "./IndustryButtons";
import CategoryButtons from "./CategoryButtons";
import SelectedJobTypeButtons from "./SelectedJobTypeButtons";
import BookingDropdown from "./BookingDropdown";
import DatePickerInput from "./DatePickerInput";
import { JobBookingContext } from "../JobBookingContext";
import { useJobCustomerModals } from "../hooks/useJobCustomerModals";
import BookingButton from "./BookingButton";

const EditJobDetailsForm = ({ handleEditJobDetails, isEdit, cancel }) => {
  const { bookingData, updateBookingData, fetchedData } =
    useContext(JobBookingContext);
  const { timeChoices } = useJobCustomerModals();

  const {
    industriesList = [],
    jobPriorityList = [],
    serviceTypesList = [],
    callOutFeesList = [],
    categoryTypesList = [],
    specialBookingsList = [],
  } = fetchedData || {};

  const initialFormState = {
    cust_id: bookingData.jobDetails.cust_id,
    industryId: bookingData.jobDetails.industryId,
    categoryId: bookingData.jobDetails.categoryId,
    jobPriorityId: bookingData.jobDetails.jobPriorityId,
    serviceTypeId: bookingData.jobDetails.serviceTypeId,
    jobTypeIds: bookingData.jobDetails.jobTypeIds || [], // Add default empty array
    callOutFeeId: bookingData.jobDetails.callOutFeeId,
    preferredDate: bookingData.jobDetails.preferredDate,
    preferredTime: bookingData.jobDetails.preferredTime,
    jobDescription: bookingData.jobDetails.jobDescription,
    specialBookingId: bookingData.jobDetails.specialBookingId,
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_FIELD":
        return { ...state, [action.field]: action.value };
      case "RESET":
        return initialFormState;
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  useEffect(() => {
    const industry = industriesList.find(
      (ind) => ind.id === formState.industryId
    );
    setSelectedIndustry(industry);
  }, [formState.industryId, industriesList]);

  const handleClickIndustry = (industry) => {
    setSelectedIndustry(industry);
    dispatch({
      type: "UPDATE_FIELD",
      field: "industryId",
      value: industry.id,
    });
  };

  const handleClickCategory = (category) => {
    dispatch({ type: "UPDATE_FIELD", field: "categoryId", value: category.id });
  };

  const handleJobTypeClick = (jobType) => {
    const jobTypeId = getAllJobTypes().find((jt) => jt.title === jobType)?.id;
    if (jobTypeId && !formState.jobTypeIds.includes(jobTypeId)) {
      dispatch({
        type: "UPDATE_FIELD",
        field: "jobTypeIds",
        value: [...formState.jobTypeIds, jobTypeId],
      });
    }
  };

  const handleRemoveJobType = (jobType) => {
    const jobTypeId = getAllJobTypes().find((jt) => jt.title === jobType)?.id;
    if (jobTypeId) {
      dispatch({
        type: "UPDATE_FIELD",
        field: "jobTypeIds",
        value: formState.jobTypeIds.filter((id) => id !== jobTypeId),
      });
    }
  };

  const getAllJobTypes = () => {
    return industriesList.flatMap((industry) => industry.jobTypes);
  };

  const formatDate = (date) => {
    if (!date) return null;
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSave = () => {
    updateBookingData("jobDetails", formState);
    cancel();
  };

  return (
    <div className={styles.modalJobDetails}>
      <p className={`${styles.fontMedium} bold`}>Industry</p>
      <div className={styles.industryButtonContainer}>
        {industriesList.map((industry) => (
          <IndustryButtons
            key={industry.id}
            industry={industry.department}
            selected={formState.industryId === industry.id}
            onClick={() => handleClickIndustry(industry)}
          />
        ))}
      </div>
      <p className={`${styles.fontMedium} bold`}>Category</p>
      <div className={styles.categoryButtonContainer}>
        {categoryTypesList.map((category) => (
          <CategoryButtons
            key={category.id}
            category={category.title}
            selected={formState.categoryId === category.id}
            onClick={() => handleClickCategory(category)}
          />
        ))}
      </div>
      <div className={styles.jobPrioServiceContainer}>
        <div className={styles.jobPrioService}>
          <p className={`${styles.fontMedium} bold`}>Job Priority</p>
          <BookingDropdown
            leftitem={
              jobPriorityList.find(
                (item) => item.id === formState.jobPriorityId
              )?.title || "Choose Job Priority"
            }
            width="315px"
            height="47px"
            onChange={(value) => {
              const selectedPriority = jobPriorityList.find(
                (item) => item.title === value
              );
              dispatch({
                type: "UPDATE_FIELD",
                field: "jobPriorityId",
                value: selectedPriority.id,
              });
            }}
            dropdownItems={jobPriorityList.map((item) => item.title)}
          />
        </div>
        <div className={styles.jobPrioService}>
          <p className={`${styles.fontMedium} bold`}>Service Type</p>
          <BookingDropdown
            leftitem={
              serviceTypesList.find(
                (item) => item.id === formState.serviceTypeId
              )?.title || "Choose Service Type"
            }
            width="315px"
            height="47px"
            onChange={(value) => {
              const selectedServiceType = serviceTypesList.find(
                (item) => item.title === value
              );
              dispatch({
                type: "UPDATE_FIELD",
                field: "serviceTypeId",
                value: selectedServiceType.id,
              });
            }}
            dropdownItems={serviceTypesList.map((item) => item.title)}
          />
        </div>
      </div>
      <p className={`${styles.fontMedium} bold`}>Select Job Types</p>
      <div className={styles.jobTypeContainer}>
        <div className={styles.jobTypeSelection}>
          {formState.jobTypeIds && formState.jobTypeIds.length === 0 ? (
            <p>Enter job type</p>
          ) : (
            formState.jobTypeIds &&
            formState.jobTypeIds.map((jobTypeId) => {
              const jobType = getAllJobTypes().find(
                (jt) => jt.id === jobTypeId
              );
              return jobType ? (
                <SelectedJobTypeButtons
                  key={jobTypeId}
                  jobType={jobType.title}
                  onClick={() => handleJobTypeClick(jobType.title)}
                  removeClick={() => handleRemoveJobType(jobType.title)}
                />
              ) : null;
            })
          )}
        </div>
        <div className={styles.jobTypeButtonContainer}>
          {selectedIndustry &&
            industriesList
              .find((item) => item.name === selectedIndustry.name)
              ?.jobTypes.filter(
                (jobType) => !formState.jobTypeIds.includes(jobType.id)
              )
              .map((jobType) => (
                <JobTypeButtons
                  key={jobType.id}
                  jobType={jobType.title}
                  onClick={() => handleJobTypeClick(jobType.title)}
                />
              ))}
        </div>
      </div>
      <p className={`${styles.fontMedium} bold`}>Callout Fee</p>
      <div style={{ marginBottom: "29px" }}>
        <BookingDropdown
          leftitem={
            callOutFeesList.find((item) => item.id === formState.callOutFeeId)
              ?.price || "Choose Callout Fee"
          }
          width="645px"
          height="47px"
          backgroundColor="rgba(246, 246, 246, 1)"
          marginBottom="0px"
          onChange={(value) => {
            const selectedCallOutFee = callOutFeesList.find(
              (item) => item.price === value
            );
            dispatch({
              type: "UPDATE_FIELD",
              field: "callOutFeeId",
              value: selectedCallOutFee.id,
            });
          }}
          dropdownItems={callOutFeesList.map((item) => item.price)}
        />
      </div>
      <p className={`${styles.fontMedium} bold`}>Preferred Date & Time</p>
      <div className={styles.dateTimeContainer}>
        <div className={styles.dateContainer}>
          <p className={`${styles.fontMedium} bold`}>Date</p>
          <DatePickerInput
            setDate={(value) => {
              dispatch({
                type: "UPDATE_FIELD",
                field: "preferredDate",
                value: formatDate(value),
              });
            }}
          />
        </div>
        <div className={styles.timeContainer}>
          <p className={`${styles.fontMedium} bold`}>Time</p>
          <BookingDropdown
            leftitem={formState.preferredTime || "Time"}
            rightitem="/images/client/clock.svg"
            dropdownItems={timeChoices}
            width="251px"
            height="49px"
            marginBottom="0px"
            marginTop="0px"
            onChange={(value) =>
              dispatch({
                type: "UPDATE_FIELD",
                field: "preferredTime",
                value,
              })
            }
          />
        </div>
      </div>
      <p className={`${styles.fontMedium} bold`}>Add Job Description</p>
      <textarea
        className={styles.jobDescriptionInput}
        placeholder="Enter Job Description Here"
        value={formState.jobDescription}
        onChange={(e) =>
          dispatch({
            type: "UPDATE_FIELD",
            field: "jobDescription",
            value: e.target.value,
          })
        }
      ></textarea>
      <p className={`${styles.fontMedium} bold`}>Special Booking</p>
      <div>
        <BookingDropdown
          leftitem={
            specialBookingsList.find(
              (item) => item.id === formState.specialBookingId
            )?.title || "Choose Special Booking"
          }
          width="645px"
          height="47px"
          backgroundColor="rgba(246, 246, 246, 1)"
          marginBottom="0px"
          onChange={(value) => {
            const selectedSpecialBooking = specialBookingsList.find(
              (item) => item.title === value
            );
            dispatch({
              type: "UPDATE_FIELD",
              field: "specialBookingId",
              value: selectedSpecialBooking.id,
            });
          }}
          dropdownItems={specialBookingsList.map((item) => item.title)}
        />
      </div>
      <div className={styles.customerDetailsButtonContainer}>
        <BookingButton
          func={cancel}
          text="Cancel"
          style={{
            width: "90px",
            height: "44px",
            backgroundColor: "white",
            border: "1px solid rgba(231, 231, 231, 1)",
          }}
        />
        <BookingButton
          func={handleSave}
          text="Update"
          textColor="white"
          bold={1}
          style={{
            width: "88px",
            height: "44px",
            backgroundColor: "rgba(0, 148, 255, 1)",
            border: "none",
            color: "blue",
          }}
        />
      </div>
    </div>
  );
};

export default EditJobDetailsForm;
