import styles from "../addJobModals.module.css";
import { useContext } from "react";
import { JobBookingContext } from "../JobBookingContext";

const CustomerDetails = () => {
  const { selectedCustomer } = useContext(JobBookingContext);
  return (
    <>
      <p className={`${styles.fontBig} bold`}>Customer Details</p>
      <div className={styles.modalCustomerSummary}>
        <p className={`${styles.fontBig} bold`}>
          {selectedCustomer?.business_name || null}
        </p>
        <p className={styles.margin4}>{`${
          selectedCustomer?.firstName || selectedCustomer?.first_name
        } ${selectedCustomer?.lastName || selectedCustomer?.last_name}`}</p>
        <div className={styles.accountStatusSummary}>
          <p>Primary Account</p>
        </div>
        <div className={styles.accountSummaryMobilePhone}>
          <div className={styles.accountSummaryContainer}>
            <div className={styles.accountSummaryMobile}>
              <p className={`${styles.fontMedium} bold`}>Mobile</p>
              <div className={styles.summaryIndivDetailsValue}>
                <img src="/images/client/mobile.svg" alt="phone" />
                <p>{selectedCustomer?.mobile || "Mobile"}</p>
              </div>
            </div>
            <div className={styles.accountSummaryMobile}>
              <p className={`${styles.fontMedium} bold`}>Phone</p>
              <div className={styles.summaryIndivDetailsValue}>
                <img src="/images/client/mobile.svg" alt="phone" />
                <p>{selectedCustomer?.mobile || "Phone"}</p>
              </div>
            </div>
          </div>
          <div className={styles.accountSummaryEmail}>
            <p className={`${styles.fontMedium} bold`}>Email</p>
            <div className={styles.summaryIndivDetailsValue}>
              <img src="/images/client/email.svg" alt="email" />
              <p>{selectedCustomer?.email || "Email"}</p>
            </div>
          </div>
          <div className={styles.accountSummaryAddress}>
            <img src="/images/client/locationpin.svg" alt="location" />
            <p>{selectedCustomer?.address || "Address"}</p>
          </div>
          <div className={styles.accountSummaryViewProfile}>
            <img src="/images/client/profile.svg" alt="profile" />
            <p>View Profile</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetails;
