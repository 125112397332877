import { useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";

const AddSpiff = ({ closeModalMethod }) => {
    const [code, setCode] = useState();
    const [cost, setCost] = useState();
    const [active, setActive] = useState();
    const [description, setDescription] = useState();

    const [changesMade, setChangesMade] = useState(false);

    const SaveSpiff = async () => {

        let bodyObj = {
            id: null,
            org_id: 1,
            code: code,
            description: description,
            payout: cost,
            status: active
        }
        const response = await PostDB({
            branch: "/editSpiff",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Spiff has been successfully updated");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Spiff. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Spiff</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matCode" className="form-label">
                                        Spiff Active Status
                                    </label>
                                    <div className="pl-10">
                                        <WBIOSToggle active={active === 1 ? true : false} func={() => setActive(true ? 1 : 0)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="spiffCode" className="form-label">
                                        Spiff Code
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="spiffCode"
                                        className="half"
                                        id="spiffCode"
                                        placeholder="enter spiff code"
                                        defaultValue={code}
                                        onChange={(e) => { setChangesMade(true); setCode(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="spiffDesc" className="form-label">
                                        Spiff Description
                                    </label>
                                    <textarea
                                        name="spiffDesc"
                                        className="half"
                                        id="spiffDesc"
                                        placeholder="enter spiff description"
                                        defaultValue={description}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setDescription(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="spiffCost" className="form-label">
                                        Spiff Payout
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="spiffCost"
                                        className="half"
                                        id="spiffCost"
                                        placeholder="enter spiff cost"
                                        defaultValue={cost}
                                        onChange={(e) => { setChangesMade(true); setCost(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
                        <WBButton title="Save" color={"green"} func={() => SaveSpiff()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddSpiff;