import React from 'react';
import styles from './MaterialsList.module.css';
import MaterialItem from './MaterialItem';

const MaterialsList = () => {
  const {container, header, title, body} = styles;

  return (
    <div className={container}>
      <div className={header}>
        <h2 className={title}>Materials</h2>
      </div>
      <div className={body}>
        <MaterialItem purchaseNumber="224153" status="Material Ready" disableToggle={true} />
        <MaterialItem purchaseNumber="224158" status="Material Ready" disableToggle={false} />
      </div>
    </div>
  );
};

export default MaterialsList;
