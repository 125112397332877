import {Line} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import styles from './SalesAndJobsChart.module.css';
import CircularProgress from '../../common/CircularProgress/CircularProgress';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const SalesAndJobsChart = ({type}) => {
  const {
    iconSales,
    iconJobs,
    chartContainer,
    chartHeader,
    titleSection,
    viewLink,
    maxValueContainer,
    maxValueText,
    chartArea,
    jobsDataContainer,
    totalJobs,
    jobsDataItem,
    percentageValue,
    decimal,
  } = styles;

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const currentYearData = type === 'sales'
    ? [356, 280, 250, 320, 290, 270, 290, 310, 340, 330, 350, 360] // sample sales data in thousands ($)
    : [2800, 3000, 2700, 2800, 2900, 2800, 2600, 1500, 2700, 2900, 2800, 2300]; // sample jobs data

  const lastYearData = type === 'sales'
    ? [320, 250, 220, 290, 260, 240, 260, 280, 310, 300, 320, 330] // sample last year sales
    : [2500, 2600, 2400, 2500, 2600, 2500, 2300, 1200, 2400, 2600, 2500, 2000]; // sample last year jobs

  const allData = [...currentYearData, ...lastYearData];
  const maxValue = Math.max(...allData);
  const minValue = Math.min(...allData);
  const medianValue = Math.floor((maxValue + minValue) / 2);

  const efficiency = 72;

  const data = {
    labels: months,
    datasets: [
      {
        label: 'Current',
        data: currentYearData,
        borderColor: 'rgba(0, 148, 255, 1)',
        backgroundColor: context => {
          const chart = context.chart;
          const {ctx, chartArea} = chart;
          if (!chartArea) return;

          // Create gradient
          const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
          gradient.addColorStop(0, 'rgba(0, 148, 255, 0)');
          gradient.addColorStop(1, 'rgba(255, 255, 255, 0.25)');

          return gradient;
        },
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 4,
        pointBackgroundColor: 'white',
        pointBorderColor: 'rgba(0, 148, 255, 1)',
        pointBorderWidth: 2,
        pointHoverBackgroundColor: 'rgba(0, 148, 255, 1)',
        pointHoverBorderColor: 'rgba(0, 148, 255, 1)',
        fill: true,
      },
      {
        label: 'Compare Last Year',
        data: lastYearData,
        borderColor: 'rgba(218, 227, 234, 1)',
        backgroundColor: 'rgba(218, 227, 234, 1)',
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: type === 'sales' ? 'Amount ($)' : 'Number of Jobs',
        position: 'left',
        color: '#0E1E29',
        font: {
          size: 14,
          family: 'robotoBold',
        },
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          gap: 10,
          font: {
            size: 14,
            family: 'robotoRegular',
          },
          boxWidth: 8,
          boxHeight: 8,
          paddingRight: 20,
          color: '#0E1E29',
          generateLabels: function (chart) {
            const original = Legend.defaults.labels.generateLabels(chart);
            const labels = original.map(label => ({
              ...label,
              text: `${label.text}          `,
              fillStyle: label.text.includes('Current') ? 'rgba(0, 148, 255, 1)' : label.fillStyle,
            }));

            return labels;
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgb(200, 200, 200)',
        borderWidth: 1,
        padding: 12,
        displayColors: false,
        callbacks: {
          label: function (context) {
            if (type === 'sales') {
              return `${context.dataset.label}: ${context.parsed.y}k`;
            } else {
              return `${context.dataset.label}: ${context.parsed.y}`;
            }
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: '#0E1E29',
        },
        border: {
          display: false,
        },
      },
      y: {
        display: true,
        position: 'left',
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: '#0E1E29',
          padding: 10,
          callback: function (value, index, values) {
            const customTicks = [maxValue, medianValue, minValue];
            if (customTicks.includes(value)) {
              return type === 'sales' ? `${value}k` : `${(value / 1000).toFixed(1)}k`;
            }
            return '';
          },
          count: 3,
          min: minValue - (type === 'sales' ? 5 : 100),
            max: maxValue + (type === 'sales' ? 5 : 100),
        },
        border: {
          display: false,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  ChartJS.register({
    id: 'customVerticalGridLines',
    beforeDraw: chart => {
      const ctx = chart.ctx;
      const {top, bottom} = chart.chartArea;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;

      if (!xAxis || !yAxis) return;

      const datasets = chart.data.datasets;

      ctx.save();
      ctx.strokeStyle = 'rgba(249, 249, 249, 1)';
      ctx.lineWidth = 1;
      ctx.fillStyle = '';

      xAxis.getTicks().forEach((_, index) => {
        const x = xAxis.getPixelForTick(index);

        let maxYValue = Number.NEGATIVE_INFINITY;
        datasets.forEach(dataset => {
          const dataPoint = dataset.data[index];
          if (dataPoint > maxYValue) {
            maxYValue = dataPoint;
          }
        });

        const y = yAxis.getPixelForValue(maxYValue);

        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x, bottom);
        ctx.stroke();
      });

      ctx.restore();
    },
  });

  return (
    <div className={chartContainer}>
      <div className={chartHeader}>
        <div className={titleSection}>
          <div className={type === 'sales' ? iconSales : iconJobs} />
          <h2>{type === 'sales' ? 'Sales' : 'Jobs'}</h2>
        </div>
        <button className={viewLink}>View</button>
      </div>
      {type === 'sales' && (
        <div className={maxValueContainer}>
          <span className={maxValueText}>${maxValue},000</span>
        </div>
      )}
      {type === 'jobs' && (
        <div className={jobsDataContainer}>
          <span className={totalJobs}>3000</span>
          <div className={jobsDataItem}>
            <div className={percentageValue}>
              {Math.floor(efficiency)}
              <span className={decimal}>.{String(efficiency).split('.')[1] || '00'}%</span>
            </div>
            <CircularProgress value={82.1} />
            <span>(2,300) Converted</span>
          </div>
        </div>
      )}
      <div className={chartArea}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default SalesAndJobsChart;
