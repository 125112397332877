import { useContext } from "react";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import FieldUsers from "../../../Components/Settings/Users/FieldUsers";
import FieldUserModal from "../../../Components/Settings/UserManagement/FieldUserModal/FieldUserModal";
import { ModalContext } from "../../../Components/Settings/UserManagement/context/ModalContext";

const FieldUserPage = () => {
	const { isModalVisible, openModal } = useContext(ModalContext);

	const addUser = () => {
		openModal("Add Field User");
	};

	return (
		<div className="content-area-full">
			<div className="flex flex-column bg-white p-20 gap-10">
				<div className="flex justify-between">
					<div className="text-base">
						<b>Field Users</b>
					</div>
					<WBButton title="Add New Field User" func={addUser} color={"white"} />
				</div>
				<WBTable
					tableData="/settings/field_users"
					tableHeaders={[
						"",
						"user",
						"type",
						"work email",
						"mobile",
						"organisation",
						"status",
						"action",
					]}
				>
					<FieldUsers />
				</WBTable>
			</div>
			{isModalVisible && <FieldUserModal />}
		</div>
	);
};

export default FieldUserPage;
