import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const DiscountLogic = ({ editFunc, discountLogicSelected }) => {
    const deleteUser = () => { };
    const [dLogicFormatted, formatDLogic] = useState();

    const dLogics = useWBTableData();

    useEffect(() => {
        // console.log("DLogic: ", dLogics);
        if (dLogics !== null && dLogics !== undefined) {
            if (dLogics.discounts.length > 0) {
                formatDLogic(
                    dLogics.discounts.map((discount) => (
                        <div key={discount.id} className="row" onDoubleClick={() => editFunc(discount.id)}>
                            <div className="staticAuto cell">{discount.title}</div>
                            <div className="cell">{discount.description}</div>
                            <div className="cell">{discount.discount}</div>
                            <div className="cell">{discount.type}</div>
                            <div className="cell">{discount.active === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => editFunc(discount.id)} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(discount.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
        }
    }, [dLogics])
    return (
        <div className="body" id="bodyData">{dLogicFormatted}</div>
    )
}

export default DiscountLogic;