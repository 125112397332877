import React, { useState, useReducer, useEffect, useContext } from "react";
import styles from "./schedulermodal.module.css";
import SchedulerButton from "./components/SchedulerButton";

const SchedulerModal = ({ onClose }) => {
  const items = [
    { title: "CCTV CAMERA INSPECTION AND DRAIN LOCATOR", price: 602.4 },
    { title: "STRAIGHT WASHER CHANGE WITH MACHINING", price: 602.4 },
  ];

  const total = 2341.86;
  const discount = 0.0;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <div className={styles.modalHeaderLeft}>
            <div className={styles.modalJobNumber}>
              <p className={styles.jobCardText}>Job Card</p>
              <h2 className={styles.jobCardNumber}>1234567890</h2>
            </div>
            <div className={styles.modalJobStatus}>
              <p>Completed</p>
            </div>
          </div>
          <div className={styles.modalHeaderRight}>
            <img
              className={styles.closeIcon}
              src="/images/scheduler/x.svg"
              alt="close"
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.modalSubHeader}>
          <div className={styles.modalSubHeaderLeft}>
            <div className={styles.selectedTab}>
              <p className={styles.bold}>Overview</p>
            </div>
            <div className={styles.unselectedTab}>
              <p>Customer Details</p>
            </div>
          </div>
          <div className={styles.modalSubHeaderRight}>
            <SchedulerButton text="View Job Details" />
          </div>
        </div>
        <div className={styles.modalSection}>
          <div className={styles.modalColumnSeparator}>
            <div className={styles.modalBodyLeft}>
              <div>
                <p className={styles.titleBold}>Site Contact</p>
                <div className={styles.infoContainer}>
                  <div className={styles.nameContainer}>
                    <p className={styles.bold}>Name</p>
                    <p>John Doe</p>
                  </div>
                  <div className={styles.customerRelationshipContainer}>
                    <p className={styles.bold}>Customer Relationship</p>
                    <p>Tenant</p>
                  </div>
                </div>
              </div>
              <div className={styles.contactsContainer}>
                <div className={styles.contacts}>
                  <p className={styles.bold}>Mobile</p>
                  <div className={styles.contactFlex}>
                    <img src="/images/scheduler/phone.svg" alt="phone" />
                    <p>0444 645 555</p>
                  </div>
                </div>
                <div className={styles.contacts}>
                  <p className={styles.bold}>Phone</p>
                  <div className={styles.contactFlex}>
                    <img src="/images/scheduler/phone.svg" alt="phone" />
                    <p>987 222 111</p>
                  </div>
                </div>
                <div className={styles.contacts}>
                  <p className={styles.bold}>Email</p>
                  <div className={styles.contactFlex}>
                    <img src="/images/scheduler/email.svg" alt="email" />
                    <p>customer@hotmail.com</p>
                  </div>
                </div>
              </div>
              <div className={styles.descriptionContainer}>
                <p className={styles.titleBold}>Description</p>
                <div className={styles.descriptionBox}>
                  <p>
                    blocked drain downstairs**water overflowing**PLS CALL Stacy
                    first to discuss quote and she is also the one responsible
                    for payments for the callout**her daughter Christine will be
                    on sitepls cba 30minsJerric
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.modalBodyRight}>
              <div className={styles.siteAddressContainer}>
                <p className={styles.titleBold}>Site Address</p>
                <div className={styles.contactFlex}>
                  <img src="/images/scheduler/location.svg" alt="location" />
                  <p>64 Cambridge Street, Lidcombe, 2141 NSW Australia</p>
                </div>
                <img
                  className={styles.mapImage}
                  src="/images/scheduler/temp-map.svg"
                  alt="map"
                />
              </div>
              <div className={styles.informationContainer}>
                <p className={styles.titleBold}>Info</p>
                <div className={styles.infoBox}>
                  <div className={styles.jobDetailsSection}>
                    <div className={styles.jobDetailsLeft}>
                      <div className={styles.jobDetailItem}>
                        <p className={styles.bold}>Job Type</p>
                        <p>Maintenance</p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className={styles.bold}>Job Priority</p>
                        <p>High Priority</p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className={styles.bold}>Industry</p>
                        <p>Plumbing</p>
                      </div>
                    </div>
                    <div className={styles.jobDetailsRight}>
                      <div className={styles.jobDetailItem}>
                        <p className={styles.bold}>Service Type</p>
                        <p>Emergency Service</p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className={styles.bold}>Callout Fee</p>
                        <p>$150.00</p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className={styles.bold}>Preferred Date & Time</p>
                        <div className={styles.jobDetailsFlexItem}>
                          <img
                            src="/images/scheduler/calendar2.svg"
                            alt="calendar"
                          />
                          <p>24/03/2024</p>
                          <p>-</p>
                          <img src="/images/scheduler/clock.svg" alt="clock" />
                          <p>09:00 AM</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.visitContainer}>
            <p className={styles.titleBold}>Visits</p>
            <div className={styles.visitBarContainer}>
              <div className={styles.visitBarContainer1}>
                <div className={styles.visitFlex}>
                  <div className={styles.greyCircle}></div>
                  <p>Quoted 7 Hours</p>
                </div>
                <div className={styles.visitBar1}></div>
              </div>
              <div className={styles.visitBarContainer2}>
                <div class={styles.visitFlex}>
                  <div className={styles.orangeCircle}></div>
                  <p>Overtime 1 Hour</p>
                </div>
                <div className={styles.visitBar2}></div>
              </div>
            </div>
            <div className={styles.visitTimeline}>
              <div className={styles.visitTimeLine1}>
                <div className={styles.visitTimeLine1Hours}>
                  <p>4 Hours</p>
                </div>
                <div className={styles.visitTimeLine1Status}>
                  <img src="/images/scheduler/icon.png" alt="icon" />
                  <p>Ongoing</p>
                </div>
                <p className={styles.visitDates}>10-9-2024</p>
              </div>
              <div className={styles.visitTimeLine2}>
                <div className={styles.visitTimeLine2Hours}>
                  <p>2 Hours</p>
                </div>
                <div className={styles.visitTimeLine2Status}>
                  <img src="/images/scheduler/icon.png" alt="icon" />
                  <p>Ongoing</p>
                </div>
                <p className={styles.visitDates}>10-9-2024</p>
              </div>
              <div className={styles.visitTimeLine3}>
                <div className={styles.visitTimeLine3Hours}>
                  <p>2 Hours</p>
                </div>
                <div className={styles.visitTimeLine3Status}>
                  <img src="/images/scheduler/icon.png" alt="icon" />
                  <p>Complete</p>
                </div>
                <p className={styles.visitDates}>10-9-2024</p>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.completedSection}>
          <div className={styles.completedInfo}>
            <p className={styles.bold}>Completed</p>
            <p>on 15-09-2024</p>
          </div>
          <img src="/images/scheduler/icon.png" alt="icon" />
          <p className={styles.bold}>Jack Bradby</p>
          <p>(ELEC MANGO HILL)</p>
        </div>
        <div className={styles.modalSection}>
          <div className={styles.taskDetailsContainer}>
            <p className={styles.titleBold}>Task Details</p>
            <div className={styles.taskDetailsBox}>
              <div className={styles.workDone}>
                <p className={styles.bold}>Work Done</p>
                <p>
                  Install customer supplied dishwasher. Changed over the
                  dishwasher hose to a new stainless steel braided hose
                </p>
              </div>
              <div className={styles.workToBeDone}>
                <p className={styles.bold}>Work To Be Done</p>
                <p>N/A</p>
              </div>
              <div className={styles.estimatedTime}>
                <p className={styles.bold}>Estimated Time</p>
                <p>N/A</p>
              </div>
              <div className={styles.materialRequired}>
                <p className={styles.bold}>Material Required</p>
                <p>N/A</p>
              </div>
            </div>
          </div>
          <div className={styles.salePaymentsContainer}>
            <div className={styles.saleContainer}>
              <p className={styles.titleBold}>Sale</p>
              <div className={styles.saleBox}>
                <div className={styles.saleHeader}>
                  <div className={styles.saleHeaderLeft}>
                    <div className={styles.saleStatus}>
                      <img src="/images/scheduler/check.svg" alt="check" />
                      <p>Paid</p>
                    </div>
                    <p>3221542631</p>
                  </div>
                  <div className={styles.saleHeaderRight}>
                    <p className={styles.bold}>Monday, March 18, 2024</p>
                    <p>at 07:48 AM</p>
                  </div>
                </div>
                <div className={styles.saleBody}>
                  <div className={styles.saleDetails}>
                    <p>Sold by</p>
                    <div className={styles.saleDetailsPerson}>
                      <img src="/images/scheduler/icon.png" alt="icon" />
                      <p>Robert Fox</p>
                    </div>
                  </div>

                  <div className={styles.invoiceContainer}>
                    <table className={styles.invoiceTable}>
                      <thead>
                        <tr>
                          <th>Title</th>
                          <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item, index) => (
                          <tr key={index}>
                            <td>{item.title}</td>
                            <td>${item.price.toFixed(2)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className={styles.discountTotalContainer}>
                      <div className={styles.discountLabel}>
                        <p className={styles.bold}>Discount</p>
                        <p>${discount.toFixed(2)}</p>
                      </div>
                      <div className={styles.totalLabel}>
                        <p className={styles.bold}>Total</p>
                        <p>${total.toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.paymentsContainer}>
              <p className={styles.titleBold}>Payments</p>
              <div className={styles.paymentsBox}>
                <div className={styles.paymentEntry}>
                  <div className={styles.paymentEntryLeft}>
                    <img
                      src="/images/scheduler/paymentbill.png"
                      alt="payment"
                    />
                    <div className={styles.paymentEntryDetails}>
                      <p>Credit Card/Eftpos</p>
                      <p>19-03-2024</p>
                    </div>
                  </div>
                  <div className={styles.paymentEntryRight}>
                    <p>$300.00</p>
                  </div>
                </div>
                <div className={styles.paymentEntry}>
                  <div className={styles.paymentEntryLeft}>
                    <img
                      src="/images/scheduler/paymentbill.png"
                      alt="payment"
                    />
                    <div className={styles.paymentEntryDetails}>
                      <p>Credit Card/Eftpos</p>
                      <p>19-03-2024</p>
                    </div>
                  </div>
                  <div className={styles.paymentEntryRight}>
                    <p>$300.00</p>
                  </div>
                </div>
              </div>
              <div className={styles.paymentsTabs}>
                <div className={styles.paymentTab}>
                  <img src="/images/scheduler/media.svg" alt="media" />
                  <p className={styles.bold}>Media</p>
                  <div className={styles.greyNumber}>
                    <p>6</p>
                  </div>
                </div>
                <div className={styles.paymentTab}>
                  <img src="/images/scheduler/document.svg" alt="document" />
                  <p className={styles.bold}>Documents</p>
                  <div className={styles.greyNumber}>
                    <p>2</p>
                  </div>
                </div>
              </div>
              <div className={styles.mediaDocumentsBox}>
                <p className={styles.bold}>Videos</p>
                <div className={styles.videosContainer}>
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                </div>
                <p className={styles.bold}>Before</p>
                <div className={styles.videosContainer}>
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                  <img src="/images/scheduler/furniture.png" alt="furniture" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.modalFooter}>
          <SchedulerButton text="Cancel" />
          <SchedulerButton text="Completed" />
        </div>
      </div>
    </div>
  );
};

export default SchedulerModal;
