import styles from './CircularProgress.module.css';

const CircularProgress = ({value, size = 40, strokeWidth = 3}) => {
  const {circularProgress, circleBackground, circleProgress} = styles;

  const calculateOffset = percent => {
    return 100 - percent;
  };

  return (
    <svg 
      className={circularProgress} 
      viewBox="0 0 36 36" 
      style={{width: size, height: size}}
    >
      <circle 
        className={circleBackground} 
        cx="18" 
        cy="18" 
        r="16" 
        strokeWidth={strokeWidth}
      />
      <circle
        className={circleProgress}
        cx="18"
        cy="18"
        r="16"
        strokeWidth={strokeWidth}
        strokeDasharray="100"
        strokeDashoffset={calculateOffset(value)}
      />
    </svg>
  );
};

export default CircularProgress; 