import {useState, useEffect} from 'react';
import styles from './TimeBlocker.module.css';
import {WBButton} from '../../Components/UIComponents/WBComponents';
import SchedulerDropdown from './components/SchedulerDropdown';
import {useSchedule} from './context/ScheduleContext';
import Calendar from './components/TimeBlocker/Calendar';
import TimePicker from './components/TimeBlocker/TimePicker';

// static list of employees
const technicians = [
  {id: 1, name: 'Savannah Nguyen', role: 'Plumber'},
  {id: 2, name: 'Robert Chen', role: 'Electrician'},
  {id: 3, name: 'James Wilson', role: 'HVAC'},
  {id: 4, name: 'Emily Rodriguez', role: 'Plumber'},
  {id: 5, name: 'Michael Davis', role: 'Electrician'},
  {id: 6, name: 'Sarah Miller', role: 'HVAC'},
  {id: 7, name: 'David Johnson', role: 'Plumber'},
  {id: 8, name: 'Lisa Garcia', role: 'Electrician'},
  {id: 9, name: 'John Smith', role: 'HVAC'},
  {id: 10, name: 'Emma Brown', role: 'Plumber'},
  {id: 11, name: 'Jessica Martinez', role: 'Electrician'},
  {id: 12, name: 'Thomas Anderson', role: 'HVAC'},
  {id: 13, name: 'Sofia Patel', role: 'Plumber'},
  {id: 14, name: 'William Taylor', role: 'Electrician'},
  {id: 15, name: 'Isabella Lee', role: 'HVAC'},
];

const TimeBlockerModal = () => {
  const [activeFilter, setActiveFilter] = useState('All');
  const {
    selectedTechnicians,
    handleTechnicianSelect,
    handleSelectAllTechnicians,
    timeBlockerModal: isOpen,
    closeTimeBlockerModal: onClose,
  } = useSchedule();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const filterOptions = ['All', 'Plumber', 'Electrician', 'HVAC'];

  const handleFilterClick = filter => {
    setActiveFilter(filter);
  };

  const getSelectAllIcon = () => {
    if (selectedTechnicians.size === 0) return '/images/scheduler/tick-unselected.svg';
    if (selectedTechnicians.size === technicians.length)
      return '/images/scheduler/tick-selected.svg';
    return '/images/scheduler/tick-deselect.svg';
  };

  const getSelectAllText = () => {
    if (selectedTechnicians.size === technicians.length) return 'Unselect All Technicians';
    return 'Select All Technicians';
  };

  if (!isOpen) return null;

  const {
    modalOverlay,
    modalContainer,
    modalHeader,
    headerTitle,
    closeButton,
    modalBody,
    searchSection,
    searchInput,
    filterButtons,
    technicianSection,
    technicianHeader,
    selectAllButton,
    technicianGrid,
    technicianCard,
    checkboxWrapper,
    profileImage,
    technicianInfo,
    technicianName,
    technicianRole,
    dateTimeSection,
    dateTimeContainer,
    dateTimeLeft,
    dateTimeRight,
    inputGroupLeft,
    inputGroupRight,
    calendar,
    timePicker,
    modalFooter,
    footerLeft,
    footerRight,
  } = styles;

  return (
    <div className={modalOverlay}>
      <div className={modalContainer}>
        {/* Header */}
        <div className={modalHeader}>
          <h2 className={headerTitle}>Time Blocker</h2>
          <button onClick={onClose} className={closeButton}>
            <img src="/images/scheduler/plus.svg" alt="close" />
          </button>
        </div>

        {/* Body */}
        <div className={modalBody}>
          {/* Search Section */}
          <div className={searchSection}>
            <h3>Search</h3>
            <div className={searchInput}>
              <img src="/images/scheduler/loupe.svg" alt="search" />
              <input type="text" placeholder="Enter technician name" />
            </div>
            <div className={filterButtons}>
              {filterOptions.map(filter => (
                <WBButton
                  key={filter}
                  title={filter}
                  color="grey"
                  styles={{
                    border: activeFilter === filter && '1px solid #0094ff',
                  }}
                  func={() => handleFilterClick(filter)}
                />
              ))}
            </div>
          </div>

          {/* Technician Selector */}
          <div className={technicianSection}>
            <div className={technicianHeader}>
              <h3>Select Technicians</h3>
              <button
                className={selectAllButton}
                onClick={() => handleSelectAllTechnicians(technicians)}
              >
                <img src={getSelectAllIcon()} alt="select all" />
                {getSelectAllText()}
              </button>
            </div>

            <div className={technicianGrid}>
              {technicians.map(tech => (
                <div
                  key={tech.id}
                  className={technicianCard}
                  onClick={() => handleTechnicianSelect(tech.id)}
                >
                  <div className={checkboxWrapper}>
                    <img
                      src={
                        selectedTechnicians.has(tech.id)
                          ? '/images/scheduler/tick-selected.svg'
                          : '/images/scheduler/tick-unselected.svg'
                      }
                      alt="checkbox"
                    />
                  </div>
                  <img src="/images/scheduler/iconModal.svg" alt="" className={profileImage} />
                  <div className={technicianInfo}>
                    <span className={technicianName}>{tech.name}</span>
                    <span className={technicianRole}>{tech.role}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Date Time Section */}
          <div className={dateTimeSection}>
            <h3>Choose Date and Time</h3>
            <div className={dateTimeContainer}>
              <div className={dateTimeLeft}>
                <div className={`${inputGroupLeft} ${calendar}`}>
                  <Calendar />
                </div>
                <div className={`${inputGroupLeft} ${timePicker}`}>
                  <TimePicker />
                </div>
              </div>
              <div className={dateTimeRight}>
                <div className={inputGroupRight}>
                  <label>Reason</label>
                  <SchedulerDropdown variant="single" />
                </div>
                <div className={inputGroupRight}>
                  <label>Description</label>
                  <textarea placeholder="Enter description"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className={modalFooter}>
          <div className={footerLeft}>
            <WBButton title="Cancel" color="grey" func={onClose} />
          </div>
          <div className={footerRight}>
            <WBButton
              title="Create Time Block"
              color="grey"
              icon={<img src="/images/scheduler/tick-circle.svg" alt="plus" />}
              func={onClose}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeBlockerModal;
