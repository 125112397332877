import { WBButton } from "../../UIComponents/WBComponents";
import styles from "./SubJobs.module.css";

const SubJobs = () => {
	const {
		iconBriefCase,

		subJobs,
		left,
		right,
	} = styles;

	return (
		<div className={subJobs}>
			<div className={left}>
				<h3>SubJobs</h3>
				<WBButton
					title="436535"
					icon={<i className={iconBriefCase}></i>}
					color="grey"
					height="40px"
					styles={{ padding: "0 24px" }}
				/>
				<WBButton
					title="365643"
					icon={<i className={iconBriefCase}></i>}
					color="grey"
					height="40px"
					styles={{ padding: "0 24px" }}
				/>
			</div>
			<div className={right}>
				<h3>Callback Jobs</h3>
				<WBButton
					title="452221"
					icon={<i className={iconBriefCase}></i>}
					color="grey"
					height="40px"
					styles={{ padding: "0 24px" }}
				/>
			</div>
		</div>
	);
};

export default SubJobs;
