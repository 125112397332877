import { useState } from "react";
import SpiffItems from "../../../Components/Settings/Spiffs/SpiffItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import EditSpiff from "./Edit/EditSpiff";
import AddSpiff from "./Add/AddSpiff";

const SpiffsPage = () => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);


  const toggleAddSpiffModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditSpiffModal = (id) => { setIsModalEditOpen(!isModalEditOpen); if (id) toggleEditSpiffModal(); };

  const [editedID, setEditedID] = useState();

  const headers = ["code", "description", "payout", "status", "action"];

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddSpiff closeModalMethod={toggleAddSpiffModal} />}
      {isModalEditOpen && <EditSpiff closeModalMethod={toggleEditSpiffModal} editedID={editedID} />}
      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Spiffs</b>
          </div>
          <WBButton title="Add New Spiff" func={toggleAddSpiffModal} color={"white"} />
        </div>
        <WBTable tableDataOrg="/spiffsJson" tableHeaders={headers}>
          <SpiffItems editFunc={toggleEditSpiffModal} spiffSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default SpiffsPage;
