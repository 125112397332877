import styles from "./OfficeUserModal.module.css";
import camera from "./assets/camera.svg";
import { WBButton } from "../../../UIComponents/WBComponents";
import { WBDropdown, WBDropdownOption } from "../../../Customer/JobCard/common/WBDropdown";
import { OfficeUsersButton } from "../common/OfficeUsersButton";
import { useState, useContext } from "react";
import { ModalContext } from "../context/ModalContext";
import { initialPermissions } from "./permissionsData";
import { useOfficeUserForm } from "./useOfficeUserForm";

const OfficeUserModal = () => {
	const {
		iconCardImage,
		iconDropDown,
		iconPlus,

		addUserModalOverlay,
		addUserModal,
		addUserModalContainer,

		addUserModalHeader,
		headerTitle,
		closeButton,
		headerContent,
		headerContentImage,
		headerContentUpload,
		headerContentLabel,
		headerContentInfo,

		addUserModalBody,
		row,
		rowSingleInput,
		rowDoubleInput,
		label,
		input,

		addUserModalPermissions,
		permissionsHeader,
		permissionsBody,
		permissionItem,
		permissionText,
		permissionTextTitle,
		permissionItemWithBorder,
		createJobs,
		accountingHeader,

		addUserModalFooter,
	} = styles;

	const [permissions, setPermissions] = useState(initialPermissions);

	const { closeModal, modalTitle, userData } = useContext(ModalContext);

	const {
		formData,
		selectedTypeName,
		// selectedTypeId,
		dropdownOptions,
		loading,
		error,
		handleTypeSelection,
		handleInputChange,
		handleSaveUser,
	} = useOfficeUserForm(userData, closeModal);

	const togglePermission = (index) => {
		setPermissions(
			permissions.map((permission, i) =>
				i === index && !permission.type
					? { ...permission, isEnabled: !permission.isEnabled }
					: permission,
			),
		);
	};

	return (
		<div className={addUserModalOverlay}>
			<div className={addUserModal}>
				<div className={headerTitle}>
					<span>{modalTitle}</span>
					<button className={closeButton} onClick={closeModal}>
						<i className={iconPlus}></i>
					</button>
				</div>
				<div className={addUserModalContainer}>
					<div className={addUserModalHeader}>
						<div className={headerContent}>
							<div className={headerContentImage}>
								<img src={camera} alt="user profile placeholder" />
							</div>
							<div className={headerContentUpload}>
								<span className={headerContentLabel}>Image Avatar</span>
								<WBButton
									title="Upload Image"
									icon={<i className={iconCardImage}></i>}
									color="grey"
									height="48px"
									width="100%"
									styles={{
										padding: "14.5px 20px",
										fontSize: "16px",
										justifyContent: "flex-start",
									}}
								/>
								{modalTitle === "Edit Office User" && (
									<span className={headerContentInfo}>
										Uploading new image will overwrite previous profile image
									</span>
								)}
							</div>
						</div>
					</div>
					<div className={addUserModalBody}>
						<div className={`${row} ${rowSingleInput}`}>
							<label htmlFor="name" className={label}>
								Display Name
							</label>
							<input
								type="text"
								id="name"
								className={input}
								placeholder="Enter company name"
								value={formData.name}
								onChange={handleInputChange}
							/>
						</div>
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label htmlFor="email_work" className={label}>
									Work Email
								</label>
								<input
									type="text"
									id="email_work"
									className={input}
									placeholder="Enter work email"
									value={ formData.email_work}
									onChange={handleInputChange}
								/>
							</div>
							<div>
								<label htmlFor="email_personal" className={label}>
									Personal Email
								</label>
								<input
									type="text"
									id="email_personal"
									className={input}
									placeholder="Enter personal email"
									value={formData.email_personal}
									onChange={handleInputChange}
								/>
							</div>
						</div>
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label htmlFor="phone_mobile" className={label}>
									Mobile Number
								</label>
								<input
									type="text"
									id="phone_mobile"
									className={input}
									placeholder="Enter mobile number"
									value={formData.phone_mobile}
									onChange={handleInputChange}
								/>
							</div>
							<div>
								<label htmlFor="phone_other" className={label}>
									Phone Number
								</label>
								<input
									type="text"
									id="phone_other"
									className={input}
									placeholder="Enter phone number"
									value={formData.phone_other}
									onChange={handleInputChange}
								/>
							</div>
						</div>
						<div className={`${row} ${rowSingleInput}`}>
							<label htmlFor="address_home" className={label}>
								Home Address
							</label>
							<input
								type="text"
								id="address_home"
								className={input}
								placeholder="Enter home address"
								value={formData.address_home}
								onChange={handleInputChange}
							/>
						</div>
						<div className={`${row} ${rowSingleInput}`}>
							<label className={label}>Type</label>

							<WBDropdown
								leftitem={selectedTypeName || "Select Type"}
								rightitem={<i className={iconDropDown} />}
								color="green"
								height="48px"
								width="100%"
								borderRadius="10px"
								leftItemPadding="0 24px"
								rightItemPadding="0 20px 0 0"
								style={{
									boxShadow: "0px 4px 4px 0px #0000000A",
									border: "none",
								}}
								background={"linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"}
							>
								{loading ? (
									<WBDropdownOption key="loading" title="Loading..." />
								) : error ? (
									<WBDropdownOption key="error" title={error} />
								) : (
									dropdownOptions.map((option) => (
										<WBDropdownOption
											key={option.id}
											id={option.id}
											title={option.name}
											func={() => handleTypeSelection(option.id, option.name)}
											pos="center"
										/>
									))
								)}
							</WBDropdown>
						</div>
					</div>
					<div className={addUserModalPermissions}>
						<div className={permissionsHeader}>
							<span>Permissions</span>
						</div>
						<div className={permissionsBody}>
							{permissions.map((permission, index) => (
								<div key={index}>
									{permission.type === "header" ? (
										<div className={accountingHeader}>
											<strong>{permission.text}</strong>
										</div>
									) : (
										<div
											className={`${permissionItem} ${
												permission.hasTitle ? permissionItemWithBorder : ""
											} ${index === 0 ? createJobs : ""}`}
										>
											{permission.hasTitle ? (
												<div className={permissionText}>
													<span className={permissionTextTitle}>
														{permission.title}
													</span>
													<span>{permission.description}</span>
												</div>
											) : (
												<span>{permission.text}</span>
											)}
											<OfficeUsersButton
												isEnabled={permission.isEnabled}
												onClick={() => togglePermission(index)}
											/>
										</div>
									)}
								</div>
							))}
						</div>
					</div>

					<div className={addUserModalFooter}>
						<WBButton
							title="Cancel"
							color="grey"
							height="40px"
							styles={{
								padding: "12px 24px",
								fontSize: "16px",
								justifyContent: "flex-start",
							}}
							onClick={closeModal}
						/>
						<WBButton
							title={`${
								modalTitle === "Add Office User" ? "Create User" : "Save Changes"
							}`}
							color="green"
							height="40px"
							bold={true}
							styles={{
								padding: "12px 24px",
								fontSize: "16px",
								justifyContent: "flex-start",
							}}
							func={handleSaveUser}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OfficeUserModal;
