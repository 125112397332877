import styles from './MaterialsUsedTable.module.css';

const MaterialsUsedTable = () => {
  const {materialsTable, title, productColumn, quantityColumn, costColumn, totalRow, wrapper} = styles;

  return (
    <div>
      <h2 className={title}>Materials used from Truck</h2>
      <div className={wrapper}>
        <table className={materialsTable}>
          <thead>
            <tr>
              <th className={productColumn}>Product</th>
              <th className={quantityColumn}>Quantity</th>
              <th className={costColumn}>Cost</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={productColumn}>Washing Machine Stainless Steel Hose</td>
              <td className={quantityColumn}>1</td>
              <td className={costColumn}>$4.00</td>
            </tr>
          </tbody>
        </table>
        <table className={totalRow}>
          <tbody>
            <tr>
              <td colSpan="2">Total Cost</td>
              <td className={costColumn}>$4.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MaterialsUsedTable;
