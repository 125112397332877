import {useState} from 'react';
import styles from './TimePicker.module.css';

const TimePicker = () => {
  const [selectedTime, setSelectedTime] = useState('10 am');

  const times = ['7 am', '8 am', '9 am', '10 am', '11 am', '12 pm', '1 pm', '2 pm'];

  return (
    <div className={styles.timePicker}>
      <h2 className={styles.title}>Time</h2>
      <div className={styles.timeList} role="radiogroup" aria-label="Select a time">
        {times.map(time => (
          <button
            key={time}
            className={`${styles.timeButton} ${selectedTime === time ? styles.selected : ''}`}
            onClick={() => setSelectedTime(time)}
            aria-checked={selectedTime === time}
            role="radio"
          >
            {time}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimePicker;
