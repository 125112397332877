import { useState } from "react";
import TagItems from "../../../Components/Settings/Tags/TagItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddTag from "./Add/AddTags";
import EditTag from "./Edit/EditTag";

const TagsPage = () => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddTagModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditTagModal = (id) => { setIsModalEditOpen(!isModalEditOpen); if (id) toggleEditTagModal(); };

  const [editedID, setEditedID] = useState();

  const headers = ["tag", "description", "status", "action"];

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddTag closeModalMethod={toggleAddTagModal} />}
      {isModalEditOpen && <EditTag closeModalMethod={toggleEditTagModal} editedID={editedID} />}
      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Tags</b>
          </div>
          <WBButton title="Add New Tag" func={toggleAddTagModal} color={"white"} />
        </div>
        <WBTable tableData="/tagsJson" tableHeaders={headers}>
          <TagItems editFunc={toggleEditTagModal} tagSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default TagsPage;
