import {Line} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import styles from './StatsGridChart.module.css';
import CircularProgress from '../../common/CircularProgress/CircularProgress';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const StatsGridChart = ({
  showPercentage,
  showJobsWon,
  showAverageSale,
  showMembership,
  efficiency,
  averageSale,
  membership,
  trend,
  isPositiveTrend,
}) => {
  const {
    chartContainer,
    header,
    titleSection,
    title,
    trendContainer,
    trendArrow,
    trendText,
    trendValue,
    percentageSection,
    percentageValue,
    decimal,
    averageSaleContainer,
    membershipContainer,
    chartWrapper,
  } = styles;

  const getHeaderTitle = () => {
    if (showPercentage) return 'Jobs Efficiency';
    if (showJobsWon) return 'Jobs Won';
    if (showAverageSale) return 'Average Sale';
    if (showMembership) return 'Memberships';
    return '';
  };

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const currentYearData = [75, 78, 76, 77, 77, 76, 77, 72, 82, 83, 82, 81];
  const lastYearData = [73, 75, 74, 75, 76, 75, 76, 70, 78, 80, 78, 77];

  // Chart JS Config
  const data = {
    labels: months,
    datasets: [
      {
        label: 'Current',
        data: currentYearData,
        borderColor: 'rgba(0, 148, 255, 1)',
        backgroundColor: 'rgba(0, 148, 255, 1)',
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
      },
      {
        label: 'Compare Last Year',
        data: lastYearData,
        borderColor: 'rgba(218, 227, 234, 1)',
        backgroundColor: 'rgba(218, 227, 234, 1)',
        tension: 0.1,
        borderWidth: 2,
        pointRadius: 0,
        pointHoverRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          gap: 10,
          font: {
            size: 14,
            family: 'robotoRegular',
          },
          boxWidth: 8,
          boxHeight: 8,
          paddingRight: 20,
          color: '#0E1E29',
          generateLabels: function (chart) {
            const original = Legend.defaults.labels.generateLabels(chart);
            const labels = original.map(label => ({
              ...label,
              // Creates extra padding between items
              text: `${label.text}          `,
            }));
            return labels;
          },
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgb(200, 200, 200)',
        borderWidth: 1,
        padding: 12,
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${context.parsed.y}%`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          color: '#0E1E29',
        },
        border: {
          display: false,
        },
      },
      y: {
        display: false,
        min: Math.min(...lastYearData, ...currentYearData) - 5,
        max: Math.max(...lastYearData, ...currentYearData) + 5,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  ChartJS.register({
    id: 'customVerticalGridLines',
    beforeDraw: chart => {
      const ctx = chart.ctx;
      const {top, bottom} = chart.chartArea;
      const xAxis = chart.scales.x;
      const yAxis = chart.scales.y;

      if (!xAxis || !yAxis) return; // Ensure scales are defined

      const datasets = chart.data.datasets;

      ctx.save();
      ctx.strokeStyle = 'rgba(249, 249, 249, 1)'; // Customize grid line color
      ctx.lineWidth = 1;

      // Loop through each tick on the x-axis
      xAxis.getTicks().forEach((_, index) => {
        // Get the x-coordinate for the vertical line
        const x = xAxis.getPixelForTick(index);

        // Find the highest y-value for this x-tick
        let maxYValue = Number.NEGATIVE_INFINITY;
        datasets.forEach(dataset => {
          const dataPoint = dataset.data[index];
          if (dataPoint > maxYValue) {
            maxYValue = dataPoint;
          }
        });

        // Get the pixel value for the maximum y-value
        const y = yAxis.getPixelForValue(maxYValue);

        // Draw the vertical line from the highest point to the bottom
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x, bottom);
        ctx.stroke();
      });

      ctx.restore();
    },
  });

  return (
    <div className={chartContainer}>
      <div className={header}>
        <div className={titleSection}>
          <h3 className={title}>{getHeaderTitle()}</h3>
          <div className={trendContainer}>
            <div className={trendArrow}>{isPositiveTrend ? '▲' : '▼'}</div>
            <span className={trendText}>
              <span className={trendValue}>{trend}%</span> from last month
            </span>
          </div>
        </div>
        {/* Show Percentage or Jobs Won */}
        {(showPercentage || showJobsWon) && (
          <div className={percentageSection}>
            <div className={percentageValue}>
              {Math.floor(efficiency)}
              <span className={decimal}>.{String(efficiency).split('.')[1] || '00'}%</span>
            </div>
            <CircularProgress value={efficiency} />
          </div>
        )}
        {/* Show Average Sale */}
        {showAverageSale && (
          <div className={averageSaleContainer}>
            <span>
              $
              {averageSale.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        )}
        {/* Show Membership */}
        {showMembership && (
          <div className={membershipContainer}>
            <span>{membership}</span>
          </div>
        )}
      </div>
      <div className={chartWrapper}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default StatsGridChart;
