import { useState, useEffect } from "react";

export const useOfficeUserForm = (initialUserData, closeModal) => {
	const [formData, setFormData] = useState({
		id: null,
		name: "",
		email_work: "",
		email_personal: "",
		phone_mobile: "",
		phone_other: "",
		address_home: "",
		org_id: 1,
		active: 1,
		active: 1,
	});

	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const [selectedTypeName, setSelectedTypeName] = useState("");
	const [selectedTypeId, setSelectedTypeId] = useState(null);
	const [isNewUser, setIsNewUser] = useState(true);

	useEffect(() => {
		fetchDropdownOptions();
		if (initialUserData) {
			setFormData({
				id: initialUserData.id,
				name: initialUserData.name || "",
				email_work: initialUserData.email_work || "",
				email_personal: initialUserData.email_personal || "",
				phone_mobile: initialUserData.phone_mobile || "",
				phone_other: initialUserData.phone_other || "",
				address_home: initialUserData.address_home || "",
				org_id: initialUserData.org_id || 1,
				active: initialUserData.active ?? 1,
			});
			setSelectedTypeId(initialUserData.role_id);
			setSelectedTypeName(initialUserData.role);
			setIsNewUser(false);
		} else {
			setIsNewUser(true);
			setFormData((prev) => ({ ...prev, active: 1 }));
		}
	}, [initialUserData]);

	const fetchDropdownOptions = async () => {
		try {
			const branchPath = "/settings/office_user_types";
			const requestOptions = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			};
			const url = process.env.REACT_APP_NODEJSAPI + branchPath;
			const response = await fetch(url, requestOptions);
			const data = await response.json();

			if (data && data.length > 0) {
				setDropdownOptions(
					data.map((option) => ({
						id: option.id,
						name: option.name,
					})),
				);
			} else {
				setDropdownOptions([{ id: "no-options", name: "No options available" }]);
			}
		} catch (e) {
			setError("Failed to load dropdown options");
			console.error("Failed to retrieve data from DB. Error:", e);
		} finally {
			setLoading(false);
		}
	};

	const handleTypeSelection = (id, name) => {
		setSelectedTypeName(name);
		setSelectedTypeId(id);
	};

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handleSaveUser = async () => {
		try {
			const payload = {
				...formData,
				user_type_id: selectedTypeId,
			};

			if (payload.id) {
				payload.user_id = payload.id;
			}
			delete payload.id;

			const url = `${process.env.REACT_APP_NODEJSAPI}/addOfficeUser`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			});

			const responseText = await response.text();

			if (response.ok) {
				console.log(
					payload.user_id ? "User updated successfully:" : "User created successfully:",
					responseText,
				);
				closeModal();
			} else {
				throw new Error(responseText || "Failed to save user");
			}
		} catch (error) {
			console.error("Error saving user:", error.message);
			setError("Failed to save user. Please try again.");
			alert("Error: " + error.message);
		}
	};

	const toggleActiveStatus = async (userId, currentActiveStatus) => {
		try {
			const newActiveStatus = currentActiveStatus === 1 ? 0 : 1;
			const payload = {
				user_id: userId,
				active: newActiveStatus,
			};

			const url = `${process.env.REACT_APP_NODEJSAPI}/statusOfficeUser`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				setFormData((prevData) => ({
					...prevData,
					active: newActiveStatus,
				}));
				return newActiveStatus;
			} else {
				throw new Error("Failed to update user status");
			}
		} catch (error) {
			console.error("Error toggling user status:", error.message);
			throw error;
		}
	};

	const deleteOfficeUser = async (userId) => {
		try {
			const url = `${process.env.REACT_APP_NODEJSAPI}/deleteOfficeUser`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ user_id: userId, delete_user: 1 }),
			});

			if (!response.ok) {
				throw new Error("Failed to delete user");
			}

			return true; // Return true if deletion was successful
		} catch (error) {
			console.error("Error deleting user:", error.message);
			throw error;
		}
	};

	return {
		formData,
		selectedTypeName,
		selectedTypeId,
		dropdownOptions,
		loading,
		error,
		handleTypeSelection,
		handleInputChange,
		handleSaveUser,
		toggleActiveStatus,
		deleteOfficeUser,
	};
};
