import {useEffect} from 'react';
import styles from './CartComponent.module.css';
import {useJobCard} from '../../context/JobCardContext';
import {WBButton} from '../../../UIComponents/WBComponents';

const CartComponent = ({isOpen, onClose}) => {
  const {
    modalData,
    updateItemQuantity,
    clearCart,
    recentlyRemovedItems,
    restoreRecentlyRemovedItem,
  } = useJobCard();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [isOpen]);

  const {
    iconPlus,

    cartModalOverlay,
    cartContainer,
    cartContent,
    cartHeader,
    cartItems,
    cartItem,
    closeButton,
    open,
    itemImageContainer,
    itemImage,
    itemDetails,
    itemDescription,
    itemPrice,
    quantityControl,
    incrementDecrement,
    emptyCartButton,
    recentlyRemovedItem,
    recentlyRemoved,
  } = styles;

  const incrementQuantity = (id, quantity) => {
    updateItemQuantity(id, quantity + 1);
  };

  const decrementQuantity = (id, quantity) => {
    if (quantity > 0) {
      updateItemQuantity(id, quantity - 1);
    }
  };

  const hasItems = modalData.some(item => item.quantity > 0);

  return (
    <>
      {isOpen && <div className={cartModalOverlay} onClick={onClose} />}
      <div className={`${cartContainer} ${isOpen ? open : ''}`}>
        <div className={cartHeader}>
          <h2>Shopping Cart</h2>
          <button onClick={onClose} className={closeButton}>
            <i className={iconPlus}></i>
          </button>
        </div>
        <div className={cartContent}>
          <div className={cartItems}>
            {modalData.map(
              item =>
                item.quantity > 0 && (
                  <div key={item.id} className={cartItem}>
                    <div className={itemImageContainer}>
                      <img src={item.image} alt={item.description} className={itemImage} />
                    </div>
                    <div className={itemDetails}>
                      <span className={itemDescription}>{item.description}</span>
                      <span className={itemPrice}>${item.price.toFixed(2)}</span>
                    </div>
                    <div className={quantityControl}>
                      <button
                        onClick={() => decrementQuantity(item.id, item.quantity)}
                        className={incrementDecrement}
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        onClick={() => incrementQuantity(item.id, item.quantity)}
                        className={incrementDecrement}
                      >
                        +
                      </button>
                    </div>
                  </div>
                )
            )}
          </div>
          {hasItems && (
            <div className={emptyCartButton}>
              <WBButton
                title="Empty the cart"
                color="grey"
                height="40px"
                styles={{padding: '10px 24px'}}
                func={clearCart}
              />
            </div>
          )}
          <div className={recentlyRemovedItem}>
            {recentlyRemovedItems.length > 0 && <h3>Recently Removed Items</h3>}
            {recentlyRemovedItems.map(item => (
              <div key={item.id} className={recentlyRemoved}>
                <span>{item.description}</span>
                <WBButton
                  title="Restore"
                  color="grey"
                  height="40px"
                  styles={{padding: '10px 24px'}}
                  func={() => restoreRecentlyRemovedItem(item.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CartComponent;
