import { useState } from "react";
import DiscountLogic from "../../../Components/Settings/Discounts/DiscountLogic";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddDiscountLogic from "./Add/AddDiscountLogic";
import EditDiscountLogic from "./Edit/EditDiscountLogic";
// import { DiscountViewJS } from "../../../lib/js/discount-view";

const DiscountLogicPage = () => {

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddDiscountLogic closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditDiscountLogic closeModalMethod={toggleEditModal} editedID={editedID} />}

      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Discounts</b>
          </div>
          <WBButton title="Add Discount" func={toggleAddModal} color={"white"} />
        </div>
        <WBTable tableData="/discountsJson" tableHeaders={["title", "description", "amount", "type", "status", "action"]}>
          <DiscountLogic editFunc={toggleEditModal} discountLogicSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default DiscountLogicPage;
