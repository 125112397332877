import {WBButton} from '../../../UIComponents/WBComponents';
import styles from './QuotesContent.module.css';
import profileQuote from '../assets/profileInvoice.svg';
import QuotesTable from './QuotesTable';
import quoteDocument from '../assets/documentText.svg';

const QuotesContent = () => {
  const {
    iconGreenCheck,
    iconSMS,
    quoteTable,
    quoteSubHeader,
    subHeaderLeft,
    quote,
    quoteNumber,
    subHeaderRight,
    quoteSummary,
    summaryCustomer,
    summaryDate,
  } = styles;

  const quoteData = {
    items: [
      {
        id: '1-117-606',
        short_desc: 'CCTV CAMERA INSPECTION AND DRAIN LOCATOR',
        categories: ['Plumbing', 'Hot Water', 'Indoor Gas Hot Water'],
        long_desc:
          'CCTV camera drain showing distance traveled and condition of pipe on screen, inserted through readily accessible opening with use of pipe locator. Includes 1st hour',
        price: 602.4,
        quantity: 1,
      },
      {
        id: '1-124-337',
        short_desc: 'STRAIGHT WASHER CHANGE WITH MACHINING',
        categories: ['Plumbing', 'Hot Water', 'Indoor Gas Hot Water'],
        long_desc:
          'Isolate main water supply, remove spindle, machine brass seat. Flush line, re assemble tap with new tap washer and Test.',
        price: 227.02,
        quantity: 1,
      },
      {
        id: 'CalloutFee',
        short_desc: 'Callout Fee',
        categories: [],
        long_desc: 'This is a service callout fee.',
        price: 49.0,
        quantity: 1,
      },
    ],
    summary: [
      {label: 'Discount', value: '$0.00 CR'},
      {label: 'Coupon Amount', value: '$0.00 CR'},
      {label: 'Sub Total', value: '$547.64'},
      {label: 'GST Tax 10%', value: '$54.76'},
      {label: 'Total (GST Included)', value: '$2,341.86'},
    ],
    documents: [
      {
        image: quoteDocument,
        date: '19-03-2024',
        technician: 'Robert Fox',
        description: 'Quote Document',
        documentId: 'QT-001',
        status: 'Pending',
      },
    ],
  };

  return (
    <div>
      <div className={quoteSubHeader}>
        <div className={subHeaderLeft}>
          <span className={quote}>Quote</span>
          <span className={quoteNumber}># QT-3221542631</span>
        </div>
        <div className={subHeaderRight}>
          <WBButton
            title="Email Quote"
            icon={<i className={iconSMS}></i>}
            color="grey"
            styles={{padding: '0 24px'}}
          />
          <WBButton title="Add Service" color="grey" styles={{padding: '0 24px'}} />
          <WBButton title="View Quote" color="grey" styles={{padding: '0 24px'}} />
          <WBButton
            title="Accept Quote"
            color="grey"
            icon={<i className={iconGreenCheck}></i>}
            styles={{padding: '0 24px'}}
          />
        </div>
      </div>

      <div className={quoteSummary}>
        <span className={summaryCustomer}>
          <img src={profileQuote} alt="Profile" />
          <span>Robert Fox</span>
        </span>
        <span className={summaryDate}>
          Monday, March 18, 2024 <span>at 07:48 AM</span>
        </span>
      </div>

      <div className={quoteTable}>
        <QuotesTable data={quoteData} />
      </div>
    </div>
  );
};

export default QuotesContent;
