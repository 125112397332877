import React, { useState, useCallback, useEffect } from "react";
import styles from "./schedulercalendar.module.css";
import ScheduledBox from "./components/ScheduledBox";
import SchedulerModal from "./SchedulerModal";
import useDragAndDrop from "./hooks/useDragAndDrop";
import useTimeIndicator from "./hooks/useTimeIndicator";
import { useSchedule } from './context/ScheduleContext';

const TIME_SLOTS = [
  "07:00 am",
  "08:00 am",
  "09:00 am",
  "10:00 am",
  "11:00 am",
  "12:00 pm",
  "01:00 pm",
  "02:00 pm",
  "03:00 pm",
  "04:00 pm",
];

// Extract calendar box component
const CalendarBox = React.memo(
  ({ index, rowHeight, rowIndex, schedulesAtPosition, isGridLocked, ...props }) => (
    <div
      key={index}
      className={styles.calendarBox}
      style={{ minHeight: `${rowHeight}px` }}
      onDragOver={!isGridLocked ? props.handleDragOver : null}
      onDragLeave={!isGridLocked ? props.handleDragLeave : null}
      onDrop={!isGridLocked ? (event) => props.handleDrop(event, index, rowIndex) : null}
    >
      {schedulesAtPosition.map((schedule) =>
        index === schedule.startIndex ? (
          <ScheduledBox
            key={schedule.id}
            {...schedule}
            draggable={!isGridLocked}
            onDragStart={!isGridLocked ? (event) => props.handleDragStart(event, schedule) : null}
            onClick={() => props.openScheduleModal(schedule)}
            stackIndex={schedulesAtPosition.indexOf(schedule)}
            isLastInStack={
              schedulesAtPosition.indexOf(schedule) ===
              schedulesAtPosition.length - 1
            }
          />
        ) : null
      )}
    </div>
  )
);

const SchedulerCalendar = ({ isGridLocked }) => {
  const { openTechProfileModal, techProfileModal } = useSchedule();
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSchedule, setActiveSchedule] = useState(null);
  const [schedules, setSchedules] = useState([
    { id: 1, startIndex: 2, span: 2, status: "scheduled", rowIndex: 1 },
    { id: 2, startIndex: 0, span: 2, status: "completed", rowIndex: 4 },
    { id: 3, startIndex: 3, span: 3, status: "ongoing", rowIndex: 2 },
    { id: 4, startIndex: 4, span: 4, status: "started", rowIndex: 7 },
    { id: 5, startIndex: 2, span: 5, status: "ongoing", rowIndex: 8 },
    { id: 6, startIndex: 3, span: 3, status: "completed", rowIndex: 10 },
    // ... add other initial schedules
  ]);
  const currentTimePosition = useTimeIndicator(styles);

  const openScheduleModal = (schedule) => {
    setActiveSchedule(schedule);
    setModalOpen(true);
  };

  useEffect(() => {
    console.log("isGridLocked", isGridLocked);
  }, [isGridLocked]);

  const { handleDragStart, handleDragOver, handleDragLeave, handleDrop } =
    useDragAndDrop(setSchedules, styles);

  // Memoize getScheduleAtPosition calculations
  const getScheduleAtPosition = useCallback(
    (rowIndex, index) => {
      const rowSchedules = schedules.filter(
        (schedule) => schedule.rowIndex === rowIndex
      );

      const schedulesAtCurrentPosition = rowSchedules.filter(
        (schedule) =>
          schedule.startIndex <= index &&
          index < schedule.startIndex + schedule.span
      );

      const allOverlappingSchedules = new Set();

      schedulesAtCurrentPosition.forEach((currentSchedule) => {
        const currentEnd = currentSchedule.startIndex + currentSchedule.span;

        rowSchedules.forEach((schedule) => {
          const scheduleStart = schedule.startIndex;
          const scheduleEnd = schedule.startIndex + schedule.span;

          if (
            scheduleStart < currentEnd &&
            scheduleEnd > currentSchedule.startIndex
          ) {
            allOverlappingSchedules.add(schedule);
          }
        });
      });

      return Array.from(allOverlappingSchedules).sort(
        (a, b) => a.startIndex - b.startIndex
      );
    },
    [schedules]
  );

  // Calculate row height helper
  const calculateRowHeight = useCallback(
    (rowIndex) => {
      const schedulesInRow = schedules.filter(
        (schedule) => schedule.rowIndex === rowIndex
      );
      const maxStackIndex = Math.max(
        ...schedulesInRow.map((schedule) => {
          const overlappingSchedules = getScheduleAtPosition(
            rowIndex,
            schedule.startIndex
          );
          return overlappingSchedules.length;
        }),
        0
      );

      return Math.max(65, (maxStackIndex - 1) * 24 + 55 + 10);
    },
    [getScheduleAtPosition, schedules]
  );

  const renderCalendarRow = useCallback(
    (rowIndex) => {
      const rowHeight = calculateRowHeight(rowIndex);

      return (
        <div
          className={styles.calendarRowWrapper}
          style={{ minHeight: `${rowHeight}px` }}
        >
          <div
            className={styles.calendarRow}
            style={{ minHeight: `${rowHeight}px` }}
          >
            <div
              className={styles.calendarFirstBox}
              style={{ minHeight: `${rowHeight}px` }}
              onDoubleClick={(event) => openTechProfileModal({
                name: 'Ralph Edwards',
                role: 'Plumber',
              }, rowIndex, event)}
            >
              <div className={`${styles.calendarFirstBoxContent} ${
                techProfileModal.isOpen && techProfileModal.selectedTechId === rowIndex 
                  ? styles.calendarFirstBoxContentActive 
                  : ''
              }`}>
                <img className={styles.calendarFirstBoxIcon} src="/images/scheduler/iconModal.svg" alt="user_icon" />
                <div className={styles.calendarNames}>
                  <p className="bold">Ralph</p>
                  <p>Edwards</p>
                </div>
              </div>
            </div>
            {[...Array(10)].map((_, index) => (
              <CalendarBox
                key={index}
                index={index}
                rowHeight={rowHeight}
                rowIndex={rowIndex}
                isGridLocked={isGridLocked}
                schedulesAtPosition={getScheduleAtPosition(rowIndex, index)}
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleDragStart={handleDragStart}
                openScheduleModal={openScheduleModal}
              />
            ))}
          </div>
        </div>
      );
    },
    [
      calculateRowHeight,
      getScheduleAtPosition,
      handleDragOver,
      handleDragLeave,
      handleDrop,
      handleDragStart,
      openScheduleModal,
      isGridLocked,
      openTechProfileModal,
      techProfileModal.isOpen,
    ]
  );

  // Modify the renderCalendarBox function to use getScheduleAtPosition directly
  const renderCalendarBox = (index, rowIndex, rowHeight) => {
    const schedulesAtPosition = getScheduleAtPosition(rowIndex, index);
    
    return (
      <div
        key={index}
        className={styles.calendarBox}
        style={{ minHeight: `${rowHeight}px` }}
        onDragOver={!isGridLocked ? handleDragOver : null}
        onDragLeave={!isGridLocked ? handleDragLeave : null}
        onDrop={!isGridLocked ? (event) => handleDrop(event, index, rowIndex) : null}
      >
        {schedulesAtPosition.map((schedule) =>
          index === schedule.startIndex ? (
            <ScheduledBox
              key={schedule.id}
              {...schedule}
              draggable={!isGridLocked}
              onDragStart={!isGridLocked ? (event) => handleDragStart(event, schedule) : null}
              onClick={() => openScheduleModal(schedule)}
              stackIndex={schedulesAtPosition.indexOf(schedule)}
              isLastInStack={
                schedulesAtPosition.indexOf(schedule) ===
                schedulesAtPosition.length - 1
              }
            />
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className={styles.calendarContainer}>
      <div className={styles.timeRow}>
        <div className={styles.calendarFirstBox}></div>
        {TIME_SLOTS.map((timeSlot, index) => (
          <div
            key={index}
            className={`${styles.calendarBox} ${styles.calendarBoxCenter}`}
          >
            <p>{timeSlot}</p>
          </div>
        ))}
      </div>
      {currentTimePosition > 136 && (
        <div
          className={styles.currentTimeIndicator}
          style={{
            left: `${currentTimePosition}px`,
            position: "absolute",
            top: 0,
            height: "100%",
          }}
        >
          <div className={styles.currentTimeIndicatorDot} />
        </div>
      )}
      <div className={styles.calendarRowWrapper} style={{ minHeight: `${calculateRowHeight(1)}px` }}>
        <div className={styles.calendarRow} style={{ minHeight: `${calculateRowHeight(1)}px` }}>
          <div 
            className={`${styles.calendarFirstBox} ${styles.calendarBoxBlue}`}
            style={{ minHeight: `${calculateRowHeight(1)}px` }}
          >
            <p className={styles.calendarOfficeTitle}>Office</p>
          </div>
          {[...Array(10)].map((_, index) => (
            <CalendarBox
              key={index}
              index={index}
              rowHeight={calculateRowHeight(1)}
              rowIndex={1}
              isGridLocked={isGridLocked}
              schedulesAtPosition={getScheduleAtPosition(1, index)}
              handleDragOver={handleDragOver}
              handleDragLeave={handleDragLeave}
              handleDrop={handleDrop}
              handleDragStart={handleDragStart}
              openScheduleModal={openScheduleModal}
            />
          ))}
        </div>
      </div>
      <div className={styles.calendarJobTitleRow}>
        <p>Plumbing</p>
      </div>
      {[2, 3, 4, 5, 6].map((rowIndex) => renderCalendarRow(rowIndex))}
      <div className={styles.calendarJobTitleRow}>
        <p>Electrical</p>
      </div>
      {[7, 8, 9, 10].map((rowIndex) => renderCalendarRow(rowIndex))}
      {isModalOpen && (
        <SchedulerModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          scheduleData={activeSchedule}
        />
      )}
    </div>
  );
};

export default SchedulerCalendar;
