import styles from './MilestoneAvatar.module.css';

const MilestoneAvatar = ({label, date, time, scheduledBy = 'Clarissa T', iconSrc}) => {
  const {
    avatarContainer,
    iconLabel,
    labelText,
    label: labelStyle,
    dateTime,
    tooltip,
    iconWrapper,
    iconAvatar,
  } = styles;

  return (
    <div className={avatarContainer}>
      <div className={iconLabel}>
        <div className={labelText}>
          <span className={labelStyle}>{label}</span>
        </div>
        <div className={iconWrapper}>
          <img src={iconSrc} alt="Milestone Avatar" className={iconAvatar} />
          <div className={tooltip}>{scheduledBy}</div>
        </div>
      </div>
      <div className={dateTime}>
        <div>{date}</div>
        <div>{time}</div>
      </div>
    </div>
  );
};

export default MilestoneAvatar;
