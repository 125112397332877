import {useState} from 'react';
import {WBDropdown, WBDropdownOption} from '../../JobCard/common/WBDropdown';
import {useCustomService} from './useCustomService';
import {useJobCard} from '../../context/JobCardContext';
import styles from './BillableHoursSelector.module.css';

const BillableHoursSelector = ({onTimeChange}) => {
  const {
    iconDownArrow,

    container,
    industrySelector,
    billableHours,
    billableHoursSubtitle,
    dropdownRow,
    label,
  } = styles;

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const {selectedIndustry, handleIndustryChange} = useJobCard();

  const {industries, loading, error} = useCustomService();

  const handleChange = (type, value) => {
    let newDays = days;
    let newHours = hours;
    let newMinutes = minutes;

    switch (type) {
      case 'days':
        newDays = value;
        setDays(value);
        break;
      case 'hours':
        newHours = value;
        setHours(value);
        break;
      case 'minutes':
        newMinutes = value;
        setMinutes(value);
        break;
      default:
        // This case will never be reached with the current implementation,
        // but it satisfies the ESLint rule
        console.warn(`Unexpected time type: ${type}`);
        return;
    }

    onTimeChange({days: newDays, hours: newHours, minutes: newMinutes});
  };

  const downArrow = <i className={iconDownArrow}></i>;

  return (
    <div className={container}>
      <div className={industrySelector}>
        <label className={label}>Industry</label>
        {/* Industry Dropdown */}
        <WBDropdown
          leftitem={selectedIndustry ? selectedIndustry.department : 'Select Industry'}
          rightitem={downArrow}
          height="40px"
          width="100%"
          borderRadius="10px"
          rightItemPadding="16px 24px 16px 0"
          leftItemPadding="16px 0 16px 24px"
          background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
          style={{border: 'none'}}
        >
          {loading ? (
            <WBDropdownOption title="Loading..." />
          ) : error ? (
            <WBDropdownOption title="Error loading industries" />
          ) : (
            industries.map(industry => (
              <WBDropdownOption
                key={industry.id}
                title={industry.department}
                func={() => handleIndustryChange(industry)}
              />
            ))
          )}
        </WBDropdown>
      </div>
      <div className={billableHours}>
        <label className={label}>Billable Hours</label>
        <p className={billableHoursSubtitle}>Time it takes to complete task</p>
        <div className={dropdownRow}>
          {/* Days Dropdown */}
          <WBDropdown
            leftitem={`${days} ${days === 1 ? 'day' : 'days'}`}
            rightitem={downArrow}
            height="40px"
            width="100%"
            borderRadius="10px"
            rightItemPadding="16px 24px 16px 0"
            leftItemPadding="16px 0 16px 24px"
            background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
            style={{border: 'none'}}
            disabled={!selectedIndustry}
          >
            {[0, 1, 2, 3].map(day => (
              <WBDropdownOption
                key={`day-${day}`}
                title={`${day} ${day === 1 ? 'day' : 'days'}`}
                func={() => handleChange('days', day)}
              />
            ))}
          </WBDropdown>

          {/* Hours Dropdown */}
          <WBDropdown
            leftitem={`${hours} ${hours === 1 ? 'hour' : 'hours'}`}
            rightitem={downArrow}
            height="40px"
            width="100%"
            borderRadius="10px"
            rightItemPadding="16px 24px 16px 0"
            leftItemPadding="16px 0 16px 24px"
            background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
            style={{border: 'none'}}
            disabled={!selectedIndustry}
          >
            {[...Array(9)].map((_, index) => (
              <WBDropdownOption
                key={`hour-${index}`}
                title={`${index} ${index === 1 ? 'hour' : 'hours'}`}
                func={() => handleChange('hours', index)}
              />
            ))}
          </WBDropdown>

          {/* Minutes Dropdown */}
          <WBDropdown
            leftitem={`${minutes} minutes`}
            rightitem={downArrow}
            height="40px"
            width="100%"
            borderRadius="10px"
            rightItemPadding="16px 24px 16px 0"
            leftItemPadding="16px 0 16px 24px"
            background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
            style={{border: 'none'}}
            disabled={!selectedIndustry}
          >
            {[0, 15, 30, 45].map(minute => (
              <WBDropdownOption
                key={`minute-${minute}`}
                title={`${minute} minutes`}
                func={() => handleChange('minutes', minute)}
              />
            ))}
          </WBDropdown>
        </div>
      </div>
    </div>
  );
};

export default BillableHoursSelector;
