import styles from "./OfficeUsersButton.module.css";

export const OfficeUsersButton = ({ isEnabled = false, onClick }) => {
	const { enabled, disabled, officeUsersButton } = styles;

	return (
		<button
			className={`${isEnabled ? enabled : disabled} ${officeUsersButton}`}
			onClick={onClick}
		>
			{isEnabled ? "Enabled" : "Disabled"}
		</button>
	);
}
