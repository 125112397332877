import styles from './OrderStatusSteps.module.css';

const OrderStatusSteps = ({completedSteps = []}) => {
  const {stepsContainer, statusStep, iconCheck, incomplete} = styles;

  const steps = ['Order Placed', 'Ready for Pick Up', 'Order Picked Up'];

  return (
    <div className={stepsContainer}>
      {steps.map((step, index) => (
        <span
          key={step}
          className={`${statusStep} ${!completedSteps.includes(index) ? incomplete : ''}`}
        >
          <span className={iconCheck}></span> {step}
        </span>
      ))}
    </div>
  );
};

export default OrderStatusSteps;
