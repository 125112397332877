import React, { createContext, useState } from "react";

// Create the context
export const JobBookingContext = createContext();

// Create a provider component
export const JobBookingProvider = ({ children }) => {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [jobsiteID, setJobsiteID] = useState(null);
  const [selectedJobAddress, setSelectedJobAddress] = useState(null);
  const [notes, setNotes] = useState("");
  const [fetchedData, setFetchedData] = useState({
    industriesList: [],
    customerTypesList: [],
    paymentTermsList: [],
    customersList: [],
    jobPriorityList: [],
    contactsRelationshipList: [],
    callOutFeesList: [],
    serviceTypesList: [],
    cancellationReasonsList: [],
  });
  
  const [bookingData, setBookingData] = useState({
    customerDetails: {},
    propertyDetails: {},
    jobDetails: {},
  });

  const updateBookingData = (section, data) => {
    setBookingData((prevData) => ({
      ...prevData,
      [section]: { ...prevData[section], ...data },
    }));
    console.log("Update booking data.");
  };

  return (
    <JobBookingContext.Provider
      value={{
        selectedCustomer,
        setSelectedCustomer,
        setCustomerID,
        customerID,
        notes,
        setNotes,
        bookingData,
        updateBookingData,
        setFetchedData,
        fetchedData,
        setSelectedJobAddress,
        selectedJobAddress,
        jobsiteID,
        setJobsiteID,
      }}
    >
      {children}
    </JobBookingContext.Provider>
  );
};
