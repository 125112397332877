import {useState} from 'react';
import styles from './CreateCallBackModal.module.css';
import {WBButton} from '../../../UIComponents/WBComponents';

const CreateCallBackModal = ({isOpen, onClose}) => {
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [time, setTime] = useState('10:00');
  const [callBackId, setCallBackId] = useState('343642');
  const [jobType, setJobType] = useState('Blocked Drain');
  const [description, setDescription] = useState('');

  const resetForm = () => {
    setDate(new Date().toISOString().split('T')[0]);
    setTime('10:00');
    setDescription('');
  };

  if (!isOpen) return null;

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = () => {
    // Handle form submission
    resetForm();
    onClose();
  };

  const {
    iconCheck,
    iconCalendarBlue,
    iconClockBlue,

    modalOverlay,
    modalContainer,
    modalHeader,
    headerTitle,
    closeButton,
    iconClose,
    modalBody,
    jobLocationContainer,
    sectionTitle,
    infoGrid,
    label,
    value,
    dateTimeContainer,
    dateTimeWrapper,
    dateTimeGroup,
    dateTimeInputWrapper,
    dateTimeInput,
    descriptionContainer,
    inputIcon,
    textarea,
    modalFooter,
  } = styles;

  return (
    <div className={modalOverlay}>
      <div className={modalContainer}>
        <div className={modalHeader}>
          <h2 className={headerTitle}>Call Back</h2>
          <button onClick={handleClose} className={closeButton}>
            <i className={iconClose}></i>
          </button>
        </div>

        <div className={modalBody}>
          <div className={jobLocationContainer}>
            <h3 className={sectionTitle}>Job Location</h3>
            <div className={infoGrid}>
              <span className={label}>Call Back For</span>
              <span className={value}>{callBackId}</span>

              <span className={label}>Job Type</span>
              <span className={value}>{jobType}</span>
            </div>
          </div>

          <div className={dateTimeContainer}>
            <h3 className={sectionTitle}>Preferred Date & Time</h3>
            <div className={dateTimeWrapper}>
              <div className={dateTimeGroup}>
                <label>Date</label>
                <div className={dateTimeInputWrapper}>
                  <input
                    type="date"
                    className={dateTimeInput}
                    value={date}
                    onChange={e => setDate(e.target.value)}
                    onClick={e => e.target.showPicker()}
                  />
                  <i
                    className={`${iconCalendarBlue} ${inputIcon}`}
                    onClick={() => {
                      document.querySelector('input[type="date"]').showPicker();
                    }}
                  ></i>
                </div>
              </div>

              <div className={dateTimeGroup}>
                <label>Time</label>
                <div className={dateTimeInputWrapper}>
                  <input
                    type="time"
                    className={dateTimeInput}
                    value={time}
                    onChange={e => setTime(e.target.value)}
                    onClick={e => e.target.showPicker()}
                  />
                  <i
                    className={`${iconClockBlue} ${inputIcon}`}
                    onClick={() => {
                      document.querySelector('input[type="time"]').showPicker();
                    }}
                  ></i>
                </div>
              </div>
            </div>
            <div className={descriptionContainer}>
              <label>Description</label>
              <textarea
                className={textarea}
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Enter description here"
              />
            </div>
          </div>
        </div>

        <div className={modalFooter}>
          <WBButton title="Cancel" color="grey" styles={{padding: '0 24px'}} func={handleClose} />
          <WBButton
            title="Create Job"
            icon={<i className={iconCheck}></i>}
            color="grey"
            styles={{padding: '0 24px'}}
            func={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCallBackModal;
