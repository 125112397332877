import { useEffect, useRef, useState } from "react";
import { PostDB } from "../../DBTools";
import { WBDropdown, WBDropdownOption, WBModal, useWBTableData } from "../../UIComponents/WBComponents";

const ServiceItems = ({ editFunc, serviceSelected }) => {
    const selectedService = useRef();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const toggleDeleteServiceModal = (service) => {
        service !== 0 ? selectedService.current = service : selectedService.current = 0;
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const [spinnerActive, setSpinnerActive] = useState(false);

    const deleteService = async () => {
        let removedService = selectedService.current.id;
        const response = await PostDB({
            branch: "/removeService", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: removedService })
            }
        });
        if (response === "success") {
            alert("Service has been removed successfully. Please refresh to reflect these changes.");
        }
        else {
            alert("This service could not be removed. Please try again later.");
        }
        toggleDeleteServiceModal();
    }

    const thumbNailExist = (media) => {
        let myThumbnail = media.find(x => x.is_thumbnail === 1);
        return myThumbnail !== undefined ? myThumbnail.media_link : null;
    }

    const [servicesFormatted, formatServices] = useState(<></>);

    const items = useWBTableData();
    useEffect(() => {
        setSpinnerActive(true);
        if (items !== null && items !== undefined) {
            if (items.services.length > 0) {
                // let myThumbnail = items.services.media.find(x => x.is_thumbnail === 1);
                formatServices(
                    items.services.map((service) => (
                        <div key={service.id} className="row" id={"service" + service.id} onDoubleClick={() => { serviceSelected(service.id); editFunc(); }}>
                            <div className="cell serviceLogo">
                                {
                                    <img
                                        src={thumbNailExist(service.media) ? thumbNailExist(service.media) : "/images/wurkbox_logo.svg"}
                                        alt="Thumbnail"
                                    />
                                }
                            </div>
                            <div className="serviceTitle cell title">
                                <div className="shortDesc" title={service.code}>
                                    {/* <i className="icon-active"></i> */}
                                    {service.code ? <b>{service.code}</b> : <i>No code available</i>}
                                </div>
                                <div className="longDesc" title={service.short_desc}>
                                    {service.short_desc}
                                </div>
                                <div className="categories">
                                    <p>{service.trade}</p>
                                    {service.category && <div className="ml-10" ><i className="icon-arrow-right-light mr-10"></i> <p>{service.category}</p></div>}
                                    {service.sub_category && <div className="ml-10" ><i className="icon-arrow-right-light mr-10"></i> <p>{service.sub_category}</p></div>}
                                    {/* {service.category &&
                                        <div className="category">
                                        </div>}
                                    {service.sub_category &&
                                        <div className="subcategory">
                                            <i className="icon-arrow-right-light mr-10"></i>{" "}
                                            {service.sub_category}{" "}
                                        </div>} */}
                                </div>
                            </div>
                            {/* <div className="cell">
                                <div className="categories">
                                    <div className="industry">
                                        <b>{service.trade}</b>
                                    </div>
                                    {service.category &&
                                        <div className="category">
                                            <i className="icon-arrow-right-light mr-10"></i> {service.category}{" "}
                                        </div>}
                                    {service.sub_category &&
                                        <div className="subcategory">
                                            <i className="icon-arrow-right-light mr-10"></i>{" "}
                                            {service.sub_category}{" "}
                                        </div>}
                                </div>
                            </div> */}
                            <div className="cell">---</div>
                            <div className="cell">---</div>
                            <div className="cell">---</div>
                            <div className="cell">---</div>
                            <div className="cell">---</div>
                            {/* <div className="cell">Active</div> */}
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align={"end"}>
                                    <WBDropdownOption title="Edit" func={() => { serviceSelected(service.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => toggleDeleteServiceModal(service)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                );
            }
            else {
                formatServices(<div>No items found</div>);
            }
        }
        else {
            formatServices(<div>No items found</div>);
        }
        setSpinnerActive(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <div className="body" id="bodyData">
            {isDeleteModalOpen && <WBModal closeFunction={toggleDeleteServiceModal} submitFunction={deleteService} title={'Service named "' + selectedService.current.short_desc + '"'} description={"Are you sure you want to delete this Service Item?"}><p>This cannot be reversed once completed.</p></WBModal>}
            {!spinnerActive && servicesFormatted}
        </div>
    )
}

export default ServiceItems;