import styles from "../properties.module.css";
import { useState } from 'react'

const PropertiesTable = ({ jobHeaderItems, jobs }) => {
  const [selectedItemId, setSelectedItemId] = useState(jobHeaderItems[0]?.id || '');

  const handleItemClick = (id) => {
    setSelectedItemId(id); 
  };

  const truncateDescription = (description, maxLength = 110) => {
    if (description.length <= maxLength) return description;
    return `${description.slice(0, maxLength)}<span class="${styles.readMore}">... Read More</span>`;
  };

  return (
    <>
      <div className={styles.jobsTable}>
        <div className={styles.jobsTableHeader}>
          {jobHeaderItems.map((item) => (
            <a
              key={item.id}
              className={`nav-item ${
                item.id === selectedItemId ? styles.jobsTableSpanSelected : ""
              }`}
              onClick={() => handleItemClick(item.id)}
            >
              {item.iconSrc && (
                <img
                  src={item.iconSrc}
                  alt={`${item.label} icon`}
                  className={styles.navIcon}
                />
              )}
              <span>{item.label}</span>
              {item.hasNotification && (
                <span className={styles.jobsNotification}>
                  {item.notificationCount}
                </span>
              )}
            </a>
          ))}
        </div>
        <div>
          <table className={styles.jobsTableList}>
            <thead>
              <tr>
                <th style={{ width: "160px" }}>Job #</th>
                <th>Booking date</th>
                <th>Description</th>
                <th>Type</th>
                <th>Priority</th>
                <th>Amount</th>
                <th>Paid</th>
                <th>Balance</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job) => (
                <tr key={job.id}>
                  <td>
                    <div className={styles.jobButton}>
                      <img src="/images/client/small-briefcase.svg" alt="briefcase" />
                      <p>{job.jobNumber}</p>
                    </div>
                  </td>
                  <td>{job.bookingDate}</td>
                  <td className={styles.description}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: truncateDescription(job.description),
                      }}
                    />
                  </td>
                  <td>{job.type}</td>
                  <td>{job.priority}</td>
                  <td>{job.amount}</td>
                  <td>{job.paid}</td>
                  <td
                    className={`${styles.balance} ${
                      parseFloat(job.balance.replace("$", "")) > 0
                        ? styles.positive
                        : ""
                    }`}
                  >
                    {job.balance}
                  </td>
                  <td className={`${styles.status} ${styles[job.status.toLowerCase()]}`}>
                    {job.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.summaryContainer}>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>Total</span>
              <span className={styles.summaryValue}>$500.00</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>Paid</span>
              <span className={styles.summaryValue}>$500.00</span>
            </div>
            <div className={styles.summaryItem}>
              <span className={styles.summaryLabel}>Balance</span>
              <span className={styles.summaryValue}>$0.00</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesTable;
