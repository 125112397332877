import styles from './TradesChart.module.css';
import {Bar} from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TradesChart = () => {
  const data = {
    labels: ['Plumbing', 'Electrical', 'HVAC', 'CCTV Security'],
    datasets: [
      {
        label: '',
        data: [75, 85, 45, 70],
        backgroundColor: ['#8A2BE2', '#FF1493', '#FFA500', '#00BFFF'],
        borderRadius: 4,
        borderSkipped: false,
        barThickness: 25,
        categoryPercentage: 0.8,
        barPercentage: 0.9,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'nearest',
        intersect: true,
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgb(200, 200, 200)',
        borderWidth: 1,
        padding: 12,
        displayColors: false,
        position: 'nearest',
        yAlign: 'center',
        callbacks: {
          label: function (context) {
            return `${context.label}: ${context.parsed.y}%`;
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'y',
          intersect: true
        }
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  const legendItems = [
    {color: '#8A2BE2', label: 'Plumbing'},
    {color: '#FF1493', label: 'Electrical'},
    {color: '#FFA500', label: 'HVAC'},
    {color: '#00BFFF', label: 'CCTV Security'},
  ];

  return (
    <div className={styles.chartWrapper}>
      <h3>Trades</h3>
      <div className={styles.chartContainerWrapper}>
        <div className={styles.chartContainer}>
          <Bar data={data} options={options} />
        </div>
        <div className={styles.legendContainer}>
          {legendItems.map((item, index) => (
            <div key={index} className={styles.legendItem}>
              <div className={styles.legendDot} style={{backgroundColor: item.color}} />
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TradesChart;
