import { useState } from "react";
import { WBButton } from "../../UIComponents/WBComponents";
import styles from "./NotesTabGroup.module.css";
import UnderlineTabs from "./common/UnderlineTabs";

const NotesTabGroup = () => {
	const {
		iconMessage,

		notesTabGroup,
		notesTabHeader,

		notesTabBody,
		notesTabTop,
		notesTabDate,
		notesTabAuthor,
		notesTabContent,
		notesTabBottom,

		verticalLine,
	} = styles;

	const [selectedTab, setSelectedTab] = useState("Office Notes");

	return (
		<div className={notesTabGroup}>
			<div className={notesTabHeader}>
				<UnderlineTabs
					text={"Office Notes"}
					isSelected={selectedTab === "Office Notes"}
					badge={"6"}
					onClick={() => setSelectedTab("Office Notes")}
				/>
				<UnderlineTabs
					text={"Technician Notes"}
					isSelected={selectedTab === "Technician Notes"}
					badge={"2"}
					onClick={() => setSelectedTab("Technician Notes")}
				/>
				<UnderlineTabs
					text={"Customer SMS"}
					isSelected={selectedTab === "Customer SMS"}
					badge={"2"}
					onClick={() => setSelectedTab("Customer SMS")}
				/>
			</div>

			<div className={notesTabBody}>
				<div className={notesTabTop}>
					<span className={notesTabDate}>April 15, 2024, 2:54 pm</span>
					<span className={notesTabAuthor}>Posted by Samantha </span>
				</div>
				<div className={notesTabContent}>
					<p>
						Waverley called to advise that the card she was going to use for the call
						out fee, she wont be able to use on the day so asked if we can invoice the
						call out. advised her we cant do that. she asked if she can pay it over the
						phone on the day, i advised thats doable. shes gonna go ahead with that.
					</p>
				</div>
				<div className={notesTabBottom}>
					<WBButton title="View All Notes" color="gray" styles={{ padding: "0 24px" }} />
					<WBButton
						title="Add Note"
						icon={<i className={iconMessage}></i>}
						color="grey"
						styles={{ padding: "0 24px" }}
					/>
				</div>
				<div className={verticalLine}></div>
			</div>
		</div>
	);
};

export default NotesTabGroup;
