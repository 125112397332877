import { useEffect, useState } from "react";
import { WBButton, WBDrawer } from "../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../Components/DBTools";
// import AllBillableHours from "../../../Data Source/BillableHours.json";

const BillableHourlyRatesPage = () => {
	const [billableHour, setBillableHours] = useState([]);

	const [serviceLocationsFormatted, setServiceLocationsFormatted] = useState(<></>);

	const [allSelectedTrades, setAllSelectedTrades] = useState([]);
	const [allTrades, setAllTrades] = useState([]);

	const Start = async () => {
		let trades = await PostDB({
			branch: "/settings/industries",
			json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		});
		// console.log("Trades:", trades);
		setAllTrades(trades);

		let response = await PostDB({
			branch: "/getAllBHTemplates",
			json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		});
		if (response !== undefined && response !== null) {
			let billableHours = response.map((bh) => {
				const extraHourlyRates = JSON.parse(bh.extra_data);
				let allBH = [];
				if (extraHourlyRates) {
					for (let i = 0; i < trades.length; i++) {
						if (!allBH[i]) {
							allBH[i] = {};
							for (let j = 0; j < extraHourlyRates.length; j++) {
								if (trades[i].id === extraHourlyRates[j].dept_id) {
									allBH[i].industry = extraHourlyRates[j].dept_id;
									allBH[i].industry_name = extraHourlyRates[j].department;
									if (extraHourlyRates[j].billable_type === "Standard Hours") {
										allBH[i].standardID = extraHourlyRates[j].id;
										allBH[i].standard = extraHourlyRates[j].hourly_rate;
									}
									if (extraHourlyRates[j].billable_type === "Helper Hours") {
										allBH[i].helperID = extraHourlyRates[j].id;
										allBH[i].helper = extraHourlyRates[j].hourly_rate;
									}
								}
							}
							if (!Object.keys(allBH[i]).length) {
								allBH[i].industry = trades[i].id;
								allBH[i].industry_name = trades[i].name;
								allBH[i].standardID = null;
								allBH[i].standard = 0.00;
								allBH[i].helperID = null;
								allBH[i].helper = 0.00;
							}
						}
					}
					// for (let i = allBH.length - 1; i >= 0; i--) {
					// 	if (!Object.keys(allBH[i]).length) {
					// 		allBH[i] = {
					// 			department: null,
					// 			department_name: "New Department",
					// 			standardID: null,
					// 			price_standard: 0.00,
					// 			helperID: null,
					// 			price_helper: 0.00
					// 		}
					// 		// allBH.splice(i, 1);
					// 	}
					// }
				}
				return {
					id: bh.id,
					title: bh.title,
					hourlyRates: (allBH !== null ? allBH.map((myBH) => ({
						department: myBH.industry,
						department_name: myBH.industry_name,
						standardID: myBH.standardID,
						price_standard: myBH.standard,
						helperID: myBH.helperID,
						price_helper: myBH.helper
					})) : [])
				}
			});
			let allSelectedTradesLoop = [];
			for (let i = 0; i < billableHours.length; i++) {
				let hourlyRate = billableHours[i].hourlyRates;
				let industries = [];
				for (let j = 0; j < hourlyRate.length; j++) {
					if (hourlyRate[j])
						if (industries.indexOf(hourlyRate[j].department) === -1) {
							industries.push(hourlyRate[j].department);
						}
				}
				allSelectedTradesLoop.push(industries);
			}
			// console.log("All BillableHours: ", billableHours);
			// console.log("All SelectedTrades: ", allSelectedTradesLoop);
			setBillableHours(billableHours);
			setAllSelectedTrades(allSelectedTradesLoop);
		}
	}

	useEffect(() => {
		Start();
	}, []);

	const AddBillableHour = () => {
		// console.log("Add billable hour");
		let newBH =
		{
			id: null,
			title: "New Billable Hour",
			hourlyRates: allTrades.map(trade => (
				{
					department: trade.id,
					department_name: trade.name,
					standardID: null,
					price_standard: 0.00,
					helperID: null,
					price_helper: 0.00
				}
			))
		};
		setBillableHours(bh => [...bh, newBH]);
	}

	// const AddHourlyRate = (index) => {
	// 	
	// 	let newHourlyRate = {
	// 		department: null,
	// 		department_name: "",
	// 		standardID: null,
	// 		price_standard: 0.00,
	// 		helperID: null,
	// 		price_helper: 0.00
	// 	}
	// 	setBillableHours(bh => {
	// 		const newBillableHours = [...bh];
	// 		newBillableHours[index] = {
	// 			...newBillableHours[index],
	// 			hourlyRates: [
	// 				...newBillableHours[index].hourlyRates,
	// 				newHourlyRate
	// 			]
	// 		};
	// 		return newBillableHours;
	// 	});
	// 	console.log(billableHour);
	// };

	const UpdateBHName = (index, value) => {
		if (value.length > 0 && billableHour) {
			const newBHour = [...billableHour];
			newBHour[index].title = value;
			setBillableHours(newBHour);
			// console.log("Label Updated for " + index + ": ", billableHour);
		}
	}

	// const UpdateHourlyRateDepartment = (index2, index, deptID, deptName) => {
	// 	if (deptID && deptName && billableHour[index]) {
	// 		const newBHour = [...billableHour];
	// 		let oldDepartment = newBHour[index].hourlyRates[index2].department;
	// 		newBHour[index].hourlyRates[index2].department = deptID;
	// 		newBHour[index].hourlyRates[index2].department_name = deptName;
	// 		setBillableHours(newBHour);
	// 		console.log("Department Updated for " + index + ": ", billableHour);

	// 		
	// 		let allSelectedTradesLoop = [...allSelectedTrades];
	// 		let subSelectedTradesLoop = allSelectedTradesLoop[index].filter(value => value !== oldDepartment);
	// 		subSelectedTradesLoop.push(deptID);
	// 		allSelectedTradesLoop[index] = subSelectedTradesLoop;
	// 		setAllSelectedTrades(allSelectedTradesLoop);
	// 		console.log("AllSelectedTrades updated for " + index + ": ", allSelectedTradesLoop);
	// 	}
	// }

	const UpdateHourlyRateStandard = (index2, index, value) => {
		const newBHour = [...billableHour];
		if (ValidateRate(index2, index, value)) {
			newBHour[index].hourlyRates[index2].price_standard = parseFloat(value);
			setBillableHours(newBHour);
			// console.log("Label Updated for " + index + ": ", billableHour);
		}
		else {
			newBHour[index].hourlyRates[index2].price = 0.00;
			setBillableHours(newBHour);
		}
	}

	const UpdateHourlyRateHelper = (index2, index, value) => {
		const newBHour = [...billableHour];
		if (ValidateRate(index2, index, value)) {
			newBHour[index].hourlyRates[index2].price_helper = parseFloat(value);
			setBillableHours(newBHour);
			// console.log("Label Updated for " + index + ": ", billableHour);
		}
		else {
			newBHour[index].hourlyRates[index2].price = 0.00;
			setBillableHours(newBHour);
		}
	}

	const ValidateRate = (index2, index, value) => {
		if (value > 0 && value !== null && index >= 0 && index2 >= 0) {
			if (billableHour[index]) {
				return true;
			}
			else {
				// console.log("WurkBox error: No Billable Hour at index " + index + " : ", billableHour)
				alert("New Hourly Rate could not be valildated. Error printed to console" + value);
				return false;
			}
		}
		else if (value < 0) {
			alert("Value cannot be negative. The value has been set to 0.")
			return false;
		}
		else if (value === 0) {
			alert("An hourly rate of 0 means the work is free. Update this before proceeding if you do not wish to not charge this hourly rate.");
			return false;
		}
		else {
			alert("Hourly Rate cannot be empty. This will be set to 0 until updated.")
			return false;
		}
	}

	// const RemoveHourlyRate = (index2, index) => {
	// 	let billablehourCopy = [...billableHour];
	// 	billablehourCopy[index] = {
	// 		...billablehourCopy[index],
	// 		hourlyRates: billablehourCopy[index].hourlyRates.filter((item, i) => i !== index2)
	// 	};
	// 	setBillableHours(billablehourCopy);
	// 	console.log("Removed Hourly Rate for " + index + ": ", billableHour);
	// };

	const SaveBillableHour = async (index) => {
		let bh = billableHour[index];
		if (Validation(bh)) {
			let bodyObj = {
				template_data: {
					id: bh.id,
					title: bh.title,
					extra_hours: bh.hourlyRates.flatMap(rate => [
						{
							id: rate.standardID ? rate.standardID : null,
							dept_id: rate.department,
							department: rate.department_name,
							hourly_rate: rate.price_standard,
							billable_type: "Standard Hours"
						},
						{
							id: rate.helperID ? rate.helperID : null,
							dept_id: rate.department,
							department: rate.department_name,
							hourly_rate: rate.price_helper,
							billable_type: "Helper Hours"
						}
					])
				}
			}
			const response = await PostDB({
				branch: "/setBHTemplates", json: {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(bodyObj)
				}
			});
			if (response === "Success")
				alert("Saved successfully.");
			else alert("There was an error sending this update to the Database. Please try again later.")
		}
	}

	const Validation = (bh) => {
		if (bh.title !== null && bh.title !== "") {
			for (let i = 0; i < bh.hourlyRates.length; i++) {
				if (bh.hourlyRates[i].department === null || bh.hourlyRates[i].department === 0) {
					alert("One of your Hourly Rates has no selected Department. Please update this to continue.");
					return false;
				}
				// else if (bh.hourlyRates[i].label === "" || bh.hourlyRates[i].label === null || bh.hourlyRates[i].label === undefined) {
				// 	alert("One of your Hourly Rates has no name. Please update this to continue.");
				// 	return false;
				// }
				else if (bh.hourlyRates[i].price_helper < 0 || bh.hourlyRates[i].price_standard < 0) {
					alert("One of your Hourly Rates has an invalid rate. Please update this to continue.");
					return false;
				}
			}
			return true;
		}
		alert("Please give a name to your Billable Hour Template.");
		return false;
	}

	useEffect(() => {
		// console.log("Billable Hour ready. BH:", billableHour);
		if (billableHour.length > 0) {
			setServiceLocationsFormatted(
				billableHour.map((bh, index) => (
					<div key={index}>
						<WBDrawer title={bh.title} active={true} small>
							<div className="billablehours">
								<div className="billablehour">
									<p className="title">Template Name*</p>
									<input className="billableInput half" placeholder="enter template name here" defaultValue={bh.title} onBlur={(e) => UpdateBHName(index, e.target.value)} />
								</div>
								<div className="hr" />
								<div className="billablehour">
									<div className="hourlyratetitle">
										<p className="title">Hourly Rates</p>
										{/* <WBButton title={"Add new Hourly Rate"} color={"blueandwhite"} func={() => AddHourlyRate(index)} /> */}
									</div>
									<div className="billablebody">
										{bh.hourlyRates.length > 0 ? <div className="billabletable">
											<div className="row">
												<div className="col">
													<div className="cell">
														<p>Department</p>
													</div>
												</div>
												<div className="col">
													<div className="cell">
														<p>Name</p>
													</div>
												</div>
												<div className="col">
													<div className="cell">
														<p>Hourly Rate*</p>
													</div>
												</div>
												{/* <div className="col">
													<div className="cell">
														<p>Action</p>
													</div>
												</div> */}
											</div>
											{allTrades.map((trade, i) => {
												let found = bh.hourlyRates.filter(rate => rate.department === trade.id)[0];
												return (
													<div key={i} className="row">
														<div className="col">
															<div className="cell">
																<input id={"trade" + found.department} type="text" className="billableInput" value={found.department_name} readOnly />
															</div>
														</div>
														<div className="col">
															<div className="cell">
																<div className="subrow">
																	<input className="billableInput" value="Tradesman Hours" readOnly />
																	<input className="billableInput" value="Helper Hours" readOnly />
																</div>
															</div>
														</div>
														<div className="col">
															<div className="cell">
																<div className="subrow">
																	<input type="number" className="billableInput " placeholder="enter rate" defaultValue={found.price_standard && found.price_standard.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onBlur={(e) => UpdateHourlyRateStandard(i, index, parseFloat(e.target.value))} />
																	<input type="number" className="billableInput " placeholder="enter rate" defaultValue={found.price_helper && found.price_helper.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} onBlur={(e) => UpdateHourlyRateHelper(i, index, parseFloat(e.target.value))} />
																</div>
															</div>
														</div>
														{/* <div className="col">
															<div className="cell">
																<WBButton title="Remove" icon={<i className="icon-trash-red"></i>} color="grey" func={() => RemoveHourlyRate(index2, index)} />
															</div>
														</div> */}
													</div>
												)
												// else {
												// 	return (
												// 		<div key={i} className="row">
												// 			<div className="col">
												// 				<div className="cell">
												// 					<input id={"trade" + trade.id} type="text" className="billableInput" value={trade.department} readOnly />
												// 				</div>
												// 			</div>
												// 			<div className="col">
												// 				<div className="cell">
												// 					<div className="subrow">
												// 						<input className="billableInput" value="Tradesman Hours" readOnly />
												// 						<input className="billableInput" value="Helper Hours" readOnly />
												// 					</div>
												// 				</div>
												// 			</div>
												// 			<div className="col">
												// 				<div className="cell">
												// 					<div className="subrow">
												// 						<input type="number" className="billableInput " placeholder="enter rate" defaultValue={0.00} onBlur={(e) => UpdateHourlyRateStandard(i, index, parseFloat(e.target.value))} />
												// 						<input type="number" className="billableInput " placeholder="enter rate" defaultValue={0.00} onBlur={(e) => UpdateHourlyRateHelper(i, index, parseFloat(e.target.value))} />
												// 					</div>
												// 				</div>
												// 			</div>
												// 			{/* <div className="col">
												// 			<div className="cell">
												// 				<WBButton title="Remove" icon={<i className="icon-trash-red"></i>} color="grey" func={() => RemoveHourlyRate(index2, index)} />
												// 			</div>
												// 		</div> */}
												// 		</div>
												// 	)
												// }
											})}
										</div> : <div />}
									</div>
								</div>
							</div>
							<div className="hr" />
							<div className="bottombar">
								<p className="p-20">* = required field</p>
								<div className="align-right p-20 gap-30">
									<WBButton title={"Cancel"} color="white" />
									<WBButton title={"Save Updates"} color="blue" func={() => SaveBillableHour(index)} />
								</div>
							</div>
						</WBDrawer>
					</div>
				))
			);
		} else {
			setServiceLocationsFormatted(
				<p>No service locations for this organisation</p>
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billableHour, allSelectedTrades]);

	return (
		<div className="settings-full-width">
			<div className="content-area bg-white bh">
				<div className="bhTitle">
					<p className="title">Billable Hourly Rates</p>
					<WBButton title={"+ Add Billable Rate"} color={"blueandwhite"} func={AddBillableHour} bold />
				</div>
				<div className="bhSearch">
					<input type="text" placeholder="Search name" onClick={() => alert("This has no code and doesn't work yet.")} />
				</div>
				<div className="hr" />
				<div className="bhBody">
					<p className="title" style={{ paddingLeft: "20px" }}>Global Template (default)</p>
					{serviceLocationsFormatted[0]}
				</div>
				<div className="bhBody">
					<p className="title" style={{ paddingLeft: "20px" }}>Other Templates</p>
					{serviceLocationsFormatted.length > 0 && serviceLocationsFormatted.filter((sl, i) => i !== 0)}
				</div>
			</div>
		</div>
	);
};

export default BillableHourlyRatesPage;
