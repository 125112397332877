import {useState} from 'react';
import styles from './Calendar.module.css';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hoverDate, setHoverDate] = useState(null);

  const daysInMonth = date => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  const startDay = date => new Date(date.getFullYear(), date.getMonth(), 1).getDay();

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const isDateInRange = date => {
    if (!startDate || !endDate) return false;
    return date >= startDate && date <= endDate;
  };

  const isDateInHoverRange = date => {
    if (!startDate || !hoverDate || endDate) return false;
    return date >= startDate && date <= hoverDate;
  };

  const handleDateSelect = date => {
    if (!startDate) {
      setStartDate(date);
      setEndDate(null);
    } else if (date.toDateString() === startDate.toDateString()) {
      setStartDate(null);
      setEndDate(null);
    } else if (!endDate && date > startDate) {
      setEndDate(date);
    } else {
      setStartDate(date);
      setEndDate(null);
    }
  };

  const handleDateHover = date => {
    if (startDate && !endDate && date >= startDate) {
      setHoverDate(date);
    }
  };

  const isStartDate = date => startDate && date.toDateString() === startDate.toDateString();
  const isEndDate = date => endDate && date.toDateString() === endDate.toDateString();
  const isToday = date => date.toDateString() === new Date().toDateString();

  const renderWeekRow = weekDates => {
    return (
      <div className={styles.weekRow}>
        {weekDates.map((date, index) => {
          const isStart = isStartDate(date);
          const isEnd = isEndDate(date);
          const inRange = isDateInRange(date) || isDateInHoverRange(date);

          let cellClassName = styles.dateCell;
          if (isStart) {
            cellClassName += ` ${styles.rangeStart}`;
            if (hoverDate || endDate) cellClassName += ` ${styles.hasHover}`;
          }
          if (isEnd) cellClassName += ` ${styles.rangeEnd}`;
          if (inRange && !isStart && !isEnd) cellClassName += ` ${styles.inRange}`;

          return (
            <div key={index} className={cellClassName}>
              <button
                className={`${styles.dateButton} 
                  ${isStart || isEnd ? styles.selectedDate : ''}
                  ${isToday(date) ? styles.today : ''}
                  ${date.getMonth() !== currentDate.getMonth() ? styles.otherMonth : ''}`}
                onClick={() => handleDateSelect(date)}
                onMouseEnter={() => handleDateHover(date)}
                onMouseLeave={() => setHoverDate(null)}
                type="button"
              >
                {date.getDate()}
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  const renderCalendar = () => {
    const days = [];
    const totalDays = daysInMonth(currentDate);
    const firstDay = startDay(currentDate);

    // Add previous month's days
    const prevMonthDays = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
    for (let i = firstDay - 1; i >= 0; i--) {
      days.push(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, prevMonthDays - i));
    }

    // Add current month's days
    for (let i = 1; i <= totalDays; i++) {
      days.push(new Date(currentDate.getFullYear(), currentDate.getMonth(), i));
    }

    // Add next month's days
    const remainingDays = 42 - days.length;
    for (let i = 1; i <= remainingDays; i++) {
      days.push(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, i));
    }

    // Split days into weeks
    const weeks = [];
    for (let i = 0; i < days.length; i += 7) {
      weeks.push(days.slice(i, i + 7));
    }

    return weeks.map((week, index) => (
      <div key={index} className={styles.weekContainer}>
        {renderWeekRow(week)}
      </div>
    ));
  };

  return (
    <div className={styles.calendar}>
      <h2 className={styles.title}>Date</h2>

      <div className={styles.header}>
        <button className={styles.navButton} onClick={prevMonth}>
          <img src="/images/scheduler/navigation-previous.svg" alt="arrow left" />
        </button>

        <span className={styles.monthDisplay}>
          {currentDate.toLocaleString('default', {month: 'long'})} - {currentDate.getFullYear()}
        </span>
        <button className={styles.navButton} onClick={nextMonth}>
          <img src="/images/scheduler/navigation-next.svg" alt="arrow right" />
        </button>
      </div>

      <div className={styles.weekdaysGrid}>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
          <div key={index} className={styles.weekdayLabel}>
            {day}
          </div>
        ))}
      </div>

      <div className={styles.daysContainer}>{renderCalendar()}</div>
    </div>
  );
};

export default Calendar;
