import styles from "../addJobModals.module.css";

const IndustryButtons = ({ industry, selected, onClick }) => {
  return (
    <>
      <div
        className={`${styles.industryButton} ${
          selected ? styles.selected : ""
        }`}
        onClick={onClick}
      >
        <p>{industry}</p>
      </div>
    </>
  );
};

export default IndustryButtons;
