import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const MaterialItems = ({ editFunc, materialSelected }) => {
    const deleteUser = () => { };

    const [materialsFormatted, formatMaterials] = useState(<></>);

    const materials = useWBTableData();

    useEffect(() => {
        //console.log("materials:", materials)
        if (materials !== null && materials !== undefined) {
            if (materials.materials.length > 0) {
                formatMaterials(
                    materials.materials.map((material) => (
                        <div key={material.id} className="row" onDoubleClick={() => { materialSelected(material.id); editFunc(); }}>
                            <div className="cell">{material.code}</div>
                            <div className="cell title">{material.title}</div>
                            <div className="cell description">{material.description}</div>
                            <div className="cell">{material.cost}</div>
                            <div className="cell">{material.multiplier}%</div> {/* IMPORTANT multiplier is hard coded to always equal 2, fix later */}
                            <div className="cell">${material.final_cost.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { materialSelected(material.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(material.id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatMaterials(<div>No items found</div>);
            }
        }
        else {
            formatMaterials(<div>No items found</div>);
        }
    }, [materials])
    return (
        <div className="body" id="bodyData">{materialsFormatted}</div>
    )
}

export default MaterialItems;