import { Link } from "react-router-dom";
import { WBButton, WBLoader } from "../Components/UIComponents/WBComponents";
import { CallURL } from "../Components/DBTools";
import { useState } from "react";

const Login = () => {
    const [userInputUsername, setUserInputUsername] = useState("");
    const [userInputPassword, setUserInputPassword] = useState("");
    const [isLoading, setisLoading] = useState(false);

    const toggleSpinner = () => {
        setisLoading(val => !val);
    }

    const CheckLogin = async () => {
        toggleSpinner();
        let URL = process.env.REACT_APP_TEMPLOGIN_API; // Ensure this is the correct API URL
        const response = await CallURL({
            url: URL,
            json: {
                method: 'PUT', // Ensure this matches the method in your API
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: userInputUsername,
                    password: userInputPassword
                }) // Include password in the request body
            }
        });

        if (response.ok) {
            const data = await response.json(); // Parse the response JSON
            if (data.api_status === true) {
                localStorage.setItem("sessiontoken", data.LoginToken);
                localStorage.setItem("lastLogin", data.LastLogin);
                window.location = "/";
            } else {
                alert("The credentials you entered are incorrect. Please try again.");
                toggleSpinner();
            }
        } else {
            alert("There was a problem logging you in. Please try again later.");
            toggleSpinner();
        }
    };

    return (
        <div className="flex">
            <div className="login">
                <div className="loginmodal">
                    <img src="/images/LoginLogo.png" alt="loginlogo" />
                    <div className="input">
                        <label htmlFor="username" className="form-label">Enter username</label>
                        <input id="username" type="text" className={"form-control" + (isLoading ? " disabled" : "")} placeholder={`user@example.com`} onChange={(e) => setUserInputUsername(e.target.value)} />
                    </div>
                    <div className="input">
                        <label htmlFor="password" className="form-label">Enter password</label>
                        <input id="password" type="password" className={"form-control" + (isLoading ? " disabled" : "")} placeholder={`************`} onChange={(e) => setUserInputPassword(e.target.value)} />
                        <button id="forgotpassword" onClick={() => alert("Button clicked")}>Reset Password</button>
                    </div>
                    <div className="checkbox" >
                        <input type="checkbox" />
                        <p>Remember Password</p>
                    </div>
                    <WBButton title={isLoading ? <WBLoader filter="filter-white" /> : "Login"} color={"blue"} func={() => { !isLoading && CheckLogin() }} />
                    <div className="input">
                        <p className="signup">Don't have account? Please <a href="/" onClick={() => alert("Pretend there is a fancy sign up page here")}>Sign Up</a></p>
                    </div>
                    <div className="hr" />
                    <div className="input googlesignin">
                        <p>...or alternatively sign in with your Google account</p>
                        <WBButton title={"Sign in with Google"} icon={<i className="icon-google" ></i>} color={"grey"} func={() => alert("This feature is not yet implemented. Please login above.")} />
                    </div>
                </div>
                <div className="input tos">
                    <p style={{ color: "#8d8d8d" }}>Registering to this website, you accept out <Link onClick={() => alert("Pretend this goes to the Terms of use page")}>Terms of use</Link> and our <Link onClick={() => alert("Pretend this goes to the Privacy Policy page")}>Privacy Policy</Link></p>
                </div>
                {/* <div className="removesession">
                <button onClick={() => removeSession()} >Delete session token</button>
                <button onClick={() => { localStorage.clear(); alert("Local storage cleared. This includes selected organisation, usertoken and other variables.") }} >Clear Local Storage</button>
            </div> */}
            </div>
        </div>
    )
}

export default Login;