import { useState } from "react";
import ServiceTypes from "../../../Components/Settings/Services/ServiceTypes";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddServiceType from "./Add/AddServiceType";
import EditServiceType from "./Edit/EditServiceType";

const ServiceTypesPage = () => {
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddServiceType closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditServiceType closeModalMethod={toggleEditModal} editedID={editedID} />}

      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Service Types</b>
          </div>
          <WBButton title="Add Service Type" func={toggleAddModal} color={"white"} />
        </div>
        <WBTable tableData="/settings/service_types" tableHeaders={["Service", "status", "action"]}>
          <ServiceTypes editFunc={toggleEditModal} serviceSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default ServiceTypesPage;
