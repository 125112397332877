import MediaDocumentTabs from '../../../Components/Customer/JobCard/MediaDocumentTabs';
import NotesTabGroup from '../../../Components/Customer/JobCard/NotesTabGroup';
import JobPerformance from '../../../Components/Customer/JobCard/JobPerformance';
import FinanceSummaryCards from '../../../Components/Customer/JobCard/FinanceSummaryCards';
import JobCardHeader from '../../../Components/Customer/JobCard/JobCardHeader';
import JobDetails from '../../../Components/Customer/JobCard/JobDetails';
import SubJobs from '../../../Components/Customer/JobCard/SubJobs';
import InvoiceSection from '../../../Components/Customer/JobCard/InvoiceSection';
import styles from './JobCardPage.module.css';
import LogDetails from '../../../Components/Customer/JobCard/LogDetails';
import PriceBookModal from '../../../Components/Customer/AddService/PriceBookModal/PriceBookModal';
import CustomServiceModal from '../../../Components/Customer/AddService/CustomServiceModal/CustomServiceModal';
import CreateCallBackModal from '../../../Components/Customer/JobCard/CreateCallBack/CreateCallBackModal';
import { useJobCard } from '../../../Components/Customer/context/JobCardContext';
import { JobDetailsProvider } from '../../../Components/Customer/JobCard/context/JobDetailsContext';
/**
 * http://localhost:3000/customer/123/456/789
 */

const JobCardPage = () => {
  const { jobCardPage, jobDetails, width, main, left, right, invoiceLog, materialsLogs } = styles;
  const {
    activeModalId,
    selectedTab,
    complaintLogs,
    invoiceLogs,
    quoteLogs,
    isCallbackModalOpen,
    closeCallbackModal,
  } = useJobCard();

  const getLogDetails = () => {
    switch (selectedTab) {
      case 'Complaints':
        return { type: 'Complaints', logs: complaintLogs };
      case 'Quotes':
        return { type: 'Quotes', logs: quoteLogs };
      case 'Milestone':
        return { type: 'Milestone', logs: null };
      case 'Materials':
        return { type: 'Materials', logs: materialsLogs };
      default:
        return { type: 'Sales', logs: invoiceLogs };
    }
  };

  const { type, logs } = getLogDetails();

  return (
    <div className={jobCardPage}>
      <header>
        <JobCardHeader />
      </header>
      <main className={main}>
        <div className={width}>
          <div>
            <SubJobs />
          </div>
          <div className={jobDetails}>
            <div className={left}>
              <JobDetailsProvider>
                <JobDetails />
              </JobDetailsProvider>
            </div>
            <div className={right}>
              <FinanceSummaryCards />
              <JobPerformance />
              <NotesTabGroup />
              <MediaDocumentTabs />
            </div>
          </div>
        </div>

        <div className="invoiceSection">
          <InvoiceSection />
        </div>
        {logs !== null && (
          <div className={invoiceLog}>
            <LogDetails type={type} rawLogs={logs} />
          </div>
        )}
      </main>

      {/* Modals */}
      {activeModalId === 'priceBook' && <PriceBookModal />}
      {activeModalId === 'customService' && <CustomServiceModal />}
      <CreateCallBackModal isOpen={isCallbackModalOpen} onClose={closeCallbackModal} />
    </div>
  );
};

export default JobCardPage;
