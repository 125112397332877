import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const CategoryItems = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { }; // deleteUser()

    const [categoriesFormatted, formatCategories] = useState(<></>);

    const categories = useWBTableData();

    useEffect(() => {
        if (categories !== null && categories !== undefined) {
            if (categories.categories.length > 0) {
                formatCategories(
                    categories.categories.map((category) => (
                        <div key={category.id} className="row" onDoubleClick={() => { setEditedID(category.id); editFunc(); }}>
                            <div className="static1 cell">{category.title}</div>
                            <div className="cell">{category.subcategories}</div>
                            <div className="cell">{category.trade}</div>
                            <div className="cell">{category.active === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell auto">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(category.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(category.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatCategories(<div>No items found</div>);
            }
        }
        else {
            formatCategories(<div>No items found</div>);
        }
    }, [categories])

    return (
        <div className="body" id="bodyData">{categoriesFormatted}</div>
    );
}

export default CategoryItems;