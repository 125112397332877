import { useState } from "react";
import Industries from "../../../Components/Settings/Industries/Industries";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddIndustry from "./Add/AddIndustry";
import EditIndustry from "./Edit/EditIndustry";

const IndustriesPage = () => {

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddIndustry closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditIndustry closeModalMethod={toggleEditModal} editedID={editedID} />}

      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Industries</b>
          </div>
          <WBButton title="Add Industry" func={toggleAddModal} color={"white"} />
        </div>
        <WBTable tableData="/settings/industries" tableHeaders={["Industry", "status", "action"]}>
          <Industries editFunc={toggleEditModal} setEditedID={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default IndustriesPage;
