import styles from "../addJobModals.module.css";
const SelectedJobTypeButtons = ({ jobType, onClick, removeClick }) => {
  return (
    <>
      <div className={styles.jobTypeButton} style={{ border: "2px solid rgba(0, 148, 255, 1) ", paddingRight: "8px" }} onClick={onClick}>
        <p style={{ color: "rgba(14, 30, 41, 1)" }}>{jobType}</p>
        <img src="/images/client/job-type-x.svg" onClick={removeClick}/>
      </div>
    </>
  );
};

export default SelectedJobTypeButtons;
