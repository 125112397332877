import { Link } from "react-router-dom";
import SearchModal from '../Search/SearchModal';
import { useState } from "react";

const NavItem = ({ title, link, icon, children, dropDown = false, search = false, profile = false, phone = false, bell = false, active }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const openModal = () => {
    setIsSearchOpen(true);
  };

  if (dropDown) {
    return (
      <li className="nav-item">
        <div className="btn-dropdown nav-link" data-menu="organisations">
          <div className="title">{title}</div>
          <i className="icon-arrow-down"></i>
          <div className="btn-dropdown-menu">
            <ul
              className="dropdown-menu-list"
              aria-labelledby="dropdownMenuButton1"
            >
              {children}
            </ul>
          </div>
        </div>
      </li>
    );
  }
  else if (search) {
    return (
      <>
        <div className="btn-nav-right noborder filter-white" onClick={openModal}>
          <i className="icon-search"></i>
        </div>
        <SearchModal isOpen={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
      </>
    );
  }
  else if (phone) {
    return (<div className="btn-nav-right noborder" onClick={() => alert("This will display the phone modal")}>
      <i className="icon-phone filter-white"></i>
    </div>)
  }
  else if (profile) {
    return (
      <div id="profile-image" className="btn-dropdown" data-menu="profile">
        <img src={icon} width="46" height="46" alt="" />
        <div className="btn-dropdown-menu">
          <ul className="dropdown-menu-list" aria-labelledby="dropdownMenuButton1">
            {children}
          </ul>
        </div>
      </div>);
  }
  else if (bell) {
    return (
      <div className="btn-nav-right noborder filter-white">
        <i className="icon-bell notification"></i>
      </div>
    )
  }
  else {
    return (
      <li className="nav-item" >
        <Link to={link} className={`nav-link ${active}`}>
          {icon ? <i className={icon}></i> : ""}
          <p className="filter-white">{title}</p>
        </Link>
      </li>
    );
  }

};

export default NavItem;
