import { useState } from "react";
import CustomerType from "../../../Components/Settings/Users/CustomerType";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddCustomerType from "./Add/AddCustomerType";
import EditCustomerType from "./Edit/EditCustomerType";

const CustomerTypePage = () => {
  //Temporary methods until js integration. Original version on right of method
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } // addService()
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddCustomerType closeModalMethod={toggleAddModal} />}
      {isModalEditOpen && <EditCustomerType closeModalMethod={toggleEditModal} editedID={editedID} />}
      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Customer Types</b>
          </div>
          <WBButton title="Add New Customer Type" func={toggleAddModal} color={"white"} />
        </div>
        <WBTable tableData="/settings/customer_types" tableHeaders={["type", "status", "action"]}>
          <CustomerType editFunc={toggleEditModal} customerTypeSelected={setEditedID} />
        </WBTable>
      </div>
    </div>
  );
};

export default CustomerTypePage;
