import styles from "../properties.module.css";

const PropertiesBar = () => {

  return (
    <>
      <div className={styles.propertyHeader}>
        <div className={styles.propertyHeaderLeft}>
          <div>
            <img src="/images/client/building.svg" alt="Building Icon" />
            <h3>Select Property</h3>
          </div>
          <div className={styles.propertyHeaderDropdown}>
            <span>64 Cambridge Street, Lidcombe, 2141 NSW Australia</span>
            <img src="/images/client/chevron-down.svg" alt="Chevron Down" />
          </div>
        </div>
        <div className={styles.propertyHeaderRight}>
          <a href="" className={styles.propertyHeaderEdit}>
            <img src="/images/client/edit.svg" alt="Edit Icon" />
          </a>
        </div>
      </div>
    </>
  );
};

export default PropertiesBar;
