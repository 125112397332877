import styles from './DashboardPage.module.css';
import FilterSection from './components/FilterSection/FilterSection';
import StatsGrid from './components/StatsGrid/StatsGrid';
import SalesAndJobsChart from './components/SalesAndJobsChart/SalesAndJobsChart';
import JobTypes from './components/JobTypes/JobTypes';
import CallCenterLeaderboard from './components/CallCenterLeaderboard/CallCenterLeaderboard';
import TechnicianRevenue from './components/TechnicianRevenue/TechnicianRevenue';
import TradesChart from './components/TradesChart/TradesChart';
import JobsByLocation from './components/JobsByLocation/JobsByLocation';

const DashboardPage = () => {
  const { dashboardContainer, metricsSection, bottomSection, smallChartsContainer } = styles;

  return (
    <div className={dashboardContainer}>
      <FilterSection />
      <StatsGrid />

      <div className={metricsSection}>
        <SalesAndJobsChart type="sales" />
        <SalesAndJobsChart type="jobs" />
      </div>

      <div className={bottomSection}>
        <JobTypes />
        <div className={smallChartsContainer}>
          <TradesChart />
          <JobsByLocation />
        </div>
      </div>

      <CallCenterLeaderboard />
      <TechnicianRevenue />
    </div>
  );
};

export default DashboardPage;
