import styles from './JobBookedMilestone.module.css';

const JobBookedMilestone = ({bookedBy, dateTime: bookingDateTime}) => {
  const {container, dashDesign, mainBody, bookingInfo, jobBooked, dateTime} = styles;

  return (
    <div className={container}>
      <div className={dashDesign}></div>
      <div className={mainBody}>
        <div className={bookingInfo}>
          <span className={jobBooked}>Job Booked</span> by {bookedBy}
        </div>
        <div className={dateTime}>{bookingDateTime}</div>
      </div>
    </div>
  );
};

export default JobBookedMilestone;
