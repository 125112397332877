import styles from './PriceBookItem.module.css';
import {useState, useEffect} from 'react';
import {WBButton} from '../../../UIComponents/WBComponents';
import {useJobCard} from '../../context/JobCardContext';

const PriceBookItem = ({id, tag, image, subtitle, description, price, originalPrice, quantity}) => {
  const {
    iconArrow,
    iconAdd,
    iconMinus,

    itemCard,
    itemHeader,
    itemTag,
    popularTag,
    saleTag,
    itemImage,
    itemDescription,
    itemSubtitle,
    itemDetails,
    itemActions,
    itemPriceContainer,
    itemOriginalPrice,
    itemPrice,
    quantityControl,
    selectedItem,
    unselectedItem,
    clickable,
  } = styles;

  const {updateItemQuantity, openItemView} = useJobCard();
  const [isSelected, setIsSelected] = useState(quantity > 0);

  useEffect(() => {
    setIsSelected(quantity > 0);
  }, [quantity]);

  const handleItemClick = () => {
    if (quantity === 0) {
      updateItemQuantity(id, 1);
    } else if (quantity === 1) {
      updateItemQuantity(id, 0);
    }
  };

  const incrementQuantity = () => {
    updateItemQuantity(id, quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 0) {
      updateItemQuantity(id, quantity === 1 ? 0 : quantity - 1);
    }
  };

  const handleOpenItemView = () => {
    openItemView(id);
  };

  return (
    <div
      className={`${itemCard} ${isSelected ? selectedItem : unselectedItem} ${
        quantity === 0 || quantity === 1 ? clickable : ''
      }`}
      onClick={handleItemClick}
    >
      <div className={itemHeader} style={{justifyContent: tag ? 'space-between' : 'flex-end'}}>
        {tag && (
          <span className={`${itemTag} ${tag === 'Most Popular' ? popularTag : saleTag}`}>
            {tag}
          </span>
        )}
        <WBButton
          icon={<i className={iconArrow}></i>}
          title="View"
          color="grey"
          styles={{
            padding: '0',
            borderRadius: '50%',
            flexDirection: 'row-reverse',
            border: 'none',
            boxShadow: 'none',
            fontSize: '12px',
          }}
          func={handleOpenItemView}
          stopPropagation={true}
        />
      </div>

      <div className={itemImage}>{image && <img src={image} alt={description} />}</div>

      <div className={itemDescription}>
        {subtitle && <span className={itemSubtitle}>{subtitle}</span>}
        <p className={itemDetails}>{description}</p>
      </div>

      <div className={itemActions}>
        <div className={itemPriceContainer}>
          {originalPrice && <span className={itemOriginalPrice}>${originalPrice.toFixed(2)}</span>}
          <span className={itemPrice}>${price.toFixed(2)}</span>
        </div>
        <div className={quantityControl}>
          <span>{quantity}</span>
          <WBButton
            icon={<i className={iconMinus}></i>}
            color="grey"
            height="40px"
            width="40px"
            styles={{padding: '0', borderRadius: '50%'}}
            func={e => decrementQuantity(e)}
            stopPropagation={true}
          />
          <WBButton
            icon={<i className={iconAdd}></i>}
            color="grey"
            height="40px"
            width="40px"
            styles={{padding: '0', borderRadius: '50%'}}
            func={e => incrementQuantity(e)}
            stopPropagation={true}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceBookItem;
