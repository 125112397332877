import styles from "../addJobModals.module.css";
import { useContext, useMemo } from "react";
import { JobBookingContext } from "../JobBookingContext";
import BookingButton from "./BookingButton";

const CustomerCard = ({ customer, index, closeModal, nextModal }) => {
  const { setSelectedCustomer, setCustomerID, updateBookingData } =
    useContext(JobBookingContext);

  const parsedContacts = useMemo(() => {
    if (typeof customer.contacts === 'object') {
      return customer.contacts;
    }
    try {
      return JSON.parse(customer.contacts);
    } catch (error) {
      console.error("Error parsing contacts:", error);
      return [];
    }
  }, [customer.contacts]);

  const handleSelectCustomer = () => {
    closeModal();
    nextModal();
    setSelectedCustomer({ ...customer, contacts: parsedContacts });
    console.log({ ...customer, contacts: parsedContacts });
    setCustomerID(customer.id);
    console.log(customer);
    updateBookingData('customerDetails', { ...customer, contacts: parsedContacts });
    updateBookingData('propertyDetails', customer.property_details);
  };

  return (
    <>
      <div key={index} className={styles.customerCard}>
        {customer.subCustomer && (
          <>
            <div className={styles.customerCardOwner}>
              {/* <p className={styles.fontSmaller}>{customer.business}</p> */}
              {/* <p className="bold">{`${customer.firstName} ${customer.lastName}`}</p> */}
              <div className={styles.customerCardLeft}>
                <p className="bold">{customer.business}</p>
                <div className={styles.customerCardRole}>
                  <p>{customer.role}</p>
                </div>
              </div>
              <div className={styles.customerCardRight}>
                <BookingButton
                  text="View Profile"
                  style={{
                    padding: "13px 20px",
                    backgroundColor: "white",
                    marginTop: "0px",
                    width: "108px",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                    whiteSpace: "nowrap",
                  }}
                />
                <BookingButton
                  func={() => handleSelectCustomer(customer)}
                  text="Select"
                  textColor="white"
                  bold={1}
                  style={{
                    padding: "13px 20px",
                    marginTop: "0px",
                    width: "86px",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className={styles.customerCardNameContainer}>
          <div className={styles.customerCardLeft}>
            <p className="bold">
              {customer.tenant
                ? `${customer.subCustomer?.firstName} ${customer.subCustomer?.lastName}`
                : `${customer.firstName} ${customer.lastName}`}
            </p>
            <div className={styles.customerCardRole}>
              <p>
                {customer.subCustomer
                  ? customer.subCustomer?.role
                  : customer.cust_type}
              </p>
            </div>
          </div>
          {!customer.subCustomer && (
            <div className={styles.customerCardRight}>
              <BookingButton
                text="View Profile"
                style={{
                  padding: "13px 20px",
                  backgroundColor: "white",
                  marginTop: "0px",
                  width: "108px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                  whiteSpace: "nowrap",
                }}
              />
              <BookingButton
                func={() => handleSelectCustomer(customer)}
                text="Select"
                textColor="white"
                bold={1}
                style={{
                  padding: "13px 20px",
                  marginTop: "0px",
                  width: "86px",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                }}
              />
            </div>
          )}
        </div>
        <div
          className={styles.summaryIndivDetailsValue}
          style={{ marginTop: 16 }}
        >
          <img src="/images/client/locationpin.svg" alt="location" />
          <p>{customer.address}</p>
        </div>
        <div className={styles.customerCardFooter}>
          <div className={styles.customerMobilePhone}>
            <div className={styles.customerCardMobile}>
              <p className="bold">Mobile</p>
              <div className={styles.summaryIndivDetailsValue}>
                <img src="/images/client/mobile.svg" alt="phone" />
                <p>{customer.subCustomer ? customer.subCustomer?.mobile : customer.mobile}</p>
              </div>
            </div>
            <div className={styles.customerCardMobile}>
              <p className="bold">Phone</p>
              <div className={styles.summaryIndivDetailsValue}>
                <img src="/images/client/mobile.svg" alt="phone" />
                <p>{customer.subCustomer ? customer.subCustomer?.phone : customer.phone}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerCard;
