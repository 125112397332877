import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const ServiceCallFees = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { }; // deleteUser()

    const [serviceCallFeesFormatted, formatServiceCallFees] = useState();

    const serviceCallFees = useWBTableData();

    useEffect(() => {
        // console.log("Service call fees: ", serviceCallFees);
        if (serviceCallFees !== null && serviceCallFees !== undefined) {
            if (serviceCallFees.length > 0) {
                formatServiceCallFees(
                    serviceCallFees.map((sCallFee) => (
                        <div key={sCallFee.id} className="row" onDoubleClick={() => { setEditedID(sCallFee.id); editFunc(); }}>
                            <div className="static1 cell">{sCallFee.title}</div>
                            <div className="cell">${parseInt(sCallFee.price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                            <div className="cell">
                                {sCallFee.active === 1 ? <span className="text-green bold">Active</span> : <span className="bold">Inactive</span>}
                            </div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(sCallFee.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(sCallFee.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatServiceCallFees(<div>No items found</div>);
            }
        }
        else {
            formatServiceCallFees(<div>No items found</div>);
        }
    }, [editFunc, serviceCallFees, setEditedID])
    return (
        <div className="body" id="bodyData">{serviceCallFeesFormatted}</div>
    )
}

export default ServiceCallFees;