import { useState, useEffect } from 'react';
import { PostDB } from '../../../Components/DBTools';

export const useSearch = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [searchResults, setSearchResults] = useState({ job: [], client: [] });
  const [isLoading, setIsLoading] = useState(false);

  const fetchSearchResults = async (searchText) => {
    try {
      return await PostDB({
        branch: '/getSearchResults',
        json: {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            search: searchText
          })
        },
      });
    } catch (error) {
      console.error('Error fetching search results:', error);
      return { job: [], client: [] };
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (searchValue.trim()) {
        setIsLoading(true);
        const results = await fetchSearchResults(searchValue);
        setSearchResults(results);
        setIsLoading(false);
      } else {
        setSearchResults({ job: [], client: [] });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    console.log(searchResults);
  }, [searchResults]);

  const getFilteredResults = () => {
    const formattedResults = [];

    if (searchResults.job?.length > 0) {
      formattedResults.push(...searchResults.job.map(job => ({
        type: 'Job',
        category: 'Job',
        data: {
          name: `Job #${job.id}`,
          jobId: job.id.toString(),
          status: job.job_priority,
          address: `${job.address_line}${job.address_line1 ? `, ${job.address_line1}` : ''}, ${job.suburb}, ${job.postcode} ${job.address}`
        }
      })));
    }

    if (searchResults.client?.length > 0) {
      formattedResults.push(...searchResults.client.map(client => ({
        type: 'Customer',
        category: 'Customer',
        data: {
          customerId: client.id,
          name: `${client.first_name} ${client.last_name}`,
          contactInfo: [
            { type: 'Mobile', number: client.mobile },
            { type: 'Phone', number: client.phone }
          ]
        }
      })));
    }

    if (selectedFilter === 'All') {
      return formattedResults;
    }

    return formattedResults.filter(result => 
      result.type.toLowerCase() === selectedFilter.toLowerCase()
    );
  };

  return {
    searchValue,
    setSearchValue,
    selectedFilter,
    setSelectedFilter,
    isLoading,
    getFilteredResults
  };
};
