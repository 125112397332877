import { useState } from "react";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddSubFilter from "./Add/AddSubFilter";
import EditSubFilter from "./Edit/EditSubFilter";
import SubFilterItems from "../../../Components/Settings/Filters/SubFiltersItems";

const SubFiltersPage = () => {
    const [edited, setEdited] = useState();

    const [isModalAddOpen, setIsModal1Open] = useState(false);
    const [isModalEditOpen, setIsModal2Open] = useState(false);

    const toggleAddFiltersModal = () => setIsModal1Open(!isModalAddOpen);  // addService()
    const toggleEditFiltersModal = (id) => setIsModal2Open(!isModalEditOpen);  // addService()

    const headers = ["sub filter name", "parent filter name", "status", "action"];

    return (
        <div className="content-area-full">
            {isModalAddOpen && <AddSubFilter closeModalMethod={toggleAddFiltersModal} />}
            {isModalEditOpen && <EditSubFilter closeModalMethod={toggleEditFiltersModal} edited={edited} />}

            <div className="flex flex-column bg-white p-20 gap-10">
                <div className="flex justify-between">
                    <div className="text-base">
                        <b>Sub Filters</b>
                    </div>
                    <div className="flex gap-10">
                        <WBButton title="Go to Filters" link={"/settings/filters"} color="white" />
                        <WBButton title="Add Sub Filter" func={toggleAddFiltersModal} color={"white"} />
                    </div>
                </div>
                <WBTable tableData="/filtersJson" tableHeaders={headers}>
                    <SubFilterItems editFunc={toggleEditFiltersModal} subFilterSelected={setEdited} />
                </WBTable>
            </div>
        </div>
    );
};

export default SubFiltersPage;