import styles from './EditJobDetailsModal.module.css';
import {WBButton} from '../../../UIComponents/WBComponents';
import {WBDropdown, WBDropdownOption} from '../common/WBDropdown';
import {useState, useEffect} from 'react';
import {useJobDetailsData} from './hooks/useJobDetailsData';
import {useJobDetails} from '../context/JobDetailsContext';

const EditJobDetailsModal = () => {
  const {
    isEditModalOpen,
    closeEditModal,
    relationship,
    setRelationship,
    jobPriority,
    setJobPriority,
    serviceType,
    setServiceType,
    calloutFee,
    setCalloutFee,
    specialBooking,
    setSpecialBooking,
    selectedIndustry,
    setSelectedIndustry,
    selectedCategory,
    setSelectedCategory,
    selectedJobTypes,
    setSelectedJobTypes,
    selectedContact,
    setSelectedContact,
    sameAsCustomer,
    setSameAsCustomer,
  } = useJobDetails();
  const [activeTab, setActiveTab] = useState('contact');
  const [contactForm, setContactForm] = useState({
    firstName: '',
    surname: '',
    phone: '',
    mobile: '',
    email: '',
    additionalNotes: '',
  });

  const {data, isLoading, error} = useJobDetailsData();

  useEffect(() => {
    if (isEditModalOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isEditModalOpen]);

  if (!isEditModalOpen) return null;

  const getAvailableJobTypes = () => {
    if (!data.industriesList || !data.jobTypesList) return [];

    // Find the selected industry's ID
    const selectedIndustryData = data.industriesList.find(
      industry => industry.department === selectedIndustry
    );

    if (!selectedIndustryData) return [];

    // Filter job types based on industry
    return data.jobTypesList
      .filter(
        jobType =>
          jobType.trade_id === selectedIndustryData.id && !selectedJobTypes.includes(jobType.title)
      )
      .map(jobType => jobType.title);
  };

  const handleJobTypeSelect = type => {
    if (!selectedJobTypes.includes(type)) {
      setSelectedJobTypes([...selectedJobTypes, type]);
    }
  };

  const removeJobType = type => {
    setSelectedJobTypes(selectedJobTypes.filter(t => t !== type));
  };

  const resetModal = () => {
    setActiveTab('contact');
    setSelectedContact('contact1');
    setSameAsCustomer(false);
    setContactForm({
      firstName: '',
      surname: '',
      phone: '',
      mobile: '',
      email: '',
      additionalNotes: '',
    });
    setSelectedIndustry('Plumbing');
    setSelectedCategory(null);
    setSelectedJobTypes([]);
    setServiceType('Choose service type');
    setCalloutFee('Choose callout fee');
    setSpecialBooking('Choose special booking');
  };

  const {
    iconCheck,
    iconDownArrow,
    iconRemove,

    modalOverlay,
    modalContainer,
    modalHeader,
    headerTitle,
    closeButton,
    iconClose,
    modalBody,
    modalFooter,
    footerLeft,
    footerRight,

    tabContainer,
    tab,
    activeTabStyle,
    contactTabs,
    contactTab,
    activeContactTab,
    primaryLabel,
    formContainer,
    inputGroup,
    inputField,
    fullWidthInput,
    toggleContainer,
    toggle,

    detailsFormContainer,
    detailsContainer,
    sectionTitle,
    selectionGroup,
    buttonGroup,
    rowContainer,
    detailsInputGroup,
    noMarginBottom,
    dateAndTimeContainer,
    dateTimeInputWrapper,
    dateTimeInput,
    iconCalendarBlue,
    iconClockBlue,
    inputIcon,
    textarea,

    // select job type
    selectedJobTypesArea,
    selectedJobType,
    jobTypesContainer,
    jobTypeButtonsContainer,
  } = styles;

  return (
    <div className={modalOverlay}>
      <div className={modalContainer}>
        <div className={modalHeader}>
          <h2 className={headerTitle}>Edit Job Details</h2>
          <button
            onClick={() => {
              resetModal();
              closeEditModal();
            }}
            className={closeButton}
          >
            <i className={iconClose}></i>
          </button>
        </div>

        {/* Main Tabs */}
        <div className={tabContainer}>
          <button
            className={`${tab} ${activeTab === 'contact' ? activeTabStyle : ''}`}
            onClick={() => setActiveTab('contact')}
          >
            Job Site Contact
          </button>
          <button
            className={`${tab} ${activeTab === 'details' ? activeTabStyle : ''}`}
            onClick={() => setActiveTab('details')}
          >
            Job Details
          </button>
        </div>

        <div className={modalBody}>
          {activeTab === 'contact' ? (
            <>
              {/* Contact Tabs */}
              <div className={contactTabs}>
                <button
                  className={`${contactTab} ${
                    selectedContact === 'contact1' ? activeContactTab : ''
                  }`}
                  onClick={() => setSelectedContact('contact1')}
                >
                  Contact 1<span className={primaryLabel}>Primary Account</span>
                </button>
                <button
                  className={`${contactTab} ${
                    selectedContact === 'contact2' ? activeContactTab : ''
                  }`}
                  onClick={() => setSelectedContact('contact2')}
                >
                  Contact 2
                </button>
                <button
                  className={`${contactTab} ${
                    selectedContact === 'contact3' ? activeContactTab : ''
                  }`}
                  onClick={() => setSelectedContact('contact3')}
                >
                  Contact 3
                </button>
              </div>

              {/* Form Fields */}
              <div className={formContainer}>
                <div className={inputGroup}>
                  <label>First Name</label>
                  <input
                    type="text"
                    className={inputField}
                    placeholder="Enter first name"
                    value={contactForm.firstName}
                    onChange={e => setContactForm({...contactForm, firstName: e.target.value})}
                  />
                </div>
                <div className={inputGroup}>
                  <label>Surname</label>
                  <input
                    type="text"
                    className={inputField}
                    placeholder="Enter surname"
                    value={contactForm.surname}
                    onChange={e => setContactForm({...contactForm, surname: e.target.value})}
                  />
                </div>
                <div className={inputGroup}>
                  <label>Phone</label>
                  <input
                    type="tel"
                    className={inputField}
                    placeholder="Enter phone number"
                    value={contactForm.phone}
                    onChange={e => setContactForm({...contactForm, phone: e.target.value})}
                  />
                </div>
                <div className={inputGroup}>
                  <label>Mobile</label>
                  <input
                    type="tel"
                    className={inputField}
                    placeholder="Enter mobile number"
                    value={contactForm.mobile}
                    onChange={e => setContactForm({...contactForm, mobile: e.target.value})}
                  />
                </div>
                <div className={inputGroup}>
                  <label>Email</label>
                  <input
                    type="email"
                    className={inputField}
                    placeholder="Enter email address"
                    value={contactForm.email}
                    onChange={e => setContactForm({...contactForm, email: e.target.value})}
                  />
                </div>
                <div className={inputGroup}>
                  <label>Relationship to Customer</label>
                  <WBDropdown
                    leftitem={relationship}
                    rightitem={<i className={iconDownArrow}></i>}
                    height="48px"
                    width="100%"
                    borderRadius="10px"
                    background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                  >
                    {data.contactsRelationshipList.map(relation => (
                      <WBDropdownOption
                        key={relation.id}
                        title={relation.title}
                        func={() => setRelationship(relation.title)}
                      />
                    ))}
                  </WBDropdown>
                </div>
                {/* Only show Additional Notes for Contact 1 */}
                {selectedContact === 'contact1' && (
                  <div className={`${inputGroup} ${fullWidthInput}`}>
                    <label>Additional Notes</label>
                    <input
                      type="text"
                      className={inputField}
                      placeholder="Enter special instruction"
                      value={contactForm.additionalNotes}
                      onChange={e =>
                        setContactForm({...contactForm, additionalNotes: e.target.value})
                      }
                    />
                  </div>
                )}
              </div>
              <div className={toggleContainer}>
                <label>Same as Customer Details</label>
                <input
                  type="checkbox"
                  className={toggle}
                  checked={sameAsCustomer}
                  onChange={() => setSameAsCustomer(!sameAsCustomer)}
                />
              </div>
            </>
          ) : (
            <div>
              {/* Job Details Tab Content */}
              {activeTab === 'details' && (
                <div className={detailsContainer}>
                  <h3 className={sectionTitle}>Job Details</h3>
                  <div className={detailsFormContainer}>
                    {/* Industry Selection */}
                    <div className={selectionGroup}>
                      <label>Industry</label>
                      <div className={buttonGroup}>
                        {['Plumbing', 'Electrical', 'HVAC'].map(industry => (
                          <WBButton
                            key={industry}
                            title={industry}
                            color="grey"
                            styles={{
                              padding: '0 24px',
                              border:
                                selectedIndustry === industry
                                  ? '1px solid #007AFF'
                                  : '1px solid #DFDFDF',
                            }}
                            func={() => setSelectedIndustry(industry)}
                            width="140px"
                          />
                        ))}
                      </div>
                    </div>

                    {/* Category Selection */}
                    <div className={selectionGroup}>
                      <label>Category</label>
                      <div className={buttonGroup}>
                        {['Residential', 'Commercial', 'Industrial'].map(category => (
                          <WBButton
                            key={category}
                            title={category}
                            color="grey"
                            styles={{
                              padding: '0 24px',
                              border:
                                selectedCategory === category
                                  ? '1px solid #007AFF'
                                  : '1px solid #DFDFDF',
                            }}
                            func={() => {
                              // Toggle selection: if clicking the same category, deselect it
                              if (selectedCategory === category) {
                                setSelectedCategory(null);
                              } else {
                                setSelectedCategory(category);
                              }
                            }}
                            width="140px"
                          />
                        ))}
                      </div>
                    </div>

                    {/* Priority and Service Type */}
                    <div className={rowContainer}>
                      <div className={detailsInputGroup}>
                        <label>Job Priority</label>
                        <WBDropdown
                          leftitem={jobPriority}
                          rightitem={<i className={iconDownArrow}></i>}
                          height="50px"
                          width="100%"
                          borderRadius="10px"
                          background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                        >
                          {data.jobPriorityList.map(priority => (
                            <WBDropdownOption
                              key={priority.id}
                              title={priority.title}
                              func={() => setJobPriority(priority.title)}
                            />
                          ))}
                        </WBDropdown>
                      </div>
                      <div className={detailsInputGroup}>
                        <label>Service Type</label>
                        <WBDropdown
                          leftitem={serviceType}
                          rightitem={<i className={iconDownArrow}></i>}
                          height="50px"
                          width="100%"
                          borderRadius="10px"
                          background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                        >
                          {data.serviceTypesList.map(service => (
                            <WBDropdownOption
                              key={service.id}
                              title={service.title}
                              func={() => setServiceType(service.title)}
                            />
                          ))}
                        </WBDropdown>
                      </div>
                    </div>

                    {/* Job Types Selection */}
                    <div className={detailsInputGroup}>
                      <label>Select Job Types</label>
                      <div className={jobTypesContainer}>
                        <div className={selectedJobTypesArea}>
                          {selectedJobTypes.length === 0 ? (
                            <span
                              style={{
                                color: '#969696',
                                alignItems: selectedJobType.length === 0 ? 'center' : 'inherit',
                              }}
                            >
                              Enter job type
                            </span>
                          ) : (
                            selectedJobTypes.map(type => (
                              <span
                                key={type}
                                className={selectedJobType}
                                onClick={() => removeJobType(type)}
                              >
                                {type}
                                <i className={iconRemove}></i>
                              </span>
                            ))
                          )}
                        </div>
                        <div className={jobTypeButtonsContainer}>
                          {getAvailableJobTypes().map(type => (
                            <WBButton
                              key={type}
                              title={type}
                              color="grey"
                              styles={{padding: '8px 16px', borderRadius: '10px'}}
                              func={() => handleJobTypeSelect(type)}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Callout Fee */}
                    <div className={detailsInputGroup}>
                      <label>Callout Fee</label>

                      <WBDropdown
                        leftitem={calloutFee}
                        rightitem={<i className={iconDownArrow}></i>}
                        width="100%"
                        height="50px"
                        borderRadius="10px"
                        background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                      >
                        {data.callOutFeesList.map(fee => (
                          <WBDropdownOption
                            key={fee.id}
                            title={`$${fee.price}`}
                            func={() => setCalloutFee(`$${fee.price}`)}
                          />
                        ))}
                      </WBDropdown>
                    </div>

                    {/* Date and Time */}
                    <div className={dateAndTimeContainer}>
                      <label>Preferred Date & Time</label>
                      <div>
                        <div className={detailsInputGroup}>
                          <label>Date</label>
                          <div className={dateTimeInputWrapper}>
                            <input
                              type="date"
                              className={dateTimeInput}
                              defaultValue={new Date().toISOString().split('T')[0]}
                              onClick={e => e.target.showPicker()}
                            />
                            <i
                              className={`${iconCalendarBlue} ${inputIcon}`}
                              onClick={() => {
                                const input = document.querySelector(`input[type="date"]`);
                                input.showPicker();
                              }}
                            ></i>
                          </div>
                        </div>
                        <div className={detailsInputGroup}>
                          <label>Time</label>
                          <div className={dateTimeInputWrapper}>
                            <input
                              type="time"
                              className={dateTimeInput}
                              defaultValue="10:00"
                              onClick={e => e.target.showPicker()}
                            />
                            <i
                              className={`${iconClockBlue} ${inputIcon}`}
                              onClick={() => {
                                const input = document.querySelector(`input[type="time"]`);
                                input.showPicker();
                              }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Job Description */}
                    <div className={detailsInputGroup}>
                      <label>Add Job Description</label>
                      <textarea
                        className={textarea}
                        placeholder="Enter Job Description Here"
                        rows={4}
                      />
                    </div>

                    {/* Special Booking */}
                    <div className={`${detailsInputGroup} ${noMarginBottom}`}>
                      <label>Special Booking</label>
                      <WBDropdown
                        leftitem={specialBooking}
                        rightitem={<i className={iconDownArrow}></i>}
                        width="100%"
                        height="50px"
                        borderRadius="10px"
                        background="linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"
                      >
                        {data.specialBookingsList.map(booking => (
                          <WBDropdownOption
                            key={booking.id}
                            title={booking.title}
                            func={() => setSpecialBooking(booking.title)}
                          />
                        ))}
                      </WBDropdown>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={modalFooter}>
          <div className={footerLeft}>
            <WBButton
              title="Cancel"
              color="grey"
              styles={{padding: '0 24px'}}
              func={closeEditModal}
            />
          </div>
          <div className={footerRight}>
            <WBButton
              title="Update"
              icon={<i className={iconCheck}></i>}
              color="grey"
              styles={{padding: '0 24px'}}
              func={() => {
                if (activeTab === 'contact') {
                  setActiveTab('details');
                } else {
                  resetModal();
                  closeEditModal();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobDetailsModal;
