const NavigatePage = ({ pageNum, symbol, active = false, disabled = false }) => {
    const loadPage = () => { }; // loadPage(this)
    return (
        <span
            type="button"
            onClick={() => loadPage(pageNum)}
            data-page={pageNum}
            className={`btn btn-default ${active ? "active" : ""} ${disabled ? "disabled" : ""}`}
        >{symbol ? symbol : pageNum}</span>
    )
}

export default NavigatePage;