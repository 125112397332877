import styles from './StatsGrid.module.css';
import StatsGridChart from './StatsGridChart';
const StatsGrid = () => {
  const {statsGrid, statsCard} = styles;

  return (
    <div className={statsGrid}>
      <div className={statsCard}>
        <StatsGridChart showPercentage={true} efficiency={82.1} trend={3} isPositiveTrend={true} />
      </div>
      <div className={statsCard}>
        <StatsGridChart showJobsWon={true} efficiency={71.2} trend={2} isPositiveTrend={true} />
      </div>
      <div className={statsCard}>
        <StatsGridChart
          showAverageSale={true}
          averageSale={1620.5}
          trend={5}
          isPositiveTrend={true}
        />
      </div>
      <div className={statsCard}>
        <StatsGridChart showMembership={true} membership={120} trend={15} isPositiveTrend={true} />
      </div>
    </div>
  );
};

export default StatsGrid;
