import {useState} from 'react';
import styles from './MaterialItem.module.css';

const MaterialItem = ({purchaseNumber, status, disableToggle = false}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const {
    container,
    header,
    purchaseNumberStyle,
    statusBadge,
    expandedContent,
    detailsTable,
    detailsRow,
    detailsCell,
    materialTable,
    materialRow,
    materialCell,
    instructions,
    notes,
    iconNavArrow,
    detailsRowHeader,
    materialRowHeader,
  } = styles;

  const toggleExpand = () => {
    if (!disableToggle) {
      setIsExpanded(!isExpanded);
    }
  };

  const preventTextSelection = e => {
    if (e.detail > 1) {
      e.preventDefault();
    }
  };

  return (
    <div className={container}>
      <div
        className={header}
        onClick={toggleExpand}
        onMouseDown={preventTextSelection}
        style={{cursor: disableToggle ? 'default' : 'pointer'}}
      >
        <span className={purchaseNumberStyle}>{purchaseNumber}</span>
        <span className={statusBadge}>{status}</span>
        {!disableToggle && (
          <i className={`${iconNavArrow} ${isExpanded ? styles.expanded : ''}`}></i>
        )}
      </div>
      {isExpanded && !disableToggle && (
        <div className={expandedContent}>
          <div className={detailsTable}>
            <div className={detailsRowHeader}>
              <span className={detailsCell}>Purchase #</span>
              <span className={detailsCell}>Needed Time</span>
              <span className={detailsCell}>Approval Status</span>
              <span className={detailsCell}>Ready Status</span>
              <span className={detailsCell}>Status</span>
            </div>
            <div className={detailsRow}>
              <span className={detailsCell}>{purchaseNumber}</span>
              <span className={detailsCell}>Needed Now</span>
              <span className={detailsCell}>Not Approved Yet</span>
              <span className={detailsCell}>Not Ready Yet</span>
              <span className={`${detailsCell} ${statusBadge}`}>{status}</span>
            </div>
          </div>
          <div className={materialTable}>
            <div className={materialRowHeader}>
              <span className={materialCell}>Material</span>
              <span className={materialCell}>Brand</span>
              <span className={materialCell}>Qty</span>
            </div>
            <div className={materialRow}>
              <span className={materialCell}>Therman 250</span>
              <span className={materialCell}>-</span>
              <span className={materialCell}>1</span>
            </div>
            <div className={materialRow}>
              <span className={materialCell}>Hws Pack</span>
              <span className={materialCell}>-</span>
              <span className={materialCell}>1</span>
            </div>
          </div>
          <div className={instructions}>No Special Instructions By Technician</div>
          <div className={notes}>No Notes</div>
        </div>
      )}
    </div>
  );
};

export default MaterialItem;
