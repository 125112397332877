import React, { useEffect } from "react";
import { useState, useContext, useReducer } from "react";
import styles from "./addJobModals.module.css";
import { WBIOSToggle } from "../../../Components/UIComponents/WBComponents";
import CustomerDetails from "./components/CustomerDetails";
import ModalNavigation from "./components/ModalNavigation";
import NotesInput from "./components/NotesInput";
import { JobBookingContext } from "./JobBookingContext";
import BookingDropdown from "./components/BookingDropdown";
import CancellationConfirmation from "./components/CancellationConfirmation";
import PropertyDetailsForm from "./components/PropertyDetailsForm";
import { useJobCustomerModals } from "./hooks/useJobCustomerModals";

const JobPropertyDetailsModal = ({
  isOpen,
  closeModal,
  customerDetailsModal,
  jobDetailsModal,
  propertyDetailsModal,
  summaryModal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(0);

  const handleHideCloseBooking = () => {
    setIsCancel(!isCancel);
  };

  const handleCustomerClick = (index) => setSelectedCustomerIndex(index);

  const { selectedCustomer, updateBookingData, customerID, bookingData, jobsiteID } =
    useContext(JobBookingContext);

  const { addJobSiteContacts } = useJobCustomerModals();

  const initialFormState = {
    cust_id: customerID,
    property_details: {
      jobsite_address: {
        jobsite_address_id: jobsiteID ? jobsiteID : null,
        address_line: "91 marigold street",
        address_line1: "",
        suburb: "Revesby",
        postcode: "2212",
        state: "NSW",
      },
      jobsite_contact: [
        {
          email: "",
          phone: "",
          mobile: "",
          lastName: "",
          firstName: "",
          relationship: "",
          notes: "",
          is_primary: 1,
        },
        {
          email: "",
          phone: "",
          mobile: "",
          lastName: "",
          firstName: "",
          relationship: "",
          notes: "", 
          is_primary: 0,
        },
        {
          email: "",
          phone: "",
          mobile: "",
          lastName: "",
          firstName: "",
          relationship: "",
          notes: "", 
          is_primary: 0,
        },
      ],
    },
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_FIELD":
        return { ...state, [action.field]: action.value };
      case "RESET":
        return initialFormState;
      case "POPULATE":
        return { ...state, ...action.data };
      case "UPDATE_CONTACT_FIELD":
        return {
          ...state,
          property_details: {
            ...state.property_details,
            jobsite_contact: state.property_details.jobsite_contact.map((contact, index) =>
              index === action.contactIndex
                ? { ...contact, [action.field]: action.value }
                : contact
            ),
          },
        };
      case "UPDATE_CUST_ID":
        return { ...state, cust_id: action.value };
      case "UPDATE_CONTACT_FIELDS":
        return { 
          ...state,
          property_details: {
            ...state.property_details,
            jobsite_contact: [
              {
                ...state.property_details.jobsite_contact[0],
                ...action.values
              },
              ...state.property_details.jobsite_contact?.slice(1)
            ],
          },
        };
      case "UPDATE_JOBSITE_ADDRESS_ID":
        return {
          ...state,
          property_details: {
            ...state.property_details,
            jobsite_address: {
              ...state.property_details.jobsite_address,
              jobsite_address_id: action.value
            }
          }
        };
      default:
        return state;
    }
  };
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  useEffect(() => {
    if (!isOpen) {
      setIsCancel(false);
    }
  }, [isOpen]);

  useEffect(() => {
    console.log("customerID got updated", customerID);
    dispatch({ type: "UPDATE_CUST_ID", value: customerID });
  }, [customerID]);

  useEffect(() => {
    if (jobsiteID) {
      console.log("jobsiteID got updated", jobsiteID);
      dispatch({ type: "UPDATE_JOBSITE_ADDRESS_ID", value: jobsiteID });
    }
  }, [jobsiteID]);

  if (!isOpen) return null;

  const toggleActive = () => {
    setIsActive(!isActive);
    if (!isActive) {
      console.log(bookingData.customerDetails);
      // Update only the first jobsite_contact
      dispatch({ 
        type: "UPDATE_CONTACT_FIELDS", 
        values: {
          email: bookingData.customerDetails.email || "",
          phone: bookingData.customerDetails.phone || "",
          mobile: bookingData.customerDetails.mobile || "",
          lastName: bookingData.customerDetails.lastName || "",
          firstName: bookingData.customerDetails.firstName || "",
          relationship: bookingData.customerDetails.relationship || "",
          notes: bookingData.customerDetails.notes || "",
          is_primary: 1
        }
      });
    } else {
      dispatch({ type: "RESET" });
    }
  };

  const handleNext = () => {
    updateBookingData("propertyDetails", formState);
    addJobSiteContacts(formState);
    console.log(formState);
    jobDetailsModal();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentPropertyDetails}>
        <div className={styles.modalBody}>
          <ModalNavigation
            customerDetailsModal={customerDetailsModal}
            propertyDetailsModal={propertyDetailsModal}
            jobDetailsModal={jobDetailsModal}
            summaryModal={summaryModal}
            closeModal={closeModal}
            selectedTab={2}
          />
          <div className={styles.modalInformationContainer}>
            <div className={styles.modalLeft}>
              {/* <h3 className={`${styles.fontBig} bold`}>Job Location</h3>
              <div className={styles.modalPropertyLocation}>
                <div className={styles.modalPropertyFlex}>
                  <p className={`${styles.fontMedium} bold`}>
                    Choose Property Location
                  </p>
                  <p className={`${styles.fontMedium}`}>(Required)</p>
                </div>
                <div className={styles.selectPropertyLocation}>
                  <p>64 Cambridge Street, Lidcombe, 2141 NSW Australia</p>
                  <img
                    src="/images/client/chevron-down.svg"
                    alt="chevron_down"
                  />
                </div>
                <div className={styles.createPropertyButton}>
                  <img src="/images/client/building.svg" alt="building" />
                  <p>Create New Property</p>
                </div>
              </div> */}
              <h3 className={`${styles.fontBig} bold`}>Job Site Contact</h3>
              <div className={styles.customerDetails}>
                {[0, 1, 2].map((index) =>
                  index === 0 ? (
                    <div key={`customer-${index}`} className={styles.primaryCustomerContainer}>
                      <div
                        className={`${styles.propertyContactCustomer} ${
                          selectedCustomerIndex === index
                            ? styles.selectedCustomer
                            : ""
                        }`}
                        onClick={() => handleCustomerClick(index)}
                      >
                        <p>{`${
                          selectedCustomer?.firstName ||
                          selectedCustomer?.first_name
                        } ${
                          selectedCustomer?.lastName ||
                          selectedCustomer?.last_name
                        }`}</p>
                      </div>
                      <div className={styles.primaryCustomer}>
                        <p>Primary Account</p>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={`customer-${index}`}
                      className={`${styles.propertyContactCustomer} ${
                        selectedCustomerIndex === index
                          ? styles.selectedCustomer
                          : ""
                      }`}
                      onClick={() => handleCustomerClick(index)}
                    >
                      <p key={index}>{`Contact ${index}`}</p>
                    </div>
                  )
                )}
              </div>
              <PropertyDetailsForm
                customerIndex={selectedCustomerIndex}
                isActive={isActive}
                toggleActive={toggleActive}
                formState={formState}
                dispatch={dispatch}
              />
            </div>
            <div className={styles.modalRight}>
              <CustomerDetails />
              <NotesInput />

              <div className={styles.buttonContainer}>
                <div
                  className={styles.cancelButton}
                  onClick={handleHideCloseBooking}
                >
                  <p>Cancel</p>
                </div>
                <div className={styles.subButtonContainer}>
                  <div
                    className={styles.backButton}
                    onClick={customerDetailsModal}
                  >
                    <p>Back</p>
                  </div>
                  <div className={styles.nextButton} onClick={handleNext}>
                    <p>Next</p>
                  </div>
                </div>
              </div>
              {isCancel && (
                <CancellationConfirmation
                  closeModal={closeModal}
                  handleHideCloseBooking={handleHideCloseBooking}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPropertyDetailsModal;
