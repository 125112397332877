import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";

const PaymentTermsPage = () => {
  //Temporary methods until js integration. Original version on right of method
  const addUser = () => {
    window.wb_modal.title("Add Account");
    window.wb_modal.show();
  }; // addUser()
  return (
    <div className="content-area-full">
      <div className="flex flex-column bg-white p-20 gap-10">
        <div className="flex justify-between">
          <div className="text-base">
            <b>Payment Terms</b>
          </div>
          <WBButton title="Add Payment Term" func={addUser} color={"white"} />
        </div>
        <WBTable tableData="/paymentTermsJson" tableHeaders={["term", "status", "action"]}>
          {/* IMPORTANT This does not exist in the DB and needs to be added later */}
        </WBTable>
      </div>
    </div>
  );
};

export default PaymentTermsPage;
