import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const CustomerType = ({ editFunc, customerTypeSelected }) => {

    const deleteUser = () => { }; // deleteUser()
    const [cTypesFormatted, formatCustomerTypes] = useState(<></>);

    const customerTypes = useWBTableData();

    useEffect(() => {
        if (customerTypes !== null && customerTypes !== undefined) {
            if (customerTypes.length > 0) {
                formatCustomerTypes(
                    customerTypes.map((ctype) => (
                        <div key={ctype.id} className="row" onDoubleClick={() => editFunc()}>
                            <div className="static1 cell">{ctype.title}</div>
                            <div className="cell">
                                <span className={ctype.active === 1 ? "text-green bold" : "text-red bold"}>{ctype.active === 1 ? "Active" : "Inactive"}</span>
                            </div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => editFunc(ctype.id)} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(ctype.id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatCustomerTypes(<div>No items found</div>);
            }
        }
        else {
            formatCustomerTypes(<div>No items found</div>);
        }
    }, [customerTypes])
    return (
        <div className="body" id="bodyData">{cTypesFormatted}</div>
    )
}

export default CustomerType;