import {useState, useEffect} from 'react';
import {PostDB} from '../../../../Components/DBTools';

export const useFetchCustomerData = customerId => {
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomerData = async () => {
      console.log('Customer ID here', customerId);
      try {
        const response = await PostDB({
          branch: '/getSearchByID',
          json: {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              id: parseInt(customerId),
              category: 'customer',
            }),
          },
        });

        setCustomerData(response);
      } catch (error) {
        console.error('Error fetching customer data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (customerId) {
      fetchCustomerData();
    }
  }, [customerId]);

  return {
    customerData,
    loading,
    error,
  };
};
