import {createContext, useContext, useState} from 'react';

const ScheduleContext = createContext();

export const ScheduleProvider = ({children}) => {
  const [techProfileModal, setTechProfileModal] = useState({
    isOpen: false,
    techData: null,
    selectedTechId: null,
    position: null,
  });

  const [timeBlockerModal, setTimeBlockerModal] = useState(false);
  const [selectedTechnicians, setSelectedTechnicians] = useState(new Set());

  const openTechProfileModal = (techData, techId, clickEvent) => {
    const rect = clickEvent.currentTarget.getBoundingClientRect();
    setTechProfileModal({
      isOpen: true,
      techData,
      selectedTechId: techId,
      position: {
        top: rect.top + window.scrollY,
        left: rect.right + 20,
      },
    });
  };

  const closeTechProfileModal = () => {
    setTechProfileModal({
      isOpen: false,
      techData: null,
      selectedTechId: null,
      position: null,
    });
  };

  const openTimeBlockerModal = () => setTimeBlockerModal(true);
  const closeTimeBlockerModal = () => {
    setTimeBlockerModal(false);
    setSelectedTechnicians(new Set());
  };

  const handleTechnicianSelect = techId => {
    setSelectedTechnicians(prev => {
      const newSet = new Set(prev);
      if (newSet.has(techId)) {
        newSet.delete(techId);
      } else {
        newSet.add(techId);
      }
      return newSet;
    });
  };

  const handleSelectAllTechnicians = (technicians) => {
    setSelectedTechnicians(prev => {
      if (prev.size === technicians.length) {
        return new Set();
      }
      return new Set(technicians.map(tech => tech.id));
    });
  };

  return (
    <ScheduleContext.Provider
      value={{
        techProfileModal,
        openTechProfileModal,
        closeTechProfileModal,
        timeBlockerModal,
        openTimeBlockerModal,
        closeTimeBlockerModal,
        selectedTechnicians,
        handleTechnicianSelect,
        handleSelectAllTechnicians,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export const useSchedule = () => {
  const context = useContext(ScheduleContext);
  if (!context) {
    throw new Error('useSchedule must be used within a ScheduleProvider');
  }
  return context;
};
