import { useCallback } from 'react';

const useDragAndDrop = (setSchedules, styles) => {
  const handleDragStart = useCallback((event, schedule) => {
    event.dataTransfer.setData("application/json", JSON.stringify(schedule));
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.currentTarget.classList.add(styles.dragOver);
  }, [styles]);

  const handleDragLeave = useCallback((event) => {
    event.currentTarget.classList.remove(styles.dragOver);
  }, [styles]);

  const handleDrop = useCallback((event, targetIndex, rowIndex) => {
    event.preventDefault();
    event.currentTarget.classList.remove(styles.dragOver);

    try {
      const data = JSON.parse(event.dataTransfer.getData("application/json"));
      
      if (data.type === "newJob") {
        const newSchedule = {
          id: Math.random(), 
          startIndex: targetIndex,
          span: 2,
          status: "scheduled",
          rowIndex: rowIndex,
          location: data.location,
          description: data.description
        };
        
        setSchedules(prevSchedules => [...prevSchedules, newSchedule]);
      } else {
        const draggedSchedule = data;
        setSchedules((prevSchedules) =>
          prevSchedules.map((schedule) =>
            schedule.id === draggedSchedule.id
              ? {
                  ...schedule,
                  startIndex: targetIndex,
                  rowIndex: rowIndex,
                }
              : schedule
          )
        );
      }
    } catch (error) {
      console.error("Error parsing drag data:", error);
    }
  }, [setSchedules, styles]);

  return {
    handleDragStart,
    handleDragOver,
    handleDragLeave,
    handleDrop
  };
};

export default useDragAndDrop;