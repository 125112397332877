import styles from "../contactNotes.module.css";
const Contact = ({ contact, onClick, isSelected }) => {
  return (
    <>
      {!isSelected ? (
        <div className={styles.tab} onClick={onClick}>
          <h3>{contact.name}</h3>
          <span>{contact.role}</span>
        </div>
      ) : (
        <div className={`${styles.tab} ${styles.contactSectionSelected}`}>
          <div className={styles.tabCustomerInfo}>
            <h3>{contact.name}</h3>
            <span>{contact.role}</span>
          </div>
          <div className={styles.chevronIcon}>
            <img
              src="/images/client/chevron-right.svg"
              className={styles.chevronImg}
              alt="Chevron Right"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;
