import styles from "../addJobModals.module.css";
import { useContext } from "react";
import { JobBookingContext } from "../JobBookingContext";

const NoteInput = () => {
  const { notes, setNotes } = useContext(JobBookingContext);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  return (
    <>
      <p className={`${styles.fontBig} bold`}>Notes</p>
      <div className={styles.modalJobNotes}>
        <textarea
          className={styles.modalNotesTextArea}
          placeholder="Enter your notes here"
          value={notes}
          onChange={handleNotesChange}
        />
        <p>Enter your notes for this booking session only</p>
      </div>
    </>
  );
};

export default NoteInput;
