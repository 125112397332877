import {useState, useEffect, useRef} from 'react';
import colours from '../../../../lib/json/colours.json';
import styles from './WBDropdown.module.css';

export const WBDropdown = ({
  leftitem,
  rightitem,
  color,
  link = false,
  defaultValue = false,
  children,
  height,
  width,
  style = {},
  leftItemPadding = '0 16px 0 24px',
  rightItemPadding = '0 12px',
  borderRadius = '4px',
  background,
  dropdownMenuStyle = {},
  disabled = false,
}) => {
  const [hidden, setHidden] = useState(true);
  const dropdownRef = useRef(null);

  const preventTextSelection = e => {
    if (e.detail > 1) {
      e.preventDefault();
    }
  };

  let myColour = colours[0][color]
    ? colours[0][color]
    : {color1: '#ffffff', color2: '#ffffff', font: 'black'};

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setHidden(true);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const containerStyle = {
    display: 'inline-block',
    position: 'relative',
    verticalAlign: 'top',
    opacity: disabled ? 0.5 : 1,
    pointerEvents: disabled ? 'none' : 'auto',
  };

  const toggleDropDown = () => {
    if (!disabled) {
      setHidden(!hidden);
    }
  };

  const validTitle =
    leftitem !== '' && leftitem !== 0 && leftitem !== null && leftitem !== undefined;

  const buttonStyle = {
    height,
    width,
    borderRadius,
    overflow: 'hidden',
    display: 'flex',
    ...style,
    background: background || undefined,
  };

  const leftItemStyle = {
    backgroundColor: background ? undefined : myColour.color1,
    color: background ? undefined : myColour.font,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: leftItemPadding,
  };

  const rightItemStyle = {
    backgroundColor: background ? undefined : myColour.color2, // Changed to color2
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: rightItemPadding,
  };

  const defaultDropdownMenuStyle = {
    position: 'absolute',
    top: '100%',
    right: 0,
    zIndex: 1000,
    minWidth: '100%',
    marginTop: '1px',
    borderRadius,
    backgroundColor: '#fff',
    boxShadow: '0px 4px 4px 0px #0000000A',
    whiteSpace: 'nowrap',
    maxHeight: '300px',
    overflowY: 'auto',
  };

  return (
    <div style={containerStyle} ref={dropdownRef}>
      <div
        className="dualbutton button"
        onClick={toggleDropDown}
        style={buttonStyle}
        onMouseDown={preventTextSelection}
      >
        {leftitem && (
          <div style={leftItemStyle}>{validTitle ? leftitem : defaultValue || 'Dropdown'}</div>
        )}
        {rightitem && <div style={rightItemStyle}>{rightitem}</div>}
      </div>
      {!hidden && !disabled && (
        <div
          className="dualbutton-dropdown-menu"
          style={{...defaultDropdownMenuStyle, ...dropdownMenuStyle}}
        >
          {children ? (
            <div onClick={toggleDropDown}>{children}</div>
          ) : (
            <div className="dropdown-menu-item">
              <p className="dropdown-item">There are no dropdown items</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// WBDropdownOption component remains unchanged
export const WBDropdownOption = ({title, id, link, func, pos, divStyle = {}, anchorStyle = {}}) => {
  const defaultDivStyle = {
    alignItems: pos,
    padding: '16px',
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
  };

  const defaultAnchorStyle = {
    textDecoration: 'none',
    color: 'inherit',
    display: 'block',
    width: '100%',
    height: '100%',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  };

  return (
    <div
      id={id}
      className={styles.dropdownMenuItem}
      style={{...defaultDivStyle, ...divStyle}}
      onClick={func}
    >
      <a
        href={link}
        className={styles.dropdownItem}
        data-modal={id}
        style={{...defaultAnchorStyle, ...anchorStyle}}
      >
        {title}
      </a>
    </div>
  );
};
