import {WBButton} from '../../UIComponents/WBComponents';
import mapImage from './assets/mapImage.svg';
import styles from './JobDetails.module.css';
import EditJobDetailsModal from './EditJobDetails/EditJobDetailsModal';
import {useJobDetails} from './context/JobDetailsContext';
import {useState} from 'react';

const JobDetails = () => {
  const [showPin, setShowPin] = useState(false);
  const {openEditModal} = useJobDetails();

  const {
    iconKey,
    iconPen,
    iconLocation,
    iconMap,
    iconPhone,
    iconSMS,
    iconCalendar,
    iconClock,

    header,
    headerLeft,
    headerRight,
    jobDetails,
    jobIdContainer,
    jobDetailsText,
    jobId,
    jobIdStatus,

    jobsiteAddress,
    addressLabel,
    address,
    mapContainer,
    map,
    mapIcon,

    siteContact,
    siteContactTitle,
    siteContactContainer,
    siteContactTop,
    siteContactBottom,
    contact,
    contactLabel,
    contactValue,

    jobDetailsContainer,
    jobDetail,
    jobDetailValueContainer,
    jobDetailLabel,
    jobDetailValue,

    jobDescriptionTitle,
    description,
    verticalLine,
    securityPinContainer,
    securityPinText,
    securityPinValue,
  } = styles;

  return (
    <div className={jobDetails}>
      {/* header */}
      <div className={header}>
        <div className={headerLeft}>
          <span className={jobDetailsText}>Job Details</span>
          <div className={jobIdContainer}>
            <span className={jobId}>595862</span>
            <span className={jobIdStatus}>Scheduled</span>
          </div>
        </div>

        <div className={headerRight}>
          {showPin ? (
            <div className={securityPinContainer}>
              <i className={iconKey}></i>
              <span className={securityPinText}>Security Pin</span>
              <span className={securityPinValue}>4256</span>
            </div>
          ) : (
            <WBButton
              title="Security Pin"
              icon={<i className={iconKey}></i>}
              color="grey"
              height="40px"
              styles={{padding: '0 24px'}}
              func={() => setShowPin(true)}
            />
          )}
          <WBButton
            icon={<i className={iconPen}></i>}
            color="grey"
            height="40px"
            styles={{padding: '12px'}}
            func={openEditModal}
          />
        </div>
      </div>

      {/* job site address */}
      <div className={jobsiteAddress}>
        <h2 className={addressLabel}>Job Site Address</h2>
        <p className={address}>
          <i className={iconLocation}></i>64 Cambridge Street, Lidcombe, 2141 NSW Australia
        </p>
        <div className={mapContainer}>
          <img src={mapImage} alt="Map" className={map} />
          <button className={`btn btn-default ${mapIcon}`}>
            <i className={iconMap}></i>
          </button>
        </div>
      </div>

      {/* site contact */}
      <div className={siteContact}>
        <h2 className={siteContactTitle}>Site Contact</h2>
        <div className={siteContactContainer}>
          <div className={siteContactTop}>
            <div className={contact}>
              <span className={contactLabel}>Name</span>
              <span className={contactValue}>Leslie Alexander</span>
            </div>
            <div className={contact}>
              <span className={contactLabel}>Customer Relationship</span>
              <span className={contactValue}>Tenant</span>
            </div>
          </div>
          <div className={siteContactBottom}>
            <div className={contact}>
              <span className={contactLabel}>Mobile</span>
              <span className={contactValue}>
                <i className={iconPhone} />
                0444 645 555
              </span>
            </div>
            <div className={contact}>
              <span className={contactLabel}>Phone</span>
              <span className={contactValue}>
                <i className={iconPhone} />
                987 222 111
              </span>
            </div>
            <div className={contact}>
              <span className={contactLabel}>Email</span>
              <span className={contactValue}>
                <i className={iconSMS} />
                customer@hotmail.com
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* job type (light blue bg) */}
      <div className={jobDetails}>
        <div className={jobDetailsContainer}>
          {/* column 1 */}
          <div className={jobDetail}>
            <span className={jobDetailLabel}>Job Type</span>
            <span className={jobDetailValue}>Other - Plumbing</span>
          </div>
          <div className={jobDetail}>
            <span className={jobDetailLabel}>Job Priority</span>
            <span className={jobDetailValue}>Non Urgent</span>
          </div>
          <div className={jobDetail}>
            <span className={jobDetailLabel}>Industry</span>
            <span className={jobDetailValue}>Plumbing</span>
          </div>

          {/* column 2 */}
          <div className={jobDetail}>
            <span className={jobDetailLabel}>Service Type</span>
            <span className={jobDetailValue}>New Installation</span>
          </div>
          <div className={jobDetail}>
            <span className={jobDetailLabel}>Callout Fee</span>
            <span className={jobDetailValue}>$49</span>
          </div>
          <div className={jobDetail}>
            <span className={jobDetailLabel}>Preferred Date & Time</span>
            <span className={jobDetailValueContainer}>
              <span className={jobDetailValue}>
                <i className={iconCalendar} />
                18-03-2024
              </span>
              <span>-</span>
              <span className={jobDetailValue}>
                <i className={iconClock} />
                8am-12pm
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* job description */}
      <div>
        <h2 className={jobDescriptionTitle}>Job Description</h2>
        <div className={description}>
          <p>blocked drain downstairs</p>
          <p>**water overflowing</p>
          <p>**PLS CALL Stacy first to discuss quote and she is also the one</p>
          <p>responsible for payments for the callout</p>
          <p>**her daughter Christine will be on site pls cba 30mins</p>
          <p>Jerric</p>
          <div className={verticalLine}></div>
        </div>
      </div>

      <EditJobDetailsModal />
    </div>
  );
};

export default JobDetails;
