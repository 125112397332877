import {useState, useEffect} from 'react';
import {WBButton} from '../../../UIComponents/WBComponents';
import styles from './PriceBookModal.module.css';
import PriceBookItem from './PriceBookItem';
import {useJobCard} from '../../context/JobCardContext';
import CartComponent from './CartComponent';
import CustomCheckbox from '../common/CustomCheckbox';
import ItemViewComponent from './ItemViewComponent';

const PriceBookModal = () => {
  const {
    iconPlus,
    iconAdd,
    iconMinus,
    iconCart,
    priceBookModalOverlay,
    priceBook,
    priceBookHeader,
    priceBookTitle,
    closeButton,
    priceBookBody,
    priceBookLeftNavigation,
    priceBookLeftNavigationHeader,
    priceBookLeftNavigationBody,
    parentTab,
    parentTabOpen,
    parentTabHeader,
    parentTitle,
    childTab,
    childTabContainer,
    childTabHeader,
    childTitle,
    childTitleSelected,
    checkboxContainer,
    priceBookContent,
    priceBookContentHeader,
    priceBookContentBody,
  } = styles;

  const [data, setData] = useState([]);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const {
    openModal,
    closeModal,
    tradeCategories,
    modalData,
    getTotalItemCount,
    addServicesToInvoice,
  } = useJobCard();

  useEffect(() => {
    if (tradeCategories.length > 0) {
      setData(tradeCategories);
    }
  }, [tradeCategories]);

  useEffect(() => {
    const filtered = modalData.filter(item => {
      const matchesSearch =
        searchText === '' || item.description.toLowerCase().includes(searchText.toLowerCase());

      const matchesFilters =
        selectedFilters.length === 0 ||
        selectedFilters.every(filter =>
          item.categories.some(category => category.toLowerCase().includes(filter.toLowerCase()))
        );

      return matchesSearch && matchesFilters;
    });

    setFilteredItems(filtered);
  }, [searchText, modalData, selectedFilters]);

  useEffect(() => {
    console.log('PriceBookModal opened');
    return () => {
      console.log('PriceBookModal closed');
    };
  }, []);

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const toggleParentTab = parentId => {
    setData(prevData =>
      prevData.map(parent =>
        parent.id === parentId ? {...parent, isExpanded: !parent.isExpanded} : parent
      )
    );
  };

  const toggleChildTab = (parentId, childId) => {
    setData(prevData =>
      prevData.map(parent =>
        parent.id === parentId
          ? {
              ...parent,
              childTabs: parent.childTabs.map(child =>
                child.id === childId ? {...child, isExpanded: !child.isExpanded} : child
              ),
            }
          : parent
      )
    );
  };

  const toggleCheckbox = (parentId, childId, checkboxId, label) => {
    setData(prevData =>
      prevData.map(parent =>
        parent.id === parentId
          ? {
              ...parent,
              childTabs: parent.childTabs.map(child =>
                child.id === childId
                  ? {
                      ...child,
                      checkboxes: child.checkboxes.map(checkbox =>
                        checkbox.id === checkboxId
                          ? {...checkbox, isChecked: !checkbox.isChecked}
                          : checkbox
                      ),
                    }
                  : child
              ),
            }
          : parent
      )
    );

    setSelectedFilters(prev => {
      if (prev.includes(label)) {
        return prev.filter(filter => filter !== label);
      } else {
        return [...prev, label];
      }
    });
  };

  const openAddCustomService = () => {
    closeModal();
    openModal('customService', modalData);
  };

  const handleSearchChange = e => {
    setSearchText(e.target.value);
  };

  const handleAddServices = () => {
    addServicesToInvoice();
    closeModal();
  };

  return (
    <div className={priceBookModalOverlay}>
      <div className={priceBook}>
        <div className={priceBookHeader}>
          <h1 className={priceBookTitle}>Price Book</h1>
          <button className={closeButton} onClick={closeModal}>
            <i className={iconPlus}></i>
          </button>
        </div>
        <div className={priceBookBody}>
          {/* left content */}
          <div className={priceBookLeftNavigation}>
            <div className={priceBookLeftNavigationHeader}>
              <WBButton
                title="Add Custom Service"
                color="grey"
                height="40px"
                width="100%"
                styles={{padding: '10px 0'}}
                func={openAddCustomService}
              />
            </div>
            <div className={priceBookLeftNavigationBody}>
              {data.map(parent => (
                <div
                  key={parent.id}
                  className={`${parentTab} ${parent.isExpanded ? parentTabOpen : ''}`}
                >
                  <div onClick={() => toggleParentTab(parent.id)} className={parentTabHeader}>
                    <span className={parentTitle}>{parent.title}</span>
                    <span>
                      <i className={parent.isExpanded ? iconMinus : iconAdd}></i>
                    </span>
                  </div>
                  {parent.isExpanded && (
                    <div className={childTabContainer}>
                      {parent.childTabs.map(child => (
                        <div key={child.id} className={childTab}>
                          <div
                            onClick={() => toggleChildTab(parent.id, child.id)}
                            className={childTabHeader}
                          >
                            <span className={child.isExpanded ? childTitleSelected : childTitle}>
                              {child.title}
                            </span>
                            <span>
                              <i className={child.isExpanded ? iconMinus : iconAdd}></i>
                            </span>
                          </div>
                          {child.isExpanded && (
                            <div className={checkboxContainer}>
                              {child.checkboxes.map(checkbox => (
                                <CustomCheckbox
                                  key={checkbox.id}
                                  id={checkbox.id}
                                  label={checkbox.label}
                                  isChecked={checkbox.isChecked}
                                  onChange={() =>
                                    toggleCheckbox(parent.id, child.id, checkbox.id, checkbox.label)
                                  }
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* right content */}
          <div className={priceBookContent}>
            <div className={priceBookContentHeader}>
              <input
                type="text"
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search services..."
              />
              <WBButton
                icon={<i className={iconCart}></i>}
                title={getTotalItemCount().toString()}
                color="grey"
                height="40px"
                width="80px"
                styles={{padding: '10px 0'}}
                func={toggleCart}
              />
              <WBButton
                title="Add Services"
                color="blue"
                height="40px"
                width="140px"
                styles={{padding: '10px 0'}}
                func={handleAddServices}
              />
            </div>
            <div className={priceBookContentBody}>
              {filteredItems.map(item => (
                <PriceBookItem key={item.id} {...item} />
              ))}
            </div>
          </div>

          <CartComponent isOpen={isCartOpen} onClose={toggleCart} />
          <ItemViewComponent />
        </div>
      </div>
    </div>
  );
};

export default PriceBookModal;
