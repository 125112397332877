/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation } from "react-router-dom";
import { CallDB } from "../DBTools";
// import orgData from "../../Data Source/Organisations.json";
import { useState, useCallback, useEffect, useRef } from "react";

const LeftNavOrganisations = () => {
    // console.log("Full rerender");
    // let myJSON = localStorage.orgLocal;
    // let jsonConv = JSON.parse(myJSON) ? JSON.parse(myJSON) : "";

    const [organisations, setOrganisations] = useState("");
    const [orgFormatted, formatOrgs] = useState(""); // locally store organisations to stop scrollbar moving between pages
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = useLocation();
    const storedOrgs = useRef();

    const StoreLocalOrg = (value) => {
        let parsedJSON = JSON.stringify(value);
        localStorage.setItem('orgLocal', parsedJSON);
    }

    const fetchDB = useCallback(async () => {
        setIsLoading(true);
        if (orgFormatted !== undefined && orgFormatted !== "") {
            // console.log("Load from memory");
            // console.log("Org Values: ", orgFormatted);
            setOrganisations(storedOrgs);
        }
        else {
            // console.log("Pull form DB");
            // console.log("Org Values: ", orgFormatted);
            let response = await CallDB({ branch: "/getOrgs" });
            let fallback = "";
            if (response !== null) {
                if (response.length > 0) {
                    setOrganisations(response);
                }
                else {
                    fallback =
                        <li>
                            <a href="true" style={{ color: "white", backgroundColor: "red" }}>No organisations present.</a>
                        </li>;
                    formatOrgs(fallback);
                }
            } else {
                fallback =
                    <li>
                        <a href="true" style={{ color: "white", backgroundColor: "red" }}>Failed to retrieve organisations.</a>
                    </li>;
                formatOrgs(fallback);
            }
        }
        setIsLoading(false);
    }, [formatOrgs]);

    useEffect(() => {
        // formatOrgs(jsonConv);
        fetchDB();
    }, [fetchDB]);

    useEffect(() => {
        let myOrgs = "";
        if (!isLoading) {
            if (organisations.length > 0) {
                myOrgs = organisations.map((org) => {
                    // console.log("OrgID: /settings/organisation/edit/" + org.id + " Pathname: " + pathname);
                    return <li key={org.id}>
                        <Link to={"/settings/organisation/edit/" + org.id} className={pathname === ("/settings/organisation/edit/" + org.id) ? "active" : ""}>
                            {org.short_name}
                        </Link>
                    </li>
                });
                StoreLocalOrg(myOrgs);
            }
            else {
                <a href="true">No organisations found. Create on by selecting +Add</a>
            }
        }
        else {
            myOrgs =
                <li>
                    <Link style={{ color: "GrayText" }}>Loading organisations...</Link>
                </li>;
        }
        formatOrgs(myOrgs);
    }, [organisations, pathname]);

    return <ul
        className="dropdown-menu-list"
        aria-labelledby="dropdownMenuButton1"
    >{orgFormatted}</ul>;
};

export default LeftNavOrganisations;
