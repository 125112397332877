import {useState, useEffect} from 'react';
import styles from './AdditionalWorkers.module.css';
import {WBButton} from '../../../UIComponents/WBComponents';
import {WBDropdown, WBDropdownOption} from '../../JobCard/common/WBDropdown';

const AdditionalWorkers = ({onTimeChange, onRemove, index, maxHours}) => {
  const {
    iconTrash,
    iconDownArrow,
    additionalWorker,
    additionalWorkerHeader,
    additionalWorkerBody,
    label,
    dropdownGroup,
    dropdownRow,
  } = styles;

  const [workerTime, setWorkerTime] = useState({days: 0, hours: 0, minutes: 0});

  useEffect(() => {
    // Ensure worker time doesn't exceed maxHours
    const totalHours = workerTime.days * 24 + workerTime.hours + workerTime.minutes / 60;
    if (totalHours > maxHours) {
      const newWorkerTime = convertHoursToTime(maxHours);
      setWorkerTime(newWorkerTime);
      onTimeChange(newWorkerTime);
    }
  }, [maxHours]);

  const handleChange = (type, value) => {
    const newWorkerTime = {...workerTime, [type]: value};
    const totalHours = newWorkerTime.days * 24 + newWorkerTime.hours + newWorkerTime.minutes / 60;

    if (totalHours <= maxHours) {
      setWorkerTime(newWorkerTime);
      onTimeChange(newWorkerTime);
    } else {
      // If new time exceeds maxHours, set to maximum allowed time
      const adjustedTime = convertHoursToTime(maxHours);
      setWorkerTime(adjustedTime);
      onTimeChange(adjustedTime);
    }
  };

  const convertHoursToTime = hours => {
    const days = Math.floor(hours / 24);
    const remainingHours = Math.floor(hours % 24);
    const minutes = Math.round(((hours % 1) * 60) / 15) * 15; // Round to nearest 15 minutes
    return {days, hours: remainingHours, minutes};
  };

  const getMaxDays = () => Math.max(0, Math.min(3, Math.floor(maxHours / 24)));
  const getMaxHours = () => {
    const remainingHours = Math.max(0, maxHours - workerTime.days * 24);
    return Math.min(8, Math.floor(remainingHours));
  };
  const getMaxMinutes = () => {
    const remainingHours = Math.max(0, maxHours - workerTime.days * 24 - workerTime.hours);
    return Math.min(45, Math.floor((remainingHours * 60) / 15) * 15);
  };

  const downArrow = <i className={iconDownArrow}></i>;

  return (
    <div className={additionalWorker}>
      <div className={additionalWorkerHeader}>
        <span className={label}>Helper {index + 1}</span>
        <WBButton
          icon={<i className={iconTrash}></i>}
          title="Remove"
          color="grey"
          height="34px"
          styles={{
            padding: '8px 24px',
          }}
          func={onRemove}
        />
      </div>
      <div className={additionalWorkerBody}>
        <div className={dropdownGroup}>
          <label className={label}>Time Required</label>
          <div className={dropdownRow}>
            {/* Days Dropdown */}
            <WBDropdown
              leftitem={`${workerTime.days} ${workerTime.days === 1 ? 'day' : 'days'}`}
              rightitem={downArrow}
              height="40px"
              width="100%"
              borderRadius="10px"
              rightItemPadding="16px 24px 16px 0"
              leftItemPadding="16px 0 16px 24px"
              background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
              style={{border: 'none'}}
              disabled={getMaxDays() === 0}
            >
              {[...Array(getMaxDays() + 1)].map((_, day) => (
                <WBDropdownOption
                  key={`day-${day}`}
                  title={`${day} ${day === 1 ? 'day' : 'days'}`}
                  func={() => handleChange('days', day)}
                />
              ))}
            </WBDropdown>

            {/* Hours Dropdown */}
            <WBDropdown
              leftitem={`${workerTime.hours} ${workerTime.hours === 1 ? 'hour' : 'hours'}`}
              rightitem={downArrow}
              height="40px"
              width="100%"
              borderRadius="10px"
              rightItemPadding="16px 24px 16px 0"
              leftItemPadding="16px 0 16px 24px"
              background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
              style={{border: 'none'}}
              disabled={getMaxHours() === 0}
            >
              {[...Array(getMaxHours() + 1)].map((_, index) => (
                <WBDropdownOption
                  key={`hour-${index}`}
                  title={`${index} ${index === 1 ? 'hour' : 'hours'}`}
                  func={() => handleChange('hours', index)}
                />
              ))}
            </WBDropdown>

            {/* Minutes Dropdown */}
            <WBDropdown
              leftitem={`${workerTime.minutes} minutes`}
              rightitem={downArrow}
              height="40px"
              width="100%"
              borderRadius="10px"
              rightItemPadding="16px 24px 16px 0"
              leftItemPadding="16px 0 16px 24px"
              background={'linear-gradient(#F6F6F6 0%, #F3F3F3 100%)'}
              style={{border: 'none'}}
              disabled={getMaxMinutes() === 0}
            >
              {[0, 15, 30, 45]
                .filter(minute => minute <= getMaxMinutes())
                .map(minute => (
                  <WBDropdownOption
                    key={`minute-${minute}`}
                    title={`${minute} minutes`}
                    func={() => handleChange('minutes', minute)}
                  />
                ))}
            </WBDropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalWorkers;
