import { useEffect, useState } from "react";
import { WBButton, WBDropdown, WBDropdownOption, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";

const EditFilter = ({ closeModalMethod, editedID }) => {
    const [filtID, setFiltID] = useState();
    const [filtName, setFiltName] = useState();
    const [filtStatus, setFiltStatus] = useState(false);
    const [filtDesc, setFiltDesc] = useState();
    const [industry, setIndustry] = useState();
    const [allIndustries, setAllIndustries] = useState();
    const [industryName, setIndustryName] = useState();
    const [allSubFilters, setAllSubFilters] = useState();

    const [changesMade, setChangesMade] = useState(false);

    const toggleFiltStatus = () => setFiltStatus(status => { let result = !status; alert("status changes: " + result); return result });

    useEffect(() => {
        const GetFilter = async () => {
            const response = await PostDB({
                branch: "/getFilter",
                json: {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ id: editedID })
                }
            });

            if (response !== undefined && response !== null) {
                setFiltID(response[0].filter_id);
                setFiltName(response[0].filter_name);
                setIndustry(response[0].trade_id);
                setAllSubFilters(response[0].sub_filters ? JSON.parse(response[0].sub_filters) : []);
                setFiltDesc(response[0].description);
                setFiltStatus(response[0].active === 1 ? true : false);
            }
        }

        const GetIndustries = async () => {
            const response = await PostDB({
                branch: "/settings/industries",
                json: {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    }
                }
            });
            if (response !== undefined && response !== null) {
                setAllIndustries(response);
            }
        }

        GetIndustries();
        GetFilter();
    }, []);

    useEffect(() => {

        if (allIndustries !== undefined && allIndustries !== null) {
            setIndustryName(allIndustries.find(ind => ind.id === industry).name);
        }
    }, [industry])

    const SaveFilter = async () => {

        let bodyObj = {
            filter_id: filtID,
            filter_name: filtName,
            trade_id: industry, // I dont think they are bound by trade????
            sub_filters: JSON.stringify(allSubFilters),
            description: filtDesc,
            active: filtStatus === true ? 1 : 0
        }
        let response = await PostDB({
            branch: "/editFilter", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Filter has been successfully added");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Utility. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Filter</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Filter ID:</label>
                                    <p className="pl-10" id="code">{filtID ? filtID : "N/A"}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtActive" className="form-label">
                                        Filter Active?
                                    </label>
                                    <div className="p-10">
                                        <WBIOSToggle active={filtStatus} func={() => toggleFiltStatus()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Filter Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="filtName"
                                        id="filtName"
                                        placeholder="enter filter name"
                                        className="half"
                                        defaultValue={filtName}
                                        onChange={(e) => { setChangesMade(true); setFiltName(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtDesc" className="form-label">
                                        Filter Description
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <textarea
                                        name="filtDesc"
                                        className="half"
                                        id="filtDesc"
                                        placeholder="enter filter description"
                                        defaultValue={filtDesc}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setFiltDesc(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Industry related to Filter
                                    </label>
                                    <WBDropdown leftitem={industryName} rightitem={<i className="icon-arrow-down"></i>} color={"white"}>
                                        {allIndustries && allIndustries.map((industry) => {
                                            return (
                                                <WBDropdownOption title={industry.name} func={() => { setChangesMade(true); setIndustryName(industry.name); setIndustry(industry.id) }} />
                                            )
                                        })}
                                    </WBDropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
                        <WBButton title="Save" color={"green"} func={() => SaveFilter()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditFilter;