import styles from "../addJobModals.module.css";

const BookingButton = ({
  style,
  func = null,
  text,
  textColor = "black",
  bold = 0,
  disabled = false,
}) => {
  let textStyling = {
    color: textColor,
    fontWeight: bold ? "bold" : "normal",
  };

  return (
    <>
      <div 
        className={styles.bookingButton} 
        style={style} 
        onClick={!disabled ? func : null} 
        aria-disabled={disabled} 
      >
        <p style={textStyling}>{text}</p>
      </div>
    </>
  );
};

export default BookingButton;
