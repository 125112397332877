import styles from './CustomCheckbox.module.css';

const CustomCheckbox = ({id, label, isChecked, onChange}) => {
  return (
    <div className={styles.customCheckbox}>
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        checked={isChecked}
        onChange={onChange}
        className={styles.hiddenCheckbox}
      />
      <label htmlFor={`checkbox-${id}`} className={styles.checkboxLabel}>
        <span className={styles.customCheckboxIcon}>
          {isChecked && <span className={styles.iconChecked} />}
        </span>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
