import { useState } from "react";
import styles from "../addJobModals.module.css";

const BookingDropdown = ({
  leftitem,
  rightitem,
  dropdownItems,
  textColor,
  backgroundColor = "rgba(243, 243, 243, 1)",
  border = "1px solid rgba(243, 243, 243, 1)",
  height,
  width,
  style = {},
  padding = "15px 20px",
  borderRadius = "10px",
  marginTop = "9px",
  marginBottom = "0px",
  value,
  onChange,  // Add onChange prop to pass selected value back to parent
}) => {
  const [hidden, setHidden] = useState(true);

  const toggleOptions = () => setHidden(!hidden);

  const handleOptionClick = (option) => {
    setHidden(true);
    onChange(option);
  };

  const options = dropdownItems
    ? dropdownItems
    : ["Test", "Run", "Dog", "Space", "Cat", "Mouse"];

  const containerStyle = {
    height,
    border,
    width,
    padding,
    borderRadius,
    backgroundColor,
    marginTop,
    marginBottom,
    ...style,
  };

  const optionContainerStyle = {
    width,
    borderRadius,
    border,
  };

  const optionItemStyle = {
    height,
    backgroundColor: "white",
    padding,
  };

  return (
    <div>
      <div
        className={styles.bookingDropdownContainer}
        style={containerStyle}
        onClick={toggleOptions}
      >
        <p>{value || leftitem}</p>
        {rightitem ? (
          <img src={rightitem} alt="chevron_down" />
        ) : (
          <img src="/images/client/chevron-down.svg" alt="chevron_down" />
        )}
      </div>
      {!hidden && (
        <div
          className={styles.bookingDropdownOptions}
          style={optionContainerStyle}
        >
          {options.map((option, index) => (
            <div
              className={`${styles.hoverColor} ${styles.bookingDropdownItems}`}
              key={index}
              style={optionItemStyle}
              onClick={() => handleOptionClick(option)}
            >
              <p>{option}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BookingDropdown;
