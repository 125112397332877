import React, { useState, useMemo } from "react";
import styles from "../addJobModals.module.css";

const SummaryCustomerDetails = ({
  bookingData,
  customerTypes,
  paymentTerms,
  handleEditCustomerDetails,
}) => {
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(0);

  const customerContacts = useMemo(() => {
    const contacts = [bookingData.customerDetails];
    const additionalContacts = bookingData.customerDetails?.contacts || [];
    
    additionalContacts.forEach(contact => {
      if (contact.firstName || contact.lastName) {
        contacts.push(contact);
      }
    });

    return contacts;
  }, [bookingData]);

  const handleCustomerClick = (index) => {
    setSelectedCustomerIndex(index);
  };

  const getSelectedCustomer = () => {
    if (selectedCustomerIndex === 0) {
      return bookingData.customerDetails;
    } else {
      const validContacts = bookingData.customerDetails.contacts.filter(
        contact => contact.firstName || contact.lastName
      );
      return validContacts[selectedCustomerIndex - 1];
    }
  };

  const selectedCustomer = getSelectedCustomer();

  return (
    <div className={styles.summaryCustomerDetails}>
      <div className={styles.headerContainer}>
        <p className={`${styles.fontMedium} bold`}>Customer Details</p>
        <div className={styles.editButton} onClick={handleEditCustomerDetails}>
          <img src="/images/client/edit.svg" alt="edit" />
        </div>
      </div>
      <div className={styles.summaryCustomersContainer}>
        {customerContacts.map((contact, index) => (
          <div
            key={index}
            className={`${styles.propertyContactCustomer} ${
              selectedCustomerIndex === index ? styles.selectedCustomer : ""
            }`}
            onClick={() => handleCustomerClick(index)}
          >
            <p>{`${contact.firstName || ""} ${contact.lastName || ""}`}</p>
          </div>
        ))}
      </div>
      {selectedCustomerIndex === 0 && (
        <div className={styles.summaryButtonDetailsContainer}>
          <div className={styles.summaryAccountSpan}>
            <p>Primary Account</p>
          </div>
        </div>
      )}
      <div className={styles.summaryDetails}>
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Name</p>
          <div className={styles.summaryIndivDetailsValue}>
            <p>{`${selectedCustomer.firstName || ""} ${
              selectedCustomer.lastName || ""
            }`}</p>
          </div>
        </div>
        {selectedCustomerIndex === 0 && (
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Billing Address</p>
            <div className={styles.summaryIndivDetailsValue}>
              <img src="/images/client/locationpin.svg" alt="location" />
              <p>{selectedCustomer.address || "N/A"}</p>
            </div>
          </div>
        )}
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Email</p>
          <div className={styles.summaryIndivDetailsValue}>
            <img src="/images/client/email.svg" alt="email" />
            <p>{selectedCustomer.email || "N/A"}</p>
          </div>
        </div>
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Phone</p>
          <div className={styles.summaryIndivDetailsValue}>
            <img src="/images/client/mobile.svg" alt="phone" />
            <p>{selectedCustomer.phone || "N/A"}</p>
          </div>
        </div>
        <div className={styles.summaryIndivDetails}>
          <p className={styles.summaryIndivDetailsKey}>Mobile</p>
          <div className={styles.summaryIndivDetailsValue}>
            <img src="/images/client/mobile.svg" alt="mobile" />
            <p>{selectedCustomer.mobile || "N/A"}</p>
          </div>
        </div>
        {selectedCustomerIndex !== 0 && (
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Relationship</p>
            <div className={styles.summaryIndivDetailsValue}>
              <p>{selectedCustomer.relationship || "N/A"}</p>
            </div>
          </div>
        )}
      </div>
      {selectedCustomerIndex === 0 && (
        <div className={styles.summaryMiscellaneous}>
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Type</p>
            <div className={styles.summaryIndivDetailsValue}>
              <p>{customerTypes}</p>
            </div>
          </div>
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Payment Term</p>
            <div className={styles.summaryIndivDetailsValue}>
              <p>{selectedCustomer.pt_title || paymentTerms || "N/A"}</p>
            </div>
          </div>
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Work Order Details</p>
            <div className={styles.summaryIndivDetailsValue}>
              <p>{selectedCustomer.workorder_details || selectedCustomer.work_order_details || "N/A"}</p>
            </div>
          </div>
          <div className={styles.summaryIndivDetails}>
            <p className={styles.summaryIndivDetailsKey}>Special Instruction</p>
            <div className={styles.summaryIndivDetailsValue}>
              <p>{selectedCustomer.special_instruction || "N/A"}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SummaryCustomerDetails;
