import styles from "../contactNotes.module.css";

const ContactDetails = ({ contact }) => {
  return (
    <>
      <div className={styles.customerInfo}>
        <div className={styles.customerData}>
          <h3 className={styles.text18}>{contact.name}</h3>
          <span>{contact.relationship}</span>
        </div>
        <div className={styles.customerBillingAddress}>
          <h4>Billing Address</h4>
          <div className={styles.location}>
            <img
              src="/images/client/locationpin.svg"
              alt="Location"
              className={styles.locationIcon}
            />
            <p>{contact.billingAddress}</p>
            <a href="" className={styles.locationButton}>
              Open Map
            </a>
          </div>
        </div>
        <div className={styles.customerContactData}>
          <div className={styles.customerContactInfo}>
            <h4>Mobile</h4>
            <div>
              <img src="/images/client/mobile.svg" alt="Mobile" />
              <span>{contact.mobile}</span>
            </div>
          </div>
          <div className={styles.customerContactInfo}>
            <h4>Phone</h4>
            <div>
              <img src="/images/client/mobile.svg" alt="Phone" />
              <span>{contact.phone}</span>
            </div>
          </div>
          <div className={styles.customerContactInfo}>
            <h4>Email</h4>
            <div>
              <img src="/images/client/email.svg" alt="Email" />
              <span>{contact.email}</span>
            </div>
          </div>
        </div>
        <div className={styles.customerPaymentTerms}>
          <h4>Payment Terms</h4>
          <span>{contact.paymentTerms}</span>
        </div>
      </div>
    </>
  );
};

export default ContactDetails;
