import React, { useState, useContext, useEffect, useReducer } from "react";
import styles from "../addJobModals.module.css";
import { WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import BookingDropdown from "./BookingDropdown";
import BookingInput from "./BookingInput";
import BookingButton from "./BookingButton";
import { JobBookingContext } from "../JobBookingContext";
import { useJobCustomerModals } from "../hooks/useJobCustomerModals";

const formReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_STATE":
      return { ...state, ...action.payload };
    case "UPDATE_FIELD":
      return {
        ...state,
        property_details: {
          ...state.property_details,
          [action.field]: action.value,
        },
      };
    case "UPDATE_CONTACT_FIELD":
      return {
        ...state,
        property_details: {
          ...state.property_details,
          jobsite_contact: state.property_details.jobsite_contact.map(
            (contact, index) =>
              index === action.contactIndex
                ? { ...contact, [action.field]: action.value }
                : contact
          ),
        },
      };
    default:
      return state;
  }
};

const EditPropertyDetailsForm = ({
  handleEditPropertyDetails,
  isEdit,
  cancel,
  address,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [customerIndex, setCustomerIndex] = useState(0);

  const { addJobSiteContacts } = useJobCustomerModals();

  const { bookingData, fetchedData, updateBookingData, customerID, jobsiteID } =
    useContext(JobBookingContext);

  const initialFormState = {
    cust_id: customerID,
    property_details: {
      jobsite_address: {
        jobsite_address_id: jobsiteID ? jobsiteID : null,
        address_line: "",
        address_line1: "",
        suburb: "",
        postcode: "",
        state: "",
      },
      jobsite_contact: [
        {
          email: "",
          phone: "",
          mobile: "",
          lastName: "",
          firstName: "",
          relationship: "",
          notes: "",
          is_primary: 1,
        },
        {
          email: "",
          phone: "",
          mobile: "",
          lastName: "",
          firstName: "",
          relationship: "",
          notes: "",
          is_primary: 0,
        },
        {
          email: "",
          phone: "",
          mobile: "",
          lastName: "",
          firstName: "",
          relationship: "",
          notes: "",
          is_primary: 0,
        },
      ],
    },
  };

  const [formState, formDispatch] = useReducer(formReducer, initialFormState);

  const contactsRelationshipList = fetchedData.contactsRelationshipList || [];

  useEffect(() => {
    console.log(bookingData);
    if (bookingData.propertyDetails) {
      formDispatch({
        type: "SET_FORM_STATE",
        payload: bookingData.propertyDetails,
      });
    }
  }, []);

  useEffect(() => {
    formDispatch({ type: "UPDATE_FIELD", field: "cust_id", value: customerID });
  }, [customerID]);
  useEffect(() => {
    if (jobsiteID) {
      formDispatch({
        type: "UPDATE_FIELD",
        field: "jobsite_address",
        value: {
          ...formState.property_details.jobsite_address,
          jobsite_address_id: jobsiteID,
        },
      });
    }
  }, [jobsiteID]);

  const handleUpdate = () => {
    addJobSiteContacts(formState.property_details);
    updateBookingData("propertyDetails", formState);
    cancel();
  };

  const toggleActive = () => {
    setIsActive((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    formDispatch({
      type: "UPDATE_CONTACT_FIELD",
      field: id,
      value,
      contactIndex: customerIndex,
    });
  };

  const getContactValue = (field) => {
    return (
      formState.property_details?.jobsite_contact[customerIndex]?.[field] ||
      bookingData.propertyDetails?.jobsite_contact?.[customerIndex]?.[field] ||
      ""
    );
  };

  return (
    <div className={styles.summaryPropertyContactDetails}>
      <div className={styles.headerContainer}>
        <p className={`${styles.fontMedium} bold`}>Property Contact Details</p>
        <div className={styles.editButton} onClick={handleEditPropertyDetails}>
          <img src="/images/client/edit.svg" alt="edit" />
        </div>
      </div>
      <div className={styles.propertyDetailsForm}>
        <div
          className={styles.summaryCustomersContainer}
          style={{ marginBottom: "36px" }}
        >
          {[0, 1, 2].map((index) => (
            <div
              key={index}
              className={`${styles.propertyContactCustomer} ${
                customerIndex === index ? styles.selectedCustomer : ""
              }`}
              onClick={() => setCustomerIndex(index)}
            >
              <p>{index === 0 ? `Primary Contact` : `Contact ${index + 1}`}</p>
            </div>
          ))}
        </div>
        <div className={styles.editPropertyContactForm}>
          {/* {customerIndex === 0 && (
            <div className={styles.sameCustomerDetails}>
              <p className={styles.fontSmall}>Same as Customer Details</p>
              <div className="switch">
                <WBIOSToggle active={isActive} func={toggleActive} />
              </div>
            </div>
          )} */}
          <div className={styles.contactFormNames}>
            <BookingInput
              title="First Name"
              placeholder="Enter firstname"
              id="firstName"
              value={getContactValue("firstName")}
              onChange={handleInputChange}
            />
            <BookingInput
              title="Surname"
              placeholder="Enter surname"
              id="lastName"
              value={getContactValue("lastName")}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.contactFormDetails}>
            <BookingInput
              title="Phone"
              placeholder="Enter phone number"
              id="phone"
              value={getContactValue("phone")}
              onChange={handleInputChange}
            />
            <BookingInput
              title="Mobile"
              placeholder="Enter mobile number"
              id="mobile"
              value={getContactValue("mobile")}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.contactFormDetails}>
            <BookingInput
              title="Email"
              placeholder="Enter email"
              id="email"
              value={getContactValue("email")}
              onChange={handleInputChange}
            />
            <div>
              <p className={`${styles.fontMedium} bold`}>
                Relationship to Customer
              </p>
              <BookingDropdown
                dropdownItems={contactsRelationshipList.map(
                  (item) => item.title
                )}
                width="303px"
                height="48px"
                leftitem={
                  formState.property_details.jobsite_contact[customerIndex]?.relationship === ""
                    ? "Choose Relationship"
                    : contactsRelationshipList.find(
                        (item) =>
                          item.id ===
                          formState.property_details.jobsite_contact[customerIndex]?.relationship
                      )?.title || "Choose Relationship"
                }
                value={
                  contactsRelationshipList.find(
                    (item) =>
                      item.id ===
                      formState.property_details.jobsite_contact[customerIndex]?.relationship
                  )?.title || ""
                }
                onChange={(value) => {
                  const selectedItem = contactsRelationshipList.find(
                    (item) => item.title === value
                  );
                  if (selectedItem) {
                    formDispatch({
                      type: "UPDATE_CONTACT_FIELD",
                      field: "relationship",
                      value: selectedItem.id,
                      contactIndex: customerIndex,
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles.contactFormRelationship}>
            <BookingInput
              title="Additional Notes"
              placeholder="Enter additional notes"
              id="notes"
              value={getContactValue("notes")}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className={styles.customerDetailsButtonContainer}>
          <BookingButton
            func={cancel}
            text="Cancel"
            style={{
              width: "90px",
              height: "44px",
              backgroundColor: "white",
              border: "1px solid rgba(231, 231, 231, 1)",
            }}
          />
          <BookingButton
            func={handleUpdate}
            text="Update"
            textColor="white"
            bold={1}
            style={{
              width: "88px",
              height: "44px",
              backgroundColor: "rgba(0, 148, 255, 1)",
              border: "none",
              color: "blue",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPropertyDetailsForm;
