import { useEffect, useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { useNavigate } from "react-router";

const AddOrganisations2 = ({ closeAddOrgMethod, toggleRefresh }) => {
    const navigate = useNavigate();
    const [orgName, setOrgName] = useState("");
    const [returnedID, setReturnedID] = useState(null);
    window.addEventListener('keydown', function (event) { if (event.key === "Escape") closeAddOrgMethod() }); // closes the modal and goes back to ServicesPage

    const BuildOrg = async (orgID) => {
        if (orgName.length > 0) {
            const response = await PostDB({
                branch: "/addOrg", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ org_name: orgID })
                }
            });
            if (response.inserted_org_id !== null) {
                setReturnedID(response.inserted_org_id);
            }
        }
        else {
            alert("Please enter a valid name.")
        }
    }

    useEffect(() => {
        if (returnedID !== null) {
            alert("Organisation has been successfully added");
            closeAddOrgMethod();
            toggleRefresh();
            return navigate("/settings/organisation/edit/" + returnedID);
        }
    }, [returnedID])

    return (
        <div className="bg-black popup">
            <div className="popup-window">
                <div className="popup-container">
                    <h1>Add New Organisation</h1>
                    <div className="popup-context">
                        <h2>Organisation Display Name</h2>
                        <h3 className="not-bold">Max 20 Characters (Remaining 20)</h3>
                        <input type="text" name="org_name" className="form-control" id="organisation_name" placeholder="Enter Organisation name" onChange={(myOrg) => setOrgName(myOrg.target.value)}></input>
                    </div>
                </div>
                <div className="hr" />
                <div className="popup-footer">
                    <div className="popup-button-row">
                        <button type="button" className="btn btn-default" onClick={closeAddOrgMethod}>Cancel</button>
                        <button type="button" className="btn btn-success" onClick={() => BuildOrg(orgName)}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddOrganisations2