import React, { useState } from "react";
import styles from "./scheduler.module.css";
import SchedulerSidebar from "./SchedulerSidebar";
import SchedulerMenubar from "./SchedulerMenubar";
import SchedulerCalendar from "./SchedulerCalendar";
import MiniTechProfileModal from "./MiniTechProfileModal";
import { ScheduleProvider } from "./context/ScheduleContext";
import TimeBlockerModal from "./TimeBlockerModal";
const SchedulePage = () => {
  const [isGridLocked, setIsGridLocked] = useState(true);

  return (
    <ScheduleProvider>
      <div className={styles.schedulerContainer}>
        <SchedulerSidebar />
        <div className={styles.schedulerContent}>
          <SchedulerMenubar
            isGridLocked={isGridLocked}
            setIsGridLocked={setIsGridLocked}
          />
          <div className={styles.calendarContainer}>
            <SchedulerCalendar isGridLocked={isGridLocked} />
          </div>
        </div>
        <MiniTechProfileModal />
        <TimeBlockerModal />
      </div>
    </ScheduleProvider>
  );
};

export default SchedulePage;
