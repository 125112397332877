import styles from './JobScheduledMilestone.module.css';

const JobScheduledMilestone = ({
  scheduledBy,
  dateTime: schedulingDateTime,
  technicianName,
  technicianLocation,
  technicianIcon,
  isUrgencyListMove,
}) => {
  const {
    container,
    dashDesign,
    milestoneBody,
    schedulingInfo,
    technicianScheduling,
    jobScheduled,
    dateTime,
    timeSlotLabel,
    contentWrapper,
    iconTechnician,
    iconClock,
    technicianInfo,
    technicianSchedule,
    urgencyMove,
  } = styles;

  return (
    <div className={container}>
      <div className={dashDesign}></div>
      <div className={contentWrapper}>
        <div className={milestoneBody}>
          <div className={schedulingInfo}>
            <span className={jobScheduled}>Job Scheduled</span> by {scheduledBy}
          </div>
          <div className={dateTime}>{schedulingDateTime}</div>
        </div>
        <div className={milestoneBody}>
          {isUrgencyListMove ? (
            <>
              <div className={urgencyMove}>
                Job has been moved on <span className={jobScheduled}>Urgency</span> list
              </div>
              <div className={dateTime}>
                <i className={iconClock}></i>
                <span className={timeSlotLabel}>Timeslot - &nbsp;</span>
                <span>{schedulingDateTime}</span>
              </div>
            </>
          ) : (
            <>
              <div className={technicianScheduling}>
                Job has been assigned to technician
                <div className={technicianInfo}>
                  <img
                    src={technicianIcon}
                    alt="Technician"
                    className={iconTechnician}
                  />
                  <span className={technicianSchedule}>
                    {technicianName} ({technicianLocation})
                  </span>
                </div>
              </div>
              <div className={dateTime}>
                <i className={iconClock}></i>
                <span className={timeSlotLabel}>Timeslot - &nbsp;</span>
                <span>{schedulingDateTime}</span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobScheduledMilestone;
