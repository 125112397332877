import React from "react";
import { useState, useEffect, useContext } from "react";
import styles from "../addJobModals.module.css";
import BookingDropdown from "./BookingDropdown";
import { JobBookingContext } from "../JobBookingContext";
import BookingButton from "./BookingButton";
import { PostDB } from "../../../../Components/DBTools";
import { toast } from 'react-toastify';

const CancellationConfirmation = ({ closeModal, handleHideCloseBooking }) => {
  const [cancellationReason, setCancellationReason] = useState(null);
  const { fetchedData, customerID, setSelectedCustomer } =
    useContext(JobBookingContext);
  const cancellationReasonsList = fetchedData.cancellationReasonsList;

  const submitCancellation = async (customerID, reason) => {
    const requestBody = {
      cust_id: customerID,
      reason: reason,
    };

    console.log(requestBody);
    try {
      const response = await PostDB({
        branch: "/cancel_call_booking",
        json: {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
      });

      if (response) {
        console.log("Cancellation submitted successfully:", response);
        toast.success("Booking cancelled.");
        setSelectedCustomer(null);
        closeModal();
      } else {
        console.error("Failed to submit cancellation:", response);
        toast.error("Error cancelling booking.");
      }
    } catch (error) {
      console.error("Error submitting cancellation:", error);
      toast.error("Error cancelling booking.");
    }
  };

  return (
    <>
      <div className={styles.bookingCancellation}>
        <p className={`${styles.fontBig} bold`}>Booking Cancellation</p>
        <p style={{ marginTop: "12px" }}>
          By Canceling Booking customer booking, data will be saved as a draft
          in the system
        </p>
        <div className={styles.bookingReasonCancel}>
          <p>Reason for Cancelation</p>
          <BookingDropdown
            width="260px"
            height="47px"
            dropdownItems={cancellationReasonsList.map((item) => item.title)}
            leftitem={cancellationReason ? cancellationReason : "Choose Reason"}
            onChange={(value) => setCancellationReason(value)}
          />
          <div className={styles.bookingButtonContainer}>
            <BookingButton
              func={handleHideCloseBooking}
              text="Cancel"
              style={{
                width: "90px",
                height: "44px",
                backgroundColor: "white",
                border: "1px solid rgba(231, 231, 231, 1)",
              }}
            />
            <BookingButton
              func={async () => {
                await submitCancellation(customerID, cancellationReason);
                setTimeout(() => {
                  window.location.reload();
                }, 750);
              }}
              text="Confirm"
              textColor="white"
              bold={1}
              style={{
                width: "158px",
                height: "44px",
                backgroundColor: cancellationReason
                  ? "rgba(255, 72, 75, 1)"
                  : "rgba(200, 200, 200, 1)",
                border: "none",
                color: cancellationReason ? "white" : "rgba(150, 150, 150, 1)",
                cursor: cancellationReason ? "pointer" : "cursor",
              }}
              disabled={!cancellationReason}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationConfirmation;
