import { useEffect, useState, useRef } from "react";
import { CallDB, checkUrlType, PostDB } from "../../../../Components/DBTools";
import { AddServiceValidation } from "../../../../lib/js/addservicevalidation";
// import { useError } from "../../../../lib/js/errorpopup";
import { ToBase64 } from "../../../../lib/js/convertimagetobase64";
import {
	WBButton,
	WBDropdown,
	WBDropdownOption,
	WBSelector,
	WBTabs,
} from "../../../../Components/UIComponents/WBComponents";

const EditService = ({ closeModalMethod, editedID }) => {
	const [industry, setIndustry] = useState(0); //required const
	const [category, setCategory] = useState(0); //required const
	const [subCategory, setSubCategory] = useState(0);
	const [spiff, setSpiff] = useState(0);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [specialNotes, setSpecialNotes] = useState("");
	const [billableRates, setBillableRates] = useState([]);

	const [industriesFormatted, formatIndustries] = useState("");
	const [categoriesFormatted, formatCategories] = useState("");
	const [subCategoriesFormatted, formatSubCategories] = useState("");
	const [spiffsFormatted, formatSpiffs] = useState("");
	const [hourlyRatesFormatted, formatHourlyRates] = useState("");

	const [daysTrade, setDaysTrade] = useState(0);
	const [hoursTrade, setHoursTrade] = useState(1);
	const [minutesTrade, setMinutesTrade] = useState(0);
	const [daysHelper, setDaysHelper] = useState(0);
	const [hoursHelper, setHoursHelper] = useState(0);
	const [minutesHelper, setMinutesHelper] = useState(0);

	const [materialCosts, setMaterialCosts] = useState(0.0);
	const [utilitiesCosts, setUtilitiesCosts] = useState(0.0);

	const industryChange = useRef(false);
	const [manualMargins, setManualMargins] = useState([]);

	const [allFilters, setAllFilters] = useState([]);
	const [allTags, setAllTags] = useState([]);
	const [allMaterials, setAllMaterials] = useState([]);
	const [allUtilities, setAllUtilities] = useState([]);

	//Display Components
	const [myFiltersSelected, setMyFiltersSelected] = useState([]);
	const [myTagsSelected, setMyTagsSelected] = useState([]);
	const [myMaterialsSelected, setMyMaterialsSelected] = useState([]);
	const [myUtilitiesSelected, setMyUtilitiesSelected] = useState([]);

	const [isDisabled, setIsDisabled] = useState(false);

	// const { errormessage, setErrorMessage } = useError();
	// const [finished, setFinished] = useState(false);
	const [submitResult, setSubmitResult] = useState();

	const [filesToSend, setFilesToSend] = useState([]);
	const [pdfsFormatted, setPDFsFormatted] = useState([]);
	const [imagesFormatted, setImagesFormatted] = useState([]);
	const [fileNames, setFileNames] = useState([]);
	const [selectedThumbnail, setSelectedThumbnail] = useState(null);

	const [organisations, setOrganisations] = useState([]);

	const [selectedOrg, setSelectedOrg] = useState(null);

	const GetService = async () => {
		const response = await PostDB({
			branch: "/getService",
			json: {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: editedID }),
			}
		});
		console.log("Returned Service: ", response);
		if (response !== undefined && response !== null) {
			await GetIndustries();
			setIndustry(response.service.trade_id);
			await GetCategories(response.service.trade_id);
			setCategory(response.service.category_id);
			await GetSubCategories(response.service.category_id);
			setSubCategory(response.service.sub_category_id);
			await GetSpiffs();
			setSpiff(response.service.spiff_id);
			setMyTagsSelected(
				JSON.parse(
					response.service.tags !== null ? response.service.tags : "[]"
				)
			);
			setMyMaterialsSelected(
				response.service.materials !== null
					? JSON.parse(response.service.materials).map((material) => ({
						id: material.id,
						name: material.title,
						cost: material.final_cost,
					}))
					: []
			);
			setMyFiltersSelected(JSON.parse(response.service.filters));
			setMyUtilitiesSelected(
				response.service.utilities !== null
					? JSON.parse(response.service.utilities).map((utility, i) => ({
						id: utility.utility_id,
						name: utility.utility_name,
						cost: utility.utility_cost,
					}))
					: []
			);

			setTitle(
				response.service.short_desc !== null ? response.service.short_desc : ""
			);
			setDescription(
				response.service.long_desc !== null ? response.service.long_desc : ""
			);
			setSpecialNotes(
				response.service.notes !== null ? response.service.notes : ""
			);
			let hrs = JSON.parse(response.service.hrs);
			setDaysHelper(hrs[0].helper_days);
			setDaysTrade(hrs[0].trademan_days);
			setHoursHelper(hrs[0].helper_hrs);
			setHoursTrade(hrs[0].trademan_hrs);
			setMinutesHelper(hrs[0].helper_mins);
			setMinutesTrade(hrs[0].trademan_mins);

			// Handle this later because its not working now. It has been cancelled out in the front-end until it is fixed
			let filesImport = response.service.media;
			if (filesImport !== null)
				for (let i = 0; i < filesImport.length; i++) {
					// const callurlResponse = await CallURL({ url: filesImport[i].media_link });
					// if (callurlResponse.ok) {
					// console.log("Response: ", callurlResponse);
					let myString = filesImport[i].media_link.lastIndexOf('/');
					let fileName = filesImport[i].media_link.substring(myString + 1);
					if (filesImport[i].is_thumbnail === 1) {
						setSelectedThumbnail(i)
					}
					setFilesToSend((oldArray) => [...oldArray,
					{
						id: filesImport[i].media_id,
						name: fileName,
						file: filesImport[i].media_link,
						action: "none"
					}])
					// }
					// else {
					// 	console.log(callurlResponse)
					// }
					// console.log("File Names: ", fileNames);
					// console.log("Files: ", filesToSend);
					console.log("Files: ", filesToSend);
				}

			let trades = await PostDB({
				branch: "/settings/industries",
				json: {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					}
				}
			});
			console.log("Trades:", trades);

			const response2 = await PostDB({
				branch: "/getAllBHTemplates",
				json: {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ org_id: 1 }), // This is just how its coded (org_id isnt actually required).....
				}
			});
			if (response2 !== null && response2 !== undefined) {
				response2.forEach((bh, i) => {
					const extraHourlyRates = JSON.parse(bh.extra_data);
					let allBH = [];
					if (extraHourlyRates) {
						for (let i = 0; i < trades.length; i++) {
							if (!allBH[i]) {
								allBH[i] = {};
								for (let j = 0; j < extraHourlyRates.length; j++) {
									if (trades[i].id === extraHourlyRates[j].dept_id) {
										allBH[i].industry = extraHourlyRates[j].dept_id;
										allBH[i].industry_name = extraHourlyRates[j].department;
										if (extraHourlyRates[j].billable_type === "Standard Hours") {
											allBH[i].standardID = extraHourlyRates[j].id;
											allBH[i].standard = extraHourlyRates[j].hourly_rate;
										}
										if (extraHourlyRates[j].billable_type === "Helper Hours") {
											allBH[i].helperID = extraHourlyRates[j].id;
											allBH[i].helper = extraHourlyRates[j].hourly_rate;
										}
									}
								}
								if (!Object.keys(allBH[i]).length) {
									allBH.splice(i, 1);
								}
							}
						}
					}
					response2[i].extra_data = allBH;
				});
				setBillableRates(response2);
			}
			if (response.service.margin !== null) {
				setManualMargins(JSON.parse(response.service.margin));
			}
		}
	};

	const GetServiceLocationData = async () => {
		const response = await CallDB({ branch: "/getorgs" });
		setOrganisations(response);
		setSelectedOrg(response[0]);
	};

	const handleImageInput = async (event) => {
		const file = event.target.files[0];
		debugger;
		if (file && file.size <= 52428800) {
			const base64File = await ToBase64(file);
			if (file.type === "application/pdf") {
				const pdf = URL.createObjectURL(file);
				// alert("PDF's are still a work in progress and will not save upon saving this service.")
				setFilesToSend((oldArray) => [...oldArray, {
					id: null,
					name: file.name,
					file: pdf,
					action: "new"
				}]);
			} else if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg") {
				setFilesToSend((oldArray) => [...oldArray, {
					id: null,
					name: file.name,
					file: base64File,
					action: "new"
				}]);

			} else {
				alert("You cannot upload this type of file. Please only upload .pdf, .png, .jpg, or .jpeg");
			}
		} else {
			alert("You cannot upload this file. Please follow the guidelines on correct files and upload again.");
		}
		event.target.files = null;
		event.target.value = null;
	};

	const handleImageRemove = (indexToRemove) => {

		setFilesToSend((prevFiles) => {
			let updatedFiles = [...prevFiles];
			updatedFiles[indexToRemove].action = "remove";
			return updatedFiles;
		});
	};

	const openImageInNewTab = (myFile) => {
		var win = window.open();
		win.document.write(
			'<iframe src="' +
			myFile +
			'" frameborder="0" style="border:0;top:0px;left:0px;bottom:0px;right:0px;width:100%;height:100%;" allowfullscreen></iframe>'
		);
	};

	useEffect(() => {
		let allImagesFormatted = [];
		let allPDFsFormatted = [];
		// console.log("Added files: ", addedFiles);
		// console.log("Removed Files: ", removedFiles);
		for (let i = 0; i < filesToSend.length; i++) {
			if (filesToSend[i].action !== "remove") {
				let myFile = filesToSend[i];
				debugger;
				if (checkUrlType(myFile.file) === "Image URL" || checkUrlType(myFile.file) === "Base64 Image") {
					allImagesFormatted.push(
						<div key={i} id={myFile.id} className="flex">
							<div>
								<img
									className="add-service-image"
									src={myFile.file}
									alt="UserSelectedImage"
								/>
							</div>
							<div className="flex-column">
								<p className="image-title">{myFile.name}</p>
								{
									(selectedThumbnail === i)
										?
										<p className="thumbnail-prompt selected">Selected thumbnail</p>
										:
										<p className="thumbnail-prompt" onClick={() => setSelectedThumbnail(i)}>Set Thumbnail Image</p>
								}
								<p className="view-image" onClick={() => openImageInNewTab(myFile.file)}>View Image</p>
								<p className="remove" onClick={() => handleImageRemove(i)}>Remove Image</p>
							</div>
						</div>
					);
					// console.log("Image ID: ", i);
				} else if (checkUrlType(myFile.file) === "PDF URL" || checkUrlType(myFile.file) === "Base64 PDF") {
					let blob = myFile.file;
					allPDFsFormatted.push(
						<div key={i} id={myFile.id} className="custom-row">
							<div className="custom-cell">{myFile.name}</div>
							<div className="custom-cell">PDF</div>
							<div className="custom-cell">
								<WBDropdown
									leftitem={
										<div className="title">
											<div className="option">
												<span className="dot-icon"></span>
												<span className="dot-icon"></span>
												<span className="dot-icon"></span>
											</div>
										</div>
									}
									color={"white"}
								>
									<WBDropdownOption title={"View"} link={blob} />
									<WBDropdownOption
										title={"Delete"}
										func={() => handleImageRemove(i)}
									/>
								</WBDropdown>
							</div>
						</div>
					);
					// console.log("PDF ID: ", i);
				}
			}
		}
		// console.log(allImagesFormatted);
		setImagesFormatted(allImagesFormatted);
		setPDFsFormatted(allPDFsFormatted);
		// console.log("My Files: ", filesToSend);
		// console.log("File Names: ", fileNames);
	}, [filesToSend, fileNames, selectedThumbnail]);

	window.addEventListener("keydown", function (event) {
		if (event.key === "Escape") closeModalMethod();
	}); // closes the modal and goes back to ServicesPage

	const increaseMargin = (slID) => {
		setManualMargins(prevMargins => {
			const updated = [...prevMargins];
			let foundIndex = updated.findIndex(x => x.service_area_id === slID);
			if (foundIndex !== -1) {
				updated[foundIndex].margin++;
			}
			else {
				updated.push({ service_area_id: slID, margin: 1 })
			}
			return updated;
		});
	};

	const decreaseMargin = (slID) => {
		setManualMargins(prevMargins => {
			const updated = [...prevMargins];
			let foundIndex = updated.findIndex(x => x.service_area_id === slID);
			if (foundIndex !== -1) {
				if (updated[foundIndex].margin !== 0)
					updated[foundIndex].margin--;
			}
			else {
				updated.push({ service_area_id: slID, margin: 0 })
			}
			return updated;
		});
		console.log("New selectedOrg: ", selectedOrg);
	}

	const GetIndustries = async () => {
		// console.log("Call all Industries");
		let response = await PostDB({
			branch: "/settings/industries",
			json: {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			},
		});
		if (response !== null) {
			if (response.length > 0) {
				formatIndustries(
					response.map((ind) => (
						<option key={ind.id} value={ind.id}>
							{ind.name}
						</option>
					))
				);
			} else {
				formatIndustries(<option value="0">No Industries found</option>);
			}
		} else {
			formatIndustries(
				<option value="0">Failed to retrieve Industries</option>
			);
		}
		//     if (finished) {
		//         console.log("Reset all dropdowns");
		//         document.getElementById("sel-industry").value = "0";
		//         document.getElementById("sel-category").value = "0";
		//         document.getElementById("sel-subcategory").value = "0";
		//     }
	};

	const GetCategories = async (ind) => {
		if (ind !== 0) {
			industryChange.current = true;
			setIndustry(parseInt(ind));
			// console.log("Call Categories for Industry: " + ind);
			let response = await CallDB({ branch: "/pricebook/cat/" + ind });
			if (response !== null && response.length > 0) {
				formatCategories(
					response.map((cat) => (
						<option key={cat.id} value={cat.id}>
							{cat.name}
						</option>
					))
				);
			} else if (response.length <= 0) {
				formatCategories(<option value="0">No Categories Found</option>);
			} else {
				formatCategories(
					<option value="0">Failed to retrieve Categories</option>
				);
			}
			// if (finished) {
			//     console.log("Reset categories and sub-categories");
			//     document.getElementById("sel-category").value = "0";
			//     document.getElementById("sel-subcategory").value = "0";
			// }
		}
	};

	// Gets the subcategories for the subcategory dropdown menu.
	const GetSubCategories = async (cat) => {
		if (parseInt(parseInt(cat)) !== 0) {
			setCategory(parseInt(cat));
			// console.log("Call Sub Categories for Category: " + cat);
			let response = await CallDB({ branch: "/pricebook/subcat/" + cat });
			if (response !== null && response.length > 0) {
				formatSubCategories(
					response.map((sub) => (
						<option
							key={sub.id}
							value={sub.id}
							className={sub.active ? "" : "disabled"}
						>
							{sub.title}
						</option>
					))
				);
			} else if (response.length <= 0) {
				formatSubCategories(<option value="0">No Sub Categories Found</option>);
			} else {
				formatSubCategories(
					<option value="0">Failed to retrieve SubCategories</option>
				);
			}
			// if (finished) {
			//     console.log("Reset sub-categories");
			//     document.getElementById("sel-subcategory").value = "0";
			// }
		}
	};

	// Gets the Spiffs.
	const GetSpiffs = async () => {
		const response = await PostDB({
			branch: "/spiffsJson",
			json: {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ org_id: 1 }),
				// body: JSON.stringify({ org_id: selectedOrg.current })
			},
		});
		// console.log("Spiffs: ", response.spiffs);
		if (response !== null) {
			if (response.spiffs.length > 0) {
				formatSpiffs(
					response.spiffs.map((spiff) => (
						<option
							key={spiff.id}
							value={spiff.id}
							className={spiff.active ? "" : "disabled"}
						>
							{spiff.title} -{" "}
							{parseInt(spiff.cost)
								.toFixed(2)
								.toString()
								.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						</option>
					))
				);
			} else {
				formatSpiffs(<option value="">No Spiffs Found</option>);
			}
		} else {
			formatSpiffs(<option value="">Failed to retrieve Spiffs</option>);
		}
	};

	// const toggleRow = (row) => {
	//     var el = document.getElementById(row);
	//     if (el.style.display === 'none')
	//         el.style.display = null;
	//     else
	//         el.style.display = 'none';
	// }

	const FormatBillableHours = async () => {
		// console.log("Billable updated ---------------------------------------------------------------");
		if (industry !== 0) {
			if (billableRates !== null && selectedOrg !== null && billableRates.length > 0) {
				var calcTradeTime = +hoursTrade + (+daysTrade * 8);
				var calcHelperTime = +hoursHelper + (+daysHelper * 8);
				let serviceLocationByOrg = selectedOrg.service_areas;
				if (serviceLocationByOrg.length > 0) {
					const sls = serviceLocationByOrg.map((sl, i) => {
						// console.log("landed index: " + index);
						let extra_data = billableRates.find(rate => rate.id === sl.bhTemplateID).extra_data;
						// For later reference:
						//      extra_data:             Has the hourly rates for calculation
						//      serviceLocationByOrg:   Has the service locations stored in it
						//      marginCalc:             Has the margin calculation for the final calculation
						//      billableRates:          All billableRates information from /getAllBH
						if (extra_data.length > 0) {
							let foundIndustry = extra_data.find(data => data.industry === industry);
							if (foundIndustry) {
								let laborCost = ((calcTradeTime + (+minutesTrade / 60)) * foundIndustry.standard) +
									((calcHelperTime + (+minutesHelper / 60)) * foundIndustry.helper);
								// let locationMargin = ((sl.margin + selectedOrg.org_margin) / 100 + 1);
								// 
								let manualMargin = manualMargins.find(x => x.service_area_id === sl.service_location_id);
								if (manualMargin === undefined) {
									// Handle the case where no matching element is found
									manualMargin = { service_area_id: sl.service_location_id, margin: 0 }; // or any default value you prefer
									manualMargins.push(manualMargin); // Optionally add the new object to the array
								}
								let locationMargin = ((sl.margin + (manualMargin.margin ? manualMargin.margin : 0)) / 100 + 1);
								let price = laborCost + materialCosts + utilitiesCosts;
								let finalCost = price * locationMargin;
								let gst = finalCost / 10;
								// console.log("(" + calcTradeTime + " * " + foundIndustry.standard + ") + (" + calcHelperTime + " * " + foundIndustry.helper + ") + " + materialCosts + " + " + utilitiesCosts + " = " + price + " / 10 = " + gst);
								// console.log("Therefore: " + price + " - " + gst + " = " + (price - gst) + " / 10 = " + (price - gst) / 10);
								return (
									<div key={sl.service_location_id} className="row">

										{/* Service Location Name */}
										<div className="cell pl-50">{sl.label}{/*<i className="pl-10">{"(+" + selectedOrg.org_margin + "%)"}</i>*/}</div>

										{/* Billable Hourly Rate */}
										<div className="cell" id="billboard_hrs_rate_1">
											<span style={{ display: "block" }}>{foundIndustry.standard.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph"}</span>
											{calcHelperTime !== 0 && <span style={{ display: "block", fontSize: "12px" }}>{foundIndustry.helper.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph (Helper)"}</span>}
										</div>

										{/* Billable Hours */}
										<div className="cell" name="billable_hrs">
											<span style={{ display: "block" }}>
												{hoursTrade !== 0 || daysTrade !== 0
													? calcTradeTime + "hr"
													: ""}
												{minutesTrade !== 0 ? minutesTrade + "m" : ""}
											</span>
											{calcHelperTime !== 0 && <span style={{ fontSize: "12px", display: "block", height: "10px" }}>
												{hoursHelper !== 0 || daysHelper !== 0 ? calcHelperTime + "hr" : ""}
												{minutesHelper !== 0 ? minutesHelper + "m" : ""}
												{hoursHelper !== 0 || daysHelper !== 0 || minutesHelper !== 0 ? "(Helper)" : ""}
											</span>}
										</div>

										{/* Labor Cost */}
										<div className="cell" id="labour_cost_1">
											${laborCost
												.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										</div>

										{/* Material Cost */}
										<div className="cell" id="material_cost_1">
											${materialCosts
												.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										</div>

										{/* Utility Cost */}
										<div className="cell" id="utility_cost_1">
											${utilitiesCosts
												.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										</div>

										{/* GST */}
										<div className="cell">
											${gst.toFixed(2)
												.toString()
												.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
										</div>

										{/* Selling Price */}
										<div className="cell" id="selling_price_1">
											<div className="flex flex-column">
												<p>${(finalCost + gst)
													.toFixed(2)
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
											</div>
										</div>

										{/* Margin Data */}
										<div className="cell pr-70" id="margin_percent_1">
											<div className="flex flex-row justify-between gap-10">
												<p className="w-50">+{manualMargin !== undefined ? manualMargin.margin : 0}%</p>
												<i className="icon-circle-minus" onClick={() => decreaseMargin(sl.service_location_id)}></i>
												<i className="icon-circle-plus" onClick={() => increaseMargin(sl.service_location_id)}></i>
											</div>
										</div>
									</div>
								);
							}
							else {
								return (
									<BlankServiceLocationRow title={sl.label + " has no Billable Hour template"} />
								);
							}
						}
						else {
							return (
								<BlankServiceLocationRow title={"No billable hours exist for " + sl.label} />
							);
						}
					});
					formatHourlyRates(sls);
				} else {
					formatHourlyRates(
						<BlankServiceLocationRow title={"No service locations found in " + selectedOrg.short_name} />
					);
				}

			} else {
				formatHourlyRates(
					<BlankServiceLocationRow title={"There was an error loading organisations. Please refresh"} />
				);
			}
		}
		else {
			formatHourlyRates(
				<BlankServiceLocationRow title={"Please select an industry to proceed."} />
			);
		}
	};

	const BlankServiceLocationRow = ({ title = "N/A" }) => {
		return (
			<div className="row">
				<div className="staticAuto cell pl-50 maxw-300">
					<i>
						{title}
					</i>
				</div>
				<div className="cell" name="billable_hrs">
					--
				</div>
				<div className="cell" id="billboard_hrs_rate_1">
					--
				</div>
				<div className="cell" id="material_cost_1">
					--
				</div>
				<div className="cell" id="labour_cost_1">
					--
				</div>
				<div className="cell" id="utility_cost_1">
					--
				</div>
				<div className="cell" id="selling_price_1">
					--
				</div>
				<div className="cell pr-70" id="margin_percent_1">
					--
				</div>
			</div>
		)
	}

	const SaveService = async () => {

		setIsDisabled(!isDisabled);
		setSubmitResult(
			<div id="search-spiner">
				<i className="icon-search-loader animate-rotate"></i>
				Saving. Please wait
			</div>
		);
		let allFiles = [];
		for (let i = 0; i < filesToSend.length; i++) {
			// 
			allFiles.push({
				id: filesToSend[i].id,
				file: filesToSend[i].file,
				file_name: filesToSend[i].name,
				activeThumbnail: i === selectedThumbnail ? true : false,
				action: filesToSend[i].action
			})
		}

		let bodyObj = JSON.stringify({
			service_id: editedID,
			inpTradeID: industry,
			inpSerTitle: title,
			inpSerDesc: description,
			inpCatID: category,
			inpSubCatID: subCategory,
			inpSerNotes: specialNotes,
			inpSpiffID: spiff,
			tagData: allTags.map((tag) => ({ tag_id: tag.id })),
			filtersData: allFilters.map((filter) => ({
				filter_id: filter.id,
				sub_filter_id: filter.subID,
			})),
			serviceHours: {
				trademan_days: daysTrade,
				trademan_hours: hoursTrade,
				trademan_mins: minutesTrade,
				helper_days: daysHelper,
				helper_hours: hoursHelper,
				helper_mins: minutesHelper,
			},
			utilData: allUtilities.map((util) => ({ util_id: util.id })),
			matData: allMaterials.map((mat) => ({ mat_id: mat.id })),
			marginData: manualMargins,
			servicefiles: allFiles,
		});
		console.log(bodyObj);
		if (AddServiceValidation(bodyObj)) {
			const response = await PostDB({
				branch: "/editService",
				json: {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: bodyObj,
				},
			});
			// console.log("This is what addService responded with: ", response);
			if (response === "success") {
				alert("Service has been edited successfully");
				closeModalMethod();
			} else {
				console.log("hit failed submitting to db");
				alert(
					"One of your fields is incorrect. Please check every field and try again"
				);
				// setErrorMessage("There was an error creating this service. Make sure all fields are filled correctly.")
			}
		} else {
			console.log("hit failed validation");
			alert(
				"One of your fields is incorrect. Please check every field and try again"
			);
			// setErrorMessage("Validation found issues");
		}
		setIsDisabled(!isDisabled);
	};

	const changeSelectedOrg = (value) => {
		let result = organisations.find((org) => org.short_name === value);
		setSelectedOrg(result);
	};

	// Used to test that all select lists were storing data correctly. Uncomment to test
	// const TestButton = () => {
	// 	console.log("Tags Selected: ", allTags);
	// 	console.log("Filters Selected: ", allFilters);
	// 	console.log("Materials Selected: ", allMaterials);
	// 	console.log("Utilities Selected: ", allUtilities);
	// 	alert("Printed to console");
	// };

	useEffect(() => {
		console.log("Edited ID: ", editedID);
		if (editedID === null || editedID === undefined) {
			alert("There was an error loading this service. Please reload this page.");
			closeModalMethod();
		} else {
			setFilesToSend([]);
			setFileNames([]);
			setManualMargins([]);
			GetServiceLocationData();
			GetService();
		}

		// setFinished(true);
		// console.log("Finished");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Run only once, on load. No function here relies on industry or other factors to run

	useEffect(() => {
		FormatBillableHours();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		materialCosts,
		utilitiesCosts,
		hoursTrade,
		hoursHelper,
		daysTrade,
		daysHelper,
		minutesHelper,
		minutesTrade,
		selectedOrg,
		manualMargins
	]); // update table when price, time or margin change

	// console.log("___ FILTERS SELECTED", myFiltersSelected);
	return (
		<div className="add-service-page">
			{/* {errormessage && <div className="alertpopup">{errormessage}</div>} */}
			<div
				className="border-bottom-grey p-10 bg-white"
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<div className="button-row left">
					<h4 className="title">
						<b>Edit Service</b>
					</h4>
				</div>
				<div className="button-row right gap-10">
					{submitResult}
					{/* <WBButton
						title="(Test Button) Return WBSelector selected to console"
						color="white"
						func={TestButton}
					/> */}
					<WBButton title="Cancel Edit" color="white" func={closeModalMethod} />
					<WBButton title="Save Edit" color="green" func={SaveService} />
				</div>
			</div>
			<WBTabs
				headers={organisations.map((org) => org.short_name)}
				func={(e) => changeSelectedOrg(e)}
			/>
			<div className="top-sticky flex-column bg-light-blue font-size-14">
				<div className="table" id="addServiceTable">
					<div
						className="table-container pb-0"
						style={{ maxHeight: "230px" }}
					>
						<div className="header bg-blue">
							<div className="staticAuto cell pl-50 maxw-300">Service Locations</div>
							<div className="cell">Billable Hourly Rate</div>
							<div className="cell">Billable Hours</div>
							<div className="cell">Labor Cost</div>
							<div className="cell">Material Cost</div>
							<div className="cell">Utility Cost</div>
							<div className="cell">Tax</div>
							<div className="cell">Price</div>
							<div className="cell pr-70">Margin</div>
						</div>
						<div className="body">{hourlyRatesFormatted}</div>
					</div>
					{/* <div className="bg-white p-10 flex space-between" style={{ overflow: "none", fontStyle: "italic", fontSize: "12px" }}>
						<div className="p-5">{selectedOrg !== null && <div>{'Organisation Margin for "' + selectedOrg.short_name + '": +' + selectedOrg.org_margin + "%"}</div>}</div>
						<div className="p-5">Price Calculation = (((Service Location Margin + Service Margin) / 100 + 1) x (Material Cost + Utility Cost + Labor Cost)) + Tax</div>
						<div className="p-5">{"* GST included in Selling Price"}</div>
					</div> */}
				</div>
			</div>
			<div className="modal_body">
				<div className="content">
					<div className="border-bottom bg-white gap-10">
						<div className="flex p-40">
							<div className="flex flex-column gap-10">
								<h3>
									Categories{" "}
									<span className="font-size-14">(Required)</span>
								</h3>
								<p className="font-blue">
									Choose which category product belongs to
								</p>
							</div>
							<div className="flex ml-100 p-20 gap-10">
								<div className="div_cat">
									<label className="cat_label" htmlFor="sel-industry">
										Industry
									</label>
									<select
										className="cat_select"
										name="sel-industry"
										value={industry}
										id="sel-industry"
										required
										onChange={(selected) => {
											GetCategories(selected.target.value);
										}}
									>
										<option value="0" disabled>
											-- Choose --
										</option>
										{industriesFormatted}
									</select>
								</div>
								<div className="div_cat">
									<label className="cat_label" htmlFor="sel-category">
										Category
									</label>
									<select
										className="cat_select"
										name="sel-category"
										value={category}
										id="sel-category"
										onChange={(selected) =>
											GetSubCategories(selected.target.value)
										}
									>
										<option value="0" disabled>
											-- Choose --
										</option>
										{categoriesFormatted}
									</select>
								</div>
								<div className="div_cat">
									<label className="cat_label" htmlFor="sel-subcategory">
										Sub Category
									</label>
									<select
										className="cat_select"
										name="sel-subcategory"
										value={subCategory}
										id="sel-subcategory"
										onChange={(selected) => {
											setSubCategory(parseInt(selected.target.value));
										}}
									>
										{" "}
										{/* Add on change here */}
										<option value="0" disabled>
											-- Choose --
										</option>
										{subCategoriesFormatted}
									</select>
								</div>
							</div>
						</div>

						{/* <div className="pb-20">
									<label className="cat_label" htmlFor="service_code">Code</label>
									<input type="text" className="form-control" name="service_code" id="service_code" placeholder="enter title of the service" required onChange={(code) => setCode(code.target.value)} />
								</div> */}

						<div id="pricebook" className={industry !== 0 ? "" : "disabled"}>
							<div className="flex-column gap-30 hidden">
								<div className="add-service-row border-top-grey p-25">
									{" "}
									{/* Row 1: Title, Description, Special Notes, Spiffs | Media */}
									<div className="add-service-column">
										<div className="pb-20">
											<label className="cat_label" htmlFor="service_title">
												Title
											</label>
											<input
												type="text"
												className="form-control"
												value={title}
												name="service_title"
												id="service_title"
												placeholder="enter title of the service"
												required
												onChange={(title) => setTitle(title.target.value)}
											/>
										</div>

										<div className="pb-20">
											<label className="cat_label" htmlFor="service_desc">
												Description
											</label>
											<textarea
												className="form-control"
												value={description}
												name="service_desc"
												id="service_desc"
												cols="40"
												rows="5"
												placeholder="enter description of the service"
												onChange={(desc) => setDescription(desc.target.value)}
											></textarea>
										</div>

										<div className="pb-20">
											<label className="cat_label" htmlFor="service_notes">
												Special Notes
											</label>
											<textarea
												className="form-control"
												value={specialNotes}
												name="service_notes"
												id="service_notes"
												cols="40"
												rows="2"
												placeholder="enter special notes for technicians"
												onChange={(specNotes) =>
													setSpecialNotes(specNotes.target.value)
												}
											></textarea>
										</div>

										<label className="sel-spiff" htmlFor="sel-spiff">
											Spiff
										</label>
										<select
											className=""
											name="sel-spiff"
											id="sel-spiff"
											value={spiff}
											required
											onChange={(selected) => {
												setSpiff(parseInt(selected.target.value));
											}}
										>
											<option value="0" disabled>
												-- Choose Spiff --
											</option>
											{spiffsFormatted}
										</select>
									</div>
									<div className="add-service-column">
										<div
											className="div_form_item border-radius-10 pb-20"
											style={{
												display: "flex",
												justifyContent: "space-between",
												alignItems: "center",
											}}
										>
											<div>
												<label className="cat_label" htmlFor="media_short_desc">
													Media
												</label>
												<p id="media_short_desc" className="font-blue pl-10">
													Be sure to name your files appropriately before
													uploading
												</p>
											</div>
											<div className="upload-media-col">
												{/* <label className="cat_label" htmlFor="service_codea">Choose File</label> */}
												<label
													htmlFor="upload_media"
													className="form-label add-service-upload_media ml-0"
													style={{ fontFamily: "RobotoRegular" }}
												>
													<i className="icon-media"></i> Upload
												</label>
												<input
													type="file"
													name="upload_media"
													id="upload_media"
													accept="image/jpeg,image/png,image/jpg,application/pdf"
													hidden
													onInput={handleImageInput}
												/>
											</div>
											{/* <div className="flex gap-10">
													
													<div className="col">
													<label className="cat_label" htmlFor="service_codea">File Type</label>
													<select className="form-select">
														<option>File</option>
														<option>How to Install</option>
														<option>Image/Picture</option>
													</select>
												</div> 
												
												IMPORTANT Removed type until deemed necessary
												 

												</div>
												<p id="filename"></p> */}
										</div>
										<div className="p-10">
											<label className="cat_label" htmlFor="service_codea">
												Files
											</label>
											<div className="custom-table p-10">
												<div className="custom-header">
													<div className="custom-cell">Name</div>
													<div className="custom-cell">Type</div>
													<div className="custom-cell">Actions</div>
												</div>
												{pdfsFormatted.length > 0 ? (
													<div className="body">{pdfsFormatted}</div>
												) : (
													<div className="body">
														<div className="custom-row">
															<div className="custom-cell">
																No Files Uploaded
															</div>
															<div className="custom-cell">--</div>
															<div className="custom-cell">--</div>
														</div>
													</div>
												)}
											</div>
										</div>
										<div className="p-10">
											<div className="div_form_item">
												<label className="cat_label" htmlFor="service_codea">
													Images
												</label>

												<div className="flex gap-10 p-10">
													<div className="col">
														{/* {images.length > 0 ? images : <p>No file selected</p>} */}
														{imagesFormatted.length > 0 ? (
															imagesFormatted
														) : (
															<p>No Images Selected</p>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="add-service-row border-top-grey p-25">
									{" "}
									{/* Row 2: Filters | Tags */}
									<div className="add-service-column">
										<WBSelector
											title="Filter"
											data="/filtersByIndustry"
											industry="1"
											identifiers={["filter_id", "filter_name"]}
											subIdentifiers={[
												"filter_id",
												"sub_filter_id",
												"sub_filter_name",
											]}
											preload={myFiltersSelected}
											returnFunc={setAllFilters}
										/>
									</div>
									<div className="add-service-column">
										<WBSelector
											title="Tag"
											data="/tagsJson"
											identifiers={["id", "title"]}
											preload={myTagsSelected}
											returnFunc={setAllTags}
										/>
									</div>
								</div>

								<div className="add-service-row border-top-grey p-25">
									{" "}
									{/* Row 3: Materials | Utilities */}
									<div className="add-service-column">
										<WBSelector
											title="Material"
											data="/materialsByKeyword"
											identifiers={["id", "title", "final_cost"]}
											preload={myMaterialsSelected}
											returnFunc={setAllMaterials}
											returnCostFunc={setMaterialCosts}
										/>
									</div>
									<div className="add-service-column">
										<WBSelector
											title="Utility"
											data="/utilitiesByKeyword"
											identifiers={["id", "title", "cost"]}
											preload={myUtilitiesSelected}
											returnFunc={setAllUtilities}
											returnCostFunc={setUtilitiesCosts}
										/>
									</div>
								</div>

								<div className="add-service-row border-top-grey p-25">
									{" "}
									{/* Row 4: Tradesman Hours | Helper Hours */}
									<div className="add-service-column">
										<div className="flex flex-column gap-10">
											<h4>Tradesman</h4>
											<div className="hourly gap-10 flex">
												<select
													className="form-select"
													name="sel-days"
													value={daysTrade}
													id="sel-days"
													aria-label="Default select example"
													onChange={(daysT) =>
														setDaysTrade(parseInt(daysT.target.value))
													}
												>
													<option value="0">0 Days</option>
													<option value="1">1 Days</option>
													<option value="2">2 Days</option>
													<option value="3">3 Days</option>
												</select>

												<select
													className="form-select"
													name="sel-hours"
													value={hoursTrade}
													id="sel-hours"
													aria-label="Default select example"
													onChange={(hoursT) =>
														setHoursTrade(parseInt(hoursT.target.value))
													}
												>
													<option value="0">0 Hour</option>
													<option value="1">1 Hour</option>
													<option value="2">2 Hour</option>
													<option value="3">3 Hour</option>
													<option value="4">4 Hour</option>
													<option value="5">5 Hour</option>
													<option value="6">6 Hour</option>
													<option value="7">7 Hour</option>
													<option value="8">8 Hour</option>
												</select>

												<select
													className="form-select"
													name="sel-mins"
													value={minutesTrade}
													id="sel-mins"
													aria-label="Default select example"
													onChange={(minutesT) =>
														setMinutesTrade(parseInt(minutesT.target.value))
													}
												>
													<option value="0">0 Min</option>
													<option value="15">15 Min</option>
													<option value="30">30 Min</option>
													<option value="45">45 Min</option>
												</select>
											</div>
											<p className="font-blue italic">
												Billable Hours (Time it takes to complete task)
											</p>
										</div>
									</div>
								</div>
								<div className="add-service-row p-25 pt-0">
									<div className="add-service-column">
										<div className="flex flex-column gap-10">
											<h4>Helper</h4>
											<div className="hourly flex gap-10">
												<select
													className="form-select"
													name="sel-days-helper"
													value={daysHelper}
													id="sel-days-helper"
													aria-label="Default select example"
													onChange={(daysH) =>
														setDaysHelper(parseInt(daysH.target.value))
													}
												>
													<option value="0">0 Days</option>
													<option value="1">1 Days</option>
													<option value="2">2 Days</option>
													<option value="3">3 Days</option>
												</select>

												<select
													className="form-select"
													name="sel-hours-helper"
													value={hoursHelper}
													id="sel-hours-helper"
													aria-label="Default select example"
													onChange={(hoursH) =>
														setHoursHelper(parseInt(hoursH.target.value))
													}
												>
													<option value="0">0 Hour</option>
													<option value="1">1 Hour</option>
													<option value="2">2 Hour</option>
													<option value="3">3 Hour</option>
													<option value="4">4 Hour</option>
													<option value="5">5 Hour</option>
													<option value="6">6 Hour</option>
													<option value="7">7 Hour</option>
													<option value="8">8 Hour</option>
												</select>

												<select
													className="form-select"
													name="sel-mins-helper"
													value={minutesHelper}
													id="sel-mins-helper"
													aria-label="Default select example"
													onChange={(minutesH) =>
														setMinutesHelper(parseInt(minutesH.target.value))
													}
												>
													<option value="0">0 Min</option>
													<option value="15">15 Min</option>
													<option value="30">30 Min</option>
													<option value="45">45 Min</option>
												</select>
											</div>
											<p className="font-blue italic">
												Billable Hours (Time it takes to complete task)
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
							<button className="btn btn-default" style={{ display: "inline-block", pointerEvents: isDisabled ? "none" : "" }} name="btn-saveService" id="btn-saveService" onClick={() => { SaveService(); window.location = "/settings/services" }}>Save Service</button>
							<button className="btn btn-default" style={{ display: "inline-block", pointerEvents: isDisabled ? "none" : "" }} name="btn-saveService&Continue" id="btnsaveService&Continue" onClick={() => SaveService()}>Save Service and Continue Editing</button>
							<button onClick={() => TestButton()}>Test</button>
							{submitResult}
						</div> */}
			</div>
		</div>
		// </div >
	);
};

export default EditService;
