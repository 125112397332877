import styles from './FilterSection.module.css';
import {WBButton} from '../../../../Components/UIComponents/WBComponents';
import {
  WBDropdown,
  WBDropdownOption,
} from '../../../../Components/Customer/JobCard/common/WBDropdown';
import {useState} from 'react';

const FilterSection = () => {
  const {iconDownArrow, headerControls, filterContainer, filterGroup} = styles;

  const [selectedView, setSelectedView] = useState('This Month');
  const [selectedTrade, setSelectedTrade] = useState('All Trades');
  const [selectedLocation, setSelectedLocation] = useState('Sydney');

  const timeFilters = ['Today', 'Yesterday', 'Last Week', 'This Month', 'Last Month'];
  const tradeOptions = ['All Trades', 'Plumbing', 'Electrical'];
  const locationOptions = ['Sydney', 'Melbourne', 'Brisbane'];

  return (
    <div className={headerControls}>
      <div className={filterContainer}>
        {timeFilters.map(filter => (
          <WBButton
            key={filter}
            title={filter}
            color={selectedView === filter ? 'blue' : 'grey'}
            height="fit-content"
            styles={{
              padding: '12px 24px',
              fontFamily: selectedView === filter ? 'robotoBold' : 'inherit',
            }}
            func={() => setSelectedView(filter)}
          />
        ))}
      </div>

      <div className={filterContainer}>
        {/* Trades Dropdown */}
        <div className={filterGroup}>
          <span>Trades</span>
          <WBDropdown
            leftitem={selectedTrade}
            leftItemPadding="0 10px 0 20px"
            rightitem={<i className={iconDownArrow} />}
            rightItemPadding="0 20px 0 0"
            width="100%"
            height="45px"
            borderRadius="10px"
            background="#FFFFFF"
            styles={{boxShadow: '0px 4px 4px 0px #00000012'}}
          >
            {tradeOptions.map(option => (
              <WBDropdownOption key={option} title={option} func={() => setSelectedTrade(option)} />
            ))}
          </WBDropdown>
        </div>

        {/* Locations Dropdown */}
        <div className={filterGroup}>
          <span>Locations</span>
          <WBDropdown
            leftitem={selectedLocation}
            leftItemPadding="0 10px 0 20px"
            rightitem={<i className={iconDownArrow} />}
            rightItemPadding="0 20px 0 0"
            width="100%"
            height="45px"
            borderRadius="10px"
            background="#FFFFFF"
            styles={{boxShadow: '0px 4px 4px 0px #00000012'}}
          >
            {locationOptions.map(option => (
              <WBDropdownOption
                key={option}
                title={option}
                func={() => setSelectedLocation(option)}
              />
            ))}
          </WBDropdown>
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
