import styles from './WorkChecklist.module.css';
import {WBButton} from '../../../../../../UIComponents/WBComponents';
import ChecklistNotes from './ChecklistNotes';
const WorkChecklist = ({isCompleted = false}) => {
  const {iconCheck, iconClose, container, header, title, body} = styles;

  return (
    <div className={container}>
      <div className={header}>
        <h2 className={title}>Ongoing Work Checklist</h2>
        <WBButton
          icon={isCompleted ? <i className={iconCheck}></i> : <i className={iconClose}></i>}
          title="Completion Declaration Submitted"
          color="grey"
          height="45px"
          styles={{
            padding: '8px 24px',
          }}
        />
      </div>
      <div className={body}>
        <ChecklistNotes />
      </div>
    </div>
  );
};

export default WorkChecklist;
