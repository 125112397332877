import { useEffect, useState } from "react"
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";
import { PostDB } from "../../DBTools";

const TagItems = ({ editFunc, tagSelected }) => {
    const DeleteTag = async ({ id }) => {
        const response = await PostDB({
            branch: "/deleteTag", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: id })
            }
        });
        if (response.api_status === "success") {
            alert("Tag has been successfully deleted");
        }
        else if (response.api_status === "error") {
            alert("There was an error deleting this Tag. Check your fields and try again");
        }
    };

    const [tagsFormatted, formatTags] = useState(<></>);

    const tags = useWBTableData();
    // console.log(tags);

    useEffect(() => {
        if (tags !== null && tags !== undefined) {
            if (tags.length > 0) {
                formatTags(
                    tags.map((tag) => (
                        <div key={tag.id} className="row" onDoubleClick={() => { tagSelected(tag.id); editFunc(); }}>
                            <div className="cell">
                                {tag.title}
                            </div>
                            <div className="cell">{tag.description}</div>
                            {/* <div className="cell">{tag.org_id ? tag.org_id : "No org"}</div> */}
                            <div className="cell">{tag.status === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { tagSelected(tag.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => DeleteTag(tag.id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatTags(<div>No items found</div>);
            }
        }
        else {
            formatTags(<div>No items found</div>);
        }
    }, [tags]);

    return (
        <div className="body" id="bodyData">{tagsFormatted}</div>
    );
}

export default TagItems;