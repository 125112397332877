import { useEffect, useRef, useState } from "react";
import ServiceItems from "../../../Components/Settings/Services/ServiceItems.js";
import AddService from "./Add/AddService.js";
import EditService from "./Edit/EditService.js";
import { CallDB, PostDB } from "../../../Components/DBTools.js";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents.js";

const ServicesPage = () => {
	const [industry, setIndustry] = useState();
	const [category, setCategory] = useState();
	const [subCategory, setSubCategory] = useState();

	const [industriesFormatted, formatIndustries] = useState("");
	const [categoriesFormatted, formatCategories] = useState("");
	const [subCategoriesFormatted, formatSubCategories] = useState("");

	const industryChange = useRef(false);
	const [tableData, setTableData] = useState();

	const [isModalAddOpen, setIsModal1Open] = useState(false);
	const [isModalEditOpen, setIsModal2Open] = useState(false);

	const toggleAddServiceModal = () => { setIsModal1Open(!isModalAddOpen); } // addService()
	const toggleEditServiceModal = (id) => { setIsModal2Open(!isModalEditOpen); if (id) toggleEditServiceModal(); } // addService()

	const [editedID, setEditedID] = useState();

	const headers = ["", "service", "billable hours", "labor cost ($)", "material cost ($)", "utility cost ($)", "price ($) inc gst", "action"];

	const GetIndustries = async () => {
		setIndustry(0);
		setCategory(0);
		setSubCategory(0);

		let response = await PostDB({
			branch: "/settings/industries", json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		});
		if (response !== null) {
			if (response.length > 0) {
				let formattedIndustries = [<option key={0} value={0}>All Industries</option>]
					.concat(response.map((ind) => (
						<option key={ind.id} value={ind.id}>{ind.name}</option>
					)));
				formatIndustries(formattedIndustries);
			} else {
				formatIndustries(
					[<option key={0} value={0}>No Industries found</option>]
				);
			}
		} else {
			formatIndustries(
				[<option key={0} value={0}>Failed to retrieve Industries</option>]
			);
		}
	};

	const GetCategories = async (ind) => {
		if (parseInt(ind) !== 0) {
			setCategory(0);
			setSubCategory(0);

			industryChange.current = true;
			setIndustry(parseInt(ind));
			let response = await CallDB({ branch: "/pricebook/cat/" + ind });
			if (response !== null && response.length > 0) {
				let formattedCategories = [<option key={0} value={0}>All Categories</option>]
					.concat(response.map((cat) => (
						<option key={cat.id} value={cat.id}>{cat.name}</option>
					)));
				formatCategories(formattedCategories);
				console.log("Formatted Categories: ", formattedCategories);
			} else if (response.length <= 0) {
				formatCategories(
					[<option key={0} value={0}>No Categories Found</option>]
				);
			} else {
				formatCategories(
					[<option key={0} value={0}>Failed to retrieve Categories</option>]
				);
			}
		} else {
			// Handle the case where ind is 0
			GetIndustries();
		}
	};

	// Gets the subcategories for the subcategory dropdown menu.
	const GetSubCategories = async (cat) => {
		if (parseInt(cat) !== 0) {
			setCategory(parseInt(cat));
			let response = await CallDB({ branch: "/pricebook/subcat/" + cat });
			if (response !== null && response.length > 0) {
				let formattedSubCategories = [<option key={0} value={0}>All Sub-Categories</option>]
					.concat(response.map((sub) => (
						<option key={sub.id} value={sub.id} className={sub.active ? "" : "disabled"}>{sub.title}</option>
					)));
				formatSubCategories(formattedSubCategories);
			} else if (response.length <= 0) {
				formatSubCategories(
					[<option key={0} value={0}>No Sub Categories Found</option>]
				);
			} else {
				formatSubCategories(
					[<option key={0} value={0}>Failed to retrieve SubCategories</option>]
				);
			}
		} else {
			// Handle the case where cat is 0
			GetCategories(industry);
		}
	};


	const clearFilters = () => {
		setIndustry(0);
		setCategory(0);
		setSubCategory(0);
	}

	useEffect(() => {
		clearFilters();
		GetIndustries();
	}, []);

	useEffect(() => {
		// console.log("Industry: " + (industry === 0 ? "All" : industry) + " | Category: " + (category === 0 ? "All" : category) + " | SubCategory: " + (subCategory === 0 ? "All" : subCategory));
		let jsonObj = {};
		if (industry !== 0) {
			jsonObj = { ...jsonObj, "trade_id": industry }
		}
		if (category !== 0) {
			jsonObj = { ...jsonObj, "category_id": category }
		}
		if (subCategory !== 0) {
			jsonObj = { ...jsonObj, "sub_category_id": subCategory }
		}
		setTableData(
			<WBTable tableData="/servicesJson" tableHeaders={headers} json={jsonObj} filters>
				<ServiceItems editFunc={toggleEditServiceModal} serviceSelected={setEditedID} />
			</WBTable >
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [industry, category, subCategory])

	return (
		<div className="content-area-full">
			{isModalAddOpen && <AddService closeModalMethod={toggleAddServiceModal} setEditID={setEditedID} switchModal={toggleEditServiceModal} preload={[industry, category, subCategory]} />}
			{isModalEditOpen && <EditService closeModalMethod={toggleEditServiceModal} editedID={editedID} />}
			<div className="flex flex-column bg-white p-20 gap-10">
				<div className="flex justify-between">
					<div className="text-base">
						<b>Services</b>
					</div>
					{/* <div className="border-grey"> */}
					<div className="flex content-center p-20 gap-10">
						<p className="cat_label" htmlFor="sel-category"><b>Filter Services:</b></p>
						<div className="div_cat">
							<select className="cat_select" name="sel-industry" id="sel-industry" value={industry} onChange={(event) => { GetCategories(event.target.value) }}>
								{industriesFormatted !== "" ? industriesFormatted : <option key={null} value={null}>All Industries</option>}
							</select>
						</div>
						<div className="div_cat">
							<select className={"cat_select " + (industry === 0 ? "disabled" : "")} name="sel-category" id="sel-category" value={category} onChange={(event) => GetSubCategories(event.target.value)}>
								{categoriesFormatted !== "" ? categoriesFormatted : <option key={null} value={null}>All Categories</option>}
							</select>
						</div>
						<div className="div_cat">
							<select className={"cat_select " + (category === 0 ? "disabled" : "")} name="sel-subcategory" id="sel-subcategory" value={subCategory} onChange={(event) => { setSubCategory(parseInt(event.target.value)) }} > {/* Add on change here */}
								{subCategoriesFormatted !== "" ? subCategoriesFormatted : <option key={null} value={null}>All Sub Categories</option>}
							</select>
						</div>
						{/* <WBButton title="Clear Filters" color="redandwhite" func={clearFilters} /> */}
					</div>
					{/* </div> */}
					<WBButton title="+ Add Service" color="blueandwhite" func={toggleAddServiceModal} />
				</div>
				{tableData}
			</div>
		</div>
	);
};

export default ServicesPage;