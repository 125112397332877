import styles from './QuotesTable.module.css';

const QuotesTable = ({data}) => {
  const {
    iconChevronRight,
    dataTable,
    primaryTable,
    primaryTitleContainer,
    primaryTitle,
    pathIndicator,
    quantity,
    amount,
    secondaryTable,
    secondaryRow,
    secondaryLabel,
    codeColumn,
    titleColumn,
    quantityColumn,
    descriptionColumn,
    space,
  } = styles;

  return (
    <div className={dataTable}>
      <table className={primaryTable}>
        <thead>
          <tr>
            <th className={space}></th>
            <th className={codeColumn}>Code</th>
            <th className={titleColumn}>Title</th>
            <th className={descriptionColumn}>Description</th>
            <th className={quantityColumn}>Qty</th>
            <th className={amount}>Amount</th>
            <th className={space}></th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item, index) => (
            <tr key={index}>
              <td></td>
              <td>{item.id !== 'CalloutFee' ? item.id : ''}</td>
              <td className={primaryTitleContainer}>
                <span className={primaryTitle}>{item.short_desc}</span>
                <div className={pathIndicator}>
                  {item.categories.reduce((acc, crumb, index) => {
                    if (index > 0) {
                      acc.push(<i key={`icon-${index}`} className={iconChevronRight}></i>);
                    }
                    acc.push(<span key={`crumb-${index}`}>{crumb}</span>);
                    return acc;
                  }, [])}
                </div>
              </td>
              <td>{item.long_desc}</td>
              <td className={quantity}>{item.quantity}</td>
              <td className={amount}>${item.price.toFixed(2)}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className={secondaryTable}>
        <tbody>
          {data.summary.map((item, index) => (
            <tr className={secondaryRow} key={index}>
              <td className={secondaryLabel}>
                {item.label.includes('(GST Included)') ? (
                  <>
                    {item.label.split('(')[0]}
                    <span className={styles.nonBoldText}>({item.label.split('(')[1]}</span>
                  </>
                ) : (
                  item.label
                )}
              </td>
              <td className={amount}>{item.value}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default QuotesTable;
