import { useState } from "react";
import { WBButton } from "../../../Components/UIComponents/WBComponents";
import { WBDropdown, WBDropdownOption } from "./common/WBDropdown";
import UnderlineTabs from "./common/UnderlineTabs";
import { useJobCard } from "../context/JobCardContext";
import styles from "./JobCardHeader.module.css";

const JobCardHeader = () => {
	const {
		iconDownArrow,
		jobCardHeader,
		"jobCardHeader-right": right,
		"jobCardHeader-left": left,
	} = styles;

	const { openCallbackModal } = useJobCard();
	const [selectedTab, setSelectedTab] = useState("Job Details");

	const downArrow = <i className={iconDownArrow}></i>;

	return (
		<div className={`${jobCardHeader}`}>
			<div className={`${left}`}>
				<UnderlineTabs
					text="Job Details"
					isSelected={selectedTab === "Job Details"}
					onClick={() => setSelectedTab("Job Details")}
				/>
				<UnderlineTabs
					text="Customer Profile"
					isSelected={selectedTab === "Customer Profile"}
					onClick={() => setSelectedTab("Customer Profile")}
				/>
			</div>
			<div className={right}>
				<WBButton
					title="Create Call Back"
					color="grey"
					height="40px"
					styles={{ padding: "0 24px" }}
					func={openCallbackModal}
				/>
				<WBButton
					title="Add Complaint"
					color="grey"
					height="40px"
					styles={{ padding: "15px 24px" }}
				/>
				<WBDropdown
					leftitem="Action"
					rightitem={downArrow}
					color="green"
					height="40px"
					width=""
					borderRadius="10px"
					leftItemPadding="0 24px"
					style={{ boxShadow: "0px 4px 4px 0px #0000000A", border: "none" }}
				>
					<WBDropdownOption title="Test 1" link="/" />
					<WBDropdownOption title="Test 2" link="/" />
				</WBDropdown>
			</div>
		</div>
	);
};

export default JobCardHeader;
