import { useEffect, useMemo, useState, useContext, useCallback } from "react";
import { useWBTableData, WBDropdown, WBDropdownOption } from "../../UIComponents/WBComponents";
import { ModalContext } from "../UserManagement/context/ModalContext";
import { useFieldUserForm } from "../UserManagement/FieldUserModal/useFieldUserForm";

const FieldUsers = () => {
	const { openModal } = useContext(ModalContext);
	const tableData = useWBTableData();
	const [fieldUsers, setFieldUsers] = useState([]);

	const { toggleActiveStatus, deleteFieldUser } = useFieldUserForm(null, () => {});

	const refreshUserList = useCallback(() => {
		if (tableData) {
			setFieldUsers(tableData);
		}
	}, [tableData]);

	useEffect(() => {
		refreshUserList();
	}, [refreshUserList]);

	const handleToggleActive = useCallback(
		async (userId, currentActiveStatus) => {
			try {
				const newActiveStatus = await toggleActiveStatus(userId, currentActiveStatus);
				setFieldUsers((prevUsers) =>
					prevUsers.map((user) =>
						user.id === userId ? { ...user, active: newActiveStatus } : user,
					),
				);
			} catch (error) {
				console.error("Failed to toggle user status:", error);
			}
		},
		[toggleActiveStatus],
	);

	const handleUserAction = useCallback(
		(action, user) => {
			switch (action) {
				case "edit":
					openModal("Edit Field User", user);
					break;
				case "toggle":
					handleToggleActive(user.id, user.active);
					break;
				case "delete":
					if (
						window.confirm(
							`Are you sure you want to delete ${user.first_name} ${user.last_name}?`,
						)
					) {
						deleteFieldUser(user.id)
							.then(() => {
								setFieldUsers((prevUsers) =>
									prevUsers.filter((u) => u.id !== user.id),
								);
								console.log("User deleted successfully");
							})
							.catch((error) => {
								console.error("Failed to delete user:", error);
							});
					}
					break;
				default:
					console.error("Unknown action:", action);
			}
		},
		[openModal, handleToggleActive, deleteFieldUser],
	);

	const RandomPic = () => {
		let allPics = [
			"/images/technicians/12/profile/user_12.png",
			"/images/technicians/13/profile/user_13.png",
		];
		return allPics[Math.floor(Math.random() * allPics.length)];
	};

	const fieldUsersFormatted = useMemo(() => {
		if (fieldUsers.length === 0) {
			return <div>No items found</div>;
		}

		return fieldUsers.map((user) => (
			<div key={user.id} className="row">
				<div className="static1 cell image_row">
					<img className="profile_image" src={RandomPic()} alt="Technician Profile" />
				</div>
				<div className="static2 cell">
					<span className={`status ${user.active === 1 ? "active" : "inactive"}`}></span>
					{user.first_name} {user.last_name}
				</div>
				<div className="cell">{user.tech_role}</div>
				<div className="cell">{user.email_work}</div>
				<div className="cell">{user.phone_mobile}</div>
				<div className="cell">{user.org_name}</div>
				<div className="cell">
					<span className={`bold ${user.active === 1 ? "text-green" : ""}`}>
						{user.active === 1 ? "Active" : "Inactive"}
					</span>
				</div>
				<div className="cell">
					<WBDropdown
						leftitem={
							<div className="title">
								<div className="option">
									<span className="dot-icon"></span>
									<span className="dot-icon"></span>
									<span className="dot-icon"></span>
								</div>
							</div>
						}
						align="end"
					>
						<WBDropdownOption
							title="Edit Account"
							func={() => handleUserAction("edit", user)}
						/>
						<WBDropdownOption
							title={user.active === 1 ? "Disable Account" : "Enable Account"}
							func={() => handleUserAction("toggle", user)}
						/>
						<WBDropdownOption
							title="Delete User"
							func={() => handleUserAction("delete", user)}
						/>
					</WBDropdown>
				</div>
			</div>
		));
	}, [fieldUsers, handleUserAction]);

	return (
		<div className="body" id="bodyData">
			{fieldUsersFormatted}
		</div>
	);
};

export default FieldUsers;
