import { createContext, useState } from "react";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalTitle, setModalTitle] = useState("");
	const [userData, setUserData] = useState(null);

	const openModal = (title, user = null) => {
		setIsModalVisible(true);
		setModalTitle(title);
		setUserData(user);
	};

	const closeModal = () => {
		setIsModalVisible(false);
		setModalTitle("");
		setUserData(null);
	};

	return (
		<ModalContext.Provider
			value={{ isModalVisible, openModal, closeModal, modalTitle, userData }}
		>
			{children}
		</ModalContext.Provider>
	);
};
