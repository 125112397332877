import {useState, useEffect} from 'react';
import styles from './ItemViewComponent.module.css';
import {useJobCard} from '../../context/JobCardContext';
import {WBButton} from '../../../UIComponents/WBComponents';
import image1 from '../assets/image1.svg';
import image2 from '../assets/image2.svg';

const ItemViewComponent = () => {
  const {
    iconPlus,
    itemViewModalOverlay,
    itemViewContainer,
    itemViewContent,
    itemViewHeader,
    closeButton,
    open,
    itemImagesContainer,
    itemImageLarge,
    itemImageThumbnails,
    itemImageSmall,
    itemTitle,
    itemSubtitle,
    itemDescription,
    specialNotesContainer,
    specialNotes,
    itemViewFooter,
  } = styles;

  const {isItemViewOpen, closeItemView, selectedItemId, modalData, updateItemQuantity} =
    useJobCard();
  const [currentLargeImage, setCurrentLargeImage] = useState(image1);

  const selectedItem = modalData.find(item => item.id === selectedItemId);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [isItemViewOpen]);

  const handleThumbnailClick = image => {
    setCurrentLargeImage(image);
  };

  const handleAddService = () => {
    if (selectedItem) {
      updateItemQuantity(selectedItemId, selectedItem.quantity + 1);
    }
    closeItemView();
  };

  return (
    <>
      {isItemViewOpen && <div className={itemViewModalOverlay} onClick={closeItemView} />}
      <div className={`${itemViewContainer} ${isItemViewOpen ? open : ''}`}>
        <div className={itemViewHeader}>
          <h2>Service Details</h2>
          <button onClick={closeItemView} className={closeButton}>
            <i className={iconPlus}></i>
          </button>
        </div>
        {selectedItem && (
          <>
            <div className={itemViewContent}>
              <div className={itemImagesContainer}>
                <div className={itemImageLarge}>
                  <img src={currentLargeImage} alt="Large view" />
                </div>
                <div className={itemImageThumbnails}>
                  <div className={itemImageSmall} onClick={() => handleThumbnailClick(image1)}>
                    <img src={image1} alt="Thumbnail 1" />
                  </div>
                  <div className={itemImageSmall} onClick={() => handleThumbnailClick(image2)}>
                    <img src={image2} alt="Thumbnail 2" />
                  </div>
                </div>
              </div>
              <h3 className={itemTitle}>{selectedItem.description}</h3>
              <h3 className={itemSubtitle}>
                ${selectedItem.price} {selectedItem.subtitle}
              </h3>
              <p className={itemDescription}>{selectedItem.long_desc}</p>
              <div className={specialNotesContainer}>
                <div className={specialNotes}>
                  <p>Special notes: This is a static text for now.</p>
                </div>
              </div>
            </div>
            <div className={itemViewFooter}>
              <WBButton
                title="Add Service"
                color="blue"
                height="40px"
                styles={{padding: '10px 24px'}}
                func={handleAddService}
              />
              <WBButton
                title="Close"
                color="gray"
                height="40px"
                styles={{padding: '10px 24px'}}
                func={closeItemView}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ItemViewComponent;
