// This calls the main API with the corresponding branch and returns the json linked to the URL.
// It returns a JSON array of the data on that API branch.

export const CallDB = async ({ branch, url }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result;
      if (url !== undefined) {
        result = await fetch(url)
          .then(response1 => response1.json())
      }
      else {
        result = await fetch(process.env.REACT_APP_NODEJSAPI + branch)
          .then(response1 => response1.json())
      }
      resolve(result);
    } catch (e) {
      console.error("Wurkbox Error: ", e);
      // reject(e);
    }
  });
};

export const PostDB = async ({ branch, json, url }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let result;
      if (url !== undefined) {
        result = await fetch(url, json)
          .then(response2 => response2.text())
          .then(text => isJsonString(text) ? JSON.parse(text) : text);
      }
      else {
        result = await fetch(process.env.REACT_APP_NODEJSAPI + branch, json)
          .then(response2 => response2.text())
          .then(text => isJsonString(text) ? JSON.parse(text) : text);
      }
      resolve(result);
    } catch (e) {
      console.error("Wurkbox Error: ", e);
      // reject(e);
    }
  });
};

// These are used for the api proxy. Setup by James and not necessary for this project, but may be used later by James

// export const CallDB = async ({ branch, url }) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let result;

//       if (url !== undefined) {
//         // If the URL is provided, use it directly for the GET request
//         result = await fetch(url).then(response1 => response1.json());
//       } else {
//         // If the URL is not provided, use the proxy API for the GET request
//         const proxyUrl = process.env.REACT_APP_NODEJSAPI_PROXY + '/proxy'; // Ensure this is set correctly in your environment variables
//         const proxyPayload = {
//           url: process.env.REACT_APP_NODEJSAPI + branch,
//           body: JSON.stringify({ method: 'GET', headers: { Accept: 'application/json' }, body: '' }),
//         };

//         result = await fetch(proxyUrl, {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(proxyPayload),
//         }).then(response2 => response2.json());
//       }

//       // Parse the result if it is a JSON string
//       if (typeof result === 'string' && isJsonString(result)) {
//         result = JSON.parse(result);
//       }

//       resolve(result);
//     } catch (e) {
//       console.error('Wurkbox Error: ', e);
//       reject(e); // Ensure the promise is rejected on error
//     }
//   });
// };

// export const PostDB = async ({ branch, json, url }) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let result;

//       if (url !== undefined) {
//         // If the URL is provided, use it directly
//         result = await fetch(url, json)
//           .then(response2 => response2.text())
//           .then(text => (isJsonString(text) ? JSON.parse(text) : text));
//       } else {
//         // If the URL is not provided, use the proxy API
//         const proxyUrl = process.env.REACT_APP_NODEJSAPI_PROXY + '/proxy'; // Ensure this is set correctly in your environment variables
//         const proxyPayload = {
//           url: process.env.REACT_APP_NODEJSAPI + branch,
//           body: JSON.stringify(json),
//         };

//         result = await fetch(proxyUrl, {
//           method: 'POST',
//           headers: {
//             Accept: 'application/json',
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(proxyPayload),
//         })
//           .then(response2 => response2.text())
//           .then(text => (isJsonString(text) ? JSON.parse(text) : text));
//       }

//       // Parse the result if it is a JSON string
//       if (typeof result === 'string' && isJsonString(result)) {
//         result = JSON.parse(result);
//       }

//       resolve(result);
//     } catch (e) {
//       console.error('Wurkbox Error: ', e);
//       reject(e); // Ensure the promise is rejected on error
//     }
//   });
// };

export const CallURL = async ({ url, json }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: json.method, // Use the method from the json object
        headers: json.headers, // Use the headers from the json object
        body: json.body, // Use the body from the json object
      });
      if (!response.ok) {
        alert('There was an error with the login system. Please try again later.');
      }
      resolve(response);
    } catch (e) {
      console.error('Wurkbox Error: ', e);
      //reject(e);
    }
  });
};

export const isValidURL = url => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isBase64 = string => {
  const base64Pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*$/;
  return base64Pattern.test(string);
};

export const checkUrlType = url => {
  if (isBase64(url)) {
    const mimeType = url.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/)[1];
    if (mimeType.startsWith('image/')) {
      return 'Base64 Image';
    } else if (mimeType === 'application/pdf') {
      return 'Base64 PDF';
    } else {
      return 'Base64 Other';
    }
  }

  if (!isValidURL(url)) {
    return 'Invalid URL';
  }

  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
  const pdfExtension = '.pdf';

  if (imageExtensions.some(ext => url.endsWith(ext))) {
    return 'Image URL';
  } else if (url.endsWith(pdfExtension)) {
    return 'PDF URL';
  } else {
    return 'Other URL';
  }
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
