import {useEffect} from 'react';
import styles from './MaterialOrderModal.module.css';
import {WBButton} from '../../../../UIComponents/WBComponents';
import {useMaterialsContext} from '../context/MaterialsContext';
import OrderStatusSteps from '../OrdersTable/OrderStatusSteps';

const MaterialOrderModal = () => {
  const {
    isMaterialOrderModalOpen,
    closeMaterialOrderModal,
    completedSteps
  } = useMaterialsContext();

  useEffect(() => {
    if (isMaterialOrderModalOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMaterialOrderModalOpen]);

  if (!isMaterialOrderModalOpen) return null;

  const {
    iconEdit,
    iconUpdate,
    modalOverlay,
    modalContainer,
    modalHeader,
    headerTitleContainer,
    headerTitle,
    headerSubtitle,
    closeButton,
    iconClose,
    orderStatusContainer,
    orderStatusLabel,
    modalBody,
    modalFooter,
    footerLeft,
    footerRight,
    statusDetailsContainer,
    statusColumn,
    statusLabel,
    statusValue,
    materialsTable,
    tableHeader,
    materialHeader,
    qtyHeader,
    priceHeader,
    materialRow,
    materialCell,
    qtyCell,
    priceCell,
    supplierName,
    totalRow,
    totalLabel,
    totalAmount,
    specialInstructions,
    materialsContainer,
  } = styles;

  return (
    <div className={modalOverlay}>
      <div className={modalContainer}>
        <div className={modalHeader}>
          <div className={headerTitleContainer}>
            <h2 className={headerTitle}>Material Order</h2>
            <h3 className={headerSubtitle}>224503</h3>
          </div>
          <button onClick={closeMaterialOrderModal} className={closeButton}>
            <i className={iconClose}></i>
          </button>
        </div>
        <div className={orderStatusContainer}>
          <span className={orderStatusLabel}>Order Status</span>
          <OrderStatusSteps completedSteps={completedSteps} />
        </div>

        <div className={statusDetailsContainer}>
          <div className={statusColumn}>
            <span className={statusLabel}>Needed Time</span>
            <span className={statusValue}>Needed Now</span>
          </div>
          <div className={statusColumn}>
            <span className={statusLabel}>Approval Status</span>
            <span className={statusValue}>Not Approved Yet</span>
          </div>
          <div className={statusColumn}>
            <span className={statusLabel}>Ready Status</span>
            <span className={statusValue}>Not Ready Yet</span>
          </div>
        </div>

        <div className={modalBody}>
          <div className={materialsTable}>
            <div className={tableHeader}>
              <div className={materialHeader}>Material</div>
              <div className={qtyHeader}>Qty</div>
              <div className={priceHeader}>Price ($)</div>
            </div>

            <div className={materialsContainer}>
              <div className={materialRow}>
                <div className={materialCell}>
                  <div className={supplierName}>Holman</div>
                  100mm Pvc
                </div>
                <div className={qtyCell}>1</div>
                <div className={priceCell}>47.50</div>
              </div>

              <div className={materialRow}>
                <div className={materialCell}>100mm Socket</div>
                <div className={qtyCell}>1</div>
                <div className={priceCell}>10.00</div>
              </div>

              <div className={materialRow}>
                <div className={materialCell}>100mm IO</div>
                <div className={qtyCell}>1</div>
                <div className={priceCell}>5.20</div>
              </div>

              <div className={materialRow}>
                <div className={materialCell}>100mm Slab Repair</div>
                <div className={qtyCell}>1</div>
                <div className={priceCell}>40.00</div>
              </div>

              <div className={materialRow}>
                <div className={materialCell}>100mm 90</div>
                <div className={qtyCell}>2</div>
                <div className={priceCell}>20.00</div>
              </div>
            </div>

            <div className={totalRow}>
              <div className={totalLabel}>Total</div>
              <div className={totalAmount}>122.70</div>
            </div>
          </div>

          <div className={specialInstructions}>No Special Instructions By Technician</div>
        </div>

        <div className={modalFooter}>
          <div className={footerLeft}>
            <WBButton
              title="Cancel"
              color="grey"
              styles={{padding: '14px 24px'}}
              func={closeMaterialOrderModal}
            />
          </div>
          <div className={footerRight}>
            <WBButton
              title="Edit"
              color="grey"
              icon={<i className={iconEdit}></i>}
              styles={{padding: '14px 24px'}}
              func={closeMaterialOrderModal}
            />
            <WBButton
              title="Update"
              color="grey"
              icon={<i className={iconUpdate}></i>}
              styles={{padding: '14px 24px'}}
              func={closeMaterialOrderModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaterialOrderModal;
