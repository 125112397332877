import { useState } from "react";
import Contact from "./Contact";
import ContactDetails from "./ContactDetails";
import styles from "../contactNotes.module.css";

const ContactList = () => {
  const [selectedContactIndex, setSelectedContactIndex] = useState(0);

  const contacts = [
    {
      name: "LJ Hooker Casula",
      role: "LJ Hooker Manager",
      imgSrc: "/images/client/chevron-right.svg",
      relationship: "Primary Customer",
      billingAddress: "123 John Street, Lidcombe, 2141 NSW Australia",
      mobile: "0412 345 678",
      phone: "0412 345 678",
      email: "ljhooker@hotmail.com",
      paymentTerms: "14 Days",
    },
    {
      name: "Steve Smith",
      role: "LJ Hooker Manager",
      imgSrc: "/images/client/chevron-right.svg",
      relationship: "Primary Customer",
      billingAddress: "567 Lemman Street, Lidcombe, 2141 NSW Australia",
      mobile: "7777 345 678",
      phone: "7777 345 678",
      email: "stevesmith@hotmail.com",
      paymentTerms: "5 Days",
    },
    {
      name: "Alex Smooth",
      role: "LJ Hooker Manager",
      imgSrc: "/images/client/chevron-right.svg",
      relationship: "Primary Customer",
      billingAddress: "333 Heshet Street, Lidcombe, 2141 NSW Australia",
      mobile: "1111 345 678",
      phone: "3333 345 678",
      email: "alexsmooth@hotmail.com",
      paymentTerms: "10 Days",
    },
  ];

  const handleContactClick = (index) => {
    setSelectedContactIndex(index);
  };

  return (
    <>
      <div className={styles.contactContainer}>
        <h2>Contacts</h2>
        <div className={styles.contactContainerDivision}>
          <div className={styles.contactSection}>
            {contacts.map((contact, index) => (
              <Contact
                key={index}
                contact={contact}
                onClick={() => handleContactClick(index)}
                isSelected={selectedContactIndex == index}
              />
            ))}
            <div>
              <img src="/images/client/plus.svg" alt="Plus Icon" />
              <a href="">Additional Contact</a>
            </div>
          </div>
          <ContactDetails contact={contacts[selectedContactIndex]} />
          <div className={styles.floatingButton}>
            <a href="">
              <img src="/images/client/edit.svg" alt="Edit Button" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactList;
