import React, { useState, useReducer, useEffect, useContext } from "react";
import styles from "./scheduler.module.css";

const JobList = ({ title, jobs }) => (
  <div className={styles.jobListContainer}>
    <p>{title}</p>
    <div className={styles.jobList}>
      {jobs.map((job, index) => (
        <div
          key={index}
          className={styles.jobItem}
          draggable="true"
          onDragStart={(e) => {
            e.dataTransfer.setData("application/json", JSON.stringify({
              ...job,
              type: "newJob"
            }));
          }}
        >
          <div className={styles.jobLine}></div>
          <p>
            {job.location} - {job.description}
          </p>
        </div>
      ))}
    </div>
  </div>
);

const SchedulerSidebar = () => {
  const unassignedJobs = [
    { location: "Homebush", description: "Leaking Tap / Toilet" },
    { location: "Other", description: "Plumbing" },
    { location: "Manly", description: "Leaking Tap / Toilet" },
    { location: "Hornsby", description: "Hot Water Service" },
  ];

  const ongoingJobs = [
    { location: "Homebush", description: "Leaking Tap / Toilet" },
    { location: "Other", description: "Plumbing" },
    { location: "Manly", description: "Leaking Tap / Toilet" },
    { location: "Hornsby", description: "Hot Water Service" },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sideBarItem}>
        <div className={styles.sidebarHeader}>
          <p>Unassigned Jobs</p>
          <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
        </div>
        <JobList title="Plumbing" jobs={unassignedJobs} />
      </div>
      <div className={styles.sideBarItem}>
        <div className={`${styles.sidebarHeader} ${styles.sidebarHeaderTop}`}>
          <p>Ongoing Jobs</p>
          <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
        </div>
        <JobList title="Plumbing" jobs={ongoingJobs} />
      </div>
    </div>
  );
};

export default SchedulerSidebar;
