import {useState} from 'react';
import styles from './scheduler.module.css';
import SchedulerButton from './components/SchedulerButton';
import SchedulerDropdown from './components/SchedulerDropdown';
const SchedulerMenubar = ({isGridLocked, setIsGridLocked}) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { 
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const navigateDay = (direction) => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + direction);
      return newDate;
    });
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const toggleGridLock = () => {
    setIsGridLocked(!isGridLocked);
  };

  return (
    <div className={styles.menuBar}>
      <div className={styles.menuBarLeft}>
        <SchedulerButton
          icon="/images/scheduler/arrow-left.svg"
          alt="arrow-left"
          onClick={() => navigateDay(-1)}
        />
        <SchedulerButton icon="/images/scheduler/calendar.svg" alt="calendar" />
        <SchedulerButton text="Today" onClick={goToToday} />
        <SchedulerButton
          icon="/images/scheduler/arrow-right.svg"
          alt="arrow-right"
          onClick={() => navigateDay(1)}
        />
        <p className={styles.menuBarDate}>{formatDate(currentDate)}</p>
      </div>
      <div className={styles.menuBarRight}>
        <SchedulerButton icon="/images/scheduler/search.svg" alt="search" />
        <SchedulerButton
          icon={isGridLocked ? "/images/scheduler/unlock.svg" : "/images/scheduler/unlock.svg"}
          alt={isGridLocked ? "unlock" : "lock"}
          text={isGridLocked ? "Unlock Grid" : "Lock Grid"}
          onClick={toggleGridLock}
        />
        <SchedulerDropdown variant="split" />
      </div>
    </div>
  );
};

export default SchedulerMenubar;
