import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const ServiceResponses = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { }; // deleteUser()

    const [serviceResponsesFormatted, formatServiceResponses] = useState(<></>);

    const serviceResponses = useWBTableData();

    useEffect(() => {
        // console.log("servicesResponses: ", serviceResponses);
        if (serviceResponses !== null && serviceResponses !== undefined) {
            if (serviceResponses.length > 0) {
                formatServiceResponses(
                    serviceResponses.map((serviceResponse) => (
                        <div key={serviceResponse.id} className="row" onDoubleClick={() => { setEditedID(serviceResponse.id); editFunc(); }}>
                            <div className="static1 cell">{serviceResponse.title}</div>
                            <div className="cell">
                                {serviceResponse.active === 1 ? <span className="text-green bold">Active</span> : <span className="bold">Inactive</span>}
                            </div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(serviceResponse.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(serviceResponse.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatServiceResponses(<div>No items found</div>);
            }
        }
        else {
            formatServiceResponses(<div>No items found</div>);
        }
    }, [serviceResponses])
    return (
        <div className="body" id="bodyData">{serviceResponsesFormatted}</div>
    )
}

export default ServiceResponses;