import styles from "../properties.module.css"
import PropertiesTable from "./PropertiesTable";
import PropertiesContactDetails from "./PropertiesContactDetails";
import PropertiesBar from "./PropertiesBar";

const jobHeaderItems = [
	{
		id: "jobs",
		label: "Jobs",
		iconSrc: "/images/client/small-briefcase.svg",
		hasNotification: true,
		notificationCount: 5,
	},
	{
		id: "pending-quotes",
		label: "Pending Quotes",
		hasNotification: false,
		notificationCount: 0,
	},
	{
		id: "complaints",
		label: "Complaints",
		hasNotification: true,
		notificationCount: 2,
	},
];

const jobs = [
	{
		id: 1,
		jobNumber: "#461366",
		bookingDate: "12-10-2024",
		description:
			"Complete overhaul of kitchen plumbing system, including replacement of old pipes, installation of new sink and dishwasher, and repair of leaky faucets. Additional work may be required to address water pressure issues and update drainage system to meet current building codes.",
		type: "Other - Plumbing",
		priority: "Callback",
		amount: "$500.00",
		paid: "$500.00",
		balance: "$0.00",
		status: "Canceled",
	},
	{
		id: 2,
		jobNumber: "#789012",
		bookingDate: "15-11-2024",
		description:
			"Comprehensive electrical wiring inspection of a three-story commercial building. This includes checking all circuits, outlets, and fixtures for safety and code compliance. Will also assess the need for upgrades to support increased power demands from modern office equipment and provide recommendations for energy-efficient lighting solutions.",
		type: "Electrical",
		priority: "Other - Plumbing",
		amount: "$750.00",
		paid: "$375.00",
		balance: "$375.00",
		status: "Ongoing",
	},
	{
		id: 3,
		jobNumber: "#234567",
		bookingDate: "03-12-2024",
		description:
			"Full HVAC system maintenance for a large residential complex. Tasks include cleaning air ducts, replacing filters, checking refrigerant levels, inspecting heat exchangers, and calibrating thermostats in all units. Will also perform an energy efficiency audit and suggest potential upgrades to improve overall system performance.",
		type: "HVAC",
		priority: "Hot Water Service",
		amount: "$600.00",
		paid: "$0.00",
		balance: "$600.00",
		status: "Completed",
	},
	{
		id: 4,
		jobNumber: "#890123",
		bookingDate: "22-01-2025",
		description:
			"Extensive roof repair project to address multiple leaks and structural damage. Work involves replacing damaged shingles, repairing underlayment, sealing around chimneys and vents, and reinforcing weakened rafters. Will also install improved drainage systems to prevent future water accumulation and potential damage.",
		type: "Roofing",
		priority: "Blocked Drain",
		amount: "$1200.00",
		paid: "$600.00",
		balance: "$600.00",
		status: "Ongoing",
	},
	{
		id: 5,
		jobNumber: "#345678",
		bookingDate: "08-02-2025",
		description:
			"Large-scale window replacement project for a historic building. This involves carefully removing old, single-pane windows and installing energy-efficient, double-pane replacements while maintaining the original architectural aesthetic. Special care will be taken to preserve and restore surrounding woodwork and ensure proper insulation.",
		type: "Carpentry",
		priority: "Blocked Drain",
		amount: "$2000.00",
		paid: "$1000.00",
		balance: "$1000.00",
		status: "Canceled",
	},
];

const PropertiesSection = () => {
	return (
	  <>
		<div className={styles.propertyComponent}>
		  <h2 className={styles.propertyHeaderTitle}>Properties</h2>
		  <PropertiesBar />
		  <div className={styles.propertySiteDetails}>
			<PropertiesContactDetails />
			<PropertiesTable jobHeaderItems={jobHeaderItems} jobs={jobs} />
		  </div>
		</div>
	  </>
	);
  };

export default PropertiesSection;