import styles from './JobStartedMilestone.module.css';

const JobStartedMilestone = ({technicianName, technicianLocation, dateTime: startDateTime}) => {
  const {container, dashDesign, mainBody, startInfo, jobStarted, dateTime} = styles;

  return (
    <div className={container}>
      <div className={dashDesign}></div>
      <div className={mainBody}>
        <div className={startInfo}>
          <span className={jobStarted}>Job Started</span> by {technicianName} ({technicianLocation})
        </div>
        <div className={dateTime}>{startDateTime}</div>
      </div>
    </div>
  );
};

export default JobStartedMilestone;
