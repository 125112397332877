import {useState, useCallback, useMemo, useEffect} from 'react';
import {useJobCard} from '../../context/JobCardContext';

const DEFAULT_TAX_RATE = 8.5; // 8.5%

export const usePriceCalculation = () => {
  const {materialsCost, selectedUtilities, selectedIndustry} = useJobCard();

  const [margin, setMargin] = useState(0);
  const [laborRates, setLaborRates] = useState({standard: 0, helper: 0});
  const [taxRate, setTaxRate] = useState(DEFAULT_TAX_RATE);

  const [billableHours, setBillableHours] = useState(0);
  const [helpers, setHelpers] = useState([]);

  useEffect(() => {
    if (selectedIndustry) {
      console.log('Selected industry changed:', selectedIndustry);
      fetchBillableRates(selectedIndustry.id);
    }
  }, [selectedIndustry]);

  const fetchBillableRates = async industryId => {
    console.log('Fetching billable rates for industry:', industryId);
    try {
      const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/getBillablerate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          service_area_id: 36, // Static for now
          industry_id: industryId,
          org_id: 1, // Static for now
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch billable rates');
      }

      const data = await response.json();
      console.log('API response:', data);
      const parsedData = parseBillableRates(data);

      setMargin(parsedData.margin);
      setLaborRates({
        standard: parsedData.hourly_rates.find(rate => rate.billable_type === 'Standard Hours')
          .billable_rate,
        helper: parsedData.hourly_rates.find(rate => rate.billable_type === 'Helper Hours')
          .billable_rate,
      });
      console.log('Updated margin and labor rates:', parsedData.margin, laborRates);
    } catch (error) {
      console.error('Error fetching billable rates:', error);
    }
  };

  const parseBillableRates = data => {
    try {
      const hourlyRates = JSON.parse(data.hourly_rates);
      const cleanedRates = hourlyRates.map(rate => ({
        billable_rate: parseFloat(rate['billable_rate:']),
        billable_type: rate['billable_type:'],
      }));

      return {
        margin: data.margin,
        hourly_rates: cleanedRates,
      };
    } catch (error) {
      console.error('Error parsing billable rates:', error);
      return null;
    }
  };

  const calculateUtilitiesCost = useCallback(() => {
    return selectedUtilities.reduce((total, utility) => total + parseFloat(utility.cost), 0);
  }, [selectedUtilities]);

  const calculateLaborCost = useCallback(() => {
    const mainWorkerCost = billableHours * laborRates.standard;
    const helpersCost = helpers.reduce(
      (total, helper) => total + helper.hours * laborRates.helper,
      0
    );
    const totalLaborCost = mainWorkerCost + helpersCost;
    return totalLaborCost;
  }, [billableHours, helpers, laborRates]);

  const calculateTotalCost = useCallback(() => {
    const laborCost = calculateLaborCost();
    const utilitiesCost = calculateUtilitiesCost();
    const basePrice = (margin / 100 + 1) * (materialsCost + utilitiesCost + laborCost);
    const tax = basePrice * (taxRate / 100);
    return basePrice + tax;
  }, [margin, materialsCost, calculateUtilitiesCost, calculateLaborCost, taxRate]);

  const totalPrice = useMemo(() => calculateTotalCost(), [calculateTotalCost]);

  const updateBillableHours = useCallback((days, hours, minutes) => {
    const totalHours =
      (parseInt(days) || 0) * 24 + (parseInt(hours) || 0) + (parseInt(minutes) || 0) / 60;
    console.log('Updating billable hours:', totalHours);
    setBillableHours(totalHours);
  }, []);

  const updateHelperHours = useCallback((index, days, hours, minutes) => {
    const totalHours =
      (parseInt(days) || 0) * 24 + (parseInt(hours) || 0) + (parseInt(minutes) || 0) / 60;
    console.log('Updating helper hours:', index, totalHours);
    setHelpers(prevHelpers => {
      const newHelpers = [...prevHelpers];
      newHelpers[index] = {...newHelpers[index], hours: totalHours};
      return newHelpers;
    });
  }, []);

  const addHelper = useCallback(() => {
    setHelpers(prevHelpers => [...prevHelpers, {hours: 0}]);
  }, []);

  const removeHelper = useCallback(index => {
    setHelpers(prevHelpers => prevHelpers.filter((_, i) => i !== index));
  }, []);

  return {
    margin,
    taxRate,
    setTaxRate,
    materialsCost,
    billableHours,
    helpers,
    updateBillableHours,
    updateHelperHours,
    addHelper,
    removeHelper,
    calculateLaborCost,
    calculateUtilitiesCost,
    totalPrice,
    calculateTotalCost,
  };
};
