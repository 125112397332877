import { useEffect, useState } from "react";
import { useWBTableData, WBDropdown, WBDropdownOption } from "../../UIComponents/WBComponents";

const Industries = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { }; // deleteUser()

    const [industriesFormatted, formatIndustries] = useState(<></>);

    const industries = useWBTableData();

    useEffect(() => {
        // console.log("Industries: ", industries);
        if (industries !== null && industries !== undefined) {
            if (industries.length > 0) {
                formatIndustries(
                    industries.map((industry) => (
                        <div key={industry.id} className="row" onDoubleClick={() => { setEditedID(industry.id); editFunc() }}>
                            <div className="static1 cell">{industry.name}</div>
                            <div className="cell w-100">
                                {industry.status === 1 ? <span className="text-green bold">Active</span> : <span className="bold">Inactive</span>}
                            </div>
                            <div className="cell w-100">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(industry.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(industry.id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatIndustries(<div>No items found</div>);
            }
        }
        else {
            formatIndustries(<div>No items found</div>);
        }
    }, [industries])
    return (
        <div className="body">{industriesFormatted}</div>
    )
}

export default Industries;