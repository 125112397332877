import { useContext } from "react";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import OfficeUsers from "../../../Components/Settings/Users/OfficeUsers";
import OfficeUserModal from "../../../Components/Settings/UserManagement/OfficeUserModal/OfficeUserModal";
import { ModalContext } from "../../../Components/Settings/UserManagement/context/ModalContext";
const OfficeUserPage = () => {
	const { isModalVisible, openModal } = useContext(ModalContext);
	//Temporary methods until js integration. Original version on right of method
	// const addUser = () => {
	//   window.wb_modal.title("Add Account");
	//   window.wb_modal.show();
	// }; // addUser()

	const addUser = () => {
		openModal("Add Office User");
	};

	return (
		<div className="content-area-full">
			<div className="flex flex-column bg-white p-20 gap-10">
				<div className="flex justify-between">
					<div>
						<b>Office Users</b>
					</div>
					<WBButton title="Add New User" func={addUser} color={"white"} />
				</div>
				<WBTable
					tableData="/settings/office_users"
					tableHeaders={[
						"user",
						"type",
						"work email",
						"mobile",
						"organisation",
						"status",
						"action",
					]}
				>
					<OfficeUsers />{" "}
					{/* Needs to be converted from GET to POST and include a keyword search */}
				</WBTable>
			</div>
			{isModalVisible && <OfficeUserModal />}
		</div>
	);
};

export default OfficeUserPage;
