import styles from "./InvoiceTable.module.css";

const InvoiceTable = ({ data }) => {
	const {
		iconChevronRight,

		invoiceTable,
		itemsTable,
		itemsTitleContainer,
		itemsTitle,
		breadcrumb,
		quantity,
		price,
		summaryTable,
		summaryRow,
		summaryLabel,
		receiptTable,
		receiptImage,
		codeColumn,
		titleColumn,
		quantityColumn,
		descriptionColumn,
		receiptImageColumn,
		dateColumn,
		technicianColumn,
		receiptDescriptionColumn,
		terminalIdColumn,
		paymentTypeColumn,
		space,
	} = styles;

	return (
		<div className={invoiceTable}>
			<table className={itemsTable}>
				<thead>
					<tr>
						<th className={space}></th>
						<th className={codeColumn}>Code</th>
						<th className={titleColumn}>Title</th>
						<th className={descriptionColumn}>Description</th>
						<th className={quantityColumn}>Qty</th>
						<th className={price}>Price</th>
						<th className={space}></th>
					</tr>
				</thead>
				<tbody>
					{data.items.map((item, index) => (
						<tr key={index}>
							<td></td>
							<td>{item.code}</td>
							<td className={itemsTitleContainer}>
								<span className={itemsTitle}>{item.title}</span>
								<div className={breadcrumb}>
									{item.breadcrumb.reduce((acc, crumb, index) => {
										if (index > 0) {
											acc.push(
												<i
													key={`icon-${index}`}
													className={iconChevronRight}
												></i>,
											);
										}
										acc.push(<span key={`crumb-${index}`}>{crumb}</span>);
										return acc;
									}, [])}
								</div>
							</td>
							<td>{item.description}</td>
							<td className={quantity}>{item.quantity}</td>
							<td className={price}>{item.price}</td>
							<td></td>
						</tr>
					))}
				</tbody>
			</table>

			<table className={summaryTable}>
				<tbody>
					{data.summary.map((item, index) => (
						<tr className={summaryRow} key={index}>
							<td className={summaryLabel}>{item.label}</td>
							<td className={price}>{item.value}</td>
							<td></td>
						</tr>
					))}
				</tbody>
			</table>

			<table className={receiptTable}>
				<thead>
					<tr>
						<th className={space}></th>
						<th className={receiptImageColumn}>Receipt Image</th>
						<th className={dateColumn}>Date</th>
						<th className={technicianColumn}>Technician</th>
						<th className={receiptDescriptionColumn}>Description</th>
						<th className={terminalIdColumn}>Terminal ID</th>
						<th className={paymentTypeColumn}>Payment Type</th>
						<th className={price}>Amount</th>
						<th className={space}></th>
					</tr>
				</thead>
				<tbody>
					{data.receipt.map((item, index) => (
						<tr key={index}>
							<td></td>
							<td>
								<img src={item.image} alt="Receipt" className={receiptImage} />
							</td>
							<td>{item.date}</td>
							<td>{item.technician}</td>
							<td>{item.description}</td>
							<td>{item.terminalId}</td>
							<td>{item.paymentType}</td>
							<td className={price}>{item.amount}</td>
							<td></td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default InvoiceTable;
