import styles from "./FinanceSummaryCards.module.css";

const FinanceSummaryCards = () => {
	const { iconCardTick, iconCoin, iconWallet, summaryCards, card, cardDetails } = styles;

	return (
		<div>
			<div className={summaryCards}>
				{/* total */}
				<div className={card}>
					<i className={iconCoin}></i>
					<div className={cardDetails}>
						<h3>Total</h3>
						<p>
							$1,800<span>.00</span>
						</p>
					</div>
				</div>
				{/* paid */}
				<div className={card}>
					<i className={iconWallet}></i>
					<div className={cardDetails}>
						<h3>Paid</h3>
						<p>
							$1,400<span>.00</span>
						</p>
					</div>
				</div>
				{/* balance */}
				<div className={card}>
					<i className={iconCardTick}></i>
					<div className={cardDetails}>
						<h3>Balance</h3>
						<p>
							$400<span>.00</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FinanceSummaryCards;
