import {useState} from 'react';
import {WBButton} from '../../../UIComponents/WBComponents';
import styles from './CustomServiceModal.module.css';
import BillableHoursSelector from './BillableHoursSelector';
import AdditionalWorkers from './AdditionalWorkers';
import MaterialsCostSlider from './MaterialsCostSlider';
import SearchUtilities from './SearchUtilities';
import {useJobCard} from '../../context/JobCardContext';
import {usePriceCalculation} from './usePriceCalculation';

const CustomServiceModal = () => {
  const {
    iconToggleOn,
    iconToggleOff,

    customServiceOverlay,
    customService,
    customServiceHeader,
    customServiceHeaderTitle,
    customServiceBody,
    customServiceContent,
    customServiceSummary,
    inputGroup,
    label,
    input,
    textarea,
    toggleGroup,
    toggleRow,
    customPriceInput,
    hiddenSection,
    additionalSection,
    additionalSectionHeader,
    additionalWorkersContainer,
    searchUtility,
    summaryCard,
    summaryTitle,
    summarySubtitle,
    summaryDescription,
    summaryTable,
    summaryTableRow,
    summaryTableColumn,
    summaryTableLabel,
    summaryTableValue,
    totalCost,
  } = styles;

  const [isCustomPriceEnabled, setIsCustomPriceEnabled] = useState(false);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [customPrice, setCustomPrice] = useState('');

  const {
    serviceLocationMargin,
    organizationMargin,
    taxRate,
    laborCostPerHour,
    materialsCost,
    billableHours,
    helpers,
    updateBillableHours,
    updateHelperHours,
    addHelper,
    removeHelper,
    calculateLaborCost,
    calculateUtilitiesCost,
    totalPrice,
  } = usePriceCalculation();

  const handleCustomPriceChange = e => {
    const value = e.target.value;
    // Allow only numbers and one decimal point, up to two decimal places
    if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
      setCustomPrice(value);
    }
  };

  const handleCustomPriceBlur = () => {
    if (customPrice && !customPrice.includes('.')) {
      setCustomPrice(`${customPrice}.00`);
    } else if (customPrice && /^\d+\.\d$/.test(customPrice)) {
      // If there is only one decimal place, append an extra 0
      setCustomPrice(`${customPrice}0`);
    }
  };

  const formatWithCommas = value => {
    if (!value) return value;
    const parts = value.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas to integer part
    return parts.join('.');
  };

  const handleBillableHoursChange = billableTime => {
    updateBillableHours(billableTime.days, billableTime.hours, billableTime.minutes);
  };

  const handleHelperChange = (index, helperTime) => {
    updateHelperHours(index, helperTime.days, helperTime.hours, helperTime.minutes);
  };

  const {openModal, closeModal, priceBookItems, addCustomItem, setMaterialsCost} = useJobCard();

  const handleBackButton = () => {
    closeModal();
    openModal('priceBook', priceBookItems);
  };

  const handleAddCustomService = () => {
    const newCustomService = {
      id: Date.now(),
      title,
      description,
      price: isCustomPriceEnabled ? parseFloat(customPrice) : calculateTotalCost(),
      quantity: 1,
    };

    addCustomItem(newCustomService);
    closeModal();
    openModal('priceBook', priceBookItems);
  };

  const calculateTotalCost = () => {
    return totalPrice;
  };

  return (
    <div className={customServiceOverlay}>
      <div className={customService}>
        <div className={customServiceHeader}>
          <WBButton
            title="Back"
            color="grey"
            width="72px"
            styles={{
              fontSize: '14px',
            }}
            func={handleBackButton}
          />
          <h1 className={customServiceHeaderTitle}>Custom Service</h1>
        </div>
        <div className={customServiceBody}>
          <div className={customServiceContent}>
            <div className={inputGroup}>
              <label className={label}>Title</label>
              <input
                type="text"
                className={input}
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Enter service title"
              />
            </div>
            <div className={inputGroup}>
              <label className={label}>Description</label>
              <textarea
                className={textarea}
                value={description}
                onChange={e => setDescription(e.target.value)}
                placeholder="Enter service description"
              ></textarea>
            </div>
            <div className={toggleGroup}>
              <div className={toggleRow}>
                <label className={label}>Custom Price</label>
                <i
                  className={isCustomPriceEnabled ? iconToggleOn : iconToggleOff}
                  onClick={() => setIsCustomPriceEnabled(!isCustomPriceEnabled)}
                />
              </div>
              {isCustomPriceEnabled && (
                <input
                  type="text"
                  className={`${input} ${customPriceInput}`}
                  placeholder="Enter price (e.g., 100.00)"
                  value={customPrice}
                  onChange={handleCustomPriceChange}
                  onBlur={handleCustomPriceBlur}
                />
              )}
            </div>
            {!isCustomPriceEnabled && (
              <div className={hiddenSection}>
                <BillableHoursSelector onTimeChange={handleBillableHoursChange} />
                <div className={additionalSection}>
                  <div className={additionalSectionHeader}>
                    <label className={label}>Additional Helpers</label>
                    <WBButton
                      title="Add Helper"
                      color="grey"
                      width="130px"
                      styles={{padding: '10px 24px'}}
                      func={addHelper}
                    />
                  </div>
                  <div className={additionalWorkersContainer}>
                    {helpers.map((helper, index) => (
                      <AdditionalWorkers
                        key={index}
                        onTimeChange={time => handleHelperChange(index, time)}
                        onRemove={() => removeHelper(index)}
                        index={index}
                        maxHours={billableHours}
                      />
                    ))}
                  </div>
                </div>
                <div className={additionalSection}>
                  <label className={label}>Materials / Parts Cost</label>
                  <MaterialsCostSlider />
                </div>
                <div className={`${additionalSection} ${searchUtility}`}>
                  <label className={label}>Search Utilities</label>

                  {/* Search utility component */}
                  <SearchUtilities />
                </div>
              </div>
            )}
          </div>
          <div className={customServiceSummary}>
            <div className={summaryCard}>
              <h2 className={summaryTitle}>Summary</h2>
              <h3 className={summarySubtitle}>{title || 'Custom Service'}</h3>
              <p className={summaryDescription}>{description || 'No description provided.'}</p>
              {!isCustomPriceEnabled && (
                <div className={summaryTable}>
                  <div className={summaryTableRow}>
                    <div className={summaryTableColumn}>
                      <span className={summaryTableLabel}>Billable Hours</span>
                      <span className={summaryTableValue}>{billableHours.toFixed(2)}</span>
                    </div>
                    <div className={summaryTableColumn}>
                      <span className={summaryTableLabel}>Material Cost</span>
                      <span className={summaryTableValue}>${materialsCost.toFixed(2)}</span>
                    </div>
                    <div className={summaryTableColumn}>
                      <span className={summaryTableLabel}>Utility Cost</span>
                      <span className={summaryTableValue}>
                        ${calculateUtilitiesCost().toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className={summaryTableRow}>
                    <div className={summaryTableColumn}>
                      <span className={summaryTableLabel}>Helpers</span>
                      <span className={summaryTableValue}>{helpers.length}</span>
                    </div>
                    <div className={summaryTableColumn}>
                      <span className={summaryTableLabel}>Billable Labor Cost</span>
                      <span className={summaryTableValue}>${calculateLaborCost().toFixed(2)}</span>
                    </div>
                    <div className={summaryTableColumn}></div>
                  </div>
                </div>
              )}
              <div className={summaryTableRow}>
                <div className={`${summaryTableColumn} ${totalCost}`}>
                  <span className={summaryTableLabel}>Total Cost</span>
                  <span className={summaryTableValue}>
                    $
                    {isCustomPriceEnabled
                      ? formatWithCommas(parseFloat(customPrice || '0').toFixed(2))
                      : formatWithCommas(totalPrice.toFixed(2))}
                  </span>
                </div>
              </div>

              <WBButton
                title="Add To Cart"
                color="blue"
                styles={{
                  fontFamily: 'robotoBold',
                  fontSize: '15px',
                  lineHeight: '17.58px',
                  textAlign: 'left',
                  padding: '12px 24px',
                }}
                func={handleAddCustomService}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomServiceModal;
