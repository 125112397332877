import React from "react";
import styles from "../scheduler.module.css";

const SchedulerButton = ({ icon = null, alt, text = null, onClick }) => {
    const buttonStyles = text !== null
        ? `${styles.schedulerButtonWide} ${styles.schedulerButtonText}`
        : styles.schedulerButton;

    return (
        <div className={buttonStyles} onClick={onClick}>
            {text == null && icon != null && <img src={icon} alt={alt} />}
            {text && icon == null && <span>{text}</span>}
            {text && icon && (
                <div className={styles.iconTextContainer}>
                    <img src={icon} alt={alt} />
                    <span>{text}</span>
                </div>
            )}
        </div>
    );
};

export default SchedulerButton;
