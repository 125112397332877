import {useState} from 'react';
import styles from './JobPerformance.module.css';

const JobPerformance = () => {
  const {
    jobPerformance,
    container,
    leftSection,
    rightSection,
    title,
    metricsGrid,
    metricItem,
    metricLabel,
    metricValue,
    circularMetrics: circularMetricsStyle,
    circularMetricContainer,
    progressContainer,
    progressRing,
    progressRingMaterial,
    progressRingLabour,
    progressRingProfit,
    progressInner,
    progressValue,
    progressPercentage,
    progressLabel,
  } = styles;

  const [performanceMetrics, setPerformanceMetrics] = useState([
    {label: 'Sold Time', value: '1 Hours'},
    {label: 'Working Time', value: '56 Minutes'},
    {label: 'Material Cost', value: '$2.00'},
    {label: 'Other Cost', value: '$100.00'},
  ]);

  const [materialCost, setMaterialCost] = useState(0.38);
  const [labourEfficiency, setLabourEfficiency] = useState(99.07);
  const [profitScore, setProfitScore] = useState(17.93);

  return (
    <div className={jobPerformance}>
      <div className={container}>
        <div className={leftSection}>
          <h2 className={title}>Job Performance</h2>
          <div className={metricsGrid}>
            {performanceMetrics.map((metric, index) => (
              <div key={index} className={metricItem}>
                <span className={metricLabel}>{metric.label}</span>
                <span className={metricValue}>{metric.value}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={rightSection}>
          <div className={circularMetricsStyle}>
            <div className={circularMetricContainer}>
              <div className={progressContainer}>
                <div
                  className={`${progressRing} ${progressRingMaterial}`}
                  style={{'--progress': `${materialCost * 3.6}deg`}}
                >
                  <div className={progressInner}>
                    <span className={progressValue}>
                      {materialCost}
                      <span className={progressPercentage}>%</span>
                    </span>
                    <span className={progressLabel}>
                      Material
                      <br />
                      Cost
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={circularMetricContainer}>
              <div className={progressContainer}>
                <div
                  className={`${progressRing} ${progressRingLabour}`}
                  style={{'--progress': `${labourEfficiency * 3.6}deg`}}
                >
                  <div className={progressInner}>
                    <span className={progressValue}>
                      {labourEfficiency}
                      <span className={progressPercentage}>%</span>
                    </span>
                    <span className={progressLabel}>
                      Labour
                      <br />
                      Efficiency
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={circularMetricContainer}>
              <div className={progressContainer}>
                <div
                  className={`${progressRing} ${progressRingProfit}`}
                  style={{'--progress': `${profitScore * 3.6}deg`}}
                >
                  <div className={progressInner}>
                    <span className={progressValue}>
                      {profitScore}
                      <span className={progressPercentage}>%</span>
                    </span>
                    <span className={progressLabel}>
                      Profit
                      <br />
                      Score
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPerformance;
