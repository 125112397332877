import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const FilterItems = ({ editFunc, filterSelected }) => {
    const deleteFilter = () => { };

    const [filtersFormatted, formatFilters] = useState(<></>);

    const filters = useWBTableData();

    useEffect(() => {
        if (filters !== null && filters !== undefined) {
            if (filters.filters.length > 0) {

                const countFilters = (allSubFilters) => {
                    let result = JSON.parse(allSubFilters);
                    if (result !== null) {
                        return result.length;
                    }
                    else {
                        return 0;
                    }
                }

                formatFilters(
                    filters.filters.map((filter) => (
                        <div key={filter.filter_id} className="row" id={filter.filter_id} onDoubleClick={() => { filterSelected(filter.filter_id); editFunc(); }}>
                            {/* <div>{filter.filter_id}</div> */}
                            <div className="cell" title={filter.name}>{filter.name}</div>
                            <div className="cell">{countFilters(filter.sub_filters) ? countFilters(filter.sub_filters) : 0}</div>
                            <div className="cell description" title={filter.description}>{filter.description}</div>
                            <div className="cell">{filter.industry}</div>
                            <div className="cell">{filter.status === 1 ? "Active" : "Not Active"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={
                                    <div className="title">
                                        <div className="option">
                                            <span className="dot-icon"></span>
                                            <span className="dot-icon"></span>
                                            <span className="dot-icon"></span>
                                        </div>
                                    </div>
                                } align="end">
                                    <WBDropdownOption title="Edit" func={() => { filterSelected(filter.sub_filter_id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteFilter(filter.sub_filter_id)} disabled />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatFilters(<div>No items found</div>);
            }
        }
        else {
            formatFilters(<div>No items found</div>);
        }
    }, [filters]);

    return (
        <div className="body" id="bodyData">{filtersFormatted}</div>
    )
}

export default FilterItems;