import NavigatePage from "../../Components/Settings/NavigatePage"

export const Pagination = (currentPageNum, totalPageNum, totalItemNum, setCurrentPageNum) => {
    var pageHeadInfo =
        <div className="pageCount">
            <div>
                <b>Page</b> {currentPageNum} of {totalPageNum}
            </div>
            <div>
                <b>Items</b> {(currentPageNum * 10 - 9) + " - " + (currentPageNum * 10 > totalItemNum ? totalItemNum : currentPageNum * 10)} of {totalItemNum}
            </div>
        </div>
    if (totalPageNum === 0 || currentPageNum === 0) { // Failsafe if bad data comes through. Not an issue if pages are not required
        return;
    }
    else if (totalPageNum === 1) { // if only one page exists
        return null;
    }
    else if (totalPageNum === 2) { // if only two pages exists
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} {...(currentPageNum === 1 ? { disabled: true } : {})} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum="1" {...(currentPageNum === 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(2)}><NavigatePage pageNum="2" {...(currentPageNum === 2 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} {...(currentPageNum === 2 ? { disabled: true } : {})} symbol=">" /></div>
                </div>
            </div>
        )
    }
    else if (totalPageNum === 3) { // if only three pages exists
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} {...(currentPageNum === 1 ? { disabled: true } : {})} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum="1" {...(currentPageNum === 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(2)}><NavigatePage pageNum="2" {...(currentPageNum === 2 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(3)}><NavigatePage pageNum="3" {...(currentPageNum === 3 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} {...(currentPageNum === 3 ? { disabled: true } : {})} symbol=">" /></div>
                </div>
            </div>
        )
    }
    else if (totalPageNum === 4) { // if only four page exists
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} {...(currentPageNum === 1 ? { disabled: true } : {})} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum="1" {...(currentPageNum === 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(2)}><NavigatePage pageNum="2" {...(currentPageNum === 2 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(3)}><NavigatePage pageNum="3" {...(currentPageNum === 3 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(4)}><NavigatePage pageNum="4" {...(currentPageNum === 4 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} {...(currentPageNum === 4 ? { disabled: true } : {})} symbol=">" /></div>
                </div>
            </div>
        )
    }
    if (currentPageNum === 1) { // If on page 1 or 2
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)} className={currentPageNum === 1 ? "disabled" : ""}><NavigatePage symbol="<" /></div>
                    <div className="invisible"><NavigatePage pageNum={1} /></div>
                    <div className="disabled"><NavigatePage pageNum={1} symbol={"..."} /></div>
                    <div className="invisible"><NavigatePage pageNum={1} /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum={1} {...(currentPageNum === 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(2)}><NavigatePage pageNum={2} {...(currentPageNum === 2 ? { active: true } : {})} /></div>
                    {/* <div onClick={() => setCurrentPageNum(3)}><NavigatePage pageNum={3} /></div> */}
                    <div onClick={() => setCurrentPageNum(currentPageNum + 3 < totalPageNum ? currentPageNum + 3 : totalPageNum)}><NavigatePage pageNum={currentPageNum + 3 < totalPageNum ? currentPageNum + 3 : totalPageNum} symbol="..." /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum)}><NavigatePage pageNum={totalPageNum} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} symbol=">" /></div>
                </div>
            </div>
        )
    }
    if (currentPageNum === 2) { // If on page 1 or 2
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)} className={currentPageNum === 1 ? "disabled" : ""}><NavigatePage symbol="<" /></div>
                    <div className="invisible"><NavigatePage pageNum={1} /></div>
                    <div className="disabled"><NavigatePage pageNum={1} symbol={"..."} /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum={1} {...(currentPageNum === 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(2)}><NavigatePage pageNum={2} {...(currentPageNum === 2 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(3)}><NavigatePage pageNum={3} {...(currentPageNum === 3 ? { active: true } : {})} /></div>
                    {/* <div onClick={() => setCurrentPageNum(3)}><NavigatePage pageNum={3} /></div> */}
                    <div onClick={() => setCurrentPageNum(currentPageNum + 3 < totalPageNum ? currentPageNum + 3 : totalPageNum)}><NavigatePage pageNum={currentPageNum + 3 < totalPageNum ? currentPageNum + 3 : totalPageNum} symbol="..." /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum)}><NavigatePage pageNum={totalPageNum} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} symbol=">" /></div>
                </div>
            </div>
        )
    }
    else if (currentPageNum === totalPageNum) { // If on last or second last page
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum={1} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum - 3 > 1 ? currentPageNum - 3 : 1)}><NavigatePage pageNum={currentPageNum - 3 > 1 ? currentPageNum - 3 : 1} symbol="..." /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum - 1)}><NavigatePage pageNum={totalPageNum - 1} {...(currentPageNum === totalPageNum - 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum)}><NavigatePage pageNum={totalPageNum} {...(currentPageNum === totalPageNum ? { active: true } : {})} /></div>
                    <div className="invisible"><NavigatePage pageNum={totalPageNum} /></div>
                    <div className="disabled"><NavigatePage pageNum={totalPageNum} symbol={"..."} /></div>
                    <div className="invisible"><NavigatePage pageNum={totalPageNum} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)} className={currentPageNum === totalPageNum ? "disabled" : ""}><NavigatePage symbol=">" /></div>
                </div>
            </div>
        )
    }
    else if (currentPageNum === totalPageNum - 1) { // If on last or second last page
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum={1} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum - 3 > 1 ? currentPageNum - 3 : 1)}><NavigatePage pageNum={currentPageNum - 3 > 1 ? currentPageNum - 3 : 1} symbol="..." /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum - 2)}><NavigatePage pageNum={totalPageNum - 2} /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum - 1)}><NavigatePage pageNum={totalPageNum - 1} {...(currentPageNum === totalPageNum - 1 ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum)}><NavigatePage pageNum={totalPageNum} {...(currentPageNum === totalPageNum ? { active: true } : {})} /></div>
                    <div className="disabled"><NavigatePage pageNum={totalPageNum} symbol={"..."} /></div>
                    <div className="invisible"><NavigatePage pageNum={totalPageNum} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)} className={currentPageNum === totalPageNum ? "disabled" : ""}><NavigatePage symbol=">" /></div>
                </div>
            </div>
        )
    }
    else if (currentPageNum <= 0) { // Break (should not be hit in production)
        console.error("An illegitimate number of pages came through and could not be displayed");
        return;
    }
    else {
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="pagination">
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum={1} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum - 3 > 1 ? currentPageNum - 3 : 1)}><NavigatePage pageNum={currentPageNum - 3 > 1 ? currentPageNum - 3 : 1} symbol="..." /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} /></div>
                    <div><NavigatePage pageNum={currentPageNum} active /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 3 < totalPageNum ? currentPageNum + 3 : totalPageNum)}><NavigatePage pageNum={currentPageNum + 3 < totalPageNum ? currentPageNum + 3 : totalPageNum} symbol="..." /></div>
                    <div onClick={() => setCurrentPageNum(totalPageNum)}><NavigatePage pageNum={totalPageNum} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} symbol=">" /></div>
                </div>
            </div>
        )
    }
}