import { WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import styles from "../addJobModals.module.css";
import { useState, useContext, useEffect } from "react";
import BookingButton from "./BookingButton";
import BookingDropdown from "./BookingDropdown";
import { useJobCustomerModals } from "../hooks/useJobCustomerModals";
import BookingInput from "./BookingInput";
import { JobBookingContext } from "../JobBookingContext";

const CustomerDetailsForm = ({
  handleEditCustomerDetails,
  isEdit,
  cancel,
  customerIndex,
  dispatch,
  formState,
  address,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [isAddressSame, setIsAddressSame] = useState(false);

  const { selectedCustomer, fetchedData } = useContext(JobBookingContext);
  const {
    customerTypesList = [],
    paymentTermsList = [],
    contactsRelationshipList = [],
  } = fetchedData || {};

  const toggleBusiness = () => {
    setIsBusiness((prev) => {
      if (!prev) {
        dispatch({ type: "UPDATE_FIELD", field: "business_name", value: "" });
      }
      return !prev;
    });
  };

  const toggleAddress = () => {
    setIsAddressSame((prev) => {
      if (!prev) {
        dispatch({ type: "UPDATE_FIELD", field: "address", value: address });
      }
      return !prev;
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const actionType =
      customerIndex === 0 ? "UPDATE_FIELD" : "UPDATE_CONTACT_FIELD";
    dispatch({
      type: actionType,
      field: id,
      value,
      ...(customerIndex !== 0 && { contactIndex: customerIndex - 1 }),
    });
  };

  const renderPrimaryCustomerFields = () => (
    <>
      <div className={styles.customerDetailsBusiness}>
        <div className={styles.textAndInputContainer}>
          <p className={`${styles.fontMedium} bold`}>
            Is the Customer a Business?
          </p>
          <div className="switch">
            <WBIOSToggle
              active={isBusiness ? true : false}
              func={toggleBusiness}
            />
          </div>
        </div>
        {isBusiness && (
          <BookingInput
            title="Business Name"
            placeholder="Enter Business Name"
            id="business_name"
            value={formState.business_name || ""}
            onChange={handleInputChange}
            width="639px"
          />
        )}
      </div>
      <div className={styles.customerDetailsBilling}>
        <p className={`${styles.fontMedium} bold`}>Billing Address</p>
        <div className={styles.textAndInputContainer}>
          <p>Same as job site address</p>
          <div className="switch">
            <WBIOSToggle
              active={isAddressSame ? true : false}
              func={toggleAddress}
            />
          </div>
        </div>
        <input
          className={styles.customerFullInput}
          type="text"
          id="address"
          placeholder="Enter Billing Address"
          value={isAddressSame ? address : ""}
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.customerContactDetails}>
        <BookingInput
          title="Email"
          placeholder="Enter Email Address"
          width="203px"
          id="email"
          value={
            customerIndex === 0
              ? formState.email || ""
              : formState.contacts[customerIndex - 1]?.email || ""
          }
          onChange={handleInputChange}
        />
        <BookingInput
          title="Phone"
          placeholder="Enter Phone Number"
          width="203px"
          id="phone"
          value={
            customerIndex === 0
              ? formState.phone || ""
              : formState.contacts[customerIndex - 1]?.phone || ""
          }
          onChange={handleInputChange}
        />
        <BookingInput
          title="Mobile"
          placeholder="Enter Mobile Number"
          width="203px"
          id="mobile"
          value={
            customerIndex === 0
              ? formState.mobile || ""
              : formState.contacts[customerIndex - 1]?.mobile || ""
          }
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.customerTypeTerms}>
        <div className={styles.customerType}>
          <p className={`${styles.fontMedium} bold`}>Type</p>
          <BookingDropdown
            dropdownItems={customerTypesList.map((item) => item.title)}
            leftitem={
              formState.customer_type_id === null
                ? "Choose Type"
                : customerTypesList.find(
                    (item) => item.id === formState.customer_type_id
                  )?.title || "Choose Type"
            }
            width="312px"
            height="48px"
            onChange={(value) => {
              const selectedItem = customerTypesList.find(
                (item) => item.title === value
              );
              if (selectedItem) {
                dispatch({
                  type: "UPDATE_FIELD",
                  field: "customer_type_id",
                  value: selectedItem.id,
                });
              }
            }}
          />
        </div>
        <div className={styles.customerType}>
          <p className={`${styles.fontMedium} bold`}>Payment Terms</p>
          <BookingDropdown
            dropdownItems={paymentTermsList.map((item) => item.title)}
            leftitem={
              formState.payment_term_id === null
                ? "Choose Terms"
                : paymentTermsList.find(
                    (item) => item.id === formState.payment_term_id
                  )?.title || "Choose Terms"
            }
            width="312px"
            height="48px"
            onChange={(value) => {
              const selectedItem = paymentTermsList.find(
                (item) => item.title === value
              );
              if (selectedItem) {
                dispatch({
                  type: "UPDATE_FIELD",
                  field: "payment_term_id",
                  value: selectedItem.id,
                });
              }
            }}
          />
        </div>
      </div>
      <div className={styles.marginAdjustment}>
        <BookingInput
          title="Work Order Details"
          placeholder="Enter Customer Details"
          width="639px"
          id="work_order_details"
          value={
            formState.work_order_details || ""
          }
          onChange={handleInputChange}
        />
      </div>
      <div className={styles.marginAdjustmentMore}>
        {/* <div className={styles.textAndInputContainer}> */}
          {/* <p className={`${styles.fontMedium} bold`}>Special Instruction</p> */}
          {/* <div className="switch">
            <WBIOSToggle
              active={isActive ? true : false}
              func={() => setIsActive(!isActive)}
            />
          </div> */}
        {/* </div> */}
        <BookingInput
          width="639px"
          title="Special Instruction"
          type="text"
          id="special_instruction"
          placeholder="Enter Special Instruction"
          value={formState.special_instruction || ""}
          onChange={handleInputChange}
        />
      </div>
      {isEdit && (
        <div className={styles.customerDetailsButtonContainer}>
          <BookingButton
            func={cancel}
            text="Cancel"
            style={{
              width: "90px",
              height: "44px",
              backgroundColor: "white",
              border: "1px solid rgba(231, 231, 231, 1)",
            }}
          />
          <BookingButton
            // func={}
            text="Update"
            textColor="white"
            bold={1}
            style={{
              width: "88px",
              height: "44px",
              backgroundColor: "rgba(0, 148, 255, 1)",
              border: "none",
              color: "blue",
            }}
          />
        </div>
      )}
    </>
  );

  const renderSecondaryCustomerFields = () => (
    <>
      <div className={styles.customerContactDetails}>
        <BookingInput
          title="Email"
          placeholder="Enter Email Address"
          width="203px"
          id="email"
          value={formState.contacts[customerIndex - 1]?.email || ""}
          onChange={handleInputChange}
        />
        <BookingInput
          title="Phone Number"
          placeholder="Enter Phone Number"
          width="203px"
          id="phone"
          value={formState.contacts[customerIndex - 1]?.phone || ""}
          onChange={handleInputChange}
        />
        <BookingInput
          title="Mobile"
          placeholder="Enter Mobile"
          width="203px"
          id="mobile"
          value={formState.contacts[customerIndex - 1]?.mobile || ""}
          onChange={handleInputChange}
        />
      </div>
      <div style={{ marginTop: "27px" }}>
        <p className={`${styles.fontMedium} bold`}>Relationship to Customer</p>
        <BookingDropdown
          dropdownItems={contactsRelationshipList.map((item) => item.title)}
          leftitem={
            formState.contacts[customerIndex - 1]?.relationship === ""
              ? "Choose Relationship"
              : contactsRelationshipList.find(
                  (item) =>
                    item.id ===
                    formState.contacts[customerIndex - 1]?.relationship
                )?.title || "Choose Relationship"
          }
          value={formState.contacts[customerIndex - 1]?.relationship || ""} // Added value prop
          width="303px"
          height="48px"
          onChange={(value) => {
            const selectedItem = contactsRelationshipList.find(
              (item) => item.title === value
            );
            if (selectedItem) {
              dispatch({
                type: "UPDATE_CONTACT_FIELD",
                contactIndex: customerIndex - 1,
                field: "relationship",
                value: selectedItem.title, // Ensure you're updating the ID
              });
            }
          }}
        />
      </div>
    </>
  );

  return (
    <div className={styles.customerDetailsForm}>
      {isEdit && (
        <>
          <div className={styles.headerContainer}>
            <p className={`${styles.fontMedium} bold`}>Customer Details</p>
            <div
              className={styles.editButton}
              onClick={handleEditCustomerDetails}
            >
              <img src="/images/client/edit.svg" alt="edit" />
            </div>
          </div>
          <div
            className={styles.summaryCustomersContainer}
            style={{ marginBottom: "36px" }}
          >
            <div
              className={`${styles.propertyContactCustomer} ${styles.selectedCustomer}`}
            >
              <p>
                {formState.firstName} {formState.lastName}
              </p>
            </div>
            {/* <div className={styles.propertyContactCustomer}>
              <p>Anna Smith</p>
            </div> */}
          </div>
        </>
      )}
      <div className={styles.customerDetailsNames}>
        <BookingInput
          title="First Name"
          placeholder="Enter first name"
          id="firstName"
          value={
            customerIndex === 0
              ? formState.firstName
              : formState.contacts[customerIndex - 1]?.firstName || ""
          } // Added optional chaining and default value
          onChange={handleInputChange}
        />
        <BookingInput
          title="Surname"
          placeholder="Enter surname"
          id="lastName"
          value={
            customerIndex === 0
              ? formState.lastName
              : formState.contacts[customerIndex - 1]?.lastName || ""
          } // Added optional chaining and default value
          onChange={handleInputChange}
        />
      </div>
      {customerIndex === 0
        ? renderPrimaryCustomerFields()
        : renderSecondaryCustomerFields()}
    </div>
  );
};

export default CustomerDetailsForm;
