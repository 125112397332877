import { useEffect, useState } from "react";
import { WBDropdown, WBDropdownOption, useWBTableData } from "../../UIComponents/WBComponents";

const MaterialMultipliers = ({ editFunc, setEditedID }) => {

    const deleteUser = () => { };

    const [materialMultipliers, formatMaterialMultipliers] = useState(<></>);

    const materialMult = useWBTableData();

    useEffect(() => {
        // console.log("MM: ", materialMult);
        if (materialMult !== null && materialMult !== undefined) {
            if (materialMult.multipliers.length > 0) {
                formatMaterialMultipliers(
                    materialMult.multipliers.map((multiplier) => (
                        <div key={multiplier.id} className="row" onDoubleClick={() => { setEditedID(multiplier.id); editFunc(); }}>
                            <div className="staticAuto cell">{multiplier.from}</div>
                            <div className="cell">{multiplier.to}</div>
                            <div className="cell">{multiplier.multiplier}</div>
                            <div className="cell">{multiplier.active === 1 ? "Active" : "Inactive"}</div>
                            <div className="cell">
                                <WBDropdown leftitem={<div className="title">
                                    <div className="option">
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                        <span className="dot-icon"></span>
                                    </div>
                                </div>} align="end">
                                    <WBDropdownOption title="Edit" func={() => { setEditedID(multiplier.id); editFunc(); }} />
                                    <WBDropdownOption title="Delete" func={() => deleteUser(multiplier.id)} />
                                </WBDropdown>
                            </div>
                        </div>
                    ))
                )
            }
            else {
                formatMaterialMultipliers(<div>No items found</div>);
            }
        }
        else {
            formatMaterialMultipliers(<div>No items found</div>);
        }
    }, [materialMult]);

    return (
        <div className="body" id="bodyData">{materialMultipliers}</div>
    )
}

export default MaterialMultipliers;