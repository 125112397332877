import {Doughnut} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import styles from './JobsByLocation.module.css';

ChartJS.register(ArcElement, Tooltip, Legend);

const JobsByLocation = () => {
  const {chartContainer, chartArea} = styles;

  const data = {
    labels: ['Sydney', 'Wollongong', 'Central Coast'],
    datasets: [
      {
        data: [1600, 1200, 1400],
        backgroundColor: ['#00497E', '#007BD3', '#0094FF'],
        borderWidth: 0,
        hoverOffset: 15,
        borderRadius: 4,
        spacing: 2,
        shadowOffsetX: 0,
        shadowOffsetY: 4,
        shadowBlur: 8.7,
        shadowColor: 'rgba(0, 0, 0, 0.3)',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    layout: {
      padding: 15,
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 9,
          boxHeight: 9,
          padding: 20,
          color: '#0E1E29',
          font: {
            family: 'robotoRegular',
            size: 14,
          },
        },
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: '#0E1E29',
        bodyColor: '#0E1E29',
        borderColor: 'rgb(200, 200, 200)',
        borderWidth: 1,
        padding: 12,
        titleFont: {
          family: 'robotoBold',
          size: 12,
        },
        bodyFont: {
          family: 'robotoRegular',
          size: 12,
        },
        displayColors: false,
        callbacks: {
          label: function (context) {
            return `${context.raw} Jobs`;
          },
        },
      },
    },
  };

  return (
    <div className={chartContainer}>
      <h3>Jobs By Location</h3>
      <div className={chartArea}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default JobsByLocation;
