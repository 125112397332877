import React from "react";
import styles from "../contactNotes.module.css";

const ViewNotesModal = ({ isOpen, closeModal, openViewAddModal , notes }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentViewNotes}>
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <h2>Notes</h2>
            <span className={styles.closeBtn} onClick={closeModal}>
              &times;
            </span>
          </div>
          <div className={styles.modalNotesContainer}>
            {notes.map((note, index) => (
              <div key={index} className={styles.modalNotes}>
                <h4>{note.date}</h4>
                <span>Posted by {note.author}</span>
                <br />
                <br />
                <p>{note.content}</p>
              </div>
            ))}
          </div>
          <div className={styles.viewNotesModalButton} onClick={openViewAddModal}>
            <img src="/images/client/plus.svg" alt="plus" />
            <p>Add Note</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotesModal;
