import { useState, useCallback, useEffect } from "react";

const useTimeIndicator = (styles) => {
  const [currentTimePosition, setCurrentTimePosition] = useState(0);

  const calculateTimePosition = useCallback(() => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const currentDecimalTime = hours + minutes / 60;

    const timeIndex = currentDecimalTime - 7;
    const firstBox = document.querySelector(`.${styles.calendarFirstBox}`);
    const calendarBox = document.querySelector(`.${styles.calendarBox}`);

    const firstBoxWidth = firstBox
      ? firstBox.getBoundingClientRect().width
      : 136;
    const calendarBoxWidth = calendarBox
      ? calendarBox.getBoundingClientRect().width
      : 100;

    return firstBoxWidth + calendarBoxWidth * timeIndex;
  }, [styles.calendarFirstBox, styles.calendarBox]);

  useEffect(() => {
    const updatePosition = () => {
      setCurrentTimePosition(calculateTimePosition());
    };

    updatePosition(); // Initial position

    window.addEventListener("resize", updatePosition);
    const interval = setInterval(updatePosition, 60000); // Update every minute

    return () => {
      clearInterval(interval);
      window.removeEventListener("resize", updatePosition);
    };
  }, [calculateTimePosition]);

  return currentTimePosition;
};

export default useTimeIndicator;
