import {useState} from 'react';
import styles from './LogDetails.module.css';

const LogDetails = ({type, rawLogs}) => {
  const [isOpen, setIsOpen] = useState(true);

  const {iconArrowDownGray, logContainer, viewDetailsButton, logBody, logDate, logDetails, open} =
    styles;

  const toggleDetails = () => {
    setIsOpen(!isOpen);
  };

  const getTitle = () => {
    switch (type) {
      case 'Complaints':
      case 'Quote':
      case 'Materials':
        return 'View Log Details';
      default:
        return 'View Invoice Log Details';
    }
  };
  return (
    <div className={logContainer}>
      <div>
        <button className={viewDetailsButton} onClick={toggleDetails}>
          {getTitle()}
          <i className={`${iconArrowDownGray} ${isOpen ? open : ''}`} />
        </button>
      </div>
      <div className={`${logBody} ${isOpen ? open : ''}`}>
        {rawLogs.map((log, index) => (
          <div key={index}>
            <span className={logDate}>{log.date} - </span>
            <span className={logDetails}>{log.details}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogDetails;
