import { Route, Routes, useLocation } from "react-router-dom";

import Dashboard from "../../Pages/Dashboard/DashboardPage";
import EnquiriesPage from "../../Pages/Enquiries/EnquiriresPage";
import ManageTechniciansPage from "../../Pages/Technicians/ManageTechniciansPage";
import SchedulePage from "../../Pages/Schedule/SchedulePage";
import ReportsJobsPage from "../../Pages/Reports/ReportsJobPage";
import PriceBookPage from "../../Pages/Price Book/PriceBookPage";
import AccountingPage from "../../Pages/Accounting/AccountingPage";
import UserOfficePage from "../../Pages/User/UserOfficePage";
import UserFieldPage from "../../Pages/User/UserFieldPage";
import ErrorPage from "../../Pages/ErrorPage";
import HelpPage from "../../Pages/Help/HelpPage"
import ClientPage from "../../Pages/ClientCard/ClientPage"
import { JobBookingProvider } from "../../Pages/ClientCard/Client/JobBookingContext";
import ComponentTest from "../../Pages/ComponentTest";
import Login from "../../Pages/Login";
import { JobCardProvider } from "../../Components/Customer/context/JobCardContext";
import JobCardPage from "../../Pages/Customer/JobCard/JobCardPage";
import RunReportsPage from "../../Pages/Reports/RunReportsPage";

import SettingsPage from "../../Pages/Settings/SettingsPage";
import OrgTemplatePage from "../../Pages/Settings/Organisation/OrgTemplatePage";
import OfficeUserPage from "../../Pages/Settings/UserManagement/OfficeUserPage";
import FieldUserPage from "../../Pages/Settings/UserManagement/FieldUserPage";
import IndustriesPage from "../../Pages/Settings/UserOperational/IndustriesPage";
import ServiceTypesPage from "../../Pages/Settings/UserOperational/ServiceTypesPage";
import JobTypesPage from "../../Pages/Settings/UserOperational/JobTypesPage";
import ServiceCallFeesPage from "../../Pages/Settings/UserOperational/ServiceCallFeesPage";
import ServiceResponsesPage from "../../Pages/Settings/UserOperational/ServiceResponsesPage";
import PaymentTermsPage from "../../Pages/Settings/CustomerType/PaymentTermsPage";
import CustomerTypePage from "../../Pages/Settings/CustomerType/CustomerTypePage";
import ServicesPage from "../../Pages/Settings/PriceBook/ServicesPage";
import UtilitiesPage from "../../Pages/Settings/PriceBook/UtilitiesPage";
import CategoriesPage from "../../Pages/Settings/UserOperational/CategoriesPage";
import FiltersPage from "../../Pages/Settings/PriceBook/FiltersPage";
import BillableHourlyRatesPage from "../../Pages/Settings/PricingAndRates/BillableHourlyRatesPage";
import DiscountLogicPage from "../../Pages/Settings/PricingAndRates/DiscountLogicPage";
import MembershipPage from "../../Pages/Settings/AdvancedSettings/MembershipsPage";
import MaterialsPage from "../../Pages/Settings/PriceBook/MaterialsPage";
import MaterialMultipliersPage from "../../Pages/Settings/AdvancedSettings/MaterialMultipliersPage";
import SpiffsPage from "../../Pages/Settings/PriceBook/SpiffsPage";
import TagsPage from "../../Pages/Settings/PriceBook/TagsPage";
import LeftNav from "../../Components/Settings/LeftNav";
import { ModalProvider } from "../../Components/Settings/UserManagement/context/ModalContext";
import SubFiltersPage from "../../Pages/Settings/PriceBook/SubFiltersPage";
import NavBar from "../../Components/Navbar/Navbar";
import { useEffect, useState } from "react";

const AllRoutes = ({ changeOrg }) => {
  const location = useLocation();
  const [refresh, Refresh] = useState(false);

  const toggleRefreshOrgs = () => { Refresh(!refresh); }

  const ValidateUser = () => {
    const validUser = localStorage.getItem("sessiontoken") !== null;
    if (validUser) {
      return true;
    }
    else if (window.location.pathname === "/login") {
      return false;
    }
    window.location = "/login";
    return false;
  }

  useEffect(() => {
    // console.log("Route Change > " + location.pathname);
    ValidateUser();
  }, [location]);

  return (
    <ModalProvider>
      <NavBar changeOrg={changeOrg} isSettings={false} />
      <div style={{ height: "70px" }}></div>
      <Routes>
        {/* Non-settings pages */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/scheduler" element={<SchedulePage />} />
        <Route path="/technicians" element={<ManageTechniciansPage />} />
        <Route path="/reports" element={<ReportsJobsPage />} />
        <Route path="/enquiries" element={<EnquiriesPage />} />
        <Route path="/pricebook" element={<PriceBookPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/customer/:customerId/:propertyId/:jobId" element={
          <JobCardProvider>
            <JobCardPage />
          </JobCardProvider>} />
        <Route path="/customer/:customerId/profile" element={
          <JobBookingProvider>
            <ClientPage />
          </JobBookingProvider>} />
        <Route path="/accounting" element={<AccountingPage />} />
        <Route path="/user" element={<UserOfficePage />} />
        <Route path="/userfield" element={<UserFieldPage />} />
        <Route path="*" element={<ErrorPage />} />

        {/* Settings pages */}
        <Route
          path="/settings/*"
          element={
            <>
              <NavBar isSettings={true} />
              <div id="settings">
                <LeftNav refreshOrgs={toggleRefreshOrgs} />
                <ModalProvider>
                  <Routes>
                    <Route path="" element={<SettingsPage />} />
                    <Route path="organisation/edit/:id" element={<OrgTemplatePage refreshOrgs={toggleRefreshOrgs} />} />
                    <Route path="user_management/office_users" element={<OfficeUserPage />} />
                    <Route path="user_management/field_users" element={<FieldUserPage />} />
                    <Route path="industries" element={<IndustriesPage />} />
                    <Route path="service_types" element={<ServiceTypesPage />} />
                    <Route path="job_types" element={<JobTypesPage />} />
                    <Route path="service_callout_fees" element={<ServiceCallFeesPage />} />
                    <Route path="service_response" element={<ServiceResponsesPage />} />
                    <Route path="payment_terms" element={<PaymentTermsPage />} />
                    <Route path="customer_types" element={<CustomerTypePage />} />
                    <Route path="services" element={<ServicesPage />} />
                    <Route path="utilities" element={<UtilitiesPage />} />
                    <Route path="categories" element={<CategoriesPage />} />
                    <Route path="filters" element={<FiltersPage />} />
                    <Route path="billhours" element={<BillableHourlyRatesPage />} />
                    <Route path="discounts" element={<DiscountLogicPage />} />
                    <Route path="membership" element={<MembershipPage />} />
                    <Route path="materials" element={<MaterialsPage />} />
                    <Route path="material_multipliers" element={<MaterialMultipliersPage />} />
                    <Route path="spiffs" element={<SpiffsPage />} />
                    <Route path="tags" element={<TagsPage />} />
                    <Route path="subfilters" element={<SubFiltersPage />} />
                    <Route path="/test" element={<ComponentTest />} />
                  </Routes>
                </ModalProvider>
              </div>
            </>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ModalProvider>
  );
};

export default AllRoutes;

