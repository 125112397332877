import styles from './TaskDetails.module.css';

const TaskDetails = ({isCompleted = false}) => {
  const {container, header, title, body, detailRow, detailLabel, detailContent} = styles;

  const taskData = {
    workDone: 'Jett from vent and cleared blockage. Sold spot dig and replace junction',
    workToBeDone:
      'Cut concrete and expose drainage, cut out junction and replace leaving io at ground level. Backfill. Remove outhouse toilet and jett the line camera also.',
    estimatedTime: 'Thursday 3-10-24, 8-12',
    materialRequired:
      'Po supplied get as much as possible from the office Take demo saw and jackhammer from office too',
  };

  const renderContent = (key, value) => {
    if (isCompleted && key !== 'workDone') {
      return '-';
    }
    return value;
  };

  return (
    <div className={container}>
      <div className={header}>
        <h2 className={title}>Task Details</h2>
      </div>
      <div className={body}>
        {Object.entries(taskData).map(([key, value]) => (
          <div key={key} className={detailRow}>
            <span className={detailLabel}>
              {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
            </span>
            <span className={detailContent}>{renderContent(key, value)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskDetails;
