import {useState, useEffect} from 'react';
import styles from './ComplaintsContent.module.css';
import {WBButton} from '../../../UIComponents/WBComponents';
import ComplaintsModal from './ComplaintsModal';

const ComplaintsTab = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [complaintData, setComplaintData] = useState({
    number: '3221542631',
    status: 'Waiting Client Response',
    date: '2023-05-15',
    loggedBy: 'Bre',
    description:
      'Hi I just had one of your "technician" attend 93 Riverview Rd Earlwood. All though Joe was courteous and nice. He was not what I specifically ask for. I specified that I wanted a Solar Hot Water specialist. You (your company) said that they were, check previous chat dialogue. He then proceeded to charge me$50 for attending. He did not have the skill you promised. Therefore I would like my money back. Thanks',
  });

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const {
    complaintsTab,
    complaintsHeader,
    headerLeft,
    headerRight,
    complaintTitle,
    complaintNumber,
    complaintsStatus,
    statusText,
    statusDate,
    statusDateTitle,
    statusDateValue,
    statusLog,
    statusLogTitle,
    statusLogValue,
    complaintsBody,
    descriptionTitle,
    descriptionText,
  } = styles;

  return (
    <>
      <div className={complaintsTab}>
        <div className={complaintsHeader}>
          <div className={headerLeft}>
            <span className={complaintTitle}>Complaint</span>
            <span className={complaintNumber}># {complaintData.number}</span>
          </div>
          <div className={headerRight}>
            <WBButton
              title="Open Complaint"
              color="grey"
              styles={{padding: '0 24px'}}
              func={() => setIsModalOpen(true)}
            />
          </div>
        </div>
        <div className={complaintsStatus}>
          <div className={statusText}>{complaintData.status}</div>
          <div className={statusDate}>
            <span className={statusDateTitle}>Date</span>
            <span className={statusDateValue}>{complaintData.date}</span>
          </div>
          <div className={statusLog}>
            <span className={statusLogTitle}>Logged by</span>
            <span className={statusLogValue}>{complaintData.loggedBy}</span>
          </div>
        </div>
        <div className={complaintsBody}>
          <div className={descriptionTitle}>Description</div>
          <p className={descriptionText}>{complaintData.description}</p>
        </div>
      </div>
      <ComplaintsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default ComplaintsTab;
