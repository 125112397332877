// Sourced from https://stackoverflow.com/questions/7853130/how-to-change-the-style-of-alert-box
// I plan to work on this later, but it will come in handy to manage custom errors.
// The code is 10 years old and needs updating, but in its current state works fine.
export const CustomAlerts = () => {
    var ALERT_BUTTON_TEXT = "X";
    var ALERT_TIMEOUT = 500; // Time in milliseconds (e.g., 500ms = 5 seconds)

    if (document.getElementById) {
        window.alert = function (txt, customTime = null) {
            createCustomAlert(txt, customTime);
        }
    }

    function createCustomAlert(txt) {
        let d = document;

        // Check if an alert is already present and remove it
        if (d.getElementById("modalContainer")) {
            removeCustomAlert();
        }

        let mObj = d.getElementsByTagName("body")[0].appendChild(d.createElement("div"));
        mObj.id = "modalContainer";

        let alertObj = mObj.appendChild(d.createElement("div"));
        alertObj.id = "alertBox";
        alertObj.style.left = (d.documentElement.scrollWidth - alertObj.offsetWidth) / 2 + "px";
        alertObj.style.visibility = "visible";

        let msg = alertObj.appendChild(d.createElement("p"));
        msg.innerHTML = txt;

        let btn = alertObj.appendChild(d.createElement("a"));
        btn.id = "closeBtn";
        btn.appendChild(d.createTextNode(ALERT_BUTTON_TEXT));
        btn.href = "#";
        btn.focus();
        btn.onclick = function () { removeCustomAlert(); return false; }

        // Automatically remove the alert after the specified timeout
        setTimeout(() => {
            alertObj.addEventListener('animationend', removeCustomAlert);
        }, ALERT_TIMEOUT);
    }

    function removeCustomAlert() {
        let modalContainer = document.getElementById("modalContainer");
        if (modalContainer) {
            document.getElementsByTagName("body")[0].removeChild(modalContainer);
        }
    }
}

