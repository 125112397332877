import { useEffect, useState } from "react";
import { useWBTableData, WBDropdown, WBDropdownOption } from "../../UIComponents/WBComponents";

const ServiceTypes = ({ editFunc, serviceSelected }) => {
  const [serviceTypesFormatted, formatServiceTypes] = useState(<></>);

  const editUser = ({ user_id }) => {
    window.wb_modal.load({
      title: "Edit Account",
      getData: {
        url: `/settings/user_management/office_users/edit/${user_id}`,
      },
      open: true,
    });
  };
  const deleteUser = () => {
    console.log("Delete User");
  }; // deleteUser()

  const serviceTypes = useWBTableData();

  useEffect(() => {
    if (serviceTypes !== null && serviceTypes !== undefined) {
      if (serviceTypes.length > 0) {
        formatServiceTypes(
          serviceTypes.map((serviceType) => (
            <div key={serviceType.id} className="row" onDoubleClick={() => { serviceSelected(serviceType.id); editFunc(); }}>
              <div className="static1 cell">{serviceType.title}</div>
              <div className="cell w-100">
                {serviceType.active === 1 ? (
                  <span className="text-green bold">Active</span>
                ) : (
                  <span className="bold">Inactive</span>
                )}{" "}
              </div>
              <div className="cell w-100">
                <WBDropdown leftitem={<div className="title">
                  <div className="option">
                    <span className="dot-icon"></span>
                    <span className="dot-icon"></span>
                    <span className="dot-icon"></span>
                  </div>
                </div>} align="end">
                  <WBDropdownOption title={"Edit"} func={() => { serviceSelected(serviceType.id); editFunc(); }} />
                  <WBDropdownOption title={"Delete"} func={deleteUser} />
                </WBDropdown>
              </div>
            </div>
          ))
        );
      } else {
        formatServiceTypes(<div>No items found</div>);
      }
    } else {
      formatServiceTypes(<div>No items found</div>);
    }
  }, [serviceTypes]);
  return (
    <div className="body" id="bodyData">
      {serviceTypesFormatted}
    </div>
  );
};

export default ServiceTypes;
