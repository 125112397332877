import { useEffect, useState } from "react";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";

const EditUtility = ({ closeModalMethod, editedID }) => {
    const [utilID, setUtilID] = useState();
    const [utilName, setUtilName] = useState();
    const [utilCode, setUtilCode] = useState();
    const [utilCost, setUtilCost] = useState();
    const [utilStatus, setUtilStatus] = useState(false);
    const [utilDesc, setUtilDesc] = useState();

    const [changesMade, setChangesMade] = useState(false);

    const toggleUtilStatus = () => setUtilStatus(status => { let result = !status; alert("status changes: " + result); return result });

    useEffect(() => {
        const GetUtility = async () => {
            const response = await PostDB({
                branch: "/getUtility",
                json: {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ util_id: editedID })
                }
            });
            if (typeof response !== 'string') {
                setUtilID(response.id);
                setUtilCode(response.code);
                setUtilName(response.title);
                setUtilDesc(response.description);
                setUtilCost(response.cost);
                setUtilStatus(response.active === 1 ? true : false);
            }
            else {
                alert("Could not retrieve utility with ID: " + editedID);
                closeModalMethod();
            }
        }

        GetUtility();
    }, []);

    const SaveUtility = async () => {
        let bodyObj = {
            id: utilID,
            code: utilCode,
            title: utilName,
            description: utilDesc,
            cost: utilCost,
            active: utilStatus === true ? 1 : 0
        }
        // let response = "Success";
        let response = await PostDB({
            branch: "/editUtility", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            alert("Utility has been successfully added");
            closeModalMethod();
        }
        else {
            alert("There was an error saving this Utility. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Utility</b></h4>
                        </div>
                        <div className="button-row right gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="utilActive" className="form-label">
                                        Utility Active?
                                    </label>
                                    <div className="p-10">
                                        <WBIOSToggle active={utilStatus} func={() => toggleUtilStatus()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="utilCode" className="form-label">
                                        Utility Code
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="utilCode"
                                        className="half"
                                        id="utilCode"
                                        placeholder="enter utility code E.g. C12345"
                                        defaultValue={utilCode}
                                        onChange={(e) => { setChangesMade(true); setUtilCode(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="utilName" className="form-label">
                                        Utility Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="utilName"
                                        className="half"
                                        id="utilName"
                                        placeholder="enter utility name"
                                        defaultValue={utilName}
                                        onChange={(e) => { setChangesMade(true); setUtilName(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="utilDesc" className="form-label">
                                        Utility Description
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <textarea
                                        name="utilDesc"
                                        className="half"
                                        id="utilDesc"
                                        placeholder="enter utility description"
                                        defaultValue={utilDesc}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setUtilDesc(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="utilCost" className="form-label">
                                        Utility Cost
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="utilCost"
                                        className="half"
                                        id="utilCost"
                                        placeholder="enter utility cost"
                                        defaultValue={utilCost}
                                        onChange={(e) => { setChangesMade(true); setUtilCost(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom bg-white p-20 gap-10 bottom-sticky">
                        <WBButton title="Save" color={"green"} func={() => SaveUtility()} />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditUtility;