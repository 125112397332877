export const initialPermissions = [
	{
		title: "Create Jobs",
		description: "Display on the scheduler & accept jobs",
		isEnabled: true,
		hasTitle: true,
	},
	{
		title: "Inventory",
		description: "Truck Inventory, truck restocking, materials used",
		isEnabled: true,
		hasTitle: true,
	},
	{
		type: "header",
		text: "Accounting",
	},
	{
		text: "Push to Xero",
		isEnabled: false,
		hasTitle: false,
	},
	{
		text: "Timesheet Payroll",
		isEnabled: false,
		hasTitle: false,
	},
	{
		text: "Accounts Receivable",
		isEnabled: false,
		hasTitle: false,
	},
	{
		text: "Purchase Orders",
		isEnabled: false,
		hasTitle: false,
	},
	{
		text: "Timesheet Report",
		isEnabled: false,
		hasTitle: false,
	},
	{
		text: "Manage Technician Cash Accounts",
		isEnabled: false,
		hasTitle: false,
	},
	{
		text: "Manage User Cash Accounts",
		isEnabled: false,
		hasTitle: false,
	},
	{
		title: "Reports",
		description: "Access to reports",
		isEnabled: false,
		hasTitle: true,
	},
	{
		title: "Flat Rate Pricing",
		description: "Access to flat rate price list",
		isEnabled: false,
		hasTitle: true,
	},
	{
		title: "Timesheet",
		description: "Click in and clock out timesheet for payroll purposes and location",
		isEnabled: true,
		hasTitle: true,
	},
	{
		text: "Account Status",
		isEnabled: true,
		hasTitle: false,
	},
];
