/* eslint-disable react/style-prop-object */
// import { React } from "react";

// This error page is for dev. Change text and style when public. Enjoy comic sans
const ErrorPage = () => {
  return (
    <div id="errorpage">
      <h1
        style={{
          padding: "5%",
          margin: "5%",
          backgroundColor: "red",
          fontSize: "xl-large",
          color: "white",
          fontFamily: "Comic Sans MS",
        }}
      >
        This page does not exist. If you just added a page, make sure its in
        App.js in the Routing Table, and make sure the URL is correct in your
        link!!!.
      </h1>
    </div>
  );
};

export default ErrorPage;
