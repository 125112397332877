import React from 'react';
import styles from './searchmodal.module.css';

const SearchResultFilter = ({ text, selected, onClick }) => {
  return (
    <div 
      className={`${styles.searchResultFilter} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default SearchResultFilter;
