import { useState, useContext } from "react";
import { JobBookingContext } from "../JobBookingContext";
import { PostDB } from "../../../../Components/DBTools";
import { toast } from 'react-toastify';

export const useJobCustomerModals = () => {
  const [query, setQuery] = useState("");
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [matchingCustomers, setMatchingCustomers] = useState([]);
  const [isCreateCustomer, setCreateCustomer] = useState(false);
  const {
    selectedCustomer,
    setSelectedCustomer,
    updateBookingData,
    setCustomerID,
    fetchedData,
    setJobsiteID
  } = useContext(JobBookingContext);

  const postData = async (branch, data, successMessage, errorMessage) => {
    const timeout = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Request timed out after 5 seconds'));
      }, 5000);
    });

    try {
      const response = await Promise.race([
        PostDB({
          branch,
          json: {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          },
        }),
        timeout
      ]);

      if (response) {
        console.log(successMessage, response);
        toast.success(successMessage);
        return response;
      } else {
        console.error(errorMessage, response);
        toast.error(errorMessage);
        return null;
      }
    } catch (error) {
      console.error(`Error ${errorMessage.toLowerCase()}:`, error.message);
      toast.error(`Error: ${error.message || errorMessage}`);
      return null;
    }
  };

  const createCustomer = async (customerData) => {
    const response = await postData(
      "/addNewCustomer",
      customerData,
      "Customer created/updated successfully.",
      "Failed to create/update customer."
    );

    if (response && customerData.cust_id == null) {
      setCustomerID(response.inserted_customer_id);
    }
  };

  const addJobSiteContacts = async (jobSiteContacts) => {
    const response = await postData(
      "/addCustomerJobsite",
      jobSiteContacts,
      "Job site contacts added/updated successfully.",
      "Failed to add/update job site contacts."
    );

    if (response) {
      setJobsiteID(response.jobsite_address_id);
    }
  };

  const addJobBooking = (jobBooking) =>
    postData(
      "/addJobBooking",
      jobBooking,
      "Job booking added successfully.",
      "Failed to add job booking."
    );

  const timeChoices = [
    "7am",
    "7am-1pm",
    "8am-12pm",
    "8am-2pm",
    "10am-2pm",
    "12pm-4pm",
    "2pm-5pm",
    "After Hours",
  ];

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setQuery(userInput);
  };

  const handleSelectAddress = (address) => {
    setQuery(address);
    setMatchingCustomers(
      fetchedData?.customersList.filter((customer) => customer.address === address)
    );
    setSelectedCustomer(null);
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    updateBookingData("customerDetails", customer);
  };

  const handleCreateCustomer = () => {
    setCreateCustomer(true);
  };

  return {
    query,
    filteredAddresses,
    timeChoices,
    matchingCustomers,
    selectedCustomer,
    isCreateCustomer,
    setQuery,
    handleInputChange,
    handleSelectAddress,
    handleSelectCustomer,
    handleCreateCustomer,
    createCustomer,
    addJobSiteContacts,
    addJobBooking,
  };
};
