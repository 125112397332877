import styles from './MaterialsCostSlider.module.css';
import {useJobCard} from '../../context/JobCardContext';

const MaterialsCostSlider = () => {
  const {materialsCost, setMaterialsCost} = useJobCard();

  const handleChange = event => {
    const value = Number(event.target.value);
    const roundedValue = Math.round(value / 100) * 100;
    setMaterialsCost(roundedValue);
  };

  const {materialsCostSlider, sliderContainer, slider, sliderValue, sliderLimits, sliderLimit} =
    styles;

  return (
    <div className={materialsCostSlider}>
      <div className={sliderValue}>${materialsCost.toLocaleString()}</div>
      <div className={sliderContainer}>
        <input
          type="range"
          min="0"
          max="10000"
          step="100"
          value={materialsCost}
          onChange={handleChange}
          className={slider}
        />
      </div>
      <div className={sliderLimits}>
        <span className={sliderLimit}>$0</span>
        <span className={sliderLimit}>$10,000</span>
      </div>
    </div>
  );
};

export default MaterialsCostSlider;
